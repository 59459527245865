
import {
  GET_USER_WALLET_FAIL,
  GET_USER_WALLET_REQUEST,
  GET_USER_WALLET_SUCCESS,
  GET_WALLETS_FAIL,
  GET_WALLETS_REQUEST,
  GET_WALLETS_SUCCESS,
} from "../constants/walletConstants";


export const walletsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_WALLETS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WALLETS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.reverse(),
      };
    case GET_WALLETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const userWalletReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case GET_USER_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_USER_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
