export const GET_PROFILES_REQUEST = "GET_PROFILES_REQUEST";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL";

export const PROFILE_ADD_REQUEST = "PROFILE_ADD_REQUEST";
export const PROFILE_ADD_SUCCESS = "PROFILE_ADD_SUCCESS";
export const PROFILE_ADD_FAIL = "PROFILE_ADD_FAIL";

export const PROFILE_EDIT_REQUEST = "PROFILE_EDIT_REQUEST";
export const PROFILE_EDIT_SUCCESS = "PROFILE_EDIT_SUCCESS";
export const PROFILE_EDIT_FAIL = "PROFILE_EDIT_FAIL";

export const REMOVE_PROFILE_REQUEST = "REMOVE_PROFILE_REQUEST";
export const REMOVE_PROFILE_SUCCESS = "REMOVE_PROFILE_SUCCESS";
export const REMOVE_PROFILE_FAIL = "REMOVE_PROFILE_FAIL";

export const REMOVE_ALL_PROFILES_REQUEST = "REMOVE_ALL_PROFILES_REQUEST";
export const REMOVE_ALL_PROFILES_SUCCESS = "REMOVE_ALL_PROFILES_SUCCESS";
export const REMOVE_ALL_PROFILES_FAIL = "REMOVE_ALL_PROFILES_FAIL";

export const CHOOSE_PROFILE_HANDLER = "CHOOSE_PROFILE_HANDLER";
export const ADD_PROFILE_HANDLER = "ADD_PROFILE_HANDLER";
export const EDIT_PROFILE_HANDLER = "EDIT_PROFILE_HANDLER";
