import React from "react";

function Sports() {
  return (
    <div className="channel-video">
      <div className="message">
        <p className="title">Sports Channels Of Your Choice</p>
        <p className="text">Select any channel to start watching !</p>
      </div>
    </div>
  );
}

export default Sports;
