import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  PROFILE_ADD_FAIL,
  PROFILE_ADD_REQUEST,
  PROFILE_ADD_SUCCESS,
  GET_PROFILES_FAIL,
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  REMOVE_ALL_PROFILES_FAIL,
  REMOVE_ALL_PROFILES_REQUEST,
  REMOVE_ALL_PROFILES_SUCCESS,
  REMOVE_PROFILE_FAIL,
  REMOVE_PROFILE_REQUEST,
  REMOVE_PROFILE_SUCCESS,
  PROFILE_EDIT_SUCCESS,
  PROFILE_EDIT_REQUEST,
  PROFILE_EDIT_FAIL,
} from "../constants/profileConstants";

import axiosPrivate from "../services/api";

export const getProfilesAction = (userId) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROFILES_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/profiles/user`);

    dispatch({
      type: GET_PROFILES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROFILES_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const addProfileAction =
  (profileData, handleRoute) => async (dispatch) => {
    dispatch({
      type: PROFILE_ADD_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/profiles`, profileData);
      dispatch({
        type: PROFILE_ADD_SUCCESS,
        payload: data,
      });
      notifySuccess("Profile created successfully !");
      handleRoute();
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: PROFILE_ADD_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const editProfileAction =
  (profileId, profileData, handleRoute) => async (dispatch) => {
    dispatch({
      type: PROFILE_EDIT_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(
        `/profiles/${profileId}`,
        profileData
      );

      dispatch({
        type: PROFILE_EDIT_SUCCESS,
        payload: data,
      });
      notifySuccess("Profile updated successfuly !");
      handleRoute();
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: PROFILE_EDIT_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteProfileAction =
  (profileId, handleRoute) => async (dispatch) => {
    dispatch({
      type: REMOVE_PROFILE_REQUEST,
    });
    try {
      await axiosPrivate.delete(`/profiles/${profileId}`);
      notifySuccess("Profile Deleted Successfully !");

      dispatch({
        type: REMOVE_PROFILE_SUCCESS,
        payload: profileId,
      });

      handleRoute();
    } catch (err) {
      notifyFailure(err.response.data.message);

      dispatch({
        type: REMOVE_PROFILE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteAllProfilesAction =
  (userId, setLoadingFlag) => async (dispatch) => {
    dispatch({
      type: REMOVE_ALL_PROFILES_REQUEST,
    });
    setLoadingFlag(true);
    try {
      await axiosPrivate.delete(`/profiles/user/${userId}`);
      setLoadingFlag(false);
      notifySuccess("Profiles deleted successfuly !");

      dispatch({
        type: REMOVE_ALL_PROFILES_SUCCESS,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);

      dispatch({
        type: REMOVE_ALL_PROFILES_FAIL,
        payload: err.response.data.message,
      });
    }
  };
