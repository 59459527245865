import React from "react";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";

function PermissionCard({ data, usersWithRoles }) {
  const navigate = useNavigate();
  return (
    <div className="card">
      <div className="card-body">
        <div className="permission-box">
          <div className="head">
            <h3>
              {data.role.slice(0, 1).toUpperCase()}
              {data.role.slice(1, data.role.length)}
            </h3>
            <p>
              Total user's with this role:{" "}
              <span>
                {data.role === "admin"
                  ? usersWithRoles.admins
                  : data.role === "agent"
                  ? usersWithRoles.agents
                  : data.role === "accountant"
                  ? usersWithRoles.accountants
                  : data.role === "manager"
                  ? usersWithRoles.managers
                  : data.role === "editor"
                  ? usersWithRoles.editors
                  : ""}
              </span>
            </p>
          </div>
          {/* <div></div> */}
          <table>
            <tr className="table-head">
              <td className="text-left">Module</td>
              <td>C</td>
              <td>R</td>
              <td>U</td>
              <td>D</td>
            </tr>

            {data.permissions.slice(0, 3).map((x, index) => (
              <tr key={x + index}>
                <td className="text-left">{x.type}</td>
                <td>
                  {x.modules.create ? (
                    <FcCheckmark />
                  ) : (
                    <IoMdClose color="red" />
                  )}
                </td>
                <td>
                  {x.modules.read ? <FcCheckmark /> : <IoMdClose color="red" />}{" "}
                </td>
                <td>
                  {x.modules.update ? (
                    <FcCheckmark />
                  ) : (
                    <IoMdClose color="red" />
                  )}{" "}
                </td>
                <td>
                  {x.modules.delete ? (
                    <FcCheckmark />
                  ) : (
                    <IoMdClose color="red" />
                  )}
                </td>
              </tr>
            ))}
          </table>
          {/* <p>and 4 more...</p> */}
          {data.permissions.slice(3, data.permissions.length).length !== 0 && (
            <p>
              and {data.permissions.slice(3, data.permissions.length).length}{" "}
              more...
            </p>
          )}
          <div>
            <SecondaryButton onClick={() => navigate(`view/${data.id}`)}>
              View Role
            </SecondaryButton>
            <PrimaryButton onClick={() => navigate(`edit/${data.id}`)}>
              Edit Role
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PermissionCard;
