import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Topbar from "../components/topbar";
import LeftSidebar from "../components/leftSidebar";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken } from "../actions/authActions";
import { getPermissionByRoleAction } from "../actions/permissionActions";
import { TailSpin } from "react-loader-spinner";
import { getStatsAction } from "../actions/statsActions";

function AdminLayout({ children }) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth, currRolePermissions } = state;

  const { userInfo, tokens } = auth;
  const { permission, loading, error } = currRolePermissions;

  const [showSidebar, setShowSidebar] = useState(false);

  // useEffect(() => {
  //   if (userInfo && userInfo.tokens) {
  //     dispatch(refreshToken(userInfo.tokens.refresh.token));
  //   }
  // }, []);

  useEffect(() => {
    // if (userInfo && userInfo.tokens) {
    //   dispatch(refreshToken(userInfo.tokens.refresh.token));
    // }
    if (tokens && !userInfo) {
      dispatch(getUserByToken(tokens.refresh.token));
    }
  }, [dispatch, tokens, userInfo]);

  useEffect(() => {
    if (userInfo) {
      dispatch(getPermissionByRoleAction(userInfo.role));
      dispatch(getStatsAction());
    }
  }, [dispatch, userInfo]);

  if ((userInfo && userInfo.role === "user") || !tokens) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <div className="wrapper admin-layout">
      {auth.loading || (!permission && loading) ? (
        <div className="loader">
          <TailSpin color="#000" height={50} width={50} />
        </div>
      ) : error ? (
        <div className="loader">
          <div className="alert alert-danger mt-4" role="alert">
            {error}
          </div>
        </div>
      ) : (
        <>
          <Topbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          <LeftSidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
          <div className="content-page">
            {children}
            {/* <footer className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                {new Date().getFullYear()} &copy; Infisom Developed by{" "}
                <Link to="https://iconictek.com/">Iconictek</Link>
              </div>
              <div className="col-md-6">
                <div className="text-md-end footer-links d-none d-sm-block"></div>
              </div>
            </div>
          </div>
        </footer> */}
          </div>
        </>
      )}
    </div>
  );
}

export default AdminLayout;
