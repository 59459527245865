import React, { useMemo } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import PrimaryButton from "../primaryButton";
import { ThreeDots } from "react-loader-spinner";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CheckoutForm = ({
  setPaymentToken,
  changeHandler,
  paymentStatus,
  disableBack,
  disableChange,
  paymentDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const options = useOptions({
    showIcon: true,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    await stripe.createToken(cardNumberElement).then(function (result) {
      setPaymentToken(result.token);
    });

    // const payload = await stripe.createToken(CardNumberElement);
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-checkout-form">
      <label>
        Email
        <div>
          <input
            type="email"
            placeholder="Email"
            min={1}
            name="email"
            onChange={changeHandler}
            disabled={disableChange}
            value={paymentDetails.email}
          />
        </div>
      </label>
      <label>
        Amount
        <div>
          <input
            type="number"
            placeholder="Amount"
            min={1}
            name="amount"
            onChange={changeHandler}
            disabled={disableChange}
            value={paymentDetails.amount}
          />
        </div>
      </label>
      <label>
        Card number
        <CardNumberElement
          showIcon={true}
          options={options}
          onReady={() => {}}
          onChange={(event) => {}}
          onBlur={() => {}}
          onFocus={() => {}}
        />
      </label>
      <label>
        Expiration date
        <CardExpiryElement
          options={options}
          onReady={() => {}}
          onChange={(event) => {}}
          onBlur={() => {}}
          onFocus={() => {}}
        />
      </label>
      <label>
        CVC
        <CardCvcElement
          options={options}
          onReady={() => {}}
          onChange={(event) => {}}
          onBlur={() => {}}
          onFocus={() => {}}
        />
      </label>
      <PrimaryButton type="submit" disabled={!stripe}>
        {paymentStatus.loading ? (
          <span>
            <ThreeDots
              color="#000000"
              height={20}
              width={50}
              style={{ width: "fit-content", margin: "auto" }}
            />
          </span>
        ) : (
          <span>Pay</span>
        )}
      </PrimaryButton>
      {/* <button type="submit" disabled={!stripe}>
        Pay
      </button> */}
    </form>
  );
};

export default CheckoutForm;
