import React, { useEffect, useLayoutEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { publicRoutes } from "./routes/publicRoutes";
import { adminRoutes } from "./routes/adminRoutes";
import { userRoutes } from "./routes/userRoutes";

import "./styles/index.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { axiosPublic } from "./services/api";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { ToastContainer } from "react-toastify";
import Test from "./screens/test";

function App() {
  // useEffect(() => {
  //   dispatch(getUserDetailsAction())
  // }, [])

  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname]);

  useEffect(() => {
    const postView = async () => {
      await axiosPublic.post("/stats/views");
    };
    if (process.env.NODE_ENV !== "development") {
      postView();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/test" element={<Test />} />
        {publicRoutes.map((route, routeIndex) => {
          if (route.subRoutes) {
            return (
              <Route
                key={route.path + routeIndex}
                path={route.path}
                element={route.element}
              >
                {route.subRoutes.map((subRoute, subRouteIndex) => (
                  <Route
                    key={subRoute.path + subRouteIndex}
                    path={subRoute.path}
                    element={subRoute.element}
                  />
                ))}
              </Route>
            );
          } else {
            return (
              <Route
                key={route.path + routeIndex}
                path={route.path}
                element={route.element}
              />
            );
          }
        })}

        {userRoutes.map((route, routeIndex) => {
          if (route.subRoutes) {
            return (
              <Route
                key={route.path + routeIndex}
                path={route.path}
                element={route.element}
              >
                {route.subRoutes.map((subRoute, subRouteIndex) => {
                  if (subRoute.subRoutes)
                    return (
                      <Route
                        key={subRoute.path + routeIndex}
                        path={subRoute.path}
                        element={subRoute.element}
                      >
                        {subRoute.subRoutes.map(
                          (subSubRoute, subSubRouteIndex) => (
                            <Route
                              key={subSubRoute.path + subSubRouteIndex}
                              path={subSubRoute.path}
                              element={subSubRoute.element}
                            />
                          )
                        )}
                      </Route>
                    );
                  else
                    return (
                      <Route
                        key={subRoute.path + subRouteIndex}
                        path={subRoute.path}
                        element={subRoute.element}
                      />
                    );
                })}
              </Route>
            );
          } else {
            return (
              <Route
                key={route.path + routeIndex}
                path={route.path}
                element={route.element}
              >
                {route.subRoutes.map((subRoute, subRouteIndex) => {
                  if (route.subRoutes)
                    return (
                      <Route
                        key={subRoute.path + routeIndex}
                        path={subRoute.path}
                        element={subRoute.element}
                      >
                        {subRoute.subRoutes.map(
                          (subSubRoute, subSubRouteIndex) => (
                            <Route
                              key={subSubRoute.path + subSubRouteIndex}
                              path={subSubRoute.path}
                              element={subSubRoute.element}
                            />
                          )
                        )}
                      </Route>
                    );
                  else
                    return (
                      <Route
                        key={subRoute.path + subRouteIndex}
                        path={subRoute.path}
                        element={subRoute.element}
                      />
                    );
                })}
              </Route>
            );
          }
        })}

        {adminRoutes.map((route, routeIndex) => {
          if (route.subRoutes) {
            return (
              <Route
                key={route.path + routeIndex}
                path={route.path}
                element={route.element}
              >
                {route.subRoutes.map((subRoute, subRouteIndex) => (
                  <Route
                    key={subRoute.path + subRouteIndex}
                    path={subRoute.path}
                    element={subRoute.element}
                  />
                ))}
              </Route>
            );
          } else {
            return (
              <Route
                key={route.path + routeIndex}
                path={route.path}
                element={route.element}
              />
            );
          }
        })}
      </Routes>
    </>
  );
}

export default App;
