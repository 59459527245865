import React from "react";
import StatsCard from "../../../components/statsCard";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function StatsSection() {
  const navigate=useNavigate();
  const stats = useSelector((state) => state.stats);
  const { data } = stats;

  return (
    <div className="row">
      <StatsCard
        icon={<i className="fe-film avatar-title font-22 text-white" />}
        title="Movies"
        value={data.movies ? data.movies : 0}
        onClick={()=>navigate("/manage/movies")}
      />
      <StatsCard
        icon={<i className="fe-tv avatar-title font-22 text-white" />}
        title="Shows"
        value={data.shows ? data.shows : 0}
        onClick={()=>navigate("/manage/shows")}

      />
      <StatsCard
        icon={
          <i className="dripicons-user-group avatar-title font-22 text-white" />
        }
        title="Users"
        value={data.users ? data.users : 0}
        onClick={()=>navigate("/manage/users")}

      />
      <StatsCard
        icon={<i className="fe-eye avatar-title font-22 text-white" />}
        title="Visitors Today"
        value={0}
      />
    </div>
  );
}

export default StatsSection;
