import React, { useEffect, useState } from "react";
import axiosPrivate from "../../services/api";
import PrimaryButton from "../primaryButton";
import { notifyFailure, notifySuccess } from "../toaster/toast";

function ChangePassword() {
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [prevPasswordSame, setPrevPasswordSame] = useState(false);
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const [passwordDetails, setPasswordDetails] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const changeHandler = (e) => {
    setPasswordDetails({
      ...passwordDetails,
      [e.target.name]: e.target.value,
    });
  };

  const changePasswordHandler = async (e) => {
    e.preventDefault();
    // dispatch(changePasswordAction({}));
    try {
      setStatus({
        loading: true,
      });
      await axiosPrivate.put("/auth/change-password", {
        oldPassword: passwordDetails.oldPassword,
        newPassword: passwordDetails.confirmNewPassword,
      });

      notifySuccess("Password Updated Successfully !");
      setPrevPasswordSame(false);
      setStatus({
        loading: false,
        success: true,
      });
      setPasswordDetails({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      });
    } catch (err) {
      notifyFailure(err.response.data.message);
      setStatus({
        loading: false,
        success: false,
        error: true,
      });
    }
  };

  useEffect(() => {
    if (
      passwordDetails.newPassword.length > 0 &&
      passwordDetails.confirmNewPassword.length > 0
    ) {
      if (passwordDetails.newPassword === passwordDetails.confirmNewPassword) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    } else {
      setPasswordMatch(true);
    }

    if (
      passwordDetails.oldPassword.length > 0 &&
      passwordDetails.newPassword.length > 0
    ) {
      if (passwordDetails.oldPassword === passwordDetails.newPassword) {
        setPrevPasswordSame(true);
      } else {
        setPrevPasswordSame(false);
      }
    } else {
      setPrevPasswordSame(false);
    }
  }, [passwordDetails]);

  return (
    <form onSubmit={changePasswordHandler}>
      <div className="my-account-form-wrap border-bottom-2 white mt--30 pb--30">
        <h3>Password change</h3>
        <div className="row account-input-box">
          <div className="col-md-4 single-input-box mt--15">
            <label htmlFor="">Current Password:</label>
            <input
              type="password"
              name="oldPassword"
              required
              value={passwordDetails.oldPassword}
              onChange={changeHandler}
            />
          </div>
          <div className="col-md-4 single-input-box mt--15">
            <label htmlFor="">New Password:</label>
            <input
              type="password"
              name="newPassword"
              required
              value={passwordDetails.newPassword}
              onChange={changeHandler}
            />
            {!false && (
              <label htmlFor="" className="email-err">
                {prevPasswordSame ? "Old and new password can't be same !" : ""}
              </label>
            )}
          </div>
          <div className="col-md-4 single-input-box mt--15">
            <label htmlFor="">Confirm password</label>
            <input
              type="password"
              name="confirmNewPassword"
              required
              value={passwordDetails.confirmNewPassword}
              onChange={changeHandler}
            />
            {!false && (
              <label htmlFor="" className="email-err">
                {!passwordMatch ? "Password did not match !" : ""}
              </label>
            )}
          </div>
        </div>
        <div className="row account-input-box">
          <div className="col-md-6 single-input-box mt--15">
            <PrimaryButton
              disabled={!passwordMatch}
              loading={status.loading ? status.loading : undefined}
            >
              Update Password
            </PrimaryButton>
          </div>
        </div>
      </div>
    </form>
  );
}

export default ChangePassword;
