import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { cloudfrontUrl } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const EpisodesSection = ({ list, setEpisodesInView }) => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    setEpisodesInView(inView);
  }, [inView]);

  return (
    <div id="episodes" ref={ref} className="show-details-episodes-section">
      <div className="row mx-0 mx-md-3 my-4 mb-0">

        <div className="series-list">
          <div className="section-title-4 st-border-bottom">
            <h2>More Episodes</h2>
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <ul className="seasons-area">
            <li className="active">
              <p>Season 1</p> <span>{list.length} Episodes</span>
            </li>
            {/* <li>
              <p>Season 2</p> <span>9 Episodes</span>
            </li>
            <li>
              <p>Season 3</p> <span>9 Episodes</span>
            </li> */}
          </ul>
        </div>
        <div className="col-lg-8 ">
          <ul className="episodes-area invisible-scroll">
            {list.map((video, i) => (
              <li key={i}>
                <div
                  className="episode-item row"
                  onClick={() => navigate(`/watch/shows/play/${video?.id}`)}
                >
                  <div className="col-lg-4">
                    <LazyLoadImage
                      src={`${cloudfrontUrl}/${video.thumbnailUrl}`}
                      alt=""
                    />
                  </div>
                  <div className="col-lg-8 title-area mt-2">
                    {/* <h5 className="title">{video.title}</h5> */}
                    <p>Episode {video.episodeNumber}</p>
                    <p>{video.duration}</p>
                    {/* <p>
                      As the snow returns to Vermont, the Winter House crew
                      arrives to heat things up.
                    </p> */}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EpisodesSection;
