import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { updateUserAction } from "../../../actions/userActions";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";
import axiosPrivate from "../../../services/api";

function EditUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { users, updateUser } = state;
  const { loading, data, error } = updateUser;

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    password: "",
    role: "",
  });

  const [password, setPassword] = useState("");
  const [isCopied, setIsCopied] = useState("");

  const changeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateUserAction({
        userData: {
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          email: userData.email,
          role: userData.role,
          displayName: `${userData.firstName} ${userData.lastName}`,
        },
        userId: userData.id,
      })
    );
  };

  useEffect(() => {
    if (users.list.length === 0) {
      navigate("/manage/users");
    } else {
      const id = location.pathname.split("/")[4];
      if (id) {
        setUserData(users.list.find((x) => x.id === id));
      }
    }
  }, [location, navigate, users]);

  useEffect(() => {
    if (data) {
      navigate("/manage/users");
    }
  }, [data, navigate]);

  function genPassword() {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var tempPassword = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      tempPassword += chars.substring(randomNumber, randomNumber + 1);
    }
    setPassword(tempPassword);
    notifySuccess("New Password Generated !");

    // document.getElementById("password").value = password;
  }

  // function copyPassword() {
  //   var copyText = document.getElementById("password");
  //   copyText.select();
  //   document.execCommand("copy");
  // }

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(password)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        notifySuccess("Password copied successfully !");
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submitPasswordHandler = async (e) => {
    e.preventDefault();

    e.preventDefault();
    // setStatus({
    //   loading: true,
    // });
    try {
      await axiosPrivate.put(`/auth/change-password-by-admin`, {
        userId: userData.id,
        password,
      });
      // setStatus({
      //   loading: false,
      //   success: true,
      // });
      notifySuccess("Password Updated Successfully !");
      navigate("/manage/users");
    } catch (err) {
      notifyFailure(err.response.data.message);
      // setStatus({
      //   loading: false,
      //   error: err.response.data.message,
      // });
    }
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0"></ol>
              </div>
              <h4 className="page-title">Edit Users</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <form onSubmit={submitHandler}>
              <div className="card">
                <div className="card-body">
                  <h5 className="form-section mb-3 font-24">Edit User</h5>

                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        First Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        onChange={changeHandler}
                        value={userData.firstName}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Last Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        onChange={changeHandler}
                        value={userData.lastName}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">Display Name</label>
                      <input
                        type="text"
                        name="displayName"
                        onChange={changeHandler}
                        value={userData.displayName}
                        className="form-control form-control-solid"
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Phone/Mobile <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        onChange={changeHandler}
                        value={userData.phone}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Email <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={changeHandler}
                        value={userData.email}
                        disabled
                        className="form-control form-control-solid"
                        required
                      />
                    </div>

                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        User Type <sup className="text-danger">*</sup>
                      </label>
                      <select
                        name="role"
                        className="form-control form-select form-select-solid"
                        onChange={changeHandler}
                        value={userData.role}
                        required
                      >
                        <option value="">Select Type...</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="agent">Agent</option>
                        <option value="accountant">Accountant</option>
                        <option value="manager">Manager</option>
                        <option value="editor">Editor</option>
                      </select>
                    </div>

                    {/* <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Password <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="password"
                        onChange={changeHandler}
                        id="drvrPass"
                        className="form-control form-control-solid"
                        aria-describedby="basic-addon2"
                        minlength="6"
                        required
                      />
                    </div> */}
                  </div>
                  {/* <div className="row">

                  </div> */}

                  {error && (
                    <div className="alert alert-danger mt-4" role="alert">
                      {error}
                    </div>
                  )}

                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      <PrimaryButton>
                        {loading ? (
                          <ThreeDots color="#000000" height={20} width={50} />
                        ) : (
                          <>
                            <i className="fe-check-circle me-1"></i> Update
                          </>
                        )}
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={() => navigate("/manage/users")}
                      >
                        <i className="fe-x me-1"></i> Cancel
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <form onSubmit={submitPasswordHandler}>
              <div className="card">
                <div className="card-body">
                  <h5 className="form-section mb-3 font-24">Reset Password</h5>

                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Password <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        // onChange={changeHandler}
                        value={password}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>

                    <div className="col-6 mb-3 d-flex align-items-end">
                      {/* <div className="col-12 text-center"> */}
                      <PrimaryButton
                        className="mb-0 h-fit"
                        onClick={genPassword}
                        button
                      >
                        <i className="fa fa-sharp fa-solid fa-plus me-1"></i>{" "}
                        Generate Password
                      </PrimaryButton>
                      <SecondaryButton
                        className="mb-0 h-fit"
                        onClick={handleCopyClick}
                        type="button"
                        button
                        disabled={password.length === 0}
                      >
                        {isCopied ? (
                          <>
                            <i className="fe-check-circle me-1"></i> Copied
                          </>
                        ) : (
                          <>
                            <i
                              className="fa fa-clipboard me-1"
                              aria-hidden="true"
                            ></i>
                            Copy Password
                          </>
                        )}
                      </SecondaryButton>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      <PrimaryButton disabled={password.length === 0}>
                        {loading ? (
                          <ThreeDots color="#000000" height={20} width={50} />
                        ) : (
                          <>
                            <i className="fe-check-circle me-1"></i> Update
                          </>
                        )}
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={() => navigate("/manage/users")}
                        button
                      >
                        <i className="fe-x me-1"></i> Cancel
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default EditUser;
