import React from "react";
import { Outlet } from "react-router-dom";
import PublicLayout from "../layout/publicLayout";

import HomeV2 from "../screens/publicScreens/homeV2";
import HomeNew from "../screens/userScreens/moviesV2";
import Auth from "../screens/publicScreens/auth/login";
import Register from "../screens/publicScreens/auth/register";
import Pricing from "../screens/pricing";
import Shows from "../screens/shows";
// import Channels from "../screens/channels";
import Contact from "../screens/contact";
import About from "../screens/about";
import VerifyEmail from "../screens/publicScreens/verifyEmail";
import ResetPassword from "../screens/resetPassword";
import NotFound from "../screens/notFound";
import ForgotPassword from "../screens/forgotPassword";
import Test from "../screens/test";

export const publicRoutes = [
  {
    path: "/",
    element: (
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    ),
    subRoutes: [
      { path: "", element: <HomeV2 /> },
      { path: "about", element: <About /> },
      { path: "contact", element: <Contact /> },
      { path: "shows", element: <Shows /> },
      // { path: "channels", element: <Channels /> },
      { path: "pricing", element: <Pricing /> },
      { path: "signin", element: <Auth /> },
      { path: "register", element: <Register /> },
      // { path: "test", element: <Test /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password", element: <ResetPassword /> },
      { path: "verify-email", element: <VerifyEmail /> },
      { path: "home-new", element: <HomeNew /> },
      { path: "404", element: <NotFound /> },
      // { path: "*", element: <NotFound /> },
    ],
  },
];
