import React, { useEffect, useState, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloudfrontUrl } from "../../constants";
import { useSelector } from "react-redux";

function NextVideoPopup({
  setShowNextVideo,
  setNextVideoFlag,
  videoRef,
  suggestedVideo,
  videoType,setLoadVideo
}) {
  const state = useSelector((state) => state);
  const { shows, selectedVideo } = state;
  const { list } = shows;

  const { id } = useParams();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(100);

  useEffect(() => {
    setTimeLeft(
      videoRef?.current?.duration - videoRef?.current?.currentTime < 100
        ? videoRef?.current?.duration - videoRef?.current?.currentTime
        : 100
    );
    // setShowCard(true);

    const interval = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 250);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timeLeft < 2) {
      // clearInterval(interval);
      setLoadVideo(false)
      setShowNextVideo(false);
      setNextVideoFlag(false);
      navigate(
        `/watch/${videoType.toLowerCase()}s/play/${suggestedVideo?.data?.id}`,
        { state: { loadData: false } }
      );


      // goToNext();
    }
  }, [timeLeft]);

  const goToNext = () => {
    setLoadVideo(false)
    navigate(
      suggestedVideo?.directPlay
        ? `/watch/${videoType.toLowerCase()}s/play/${suggestedVideo?.data?.id}`
        : `/watch/${videoType.toLowerCase()}s/${
            list.filter((x) => x.id !== id)?.id
          }`
    );
  };

  
  return (
    <>
      {/* {showCard && ( */}
      <div className="next-video-card" onClick={goToNext}>
        <img src={`${cloudfrontUrl}/${suggestedVideo?.data?.thumbnailUrl}`} />
        <div className="progress">
          <div
            className="progress-bar progress-bar-success"
            role="progressbar"
            aria-valuenow="40"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: timeLeft + "%" }}
          >
            {/* 40% Complete (success) */}
          </div>
        </div>
        <div className="body-area">
          <p className="next-title">Up Next : </p>
          <div className="video-card-title-area">
            <h4>{suggestedVideo?.data?.title}</h4>
            <p>{suggestedVideo?.data?.category}</p>
          </div>
          {/* <div></div> */}
          <button
            onClick={(event) => {
              event.stopPropagation();
              setShowNextVideo(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
      {/* )} */}
    </>
  );
}

export default memo(NextVideoPopup);
