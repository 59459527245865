import {
  FAVOURITE_ADD_SUCCESS,
  GET_FAVOURITE_FAIL,
  GET_FAVOURITE_REQUEST,
  GET_FAVOURITE_SUCCESS,
  REMOVE_ALL_FAVOURITES_SUCCESS,
  REMOVE_FAVOURITE_SUCCESS,
} from "../constants/favouriteConstants";

export const favouritesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_FAVOURITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FAVOURITE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.reverse(),
      };
    case GET_FAVOURITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FAVOURITE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      };
    case REMOVE_FAVOURITE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.video.id !== action.payload),
      };

    case REMOVE_ALL_FAVOURITES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
      };

    default:
      return state;
  }
};
