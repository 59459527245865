import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../../../components/modal";
// import {
//   getPackagesAction,
//   packageDetailsAction,
// } from "../../../actions/packageActions";
import axiosPrivate, { axiosNoAuth, axiosPublic } from "../../../services/api";
import { userRegisterAction } from "../../../actions/authActions";
import ModalContent from "../../../components/modal/modalContent";
import axios from "axios";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = useSelector((state) => state);
  const { userRegister } = state;
  const { loading, userInfo, error } = userRegister;

  const [showModal, setShowModal] = useState({ open: false });
  const [emailStatus, setEmailStatus] = useState("");

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const changeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const checkEmail = async () => {
      setEmailStatus("loading");
      try {
        const { data } = await axiosPublic.post(`/auth/check-email`, {
          email: userData.email,
        });
        if (data.message) {
          setEmailStatus("error");
        } else {
          setEmailStatus("success");
        }

        // localStorage.setItem("userData", JSON.stringify(data));
      } catch (err) {}
    };
    setEmailStatus("");
    if (
      !userInfo &&
      (userData.email.includes("@") || userData.email.includes(".com"))
    ) {
      checkEmail();
    }
  }, [userInfo, userData.email]);

  // useEffect(() => {
  //   if (userInfo) {
  //     setUserData({
  //       ...userInfo,
  //       // package: data.find((x) => x.type === "Basic")
  //     });
  //   } else {
  //     navigate("/auth");
  //   }
  // }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();

    // if (userInfo) {
    //   //   if (userInfo.isEmailVerified) {
    //   //     navigate()

    //   //   } else {
    //   //     sendVerificationEmail();
    //   //   }
    //   dispatch(
    //     packageDetailsAction({
    //       ...userData,
    //       package: list.find((x) => x.id === userData.package),
    //     })
    //   );
    //   navigate("/checkout");
    // } else {
    dispatch(
      userRegisterAction(
        {
          email: userData.email,
          password: userData.password,
          // displayName: `${userData.firstName} ${userData.lastName}`,
          firstName: userData.firstName,
          lastName: userData.lastName,
          phone: userData.phone,
          // role: userData.role,
          // role: "user",
        },
        handleSuccess

        // sendVerificationEmail
      )
    );
    // }
  };

  // const sendVerificationEmail = async (data) => {
  //   try {
  //     await axiosNoAuth.post(`/auth/send-verification-email`, {
  //       email: userData.email,
  //     });
  //     handleSuccess();

  //     // localStorage.setItem("userData", JSON.stringify(data));
  //   } catch (err) {
  //     console.log("err==>", err);
  //   }
  //   // dispatch(
  //   //   sendVerificationEmailAction({
  //   //     email: userInfo.email,
  //   //     token: userInfo.tokens.access.token,
  //   //   })
  //   // );
  // };

  const handleSuccess = () => {
    navigate("/signin");
    // setShowModal({ open: true });
    // localStorage.removeItem("tokens");
  };
  // const sendVerificationEmail = async () => {
  //   try {
  //     await axiosPrivate.post(`/auth/send-verification-email`, {
  //       email: userInfo.email,
  //     });
  //     setShowModal(true);
  //     // localStorage.setItem("userData", JSON.stringify(data));
  //   } catch (err) {}
  //   // dispatch(
  //   //   sendVerificationEmailAction({
  //   //     email: userInfo.email,
  //   //     token: userInfo.tokens.access.token,
  //   //   })
  //   // );
  // };

  // const closeHandler = () => {
  //   setShowModal(false);
  //   navigate("/pricing");
  // };

  // useEffect(() => {
  //   if (auth.newRegisteration) {
  //     dispatch({ type: OLD_REGISTRAION });
  //   }
  // }, [dispatch, auth.newRegisteration]);

  // useEffect(() => {
  //   if (userInfo) {
  //     setUserData({ ...userData, ...userInfo });
  //   }
  // }, [userInfo, userData]);

  // useEffect(() => {
  //   const sendVerificationEmail = async () => {
  //     try {
  //       await axiosPrivate.post(`/auth/send-verification-email`, {
  //         email: userInfo.email,
  //       });
  //       setShowModal(true);
  //       // localStorage.setItem("userData", JSON.stringify(data));
  //     } catch (err) {}
  //     // dispatch(
  //     //   sendVerificationEmailAction({
  //     //     email: userInfo.email,
  //     //     token: userInfo.tokens.access.token,
  //     //   })
  //     // );
  //   };
  //   if (userInfo && !userInfo.isEmailVerified) {
  //     // if (auth.newRegisteration) {
  //     sendVerificationEmail();
  //     dispatch(
  //       packageDetailsAction({
  //         package: list.find((x) => x.id === userData.package),
  //       })
  //     );
  //     // navigate("/checkout")
  //     // }
  //   }
  // }, [dispatch, list, userData.package, userInfo]);

  return (
    <>
      {/* <div className="breadcrumb-area breadcrumb-modify-padding bg-black-3">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="container">
                  <div className="breadcrumb-style-2 center">
                    <h2>Buy Now</h2>
                    <ul className="breadcrumb-list-2 black">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>{userInfo ? "Buy Now" : "Sign up"}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="register-page bg-black section-pt-90 section-pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 m-auto">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list white nav">
                  <Link to="/register">
                    <h4> Register </h4>
                  </Link>
                </div>
                <div className="tab-content">
                  <div className="login-form-container border-black">
                    <div className="login-register-form black-style">
                      <form onSubmit={submitHandler} autoComplete="off">
                        <div className="login-input-box">
                          <div className="row">
                            <div className="form-group col-md-6">
                              <label htmlFor="">
                                First Name <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                value={userData.firstName}
                                onChange={changeHandler}
                                className="form-control form-control-solid"
                                placeholder="First Name"
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="">
                                Last Name <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                value={userData.lastName}
                                onChange={changeHandler}
                                className="form-control form-control-solid"
                                placeholder="Last Name"
                                required
                              />
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="form-group col-md-6">
                              <label htmlFor="">
                                Email <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={changeHandler}
                                className="form-control form-control-solid mb-1"
                                placeholder="Email"
                                required
                                autoComplete="off"
                              />
                              {/* {!userInfo && ( */}
                              <label htmlFor="" className="email-err">
                                {emailStatus === "error"
                                  ? "Email already exist !"
                                  : ""}
                              </label>
                              {/* )} */}
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="">
                                Phone/Mobile{" "}
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                name="phone"
                                value={userData.phone}
                                onChange={changeHandler}
                                className="form-control form-control-solid mb-1"
                                placeholder="Phone"
                                required
                              />
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="form-group col-md-6">
                              <label htmlFor="">
                                Password <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="password"
                                name="password"
                                value={userData.password}
                                onChange={changeHandler}
                                className="form-control form-control-solid"
                                placeholder="Password"
                                required
                                autoComplete="off"
                              />
                            </div>
                            {/* <div className="form-group col-md-6">
                              <label htmlFor="">
                                Confirm Password <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="password"
                                name="password"
                                value={userData.password}
                                onChange={changeHandler}
                                className="form-control form-control-solid"
                                placeholder="Password"
                                required
                                autoComplete="off"
                              />
                            </div> */}
                          </div>
                        </div>
                        <div className="row">
                          {error && (
                            <div
                              className="alert alert-danger mt-4"
                              role="alert"
                            >
                              {error}
                            </div>
                          )}
                          <div className="button-box    ">
                            <button
                              className="register-btn btn"
                              type="submit"
                              disabled={emailStatus === "error"}
                            >
                              {loading ? (
                                <ThreeDots
                                  color="#000000"
                                  height={20}
                                  width={50}
                                />
                              ) : (
                                <span>Next</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <StripeComponent /> */}
      {/* {userInfo && ( */}
      <Modal
        showModal={showModal.open}
        setShowModal={setShowModal}
        type="success"
      >
        <ModalContent email={userInfo ? userInfo.email : ""} />
      </Modal>
      {/* )} */}
      {/* {step === 1 ? (
          <> */}
      {/* <p className="">
          <GoVerified color="green" size={22} className="mx-1" />
          You have successfully registered and verification email has been sent !
        </p>

        <button className="modal-btn btn" onClick={closeHandler}>
          <span>Close</span>
        </button> */}

      {/* </>
        ) : (
          <>
            <p> */}
      {/* <span className="">
            <FeatherIcon icon="alert-triangle" />
          </span> */}
      {/* It seems that your email is not Verified, please verify your email
              to continue !
            </p>
            <div>
              <div className="button-box">
                <button
                  className="modal-btn btn"
                  onClick={sendVerificationEmail}
                >
                  {showLoading ? (
                    <ThreeDots color="#000000" height={20} width={50} />
                  ) : (
                    <span>Verify Now</span>
                  )}
                </button>
                <button className="modal-btn btn secondary mx-2">
                  <span>Close</span>
                </button>
              </div>
            </div>
          </> */}
      {/* )} */}
      {/* </Modal> */}
    </>
  );
}

export default Register;
