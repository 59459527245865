import {} from "../constants";
import {
  DELETE_PERMISSION_SUCCESS,
  GET_PERMISSION_FAIL,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_SUCCESS,
  GET_ROLE_PERMISSION_FAIL,
  GET_ROLE_PERMISSION_REQUEST,
  GET_ROLE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_SUCCESS,
} from "../constants/permissionConstants";

export const permissionsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case GET_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    case DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.id !== action.payload),
      };

    default:
      return state;
  }
};

export const currRolePermissionsReducer = (
  state = { permission: null },
  action
) => {
  switch (action.type) {
    case GET_ROLE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLE_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        permission: action.payload,
      };
    case GET_ROLE_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

