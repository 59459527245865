import React from "react";

function Test() {
  return (
    <div className="d-flex">
      <div style={{ width: "500px", height: "100vh", position: "relative" }}>
        <iframe
          src="https://iframes.5centscdn.com/videojs/hls/aHR0cHM6Ly82bjN5b2U4amw5b2staGxzLWxpdmUuNWNlbnRzY2RuLmNvbS9UcnUvVFYuc3RyZWFtL3BsYXlsaXN0Lm0zdTg="
          frameBorder="0"
          // allow="autoplay; encrypted-media"
          allowFullScreen
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
          }}
        ></iframe>{" "}
      </div>{" "}
      <div style={{ width: "500px", height: "100vh", position: "relative" }}>
        <iframe
          src="https://iframes.5centscdn.com/videojs/hls/aHR0cHM6Ly82bjN5b2U4amw5b2staGxzLWxpdmUuNWNlbnRzY2RuLmNvbS9jbmJjdHZsaXZlL3R2LnN0cmVhbS9wbGF5bGlzdC5tM3U4"
          frameBorder="0"
          // allow="autoplay; encrypted-media"
          allowFullScreen
          style={{
            overflow: "hidden",
            overflowX: "hidden",
            overflowY: "hidden",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
          }}
        ></iframe>{" "}
      </div>{" "}
    </div>
  );
}

export default Test;
