import React from "react";
import PageTitle from "../../../components/pageTitle";
import ChartSection from "./chartSection";
import StatsSection from "./statsSection";
import TablesSection from "./tablesSection";

function Dashboard() {
  return (
    <div className="dashboard content">
      <div className="container-fluid">
        <PageTitle title="Dashboard" />

        <StatsSection />
        <ChartSection />

        <TablesSection />
      </div>
    </div>
  );
}

export default Dashboard;
