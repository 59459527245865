import React, { useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import { UPDATE_PACKAGE_SUCCESS } from "../../../constants";
import axiosPrivate from "../../../services/api";

function EditPricingModal({ selectedPackage, setSelectedPackage }) {
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");

  const closeBtnRef = useRef();

  const submitHandler = async (e) => {
    e.preventDefault();

    const packageId = selectedPackage.id;
    let tempPkg = selectedPackage;

    delete tempPkg.id;
    setStatus("loading");
    try {
      const { data } = await axiosPrivate.patch(
        `/packages/${packageId}`,
        tempPkg
      );

      dispatch({
        type: UPDATE_PACKAGE_SUCCESS,
        payload: data,
      });
      setStatus("success");
      closeBtnRef.current.click();
    } catch (err) {
      setStatus("error");
      // setError(err.response.data.message);
    }
  };

  return (
    <div
      className="modal fade"
      id="centermodal"
      tabIndex="-1"
      role="dialog"
      aria-modal="true"
    >
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content no-border">
          {selectedPackage && (
            <form className="form" onSubmit={submitHandler}>
              <div className="modal-header">
                <h4 className="modal-title" id="myCenterModalLabel">
                  Edit Pricing Plan
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#centermodal"
                  aria-label="Close"
                  ref={closeBtnRef}
                ></button>
              </div>

              <div className="modal-body py-10 px-lg-17">
                <div
                  className="scroll-y me-n7 pe-7"
                  id="kt_modal_add_customer_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                  data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                  data-kt-scroll-offset="300px"
                >
                  <div className="fv-row mb-3">
                    <label className="required fs-6 fw-bold">Plan Name</label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="type"
                      value={selectedPackage.type}
                      onChange={(e) =>
                        setSelectedPackage({
                          ...selectedPackage,
                          type: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="fv-row mb-3">
                    <label className="required fs-6 fw-bold">Plan Price</label>
                    <input
                      type="number"
                      className="form-control form-control-solid"
                      name="subscriptionFee"
                      value={selectedPackage.subscriptionFee}
                      onChange={(e) =>
                        setSelectedPackage({
                          ...selectedPackage,
                          subscriptionFee: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="fv-row mb-3">
                    <label className="required fs-6 fw-bold">Screens</label>
                    <input
                      type="number"
                      className="form-control form-control-solid"
                      name="numberOfScreens"
                      value={selectedPackage.numberOfScreens}
                      onChange={(e) =>
                        setSelectedPackage({
                          ...selectedPackage,
                          numberOfScreens: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="fv-row mb-3">
                    <label className="required fs-6 fw-bold">Plan Type</label>
                    <select
                      name="client_id"
                      className="form-control form-select form-select-solid"
                      value={selectedPackage.subscriptionType}
                      onChange={(e) =>
                        setSelectedPackage({
                          ...selectedPackage,
                          subscriptionType: e.target.value,
                        })
                      }
                      required
                    >
                      <option value="">Select</option>
                      <option value="15D">15 Days</option>
                      <option value="Month">1 Month</option>
                      <option value="3M">3 Month</option>
                      <option value="6M">6 Month</option>
                      <option value="Year">1 Year</option>
                    </select>
                  </div>

                  <div className="fv-row mb-3">
                    <label htmlFor="product-description" className="form-label">
                      Features
                    </label>
                    <br />
                    <div className="form-check-inline mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="autoSizingCheck1"
                        checked={selectedPackage.features.hd}
                        onChange={(e) =>
                          setSelectedPackage({
                            ...selectedPackage,
                            features: {
                              ...selectedPackage.features,
                              hd: e.target.checked,
                            },
                          })
                        }
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor="autoSizingCheck1"
                      >
                        HD available
                      </label>
                    </div>
                    <div className="form-check-inline mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="autoSizingCheck2"
                        checked={selectedPackage.features.uhd}
                        onChange={(e) =>
                          setSelectedPackage({
                            ...selectedPackage,
                            features: {
                              ...selectedPackage.features,
                              uhd: e.target.checked,
                            },
                          })
                        }
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor="autoSizingCheck2"
                      >
                        Ultra HD available
                      </label>
                    </div>
                    <div className="form-check-inline mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="autoSizingCheck3"
                        checked={selectedPackage.features.watchOnLaptop}
                        onChange={(e) =>
                          setSelectedPackage({
                            ...selectedPackage,
                            features: {
                              ...selectedPackage.features,
                              watchOnLaptop: e.target.checked,
                            },
                          })
                        }
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor="autoSizingCheck3"
                      >
                        Watch on your laptop
                      </label>
                    </div>
                    <div className="form-check-inline mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="autoSizingCheck4"
                        checked={selectedPackage.features.watchOnTv}
                        onChange={(e) =>
                          setSelectedPackage({
                            ...selectedPackage,
                            features: {
                              ...selectedPackage.features,
                              watchOnTv: e.target.checked,
                            },
                          })
                        }
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor="autoSizingCheck4"
                      >
                        Watch on your TV
                      </label>
                    </div>
                    <div className="form-check-inline mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="autoSizingCheck5"
                        checked={selectedPackage.features.unlimited}
                        onChange={(e) =>
                          setSelectedPackage({
                            ...selectedPackage,
                            features: {
                              ...selectedPackage.features,
                              unlimited: e.target.checked,
                            },
                          })
                        }
                      />
                      <label
                        className="form-check-label mx-1"
                        htmlFor="autoSizingCheck5"
                      >
                        Unlimited movies, TV shows
                      </label>
                    </div>
                  </div>

                  <div className="fv-row mb-3">
                    <label className="required fs-6 fw-bold">Status</label>
                    <select
                      name="client_id"
                      className="form-control form-select form-select-solid"
                      value={selectedPackage.active}
                      onChange={(e) =>
                        setSelectedPackage({
                          ...selectedPackage,
                          active: e.target.value,
                        })
                      }
                      required
                    >
                      {/* <option value="">Select</option> */}
                      <option value={true}>Active</option>
                      <option value={false}>In-Active</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer flex-center">
                <SecondaryButton
                  type="reset"
                  data-bs-toggle="modal"
                  data-bs-target="#centermodal"
                  id="kt_modal_add_customer_cancel"
                >
                  Discard
                </SecondaryButton>
                <PrimaryButton>
                  {status === "loading" ? (
                    <ThreeDots color="#fff" height={20} width={50} />
                  ) : (
                    <>Update</>
                  )}{" "}
                </PrimaryButton>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditPricingModal;
