import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { TOGGLE_CHANNEL_SIDEBAR, cloudfrontUrl } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

function HeaderMobile({ navLinks, homeUrl }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { userInfo, profile, tokens } = auth;
  const { pathname } = location;

  const searchInputRef = useRef();
  const [menuFlag, setMenuFlag] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const [searchFlag, setSearchFlag] = useState(false);
  const [lastLocation, setLastLocation] = useState(location.pathname);

  // const logoutHandler = () => {
  //   dispatch(
  //     userLogout({
  //       refreshToken: tokens.refresh.token,
  //     })
  //   );
  // };

  const menuHandler = () => {
    setMenuFlag(!menuFlag);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const listenScrollEvent = () => {
    let header = document.getElementById("header");
    if (document.body.scrollTop < 300) {
      header.classList.add("transparent");
      // return setHeader("header");
    } else if (document.body.scrollTop > 300) {
      header.classList.remove("transparent");
    }
  };

  useEffect(() => {
    document.body.addEventListener("scroll", listenScrollEvent);

    return () => document.body.removeEventListener("scroll", listenScrollEvent);
  }, []);

  // if ((userInfo && userInfo.role !== "user") || !tokens) {
  //   return <Navigate to="/signin" replace />;
  // }

  const searchHandler = (e) => {
    const searchText = e.target.value;
    if (searchText === "") {
      navigate(lastLocation);
    } else {
      navigate(`/watch/search?q=${searchText}`);
    }
  };

  // useEffect(() => {
  //   if (searchFlag) {
  //     setLastLocation(location.pathname);
  //     setTimeout(() => {
  //       searchInputRef.current.focus();
  //     }, 200);
  //   } else {
  //     navigate(lastLocation);
  //     setLastLocation("");
  //   }
  // }, [searchFlag]);

  useEffect(() => {
    if (searchFlag) {
      setLastLocation(location.pathname);
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 200);
    } else {
      navigate(lastLocation);
      // setLastLocation("");
    }
  }, [searchFlag]);

  useEffect(() => {
    if (!location.pathname.includes("search"))
      setLastLocation(location.pathname);
  }, [location.pathname]);

  return (
    <header id="header" className="header-area-v2 navbar-custom mb-2">
      <div className="iftiin-header-section ">
        <div className={`first-cont py-2 ${isTabletOrMobile ? "" : ""}`}>
          <div className="container">
            <div className="row p-0">
              <div className="col-md-12 d-flex justify-content-between align-items-center p-0">
                <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                  <li className="d-flex align-items-center">
                    {!location.pathname.includes("profiles") && (
                      <button
                        // onClick={() => setShowSidebar(!showSidebar)}
                        onClick={() =>
                          dispatch({ type: TOGGLE_CHANNEL_SIDEBAR })
                        }
                        className="button-menu-mobile waves-effect waves-light"
                      >
                        <i className="fe-menu"></i>
                      </button>
                    )}
                    {/* <p className="font-weight-bold text-white">Home</p> */}
                    <Link to={homeUrl}>
                      <LazyLoadImage
                        src={
                          !isTabletOrMobile && location.pathname.includes("tv")
                            ? "https://www.pngmart.com/files/5/Snow-PNG-Transparent-Image.png"
                            : "/assets/new-images/infisom-logo.png"
                        }
                        alt=""
                        height={50}
                      />
                    </Link>{" "}
                  </li>
                </ul>

                {/* <p>Home</p> */}
                {userInfo && (
                  <>
                    <div className="nav-large">
                      {navLinks &&
                        navLinks.map((x) => {
                          if (x.type == "Link")
                            return (
                              <Link to={x.path} className="nav-item ">
                                {x.title}
                              </Link>
                            );
                          else
                            return (
                              <NavLink to={x.path} className="nav-item">
                                {x.title}
                              </NavLink>
                            );
                        })}
                      {/* <Link to="/watch/all" className="nav-item ">
                          Browse
                        </Link>
                        <NavLink to="/watch/all" className="nav-item">
                          Featured
                        </NavLink>
                        <NavLink to="/watch/movies" className="nav-item">
                          Movies
                        </NavLink>
                        <NavLink to="/watch/tv/live" className="nav-item">
                          Live TV
                        </NavLink>
                    
                        <NavLink to="/watch/tv/sports" className="nav-item">
                          Sports
                        </NavLink>
                        <NavLink to="/watch/tv/news" className="nav-item">
                          News
                        </NavLink>
                        <NavLink to="/watch/shows" className="nav-item">
                          Shows
                        </NavLink>
                        <NavLink to="/watch/my-list" className="nav-item">
                          My List
                        </NavLink> */}
                    </div>
                  </>
                )}
                <div className="right-side d-flex">
                  {userInfo ? (
                    <>
                      <div className="header-search">
                        {/* <button className="button-search">
                              <i className="zmdi zmdi-search"></i>
                            </button> */}
                        {searchFlag ? (
                          <div className="search-wrap">
                            <input
                              ref={searchInputRef}
                              placeholder="Search title, genre, etc"
                              type="text"
                              onChange={searchHandler}
                            />
                            <Link className="search-toggle">
                              <i
                                className="zmdi zmdi-close"
                                onClick={() => {
                                  // navigate(lastLocation);
                                  setSearchFlag(false);
                                  // setLastLocation("");
                                }}
                              ></i>
                            </Link>
                          </div>
                        ) : (
                          <div className="search-btn">
                            <Link className="search-toggle">
                              <i
                                className="zmdi zmdi-search"
                                onClick={() => {
                                  setSearchFlag(true);
                                }}
                              ></i>
                            </Link>
                          </div>
                        )}
                      </div>
                      {/*  <div className="notifications-bar btn-group">
                <Link
                  to=""
                  className="notifications-iocn white"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="zmdi zmdi-notifications"></i> <span>5</span>
                </Link>
                <div className="dropdown-menu netflix-notifications-style red">
                  <h5>Notifications</h5>
                  <ul>
                    <li className="single-notifications">
                      <Link to="">
                        <span className="image">
                          <img
                            src="assets/images/review/author-01.png"
                            alt=""
                          />
                        </span>
                        <span className="notific-contents">
                          <span>Lorem ipsum dolor sit amet consectetur.</span>
                          <span className="time">21 hours ago</span>
                        </span>
                      </Link>
                    </li>
                    <li className="single-notifications">
                      <Link to="">
                        <span className="image">
                          <img
                            src="assets/images/review/author-01.png"
                            alt=""
                          />
                        </span>
                        <span className="notific-contents">
                          <span>Lorem ipsum dolor sit amet consectetur.</span>
                          <span className="time">21 hours ago</span>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
                      {/* <li className="dropdown notification-list topbar-dropdown"> */}
                      <Link
                        to="/watch/profiles"
                        onClick={() =>
                          dispatch({
                            type: TOGGLE_CHANNEL_SIDEBAR,
                            payload: false,
                          })
                        }
                      >
                        <div
                          className="our-profile-area b-0"
                          style={{
                            background: profile ? profile.bgColor : "",
                            borderRadius: "50%",
                            padding: 2,
                          }}
                        >
                          {/* <span
                    className="our-profile-pc"

                    // aria-haspopup="true"
                    // aria-expanded="false"
                  > */}
                          <LazyLoadImage
                            src={
                              profile
                                ? profile.mainImage
                                : cloudfrontUrl + "/" + userInfo.profileUrl
                            }
                            width={30}
                            alt=""
                          />
                          {/* </span> */}
                          {/* <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                  <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">
                  Welcome {userInfo.firstName} !
                  </h6>
                  </div>
                  {userInfo.role === "user" ? (
                    <Link to="/watch/all" className="dropdown-item notify-item">
                    <i className="fe-user"></i>
                    <span>My Account</span>
                    </Link>
                    ) : (
                      <Link to="/manage" className="dropdown-item notify-item">
                      <i className="fe-settings"></i>
                      <span>Dashboard</span>
                      </Link>
                      )}
                      
                      <div className="dropdown-divider"></div>
                      <p
                      onClick={logoutHandler}
                      to=""
                      className="dropdown-item notify-item"
                      >
                      <i className="fe-log-out"></i>
                      <span>Logout</span>
                      </p>
                    </div> */}
                        </div>
                      </Link>
                      {/* <div className="our-profile-area ">
                  <NavLink
                    to=""
                    className="our-profile-pc"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="assets/images/review/author-01.png" alt="" />
                  </NavLink>
                  <div className="dropdown-menu netflix-profile-style red">
                    <ul>
                      {userInfo.role === "user" ? (
                        <li className="single-list">
                          <NavLink to="/my-account">My Account</NavLink>
                        </li>
                      ) : (
                        <li className="single-list">
                          <NavLink to="/manage">Dashboard</NavLink>
                        </li>
                      )}
                      <li className="single-list" onClick={logoutHandler}>
                        <NavLink to="">Log Out</NavLink>
                      </li>
                    </ul>
                  </div>
                </div> */}
                    </>
                  ) : (
                    <div className="sign-in-up-btn-wrap">
                      <Link className="sign-in-up-btn-style" to="/signin">
                        Sign In
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderMobile;
