import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChangePassword from "../../../components/changePassword";
import PrimaryButton from "../../../components/primaryButton";
import { UPDATE_USER_INFO, cloudfrontUrl } from "../../../constants";
import axiosPrivate from "../../../services/api";
import { Uploader } from "../../../services/fileUploader";
import { ThreeDots } from "react-loader-spinner";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";

const dummyProfileUrl =
  "https://fiverr-res.cloudinary.com/t_profile_original,q_auto,f_auto/attachments/profile/photo/45a1fa2636189c512f04a6ec84eb9d32-1597032088600/16b4c03a-dcff-4ea8-83a6-33be03e691a6.jpg";

function AccountDetails() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;

  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [profileUrl, setProfileUrl] = useState("");

  useEffect(() => {
    if (userInfo) {
      setUserDetails(userInfo);
      setProfileUrl(userInfo.profileUrl);
    }
  }, [userInfo]);

  const changeHandler = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const infoHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosPrivate.patch(`/users/${userInfo.id}`, {
        ...userDetails,
        profileUrl,
      });
      notifySuccess("Profile details updated successfully !");
      setLoading(false);
      dispatch({ type: UPDATE_USER_INFO, payload: data });
    } catch (err) {
    setLoading(false);

      notifyFailure(err.response.data.message);
      console.log("err=>", err);
    }
  };

  const uploadImage = async (event, path, successHandler) => {
    const file = event.target.files[0];
    let fileName = file.name;
    if (fileName.includes(" ")) {
      fileName = fileName.replaceAll(" ", "-");
    }
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: `${path}/${fileName}`,
      file: file,
    };
    // successHandler(`${path}/${fileName}`);
    // setThumbnailPath(`${path}/${file.name}`);
    const uploader = new Uploader(videoUploaderOptions);
    // setUploader(uploader);
    setUploadLoading(true);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          // setProgress(percentage);
          if (percentage >= 100) {
            setUploadLoading(false);

            successHandler(`${path}/${fileName}`);
          }
        }
      })
      .onError((error) => {
        // setFile(undefined);
        console.error(error);
      });
    // uploader.complete((res) => {
    //   console.log("completed=>", res);
    // });

    uploader.start();
  };


  return (
    <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4 mt-5">
      <div className="container">
        <div className="row">
          <div className="tab-pane">
            <div className="row">
              {userDetails && (
                <div className="col-lg-12">
                  <div className="account-form-container-wrapper">
                    <div className="login">
                      <div className="account-form-container">
                        <div className="account-login-form">
                          <form onSubmit={infoHandler}>
                            <div className="my-account-form-wrap border-bottom-2 white pb--30">
                              <h3>General Information</h3>
                              <div className="our-profile-images mb--30 ">
                                <div className="profile-media">
                                  <div className="edit-profile">
                                    <img
                                      src={
                                        profileUrl !== ""
                                          ? cloudfrontUrl + "/" + profileUrl
                                          : dummyProfileUrl
                                      }
                                      alt=""
                                    />
                                    <div className="btn-file">
                                      {uploadLoading ? (
                                        <ThreeDots
                                          color="#fff"
                                          height={20}
                                          width={50}
                                        />
                                      ) : (
                                        <>
                                          <span className="btn-file-icon">
                                            <i className="zmdi zmdi-camera-bw"></i>
                                          </span>
                                          <p>Upload Image.</p>
                                          <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) =>
                                              uploadImage(
                                                e,
                                                "profileImages",
                                                setProfileUrl
                                              )
                                            }
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="my-3">
                                <h3 className="text-white">
                                  Account #{" "}
                                  <span className="text-danger">
                                    {userInfo.accountNumber}
                                  </span>
                                </h3>
                              </div>
                              <div className="row account-input-box">
                                <div className="col-md-6 single-input-box">
                                  <label htmlFor="">First Name</label>
                                  <input
                                    type="text"
                                    name="firstName"
                                    required
                                    value={userDetails.firstName}
                                    onChange={changeHandler}
                                  />
                                </div>
                                <div className="col-md-6 single-input-box">
                                  <label htmlFor="">Last Name</label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    required
                                    value={userDetails.lastName}
                                    onChange={changeHandler}
                                  />
                                </div>
                                <div className="col-md-6 single-input-box mt--15">
                                  <label htmlFor="">Display Name</label>
                                  <input
                                    type="text"
                                    name="displayName"
                                    required
                                    value={userDetails.displayName}
                                    onChange={changeHandler}
                                  />
                                </div>
                                <div className="col-md-6 single-input-box mt--15">
                                  <label htmlFor="">Email Address</label>
                                  <input
                                    type="email"
                                    name="email"
                                    required
                                    value={userDetails.email}
                                    onChange={changeHandler}
                                  />
                                </div>
                              </div>
                              <div className="row account-input-box">
                                <div className="col-md-6 single-input-box mt--15">
                                  <PrimaryButton loading={loading}>
                                    Save Changes
                                  </PrimaryButton>
                                </div>
                              </div>
                            </div>
                          </form>
                          <ChangePassword />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AccountDetails;
