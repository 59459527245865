import React from "react";

function SecondaryButton(props) {
  return (
    <button
      {...props}
      className={`sign-in-up-btn-style secondary admin ${props.className}`}
      type={props.button ? "button" : "submit"}
    >
      {props.children}
    </button>
  );
}

export default SecondaryButton;
