import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import axiosPrivate from "../../../services/api";
import {
  notifySuccess,
  notifyFailure,
} from "../../../components/toaster/toast";

function AddUsers() {
  const navigate = useNavigate();

  const [status, setStatus] = useState({});

  const { loading, error } = status;

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    displayName: "",
    email: "",
    password: "",
    role: "",
  });

  const changeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setStatus({
      loading: true,
    });
    try {
      await axiosPrivate.post(`/users`, userData);
      setStatus({
        loading: false,
        success: true,
      });
      notifySuccess("User Created Successfully !");
      navigate("/manage/users");
    } catch (err) {
      notifyFailure(err.response.data.message);
      setStatus({
        loading: false,
        error: err.response.data.message,
      });
    }
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0"></ol>
              </div>
              <h4 className="page-title">Add Users</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <form onSubmit={submitHandler}>
              <div className="card">
                <div className="card-body">
                  <h5 className="form-section mb-3 font-24">Add User</h5>

                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        First Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        onChange={changeHandler}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Last Name <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        onChange={changeHandler}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">Display Name</label>
                      <input
                        type="text"
                        name="displayName"
                        onChange={changeHandler}
                        className="form-control form-control-solid"
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Phone/Mobile <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        onChange={changeHandler}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Email <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="email"
                        name="email"
                        onChange={changeHandler}
                        className="form-control form-control-solid"
                        required
                      />
                    </div>
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        Password <sup className="text-danger">*</sup>
                      </label>
                      <input
                        type="text"
                        name="password"
                        onChange={changeHandler}
                        id="drvrPass"
                        className="form-control form-control-solid"
                        aria-describedby="basic-addon2"
                        minlength="6"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6 mb-3">
                      <label htmlFor="">
                        User Type <sup className="text-danger">*</sup>
                      </label>
                      <select
                        name="role"
                        className="form-control form-select form-select-solid"
                        onChange={changeHandler}
                        required
                      >
                        <option value="">Select Type...</option>
                        <option value="user">User</option>
                        <option value="admin">Admin</option>
                        <option value="agent">Agent</option>
                        <option value="accountant">Accountant</option>
                        <option value="manager">Manager</option>
                        <option value="editor">Editor</option>
                      </select>
                    </div>
                  </div>

                  {error && (
                    <div className="alert alert-danger mt-4" role="alert">
                      {error}
                    </div>
                  )}

                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      <PrimaryButton type="submit">
                        {loading ? (
                          <ThreeDots color="#000000" height={20} width={50} />
                        ) : (
                          <>
                            <i className="fe-check-circle me-1"></i> Create
                          </>
                        )}{" "}
                      </PrimaryButton>
                      <SecondaryButton
                        onClick={() => navigate("/manage/users")}
                      >
                        <i className="fe-x me-1"></i> Cancel
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUsers;
