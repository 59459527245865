import React from "react";
import { Link } from "react-router-dom";
import HowItWorks from "./howItWorks";
import WhyUs from "./whyUs";

function About() {
  return (
    <>
      <div className="breadcrumb-area breadcrumb-modify-padding bg-black-2">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-style-2 center">
                  <h2>About Us</h2>
                  <ul className="breadcrumb-list-2 black">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="page-content">
        <WhyUs />
        <HowItWorks />
      </main>
    </>
  );
}

export default About;
