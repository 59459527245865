import React from "react";
import { toast, ToastContainer } from "react-toastify";

export const notifySuccess = (msg) => {
  toast.success(msg, {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return <ToastContainer />;
};

export const notifyFailure = (msg) => {
  toast.error(msg, {
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return <ToastContainer />;
};
