import { GET_USERS_ROLES_SUCCESS } from "../constants";
import {
  CREATE_PERMISSION_FAIL,
  CREATE_PERMISSION_REQUEST,
  CREATE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAIL,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  GET_PERMISSION_FAIL,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_SUCCESS,
  GET_ROLE_PERMISSION_FAIL,
  GET_ROLE_PERMISSION_REQUEST,
  GET_ROLE_PERMISSION_SUCCESS,
  UPDATE_PERMISSION_FAIL,
  UPDATE_PERMISSION_REQUEST,
  UPDATE_PERMISSION_SUCCESS,
} from "../constants/permissionConstants";

import axiosPrivate from "../services/api";

export const getPermissionsAction = () => async (dispatch) => {
  dispatch({
    type: GET_PERMISSION_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/permissions`);

    dispatch({
      type: GET_PERMISSION_SUCCESS,
      payload: data,
    });
    // dispatch({
    //   type: GET_USERS_ROLES_SUCCESS,
    //   payload: data.usersWithRoles,
    // });
  } catch (err) {
    dispatch({
      type: GET_PERMISSION_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const getPermissionByRoleAction =
  (role) => async (dispatch) => {
    dispatch({
      type: GET_ROLE_PERMISSION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/permissions/role`, { role });

      dispatch({
        type: GET_ROLE_PERMISSION_SUCCESS,
        payload: data,
      });
      // dispatch({
      //   type: GET_USERS_ROLES_SUCCESS,
      //   payload: data.usersWithRoles,
      // });
    } catch (err) {
      dispatch({
        type: GET_ROLE_PERMISSION_FAIL,
        payload: err.response.data.message,
      });
    }
  };
export const createPermissionAction =
  ({ permissionData }) =>
  async (dispatch) => {
    dispatch({
      type: CREATE_PERMISSION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/permissions`, permissionData);

      dispatch({
        type: CREATE_PERMISSION_SUCCESS,
        payload: data.sort((a, b) => (a.priority > b.priority ? 1 : -1)),
      });
    } catch (err) {
      dispatch({
        type: CREATE_PERMISSION_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const updatePermissionAction =
  ({ permissionData, permissionId }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_PERMISSION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(
        `/permissions/${permissionId}`,
        permissionData
      );

      dispatch({
        type: UPDATE_PERMISSION_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PERMISSION_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deletePermissionAction =
  ({ permissionId }) =>
  async (dispatch) => {
    dispatch({
      type: DELETE_PERMISSION_REQUEST,
    });
    try {
      await axiosPrivate.delete(`/permissions/${permissionId}`);

      dispatch({
        type: DELETE_PERMISSION_SUCCESS,
        payload: permissionId,
      });
    } catch (err) {
      dispatch({
        type: DELETE_PERMISSION_FAIL,
        payload: err.response.data.message,
      });
    }
  };
