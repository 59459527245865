import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getChannelsAction } from "../../../actions/channelActions";
import ChannelsTable from "../../../components/dataTable/channelsTable";
import { CREATE_USER_RESET } from "../../../constants";
import PrimaryButton from "../../../components/primaryButton";

const tableColumns = ["Name", "Quality", "Category", "Description", "Status"];

function Channels() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { channels, currRolePermissions } = state;
  const { loading, list, error } = channels;
  const { permission } = currRolePermissions;

  const [search, setSearch] = useState("");
  const [type, setType] = useState("other");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getChannelsAction());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      const tempData = list.filter(
        (x) =>
          x.category === type &&
          x.name.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(tempData || []);
    }
  }, [search, list, type]);

  // useEffect(() => {
  //   if (list) {
  //     setFilteredData(list);
  //   }
  // }, [list]);

  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [type])

  return (
    <>
      {permission && (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0"></ol>
                  </div>
                  <h4 className="page-title">Channels List</h4>
                </div>
              </div>
            </div>
            {list.length === 0 && loading ? (
              <div className="loader">
                <TailSpin color="#000" height={50} width={50} />
              </div>
            ) : error ? (
              <div className="loader">
                <div className="alert alert-danger mt-4" role="alert">
                  {error}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <form className="search-bar position-relative mb-sm-0 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="mdi mdi-magnify"></span>
                          </form>
                        </div>
                        <div className="col-auto d-flex align-items-center ">
                          <div className="bg-grey pointer">
                            <button
                              onClick={() => setType("other")}
                              className={`py-2 px-3 ${
                                type === "other"
                                  ? " bg-secondary text-white"
                                  : ""
                              }`}
                            >
                              Live
                            </button>
                            <button
                              onClick={() => setType("news")}
                              className={`py-2 px-3 ${
                                type === "news"
                                  ? " bg-secondary text-white"
                                  : ""
                              }`}
                            >
                              News
                            </button>
                            <button
                              onClick={() => setType("sports")}
                              className={`py-2 px-3 ${
                                type === "sports"
                                  ? " bg-secondary text-white"
                                  : ""
                              }`}
                            >
                              Sports
                            </button>
                          </div>
                        </div>
                        {permission.permissions.find(
                          (x) => x.type == "Channels"
                        )?.modules.create && (
                          <div className="col-md-6">
                            <div className="text-md-end">
                              {/* <Link
                          onClick={() => dispatch({ type: CREATE_USER_RESET })}
                          to="/manage/channels/add"
                          className="btn text-white mi-bgre waves-effect waves-light mb-2 me-2"
                        >
                          <i className="mdi mdi-plus-circle-outline me-1"></i>{" "}
                          Add Channel
                        </Link> */}
                              <PrimaryButton
                                onClick={() => {
                                  dispatch({ type: CREATE_USER_RESET });
                                  navigate("/manage/channels/add");
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1"></i>{" "}
                                Add Channel
                              </PrimaryButton>
                            </div>
                          </div>
                        )}
                      </div>

                      <ChannelsTable
                        modules={
                          permission.permissions.find(
                            (x) => x.type === "Channels"
                          ).modules
                        }
                        tableColumns={tableColumns}
                        data={filteredData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Channels;
