import {
  GET_HISTORY_FAIL,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  REMOVE_ALL_HISTORIES_SUCCESS,
  REMOVE_HISTORY_SUCCESS,
} from "../constants/historyConstants";

export const historiesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.reverse(),
      };
    case GET_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.id !== action.payload),
      };

    case REMOVE_ALL_HISTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
      };
    
    default:
      return state;
  }
};
