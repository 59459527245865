import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getShowsAction } from "../../../actions/videoActions";
import FormLeft from "./FormLeft";
import FormRight from "./FormRight";

function AddShow() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { shows, currRolePermissions } = state;
  const { list } = shows;

  
  const [showDetails, setShowDetails] = useState({});
  // const [loading, setLoading] = useState(false);
  // const [episodesData, setEpisodesData] = useState([
  //   episodeObj,
  //   episodeObj,
  //   episodeObj,
  //   episodeObj,
  // ]);

  // const changeHandler = (e) => {
  //   setShowDetails({
  //     ...showDetails,
  //     [e.target.name]: e.target.value,
  //   });
  // };






  useEffect(() => {
    dispatch(getShowsAction());
  }, [dispatch]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Show Details</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FormLeft />
          </div>
          <div className="col-lg-6">
            <FormRight />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddShow;
