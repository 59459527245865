import {
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
  GET_AGENT_TRANSACTIONS_FAIL,
  GET_AGENT_TRANSACTIONS_REQUEST,
  GET_AGENT_TRANSACTIONS_SUCCESS,
} from "../constants/transactionConstants";

export const transactionsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return {
        loading: true,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const agentTransactionsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REQUEST:
      return {
        loading: true,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.reverse(),
      };
    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
