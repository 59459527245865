import {
  GET_PACKAGES_FAIL,
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
  SAVE_PACKAGE_DETAILS,
  UPDATE_PACKAGE_SUCCESS,
} from "../constants";

export const packagesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_PACKAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    default:
      return state;
  }
};

export const selectedPackageReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_PACKAGE_DETAILS:
      return action.payload;
    default:
      return state;
  }
};
