import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { cloudfrontUrl, UPDATE_USER_RESET } from "../../../constants";
import TablePagination from "../../../components/dataTable/tablePagination";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

function SubscriptionsTable({ tableColumns, data, modules }) {
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // const { auth } = state;
  // const { userInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  // const deleteHandler = (id) => {
  //   dispatch(
  //     deleteMovieAction({
  //       movieId: id,
  //     })
  //   );
  // };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
        <thead className="table-light">
          <tr>
            {/* <th style={{ width: "20px" }}>
              <div className="form-check">
                <label className="form-check-label" for="customCheck1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                  />
                </label>
              </div>
            </th> */}
            {tableColumns.map((column, index) => (
              <th className={column.className} key={column.key + index}>
                {column.title}
              </th>
            ))}
            {modules.delete || modules.update ? (
              <th style={{ width: "82px" }}>Action</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              currentPage * entriesToShow - entriesToShow,
              currentPage * entriesToShow
            )
            .map(
              (subscription, index) =>
                subscription.user && (
                  <tr key={subscription.id + index}>
                    {/* <td>
                  <div className="form-check">
                    <label className="form-check-label" for="customCheck2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck2"
                      />
                    </label>
                  </div>
                </td> */}
                    {/* <td className="name">
                  <div className="media align-items-center">
                    <div className="iq-movie float-start me-2">
                      <Link to="">
                        <LazyLoadImage
                          src={`${cloudfrontUrl}/${movie.thumbnailUrl}`}
                          className="img-border-radius avatar-40 img-fluid movie-thumbnail"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="media-body text-left ml-3 me-2">
                      <p className="mb-0">{movie.title}</p>
                      <small>{movie.duration}</small>
                    </div>
                  </div>
                </td> */}
                    <td>
                      {subscription?.user?.firstName}{" "}
                      {subscription?.user?.lastName}
                    </td>
                    <td>{subscription?.user?.accountNumber}</td>
                    {/* <td>{subscription[tableColumns[2].key]}</td> */}
                    <td>{subscription?.package?.type}</td>
                    <td>${subscription?.package?.subscriptionFee}</td>
                    <td>
                      {subscription?.subscribedOn
                        ? moment(subscription?.subscribedOn).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </td>
                    <td>
                      {moment(subscription?.expiringOn).format("DD-MM-YYYY")}
                    </td>
                    <td>{subscription?.status}</td>

                    {/* <td>{subscription.descriptionText}</td> */}
                    {/* <td>{subscription[tableColumns[7].key]}</td> */}

                    {/* <td>04-04-2022</td>
                <td>20-10-2021</td> */}
                    {/* <td>
                  {user.isEmailVerified ? (
                    <div className="badge badge-soft-success">Verified</div>
                  ) : (
                    <div className="badge badge-soft-danger">Not Verified</div>
                  )}
                </td> */}
                    {modules.delete || modules.update ? (
                      <td className="text-center">
                        {modules.update && (
                          <Link
                            // onClick={() => dispatch({ type: UPDATE_USER_RESET })}
                            to={`/manage/subscriptions/edit/${subscription.id}`}
                            className="action-icon"
                          >
                            <i className="mdi mdi-square-edit-outline"></i>
                          </Link>
                        )}
                        {/* {modules.delete && (
                      <Link
                        onClick={() => deleteHandler(movie.id)}
                        to=""
                        className="action-icon"
                      >
                        <i className="mdi mdi-delete"></i>
                      </Link>
                    )} */}
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                )
            )}
        </tbody>
      </table>

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default SubscriptionsTable;
