import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFavouritesByProfileIdAction } from "../../../actions/favouriteActions";
import VideoCardV2 from "../../../components/cards/videoCardV2";
import { cloudfrontUrl } from "../../../constants";

function MyList() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { auth, favourites } = state;

  const { userInfo, profile } = auth;
  const { loading, list } = favourites;

  const [loadingFlag, setLoadingFlag] = useState(false);

  useEffect(() => {
    if (profile) {
      dispatch(getFavouritesByProfileIdAction(profile.id));
    }
  }, [profile]);

  return (
    <div className="row mt-md-5 mx-1 mx-md-3">
      <div className="col-lg-12">
        <div className="series-list section-ptb-50">
          <div className="section-title-4 st-border-bottom">
            <h2>My List</h2>
          </div>
          <div className="row">
            {list.length === 0 ? (
              <div className="alert alert-info mt-4" role="alert">
                Your List is Empty !
              </div>
            ) : (
              <>
                {list.map((favourite, index) => {
                  if (favourite.video)
                    return (
                      <div
                        key={favourite.id + index}
                        className="col-lg-3 col-md-4 col-6 p-2"
                      >
                        <VideoCardV2
                          key={favourite.id}
                          video={favourite?.video}
                          data={favourite}
                          videoType={favourite.modelNameOfTheVideoType}
                          cloudBaseUrl={cloudfrontUrl}
                        />
                      </div>
                    );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyList;
