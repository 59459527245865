import {
  ADD_PROFILE_HANDLER,
  CHOOSE_PROFILE_HANDLER,
  EDIT_PROFILE_HANDLER,
  GET_PROFILES_FAIL,
  GET_PROFILES_REQUEST,
  GET_PROFILES_SUCCESS,
  PROFILE_ADD_SUCCESS,
  PROFILE_EDIT_SUCCESS,
} from "../constants/profileConstants";

export const profilesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_PROFILES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };

    case PROFILE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [...state.list, action.payload],
      };

    case PROFILE_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    case GET_PROFILES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const selectProfileReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case CHOOSE_PROFILE_HANDLER:
      return {
        ...state,
        data: action.payload,
      };
    case ADD_PROFILE_HANDLER:
      return {
        ...state,
        data: action.payload,
      };
    case EDIT_PROFILE_HANDLER:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
