import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAction } from "../../../actions/categoryActions";
import { getHomePageDataAction } from "../../../actions/pageActions";

import GenreSliderV2 from "../../../components/sliders/genreSliderV2";
import HeroSlider from "../../../components/sliders/heroSlider";
import VideoSlider from "../../../components/sliders/videoSlider";
import { createSearchParams, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

function Browse() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { movies, trendingMovies, categories, shows, favourites, homePage } =
    state;
  const { loading, list, error } = movies;

  const { data } = homePage;

  useEffect(() => {
    dispatch(getHomePageDataAction());
  }, [dispatch]);

  return (
    <div>
      {homePage.loading ? (
        <div className="loader">
          <TailSpin color="#fff" height={50} width={50} />
        </div>
      ) : (
        data && (
          <>
            <HeroSlider
              data={data.slides}
              videoType="Movie"
              noDesc
              videoDetail
            />
            <VideoSlider
              title="Just Added"
              list={data.justAdded}
              delay={4000}
              videoType="Movie"
            />
            <GenreSliderV2
              title="Browse By Genre"
              list={categories.list}
              type="movies"
            />
            <VideoSlider
              title="Your Watchlist"
              list={favourites.list.map((x) => x.video)}
              delay={3000}
              videoType="Movie"
            />
            {/* <VideoSlider
        title="Infisom Picks"
        list={list.slice().reverse()}
        delay={5000}
        videoType="Movie"
      /> */}
            <VideoSlider
              title="Trending Movies"
              list={trendingMovies.list.map((x) => x.video)}
              delay={6000}
              videoType="Movie"
            />
            {data.shows && data.shows.length > 0 && (
              <VideoSlider
                title="Only On Infisom"
                list={data.shows}
                delay={6000}
                videoType="Show"
              />
            )}
            {data.actionMovies && data.actionMovies.length > 0 && (
              <VideoSlider
                title="Action Movies"
                list={data.actionMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="action-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      genre: `Action`,
                    }).toString(),
                  })
                }
              />
            )}
            {data.horrorMovies && data.horrorMovies.length > 0 && (
              <VideoSlider
                title="Horror Movies"
                list={data.horrorMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="horror-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      genre: `Horror`,
                    }).toString(),
                  })
                }
              />
            )}
            {data.comedyMovies && data.comedyMovies.length > 0 && (
              <VideoSlider
                title="Comedy Movies"
                list={data.comedyMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="comedy-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      genre: `Comedy`,
                    }).toString(),
                  })
                }
              />
            )}
            {data.dramaMovies && data.dramaMovies.length > 0 && (
              <VideoSlider
                title="Drama Movies"
                list={data.dramaMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="drama-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      genre: `Drama`,
                    }).toString(),
                  })
                }
              />
            )}
            {data.documentryMovies && data.documentryMovies.length > 0 && (
              <VideoSlider
                title="Documentry Movies"
                list={data.documentryMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="documentry-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      genre: `Documentry`,
                    }).toString(),
                  })
                }
              />
            )}
            {data.kidsMovies && data.kidsMovies.length > 0 && (
              <VideoSlider
                title="Kids Movies"
                list={data.kidsMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="documentry-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      genre: `Kids`,
                    }).toString(),
                  })
                }
              />
            )}
            {data.indianMovies && data.indianMovies.length > 0 && (
              <VideoSlider
                title="Indian Movies"
                list={data.indianMovies}
                delay={3000}
                videoType="Movie"
                keySuffix="indian-movies-"
                showMoreHandler={() =>
                  navigate({
                    pathname: `/watch/movies`,
                    search: createSearchParams({
                      industry: `Bollywood`,
                    }).toString(),
                  })
                }
              />
            )}
          </>
        )
      )}
      {/* <VideoSlider
        title="Only On Infisom"
        list={list}
        delay={3000}
        videoType="Movie"
      /> */}
    </div>
  );
}

export default Browse;
