import React, { useState } from "react";
import { Link } from "react-router-dom";
import TablePagination from "./tablePagination";

function CategoriesTable({ tableColumns, data, modules }) {
  // const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // const { auth } = state;
  // const { userInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  // const [selectedWallet, setSelectedWallet] = useState(null);
  // const [paymentDetails, setPaymentDetails] = useState({
  //   amount: "",
  //   email: "",
  // });
  // const [showModal, setShowModal] = useState({ open: false, type: null });
  // const [paymentToken, setPaymentToken] = useState(null);
  // const [paymentStatus, setPaymentStatus] = useState({});

  // const handlePayment = async () => {
  //   setPaymentStatus({
  //     loading: true,
  //   });
  //   try {
  //     await axiosPrivate.post(`/transactions/pay`, {
  //       token: paymentToken,
  //       payer: auth.userInfo.id,
  //       type: "Wallet",
  //       typeId: selectedWallet.id,
  //       receiver: selectedWallet.user.id,
  //       modelNameOfTypeId: "Wallet",
  //       amount: paymentDetails.amount,
  //       email: paymentDetails.email,
  //     });
  //     setPaymentStatus({
  //       loading: false,
  //     });
  //     setShowModal({ open: false, type: null });
  //     //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
  //     // setStatus("success");
  //   } catch (err) {
  //     setPaymentStatus({
  //       loading: false,
  //       error: err.response.data.message,
  //     });
  //     // setStatus("error");
  //   }
  // };

  // useEffect(() => {
  //   if (paymentToken) {
  //     handlePayment();
  //   }
  // }, [paymentToken]);

  // const changeHandler = (e) => {
  //   setPaymentDetails({
  //     ...paymentDetails,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const clickHandler = (wallet) => {
  //   setSelectedWallet(wallet);
  //   setPaymentDetails({
  //     ...paymentDetails,
  //     email: wallet.user.email,
  //   });
  //   setShowModal({ open: true, type: null });
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setPaymentStatus({
  //     loading: true,
  //   });
  //   try {
  //     await axiosPrivate.post("/transactions", {
  //       payer: userInfo.id,
  //       receiver: selectedWallet.user.id,
  //       type: "Wallet",
  //       typeId: selectedWallet.id,
  //       modelNameOfTypeId: "Wallet",
  //       amount: paymentDetails.amount,
  //       email: userInfo.email,
  //       transactionBy: "Agent",
  //       status: "Completed",
  //     });
  //     dispatch(getWalletsAction());

  //     setPaymentStatus({
  //       loading: false,
  //       success: true,
  //     });
  //     setShowModal({ open: false });
  //   } catch (err) {
  //     setPaymentStatus({
  //       loading: false,
  //       error: err.response.data.message,
  //     });
  //   }
  // };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
        <thead className="table-light">
          <tr>
            {/* <th style={{ width: "20px" }}>
              <div className="form-check">
                <label className="form-check-label" htmlFor="customCheck1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                  />
                </label>
              </div>
            </th> */}
            {tableColumns.map((column, index) => (
              <th key={column + index}>{column}</th>
            ))}
            {modules.delete || modules.update ? (
              <th style={{ width: "82px" }}>Action</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              currentPage * entriesToShow - entriesToShow,
              currentPage * entriesToShow
            )
            .map((category, index) => (
              <tr key={category.id + index}>
                {/* <td>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="customCheck2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck2"
                      />
                    </label>
                  </div>
                </td> */}
                <td>{category.name}</td>
                <td>{category.description}</td>
                <td>20</td>

                <td>{category.addedOn}</td>
                <td>
                  <div className="badge badge-soft-success">
                    {category.status}
                  </div>
                </td>

                <td>
                  <Link
                    to=""
                    className="action-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#centermodal"
                  >
                    <i className="mdi mdi-square-edit-outline"></i>
                  </Link>
                  <Link to="" className="action-icon">
                    <i className="mdi mdi-delete"></i>
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default CategoriesTable;
