import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../actions/authActions";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/primaryButton";
import { useEffect } from "react";

function NotAllowed() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { auth, currUserSubscription } = state;

  const logoutHandler = () => {
    dispatch(
      userLogout({
        refreshToken: auth.tokens.refresh.token,
      })
    );
  };

  const myAccountHandler = () => {
    navigate("/my-account/details");
  };

  useEffect(() => {
    if (currUserSubscription.data?.status === "Active") {
      navigate("/watch/profiles");
    }
  }, [currUserSubscription, navigate]);

  return (
    <div className="profile-page">
      <div className="profile-bg">
        <div className="title full">
          <h3 className="">
            <span color="text-danger">
              <i class="fa fa-ban text-danger" aria-hidden="true"></i>{" "}
            </span>
            Not Allowed!
          </h3>
          <div className="ml-auto">
            <PrimaryButton onClick={myAccountHandler}>
              Account Details
            </PrimaryButton>
            <PrimaryButton onClick={logoutHandler}>Logout</PrimaryButton>
          </div>
        </div>
        <div className="not-allowed-message-container">
          {currUserSubscription.data ? (
            <>
              {currUserSubscription.data.status === "Expired" && (
                <>
                  <h3 className="text-danger">Subscription Expired</h3>
                  <p className="not-allowed-message">
                    Hi {auth?.userInfo?.firstName},
                    <br />
                    Looks like your account ( {auth?.userInfo?.accountNumber} )
                    has expired, please contact the customer service
                    representative.
                  </p>
                  <p className="not-allowed-message text-center">
                    252-615 777888
                  </p>
                  {/* <p className="not-allowed-message">
                    You are not allowed to continue watching Infisom because
                    your subscription is expired. Please go to{" "}
                    <Link to="/my-account/subscription">Subscription</Link> page
                    and subscribe again !
                  </p> */}
                </>
              )}
              {currUserSubscription.data.status === "Inactive" && (
                <>
                  <h3 className="text-danger">Subscription is Not Active</h3>
                  <p className="not-allowed-message">
                    Hi {auth?.userInfo?.firstName},
                    <br />
                    Looks like your account ( {auth?.userInfo?.accountNumber} )
                    is not active, please contact the customer service
                    representative.
                  </p>
                  <p className="not-allowed-message text-center">
                    252-615 777888
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              <h3 className="text-danger">Subscription Not Found</h3>
              <p className="not-allowed-message">
                Hi {auth?.userInfo?.firstName},
                <br />
                Looks like your account ( {auth?.userInfo?.accountNumber} ) is
                not subscribed to any package, please subscribe to a package and
                contact the customer service representative.
              </p>
              <p className="not-allowed-message text-center">252-615 777888</p>
            </>
          )}
        </div>
        <div className="not-allowed-footer-container">
          <p className="not-allowed-message">
            Thank you!
            <br /> <b>Infisom Team</b>
          </p>
        </div>
        {/* Data */}
      </div>
    </div>
  );
}

export default NotAllowed;
