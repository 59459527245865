import React from "react";
// import { useSelector } from "react-redux";

function Live() {
  // const state = useSelector((state) => state);
  // const { auth, currRolePermissions, channels } = state;
  // const { list } = channels;

  // useEffect(() => {}, [list]);

  return (
    <div className="channel-video">
      <div className="message">
        <p className="title">Live TV Channels Of Your Choice</p>
        <p className="text">Select any channel to start watching !</p>
      </div>
    </div>
  );
}

export default Live;
