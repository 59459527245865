import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import axiosPrivate from "../../../services/api";
import { Uploader } from "../../../services/fileUploader";

import { useDispatch, useSelector } from "react-redux";
import { getShowsAction } from "../../../actions/videoActions";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";
import moment from "moment";
import EditShow from "./editShow";

function FormLeft({ data, type }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const state = useSelector((state) => state);
  const { shows, currRolePermissions } = state;
  const { list } = shows;

  const [bgImagePath, setBgImagePath] = useState("");
  const [titleImagePath, setTitleImagePath] = useState("");
  const [thumbnailPath, setThumbnailPath] = useState("");
  const [sliderImagePath, setSliderImagePath] = useState("");

  const [showUploadDetails, setShowUploadDetails] = useState(null);
  const [showDetails, setShowDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const changeHandler = (e) => {
    setShowDetails({
      ...showDetails,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) {
      setShowDetails(data);
      setThumbnailPath(data.thumbnailUrl);
      setBgImagePath(data.bgImageUrl);
      setTitleImagePath(data.titleImageUrl);
      setSliderImagePath(data.sliderImagePath);
    }
  }, [data]);

  const submitHandler = async (e) => {
    e.preventDefault();

    if (type == "Edit") updateShow();
    else addShow();
  };

  const addShow = async () => {
    try {
      setLoading(true);

      await axiosPrivate.post("/shows", {
        ...showDetails,
        thumbnailUrl: thumbnailPath,
        bgImageUrl: bgImagePath,
        titleImageUrl: titleImagePath,
        sliderImageUrl: sliderImagePath,
        // videoUrl: showUploadDetails.path,
        addedOn: moment().format("DD-MM-YYYY"),
      });
      notifySuccess("Show Added Successfully");
      setLoading(false);
      dispatch(getShowsAction());
      // navigate("/manage/shows");
    } catch (err) {
      setLoading(false);
      notifyFailure(err.response.data.message);
    }
  };
  const updateShow = async () => {
    try {
      setLoading(true);

      await axiosPrivate.patch(`/shows/${params.id}`, {
        ...showDetails,
        thumbnailUrl: thumbnailPath,
        bgImageUrl: bgImagePath,
        titleImageUrl: titleImagePath,
        sliderImageUrl: sliderImagePath,
        // videoUrl: showUploadDetails.path,
        addedOn: moment().format("DD-MM-YYYY"),
      });
      notifySuccess("Show Updated Successfully");
      setLoading(false);
      dispatch(getShowsAction());
      // navigate("/manage/shows");
    } catch (err) {
      setLoading(false);
      notifyFailure(err.response.data.message);
    }
  };
  // const uploadThumbnail = async (event) => {
  //   const file = event.target.files[0];
  //   let percentage = undefined;
  //   const videoUploaderOptions = {
  //     fileName: `thumbnails/${file.name}`,
  //     file: file,
  //   };
  //   setThumbnailPath(`thumbnails/${file.name}`);
  //   const uploader = new Uploader(videoUploaderOptions);
  //   // setUploader(uploader);

  //   uploader
  //     .onProgress(({ percentage: newPercentage }) => {
  //       // to avoid the same percentage to be logged twice
  //       if (newPercentage !== percentage) {
  //         percentage = newPercentage;
  //       }
  //     })
  //     .onError((error) => {
  //       console.error(error);
  //     });

  //   uploader.start();
  // };

  const uploadImage = async (event, path, successHandler) => {
    const file = event.target.files[0];
    let fileName = file.name;
    if (fileName.includes(" ")) {
      fileName = fileName?.replaceAll(" ", "-");
    }
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: `${path}/${fileName}`,
      file: file,
    };
    successHandler(`${path}/${fileName}`);
    // setThumbnailPath(`${path}/${file.name}`);
    const uploader = new Uploader(videoUploaderOptions);
    // setUploader(uploader);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          // setProgress(percentage);
        }
      })
      .onError((error) => {
        // setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  const quillChange = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setShowDetails({
      ...showDetails,
      description: content,
      descriptionText: text,
    });
  };

  const renderHTML = () => {
    let tempValue = showDetails?.description?.replaceAll("&lt;", "<");
    tempValue = tempValue?.replaceAll("&rt;", ">");
    return tempValue;
  };

  return (
    <form id="show_form" onSubmit={submitHandler}>
      <div className="card">
        <div className="card-body">
          <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">{type} Show</h5>

          <div className="mb-3">
            <label htmlFor="product-name" className="form-label">
              Show Title <span className="text-danger">*</span>
            </label>

            <input
              type="text"
              className="form-control"
              placeholder="e.g : Money Heist"
              name="title"
              value={showDetails?.title}
              onChange={changeHandler}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="product-categoryq" className="form-label">
              Season Number <span className="text-danger">*</span>
            </label>
            <select
              className="form-control form-select form-select-solid"
              id="product-categoryq"
              onChange={changeHandler}
              name="seasonNumber"
              value={showDetails?.seasonNumber}
            >
              <option selected disabled="">
                Select
              </option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="product-reference" className="form-label">
              Poster Image <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              // onChange={uploadThumbnail}
              onChange={(e) =>
                uploadImage(e, "shows/posterImages", setThumbnailPath)
              }
            />
          </div>

          <div className="mb-3">
            <label htmlFor="product-reference" className="form-label">
              Background Image <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) =>
                uploadImage(e, "shows/backgroundImages", setBgImagePath)
              }
            />
          </div>

          <div className="mb-3">
            <label htmlFor="product-reference" className="form-label">
              Title Image <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) =>
                uploadImage(e, "shows/titleImages", setTitleImagePath)
              }
            />
          </div>

          <div className="mb-3">
            <label htmlFor="product-reference" className="form-label">
              Slider Image <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) =>
                uploadImage(e, "shows/sliderImages", setSliderImagePath)
              }
            />
          </div>

          <div className="mb-3">
            <label htmlFor="product-category" className="form-label">
              Category <span className="text-danger">*</span>
            </label>
            <select
              className="form-control form-select form-select-solid"
              id="product-category"
              name="category"
              onChange={changeHandler}
              value={showDetails.category}
            >
              <option disabled="">Select</option>
              <option value="Comedy">Comedy</option>
              <option value="Crime">Crime</option>
              <option value="Drama">Drama</option>
              <option value="Horror">Horror</option>
              <option value="Romance">Romance</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="product-description" className="form-label">
              Show Description <span className="text-danger">*</span>
            </label>
            <ReactQuill
              theme="snow"
              value={renderHTML()}
              onChange={quillChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="product-categoryq" className="form-label">
              Choose Language <span className="text-danger">*</span>
            </label>
            <select
              className="form-control form-select form-select-solid"
              id="product-categoryq"
              name="language"
              onChange={changeHandler}
              value={showDetails.language}
            >
              <option selected disabled="">
                Select
              </option>
              <option>English</option>
              <option>Somali</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="mb-2">
              Status <span className="text-danger">*</span>
            </label>
            <br />
            <div className="radio form-check-inline">
              <input type="radio" value="Active" name="status" id="status-1" />
              <label htmlFor="status-1"> Active </label>
            </div>
            <div className="radio form-check-inline">
              <input
                type="radio"
                value="Inactive"
                name="status"
                checked={showDetails.status === "Active"}
                onChange={(e) =>
                  setShowDetails({
                    ...showDetails,
                    status: e.target.value,
                  })
                }
              />
              <label htmlFor="status-2"> In-active </label>
            </div>
            <div className="radio form-check-inline">
              <input type="radio" value="Draft" name="status" id="status-3" />
              <label htmlFor="status-3"> Draft </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="text-center mb-3">
              <PrimaryButton>
                {loading ? (
                  <ThreeDots color="#000000" height={20} width={50} />
                ) : (
                  <>
                    <i className="fe-check-circle me-1"></i>{" "}
                    {type == "Edit" ? "Update" : "Create"}
                  </>
                )}{" "}
              </PrimaryButton>
              <SecondaryButton onClick={() => navigate("/manage/shows")}>
                <i className="fe-x me-1"></i> Cancel
              </SecondaryButton>
            </div>
          </div>
        </div>{" "}
      </div>
    </form>
  );
}

export default FormLeft;
