import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { addProfileAction } from "../../../actions/profileActions";

function AddProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { auth, selectedProfile } = state;
  const { userInfo } = auth;

  const { data } = selectedProfile;

  const [name, setName] = useState("");

  const style = {
    normal: {
      background: data.bgColor,
      boxShadow: `0px 0px 400px 77px ${data.bgColor}`,
    },
  };
  if (!data) {
    return <Navigate to="/watch/profiles/choose" />;
  }

  const submitHandler = () => {
    dispatch(
      addProfileAction(
        {
          bgColor: data.bgColor,
          mainImage: data.mainImage,
          textImage: data.textImage,
          user: userInfo.id,
          name,
        },
        handleRoute()
      )
    );
  };

  const handleRoute = () => {
    navigate("/watch/profiles");
  };

  return (
    <div className="profile-page ">
      <div className="profile-bg">
        <div className="title">
          <Link to="" onClick={() => navigate(-1)}>
            <IoIosArrowBack size={28} />
          </Link>
          <h3>What's your profile name ?</h3>
        </div>
        <div className="">
          <div
            className={`edit-profile-img`}
            style={{
              ...style.normal,
            }}
          >
            <img src={data.mainImage} alt="" className="main-image" />
          </div>
        </div>
        <div className="name-input">
          <input
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="submit-btn">
          <button onClick={submitHandler}>Done</button>
        </div>
      </div>
    </div>
  );
}

export default AddProfile;
