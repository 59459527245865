import React from "react";
import { GoMailRead } from "react-icons/go";
import { Link } from "react-router-dom";

function PopupContent() {
  return (
    <>
      <GoMailRead color="#a158a1" size={45} className="mx-1" />
      <h2>Success !</h2>
      <p>
        Your password is successfully updated ! Go back to{" "}
        <Link to="/signin">Login</Link> page.
      </p>
    </>
  );
}

export default PopupContent;
