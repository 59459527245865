import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { cloudfrontUrl, TOGGLE_CHANNEL_SIDEBAR } from "../../constants";
import { useBasePath } from "../../services/hooks";

function MobileSidebar({ navLinks }) {
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = useBasePath();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth, currRolePermissions, channels, sidebars } = state;
  const { userInfo } = auth;
  const { list } = channels;
  const { channelSidebar } = sidebars;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [filteredChannels, setFilteredChannels] = useState([]);

  // const logoutHandler = () => {
  //   dispatch(
  //     userLogout({
  //       refreshToken: tokens.refresh.token,
  //     })
  //   );
  // };

  useEffect(() => {
    if (list.length > 0) {
      if (location.pathname.split("/")[3] == "live") {
        setFilteredChannels(list.filter((x) => x.category == "other"));
        // navigate(
        //   `/watch/tv/${location.pathname.split("/")[3]}/${
        //     list.filter((x) => x.category == "Other")[0].id
        //   }`
        // );
      }

      if (location.pathname.split("/")[3] == "sports") {
        setFilteredChannels(list.filter((x) => x.category == "sports"));
      }

      if (location.pathname.split("/")[3] == "news") {
        setFilteredChannels(list.filter((x) => x.category == "news"));
        // navigate(
        //   `/watch/tv/${location.pathname.split("/")[3]}/${
        //     list.filter((x) => x.category == "News")[0].id
        //   }`
        // );
      }
    }
  }, [list, basePath]);

  useEffect(() => {
    if (filteredChannels.length > 0)
      navigate(
        `/watch/tv/${location.pathname.split("/")[3]}/${filteredChannels[0].id}`
      );
    dispatch({ type: TOGGLE_CHANNEL_SIDEBAR, payload: false });
  }, [filteredChannels]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  // console.log(
  //   "channels sorted ==>",
  //   filteredChannels.sort((a, b) => (a.name < b.name ? -1 : 1))
  // );
  return (
    <>
      <div className={`sidebar-overlay ${channelSidebar ? "show" : ""}`}></div>
      <div
        className={`left-side-menu mobile z-100 ${
          channelSidebar ? "show" : ""
        }`}
      >
        {!isTabletOrMobile && (
          <img
            src={"/assets/new-images/infisom-logo.png"}
            className="mx-2 mb-4"
            alt=""
            height={80}
          />
        )}
        <div className="mb-4 visble-scroll" data-simplebar>
          <div id="sidebar-menu">
            <ul id="side-menu " className="pb-5 mb-5">
              {/* <li>
              <button
                onClick={() => setShowSidebar(!showSidebar)}
                className="button-menu-mobile waves-effect waves-light"
              >
                <i className="fe-menu"></i>
              </button>
            </li> */}
              {/* <li className="category-title font-bold">
              {location?.pathname.split("/")[3]} Channels
            </li> */}
              {navLinks &&
                navLinks.map((x, i) => (
                  <li key={i} className="menuitem">
                    <NavLink
                      to={x.path}
                      onClick={() => dispatch({ type: TOGGLE_CHANNEL_SIDEBAR })}
                      className={`sidebar-nav ${
                        location.pathname.includes(x.id) ? "active" : ""
                      }`}
                      activeClassName="active"
                    >
                      {/* <FeatherIcon icon="airplay" /> */}
                      {/* <span className="badge rounded-pill float-end">4</span> */}
                      <div className="mr-2">
                        <img
                          src={x.icon}
                          width={x.iconWidth}
                          height={x.iconHeight}
                          alt=""
                        />
                      </div>
                      <p> {x.title} </p>
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>

          {/* <div className="clearfix"></div> */}
        </div>
      </div>
    </>
  );
}

export default MobileSidebar;
