import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import { addFavouiteAction } from "../../actions/favouriteActions";
import { cloudfrontUrl } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";

function VideoCardV2({ video, videoType, directPlay, showProgress }) {
  // const auth = useSelector((state) => state.auth);
  // const { userInfo } = auth;

  // const [loading, setLoading] = useState(false);

  // const favouriteHandler = () => {
  //   if (userInfo) {
  //     setLoading(true);
  //     dispatch(
  //       addFavouiteAction(
  //         {
  //           video: video.id,
  //           user: userInfo.id,
  //           modelNameOfTheVideoType: videoType,
  //         },
  //         handleToast
  //       )
  //     );
  //   }
  // };

  // const handleToast = () => {
  //   setLoading(false);
  //   toast("Added to watchlist !");
  // };

  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div
      className={`video-card-v2 ${showProgress ? "top-border" : ""}`}
      // onClick={() => navigate(`/home/${videoType.toLowerCase()}s/${data.id}`)}
    >
      {/* <button
        title="Watchlist"
        className="Watch-list-btn"
        type="button"
        onClick={favouriteHandler}
      >
        {loading ? (
          <ThreeDots color="#fff" height={19} width={19} />
        ) : (
          <i className={`zmdi zmdi-${favourite ? "check" : "plus"}`}></i>
        )}
      </button> */}
      <Link
        to={
          directPlay
            ? `/watch/${videoType.toLowerCase()}s/play/${video?.id}`
            : `/watch/${videoType.toLowerCase()}s/${video?.id}`
        }
      >
        <div>
          <LazyLoadImage
            src={`${cloudfrontUrl}/${video.thumbnailUrl}`}
            alt=""
            afterLoad={() => setImageLoaded(true)}
          />
        </div>
        <div className="text-section">
          <p className="title m-0">{video.title}</p>
          <p>
            {video.category}{" "}
            {videoType !== "Show" &&
              video?.quality !== "" &&
              `- ${video.quality}`}
          </p>
        </div>
      </Link>{" "}
      {showProgress && imageLoaded && (
        <div className="progress-container">
          <div className="progress">
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{ width: video.percentWatched * 100 + "%" }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoCardV2;
