import React from "react";
import { useNavigate } from "react-router-dom";

import { cloudfrontUrl } from "../../constants";
import PrimaryButton from "../primaryButton";
import { FaPlay } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ImageSlide({ data, videoDetail, videoType, noDesc, hidePlayButton }) {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { bg, textImage } = data;

  // const auth = useSelector((state) => state.auth);
  // const { userInfo } = auth;

  // const addFavouriteHandler = () => {
  //   if (userInfo) {
  //     dispatch(
  //       addFavouiteAction({
  //         video: videoId,
  //         user: userInfo.id,
  //         modelNameOfTheVideoType: videoType,
  //       })
  //     );
  //   }
  // };

  // const deleteFavouriteHandler = () => {
  //   dispatch(deleteFavouriteAction(videoId));
  // };

  return (
    <div className="single-hero-img-2">
      <LazyLoadImage
        src={`${cloudfrontUrl}/${data.bgImageUrl}`}
        alt=""
        className="bg"
      />

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <LazyLoadImage
                src={`${cloudfrontUrl}/${data.titleImageUrl}`}
                alt=""
                className="text-img"
              />
              <div className="video-detail-cont">
                <span>
                  <LazyLoadImage
                    src="https://www.peacocktv.com/webwatch/release/prod/3.10.12/assets/audience-score.svg"
                    alt=""
                  />
                  <p>70%</p>
                </span>
                <span>
                  <LazyLoadImage
                    src="https://www.peacocktv.com/webwatch/release/prod/3.10.12/assets/audience-score.svg"
                    alt=""
                  />
                  <p>89%</p>
                </span>
                <span>
                  {data.addedOn} &bull; {data.category} &bull; {data.duration}
                </span>{" "}
              </div>
              {videoDetail && (
                <div className="button-div">
                  {/* {addedToList ? (
                  <PrimaryButton onClick={deleteFavouriteHandler}>
                    <i className="fe-check-circle me-1"></i> My List
                  </PrimaryButton>
                ) : (
                  <PrimaryButton onClick={addFavouriteHandler}>
                    <i className="fe-plus-circle me-1"></i> My List
                  </PrimaryButton>
                )}{" "} */}
                  {!hidePlayButton && (
                    <PrimaryButton
                      onClick={() =>
                        navigate(
                          `/watch/${videoType.toLowerCase()}s/play/${data.id}`
                        )
                      }
                    >
                      <FaPlay size={16} /> Watch Now
                    </PrimaryButton>
                  )}
                  <PrimaryButton
                    onClick={() =>
                      navigate(`/watch/${videoType.toLowerCase()}s/${data.id}`)
                    }
                  >
                    <FaInfoCircle size={16} /> More info
                  </PrimaryButton>
                  {/* <PrimaryButton>Make Like This</PrimaryButton>
                <PrimaryButton>Extras</PrimaryButton> */}
                </div>
              )}
            </div>
            {!noDesc && (
              <div className="col-md-6">
                <p className="desc-text">
                  Sheila discovers a nail salon tanning bed is a time machine
                  and travels back to make her boyfriend more perfect, unaware
                  of how meddling in the past could ruin the future. Starring
                  Kaley Cuoco, Pete Davidson, Deborah S. Craig
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSlide;
