import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { userLogin } from "../../../actions/authActions";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const { loading, userInfo, error } = auth;

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const redirect = location.search.split("=")[1];

  const changeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  // const forgotPassHandler = async() => {
  //   try {
  //     await axiosPrivate.post(`/auth/forgot-password`, {
  //       email: email,
  //     });
  //     setStatus("success");
  //   } catch (err) {
  //     setStatus("error");
  //     setMessage(err.response.data.message);
  //   }

  // };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(userLogin(userData));
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "user") {
        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/watch/profiles");
        }
      } else {
        if (redirect) {
          navigate(redirect);
        } else {
          navigate("/manage");
        }
      }
    }
  }, [userInfo, navigate, redirect]);

  return (
    <div className="register-page bg-black section-pt-90 section-pb-90">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 m-auto">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list white nav">
                <Link
                  className={
                    location.pathname.includes("login") ? "active" : ""
                  }
                  to="/signin"
                >
                  <h4> Login </h4>
                </Link>
              </div>
              <div className="tab-content">
                <div id="lg1" className="tab-pane active">
                  <div className="login-form-container border-black">
                    <div className="login-register-form black-style">
                      <form onSubmit={submitHandler}>
                        <div className="login-input-box">
                          <input
                            type="email"
                            name="email"
                            placeholder="User Email"
                            onChange={changeHandler}
                          />
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={changeHandler}
                          />
                        </div>
                        <div className="button-box">
                          <div className="login-toggle-btn">
                            <input type="checkbox" />
                            <label htmlFor="">Remember me</label>
                            <Link to="/forgot-password">Forgot Password?</Link>
                          </div>
                          {error && (
                            <div
                              className="alert alert-danger mt-4"
                              role="alert"
                            >
                              {error}
                            </div>
                          )}
                          <div className="button-box">
                            <button className="login-btn btn" type="submit">
                              {loading ? (
                                <ThreeDots
                                  color="#000000"
                                  height={20}
                                  width={50}
                                />
                              ) : (
                                <span>Login</span>
                              )}{" "}
                            </button>
                          </div>

                          <div className="text-white my-3">
                            Don't have an account ?{" "}
                            <Link className="text-danger" to="/register">
                              Register Now
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
