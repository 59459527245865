import React from "react";
import { Link } from "react-router-dom";

function MapSection() {
  return (
    <div className="map-wrapper relative">
      <div className="gogle_map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90325.47666039692!2d-93.33151828200064!3d44.970675595414185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b333909377bbbd%3A0x939fc9842f7aee07!2sMinneapolis%2C%20MN%2C%20USA!5e0!3m2!1sen!2s!4v1647955382677!5m2!1sen!2s"
          width="100%"
          height="650"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
      <div className="contact-info-area bg-black-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 ms-auto">
              <div className="contact-info-inner black">
                <div className="single-contact-info">
                  <div className="contact-info-icon">
                    <i className="zmdi zmdi-home"></i>
                  </div>
                  <div className="contact-info-text">
                    <p>
                      {" "}
                      123 apt Kings Jr Street <br /> Minneapolis , Mn{" "}
                    </p>
                  </div>
                </div>

                <div className="single-contact-info">
                  <div className="contact-info-icon">
                    <i className="zmdi zmdi-phone"></i>
                  </div>
                  <div className="contact-info-text">
                    <p>
                      {" "}
                      <Link to="">612-123-4567</Link>
                    </p>
                  </div>
                </div>

                <div className="single-contact-info">
                  <div className="contact-info-icon">
                    <i className="zmdi zmdi-email"></i>
                  </div>
                  <div className="contact-info-text">
                    <p>
                      <Link to="">info@infisom.com</Link>
                    </p>
                  </div>
                </div>

                <div className="single-contact-info">
                  <div className="contact-info-icon">
                    <i className="zmdi zmdi-globe-alt"></i>
                  </div>
                  <div className="contact-info-text">
                    <p>
                      {" "}
                      <Link to="">www.infisom.com</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapSection;
