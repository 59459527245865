import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axiosPrivate from "../../services/api";
import Popup from "../../components/modal";
import PopupContent from "./popupContent";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("Your verification link is expired");

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setStatus("loading");

    try {
      await axiosPrivate.post(`/auth/forgot-password`, {
        email: email,
      });
      setStatus("success");
      setShowModal(true);
      // navigate("/signin")
    } catch (err) {
      setStatus("error");
      setMessage(err.response.data.message);
    }
  };

  return (
    <>
      <div className="breadcrumb-area breadcrumb-modify-padding bg-black-3">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-style-2 center">
                  <h2>Forgot Password</h2>
                  <ul className="breadcrumb-list-2 black">
                    <li>
                      <Link to="/signin">Login</Link>
                    </li>
                    <li>Forgot Password</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="register-page bg-black section-pt-90 section-pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-9 m-auto">
              <div className="login-register-wrapper">
                <div className="login-form-container border-black">
                  <div className="login-register-form black-style">
                    <form onSubmit={submitHandler}>
                      <div className="login-input-box">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label htmlFor="">
                              Email <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={changeHandler}
                              className="form-control form-control-solid"
                              placeholder="Enter your email"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {status === "error" && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {message}
                          </div>
                        )}
                        <div className="button-box">
                          <button className="register-btn btn" type="submit">
                            {status === "loading" ? (
                              <ThreeDots
                                color="#000000"
                                height={20}
                                width={50}
                              />
                            ) : (
                              <span>Submit</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup showModal={showModal} setShowModal={setShowModal}>
        <PopupContent email={email} />
      </Popup>
    </>
  );
}

export default ForgotPassword;
