import React, { useState } from "react";
import TablePagination from "./tablePagination";

function TransactionsTable({ tableColumns, data, modules }) {
  // const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // const { auth } = state;
  // const { userInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  // const [selectedWallet, setSelectedWallet] = useState(null);
  // const [paymentDetails, setPaymentDetails] = useState({
  //   amount: "",
  //   email: "",
  // });
  // const [showModal, setShowModal] = useState({ open: false, type: null });
  // const [paymentToken, setPaymentToken] = useState(null);
  // const [paymentStatus, setPaymentStatus] = useState({});

  // const handlePayment = async () => {
  //   setPaymentStatus({
  //     loading: true,
  //   });
  //   try {
  //     await axiosPrivate.post(`/transactions/pay`, {
  //       token: paymentToken,
  //       payer: auth.userInfo.id,
  //       type: "Wallet",
  //       typeId: selectedWallet.id,
  //       receiver: selectedWallet.user.id,
  //       modelNameOfTypeId: "Wallet",
  //       amount: paymentDetails.amount,
  //       email: paymentDetails.email,
  //     });
  //     setPaymentStatus({
  //       loading: false,
  //     });
  //     setShowModal({ open: false, type: null });
  //     //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
  //     // setStatus("success");
  //   } catch (err) {
  //     setPaymentStatus({
  //       loading: false,
  //       error: err.response.data.message,
  //     });
  //     // setStatus("error");
  //   }
  // };

  // useEffect(() => {
  //   if (paymentToken) {
  //     handlePayment();
  //   }
  // }, [paymentToken]);

  // const changeHandler = (e) => {
  //   setPaymentDetails({
  //     ...paymentDetails,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const clickHandler = (wallet) => {
  //   setSelectedWallet(wallet);
  //   setPaymentDetails({
  //     ...paymentDetails,
  //     email: wallet.user.email,
  //   });
  //   setShowModal({ open: true, type: null });
  // };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
        <thead className="table-light">
          <tr>
            {/* <th style={{ width: "20px" }}>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customCheck1"
                />
                <label
                  htmlFor=""
                  className="form-check-label"
                  htmlFor="customCheck1"
                >
                  &nbsp;
                </label>
              </div>
            </th> */}
            {tableColumns.map((column, index) => (
              <th key={column + index}>{column}</th>
            ))}
            {modules.delete || modules.update ? (
              <th style={{ width: "82px" }}>Action</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              currentPage * entriesToShow - entriesToShow,
              currentPage * entriesToShow
            )
            .map((transaction, index) => (
              <tr key={transaction.id + index}>
                {/* <td>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheck2"
                    />
                    <label
                      htmlFor=""
                      className="form-check-label"
                      htmlFor="customCheck2"
                    >
                      &nbsp;
                    </label>
                  </div>
                </td> */}
                <td>{transaction.id}</td>
                <td>{transaction.typeId?.pin}</td>
                <td>{transaction.receiver?.displayName}</td>

                <td>{transaction.receiver?.email}</td>
                <td>{transaction.receiver?.phone}</td>
                {/* <td>{wallet.user.role}</td> */}
                {/* <td>
                  <div className="badge bg-success">
                    {wallet.user.role === "user" ? "Basic" : ""}
                  </div>
                </td> */}
                {/* <td>04-04-2022</td>
                <td>20-10-2021</td> */}
                <td>${transaction.amount}</td>
                <td>{transaction.status}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default TransactionsTable;
