import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUserByToken } from "../actions/authActions";
import Footer from "../components/footer";
import HeaderV2 from "../components/headerV2";
import { getcurrUserSubscriptionAction } from "../actions/subscriptionActions";
import {
  getMoviesAction,
  getShowsAction,
  getTrendingMoviesAction,
} from "../actions/videoActions";
import { getCategoriesAction } from "../actions/categoryActions";
import { getFavouritesByProfileIdAction } from "../actions/favouriteActions";
import "react-toastify/dist/ReactToastify.css";
import { useMediaQuery } from "react-responsive";
import HeaderMobile from "../components/mobileComponents/headerMobile";
import MobileSidebar from "../components/mobileComponents/mobileSidebar";

// import openSocket from "socket.io-client";
// const socket = openSocket("http://localhost:5000");

const navLinks = [
  {
    title: "Home",
    path: "/watch/all",
    type: "Link",
    icon: "assets/mobile/home.png",
    iconWidth: 15,
    iconHeight: 15,
  },
  {
    title: "Movies",
    path: "/watch/movies",
    icon: "assets/mobile/movies.png",
    iconWidth: 15,
    iconHeight: 15,
  },
  {
    title: "Live TV",
    path: "/watch/tv/live",
    icon: "assets/mobile/live.png",
    iconWidth: 15,
    iconHeight: 15,
  },
  {
    title: "Sports",
    path: "/watch/tv/sports",
    icon: "assets/mobile/sports.png",
    iconWidth: 15,
    iconHeight: 15,
  },
  {
    title: "News",
    path: "/watch/tv/news",
    icon: "assets/mobile/news.png",
    iconWidth: 15,
    iconHeight: 15,
  },
  {
    title: "Shows",
    path: "/watch/shows",
    icon: "assets/mobile/shows.png",
    iconWidth: 15,
    iconHeight: 15,
  },
  {
    title: "My List",
    path: "/watch/my-list",
    icon: "assets/mobile/list.png",
    iconWidth: 15,
    iconHeight: 20,
  },
];

function UserLayout({ children }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { auth, currUserSubscription } = state;
  const { userInfo, profile, tokens } = auth;

  // const { pathname } = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  // sendSocketIO() {
  // socket.emit("example_message", "demo");
  // }

  useEffect(() => {
    function getData() {
      dispatch(getcurrUserSubscriptionAction(userInfo.id));
      dispatch(getFavouritesByProfileIdAction(profile.id));
      dispatch(getMoviesAction());
      dispatch(getTrendingMoviesAction());
      dispatch(getShowsAction());
      dispatch(getCategoriesAction());
    }
    // if (userInfo && userInfo.tokens) {
    //   dispatch(refreshToken(userInfo.tokens.refresh.token));
    // }
    if (tokens && !userInfo) {
      dispatch(getUserByToken(tokens.refresh.token));
    } else if (userInfo && userInfo.role === "user" && profile) {
      getData();
      // socket.emit("login", "userInfo.id");
    }

  }, [dispatch, tokens, userInfo, profile]);


  useEffect(() => {
    if (currUserSubscription.subscriptionChecked) {
      if (
        !currUserSubscription.data ||
        currUserSubscription.data.status !== "Active"
      ) {
        navigate("/watch/not-allowed");
      }
    }
  }, [navigate, currUserSubscription]);

  // useEffect(() => {
  //   if (!profile) navigate("/watch/profiles");
  // }, [navigate, profile]);

  if (!tokens) {
    return <Navigate to="/signin" replace />;
  }
  
  return (
    <>
      {isTabletOrMobile ? (
        <div className="main-wrapper user-layout mobile-layout">
          <HeaderMobile navLinks={navLinks} />
          <MobileSidebar navLinks={navLinks} />
          {children}
        </div>
      ) : (
        <div className="main-wrapper user-layout">
          {location.pathname.includes("profiles") ||
            (!location.pathname.includes("not-allowed") && (
              <HeaderV2 navLinks={navLinks} watchLayout homeUrl="/watch/all" />
            ))}
          {/* <Header /> */}
          {children}
          {!location.pathname.includes("profiles") ||
            (!location.pathname.includes("tv") && <Footer />)}
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>
      )}
    </>
  );
}

export default UserLayout;
