import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, createSearchParams } from "react-router-dom";
import { getMoviesPageDataAction } from "../../../actions/pageActions";
// import { getCategoriesAction } from "../../../actions/categoryActions";
import {
  getMoviesAction,
  // getTrendingMoviesAction,
} from "../../../actions/videoActions";
import HeroSlider from "../../../components/sliders/heroSlider";
import HeroImageSlider from "../../../components/sliders/heroImageSlider";
import VideoCardV2 from "../../../components/cards/videoCardV2";
import VideoSlider from "../../../components/sliders/videoSlider";

import GenreSliderV2 from "../../../components/sliders/genreSliderV2";
// import { getFavouritesByProfileIdAction } from "../../../actions/favouriteActions";
import { cloudfrontUrl } from "../../../constants";
// import {
//   getWatchHistoryListAction,
//   getMoviesWatchHistoryListAction,
// } from "../../../actions/watchHistoryActions";
import { TailSpin } from "react-loader-spinner";

function Movies() {
  const MVideoSlider = React.memo(VideoSlider);
  const MGenreSliderV2 = React.memo(GenreSliderV2);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const query = location.search ? location.search : null;

  const state = useSelector((state) => state);
  const {
    movies,
    //  trendingMovies,
    // categories,
    // auth,
    moviesPage,
  } = state;
  // const { userInfo, profile } = auth;
  const { list, watchHistory } = movies;
  const { data } = moviesPage;

  useEffect(() => {
    dispatch(getMoviesPageDataAction());
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(getCategoriesAction());
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getMoviesAction(query));
    // dispatch(getTrendingMoviesAction());
  }, [dispatch, query]);

  // useEffect(() => {
  //   if (userInfo && profile) {
  //     dispatch(getFavouritesByProfileIdAction(profile.id));
  //     dispatch(getMoviesWatchHistoryListAction(profile.id));
  //   }
  // }, [userInfo, profile]);

  // const { history } = useRouter();
  // useEffect(() => {
  //   return () => {
  //     // && history.location.pathname === "any specific path")
  //     if (history.action === "POP") {
  //       history.replace(history.location.pathname /* the new state */);
  //     }
  //   };
  // }, [history]);

  return (
    <div>
      <>
        {!query ? (
          <>
            {moviesPage.loading ? (
              <div className="loader">
                <TailSpin color="#fff" height={50} width={50} />
              </div>
            ) : (
              data && (
                <>
                  {/* <HeroSlider
                    data={data?.slides}
                    noDesc
                    videoDetail
                    videoType="Movie"
                  /> */}
                  <HeroImageSlider
                    data={data?.slides}
                    noDesc
                    videoDetail
                    videoType="Movie"
                  />
                  {watchHistory && watchHistory.length > 0 && (
                    <VideoSlider
                      title="Continue Watching"
                      list={watchHistory
                        .filter((x) => !x.completed)
                        .map((x) => ({
                          ...x?.video,
                          percentWatched: x.watchedTime / x.totalDuration,
                        }))
                        .slice()
                        .reverse()}
                      delay={4000}
                      videoType="Movie"
                      directPlay
                      dynamicBack
                      backTo="/watch/movies"
                      showProgress
                    />
                  )}
                  {data.justAdded && data.justAdded.length > 0 && (
                    <VideoSlider
                      title="Just Added"
                      list={data.justAdded}
                      delay={4000}
                      videoType="Movie"
                      keySuffix="just-added-"
                      // showMoreHandler={() =>
                      //   navigate({
                      //     pathname: ``,
                      //     search: createSearchParams({
                      //       industry: `Bollywood`,
                      //     }).toString(),
                      //   })
                      // }
                    />
                  )}
                  {data?.categories && data?.categories?.length > 0 && (
                    <MGenreSliderV2
                      title="Browse By Genre"
                      list={data.categories}
                      type="movies"
                    />
                  )}
                  {data.trendingNow && data.trendingNow.length > 0 && (
                    <MVideoSlider
                      title="Trending Now"
                      list={data.trendingNow.map((x) => x.video)}
                      delay={5000}
                      videoType="Movie"
                      keySuffix="trending-now-"
                      // showMore
                    />
                  )}
                  {data.actionMovies && data.actionMovies.length > 0 && (
                    <MVideoSlider
                      title="Action Movies"
                      list={data.actionMovies}
                      delay={3000}
                      videoType="Movie"
                      keySuffix="action-movies-"
                      showMoreHandler={() =>
                        navigate({
                          pathname: ``,
                          search: createSearchParams({
                            genre: `Action`,
                          }).toString(),
                        })
                      }
                    />
                  )}
                  {data.horrorMovies && data.horrorMovies.length > 0 && (
                    <MVideoSlider
                      title="Horror Movies"
                      list={data.horrorMovies}
                      delay={3000}
                      videoType="Movie"
                      keySuffix="horror-movies-"
                      showMoreHandler={() =>
                        navigate({
                          pathname: ``,
                          search: createSearchParams({
                            genre: `Horror`,
                          }).toString(),
                        })
                      }
                    />
                  )}
                  {data.comedyMovies && data.comedyMovies.length > 0 && (
                    <MVideoSlider
                      title="Comedy Movies"
                      list={data.comedyMovies}
                      delay={3000}
                      videoType="Movie"
                      keySuffix="comedy-movies-"
                      showMoreHandler={() =>
                        navigate({
                          pathname: ``,
                          search: createSearchParams({
                            genre: `Comedy`,
                          }).toString(),
                        })
                      }
                    />
                  )}
                  {data.dramaMovies && data.dramaMovies.length > 0 && (
                    <MVideoSlider
                      title="Drama Movies"
                      list={data.dramaMovies}
                      delay={3000}
                      videoType="Movie"
                      keySuffix="drama-movies-"
                      showMoreHandler={() =>
                        navigate({
                          pathname: ``,
                          search: createSearchParams({
                            genre: `Drama`,
                          }).toString(),
                        })
                      }
                    />
                  )}
                  {data.documentryMovies &&
                    data.documentryMovies.length > 0 && (
                      <MVideoSlider
                        title="Documentry Movies"
                        list={data.documentryMovies}
                        delay={3000}
                        videoType="Movie"
                        keySuffix="documentry-movies-"
                        showMoreHandler={() =>
                          navigate({
                            pathname: ``,
                            search: createSearchParams({
                              genre: `Documentry`,
                            }).toString(),
                          })
                        }
                      />
                    )}
                  {data.kidsMovies && data.kidsMovies.length > 0 && (
                    <MVideoSlider
                      title="Kids Movies"
                      list={data.kidsMovies}
                      delay={3000}
                      videoType="Movie"
                      keySuffix="documentry-movies-"
                      showMoreHandler={() =>
                        navigate({
                          pathname: ``,
                          search: createSearchParams({
                            genre: `Kids`,
                          }).toString(),
                        })
                      }
                    />
                  )}
                  {data.indianMovies && data.indianMovies.length > 0 && (
                    <MVideoSlider
                      title="Indian Movies"
                      list={data.indianMovies}
                      delay={3000}
                      videoType="Movie"
                      keySuffix="indian-movies-"
                      showMoreHandler={() =>
                        navigate({
                          pathname: ``,
                          search: createSearchParams({
                            industry: `Bollywood`,
                          }).toString(),
                        })
                      }
                    />
                  )}
                </>
              )
            )}
          </>
        ) : (
          <>
            {movies.loading ? (
              <div className="loader">
                <TailSpin color="#fff" height={50} width={50} />
              </div>
            ) : (
              <div className="row my-lg-5 pt-lg-5 mt-lg-5 mx-1 mx-md-3">
                <div className="col-lg-12">
                  <div className="series-list section-ptb-50">
                    <div className="section-title-4 st-border-bottom">
                      <h2>{query.split("=")[1]} Movies</h2>
                    </div>
                    <div className="row">
                      {list.length === 0 ? (
                        <div className="alert alert-info mt-4" role="alert">
                          {query.split("=")[1]} Movies not found !
                        </div>
                      ) : (
                        <>
                          {list.map((movie, index) => (
                            <div
                              key={movie.id + index}
                              className="col-lg-3 col-md-3 col-sm-4 col-6 p-2"
                            >
                              <VideoCardV2
                                key={movie.id}
                                data={movie}
                                video={movie}
                                videoType="Movie"
                                cloudBaseUrl={cloudfrontUrl}
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

export default Movies;
