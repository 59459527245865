import React from "react";
import { Outlet } from "react-router-dom";
import UserLayout from "../layout/userLayout";

import Wallet from "../screens/myAccountScreens/wallet";
import Browse from "../screens/userScreens/browse";

import Profiles from "../screens/userScreens/profiles";
import AddProfile from "../screens/userScreens/profiles/addProfile";
import EditProfile from "../screens/userScreens/profiles/editProfile";
import ChooseProfiles from "../screens/userScreens/profiles/chooseProfiles";

// import Profiles from "../screens/myAccountScreens/profiles";
// import AddProfile from "../screens/myAccountScreens/profiles/addProfile";
// import EditProfile from "../screens/myAccountScreens/profiles/editProfile";
// import ChooseProfiles from "../screens/myAccountScreens/profiles/chooseProfiles";

import MyList from "../screens/userScreens/myList";
import MoviesV2 from "../screens/userScreens/moviesV2/index";
import MovieDetailsV2 from "../screens/userScreens/moviesV2/movieDetails";
import ShowsV2 from "../screens/userScreens/showsV2/index";
// import ShowDetails from "../screens/userScreens/shows/showDetails";
import IPTV from "../screens/userScreens/iptv/index";
// import ChannelDetails from "../screens/userScreens/channels/channelDetails";
import PlayMovie from "../screens/userScreens/moviesV2/playMovie";
import NotFound from "../screens/notFound";
import PlayChannel from "../screens/userScreens/iptv/playChannel";
import Live from "../screens/userScreens/iptv/live";
import Sports from "../screens/userScreens/iptv/sports";
import News from "../screens/userScreens/iptv/news";
import ShowDetailsV2 from "../screens/userScreens/showsV2/ShowDetailsV2";
import PlayShow from "../screens/userScreens/showsV2/playShow";
import SearchScreen from "../screens/userScreens/search";
import NotAllowed from "../screens/userScreens/notAllowed";

import MyAccount from "../layout/MyAccountLayout";
import AccountDetails from "../screens/myAccountScreens/accountDetails";
import PaymentInfo from "../screens/myAccountScreens/myAccount/paymentInfo";
import Pricing from "../screens/pricing";
import BuyPackage from "../screens/myAccountScreens/buyPackage";
import Subscription from "../screens/myAccountScreens/subscription";
import Checkout from "../screens/myAccountScreens/checkout";

export const userRoutes = [
  // { path: "my-account", element: <MyAccount /> },

  {
    path: "my-account",
    element: (
      <MyAccount>
        <Outlet />
      </MyAccount>
    ),
    subRoutes: [
      // { path: "profiles", element: <Profiles /> },
      // { path: "profiles/choose", element: <ChooseProfiles /> },
      // { path: "profiles/add", element: <AddProfile /> },
      // { path: "profiles/edit/:id", element: <EditProfile /> },
      {
        path: "details",
        element: <AccountDetails />,
      },
      {
        path: "subscription",
        element: <Subscription />,
      },
      { path: "wallet", element: <Wallet /> },
      { path: "pricing", element: <Pricing /> },
      { path: "buy-now", element: <BuyPackage /> },
      { path: "checkout", element: <Checkout /> },
    ],
  },
  {
    path: "watch",
    element: (
      <UserLayout>
        <Outlet />
      </UserLayout>
    ),
    subRoutes: [
      {
        path: "all",
        element: <Browse />,
      },
      { path: "shows", element: <ShowsV2 /> },
      { path: "shows/:id", element: <ShowDetailsV2 /> },
      { path: "shows/play/:id", element: <PlayShow /> },
      {
        path: "tv",
        element: (
          <IPTV>
            <Outlet />
          </IPTV>
        ),
        subRoutes: [
          { path: "live", element: <Live /> },
          { path: "live/:id", element: <PlayChannel /> },
          { path: "sports", element: <Sports /> },
          { path: "sports/:id", element: <PlayChannel /> },
          { path: "news", element: <News /> },
          { path: "news/:id", element: <PlayChannel /> },
        ],
      },
      { path: "movies", element: <MoviesV2 /> },
      { path: "movies/:id", element: <MovieDetailsV2 /> },
      { path: "movies/play/:id", element: <PlayMovie /> },

      { path: "profiles", element: <Profiles /> },
      { path: "profiles/choose", element: <ChooseProfiles /> },
      { path: "profiles/add", element: <AddProfile /> },
      { path: "profiles/edit/:id", element: <EditProfile /> },
      { path: "my-list", element: <MyList /> },
      { path: "search", element: <SearchScreen /> },
      { path: "not-allowed", element: <NotAllowed /> },

      // { path: "*", element: <NotFound /> },
    ],
  },
];
