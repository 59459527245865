export const GET_CATEGORIES_REQUEST = "GET_CATEGORIES_REQUEST";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";

export const CATEGORY_ADD_REQUEST = "CATEGORY_ADD_REQUEST";
export const CATEGORY_ADD_SUCCESS = "CATEGORY_ADD_SUCCESS";
export const CATEGORY_ADD_FAIL = "CATEGORY_ADD_FAIL";

export const REMOVE_CATEGORY_REQUEST = "REMOVE_CATEGORY_REQUEST";
export const REMOVE_CATEGORY_SUCCESS = "REMOVE_CATEGORY_SUCCESS";
export const REMOVE_CATEGORY_FAIL = "REMOVE_CATEGORY_FAIL";

export const REMOVE_ALL_CATEGORIES_REQUEST = "REMOVE_ALL_CATEGORIES_REQUEST";
export const REMOVE_ALL_CATEGORIES_SUCCESS = "REMOVE_ALL_CATEGORIES_SUCCESS";
export const REMOVE_ALL_CATEGORIES_FAIL = "REMOVE_ALL_CATEGORIES_FAIL";
