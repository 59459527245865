import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMovieByIdAction,
  getMoviesAction,
} from "../../../actions/videoActions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeroSlider from "../../../components/sliders/heroSlider";
import VideoSlider from "../../../components/sliders/videoSlider";
import { getFavouritesByProfileIdAction } from "../../../actions/favouriteActions";
import { cloudfrontUrl } from "../../../constants";
import { TailSpin } from "react-loader-spinner";
import { getMovieWatchHistoryByProfileAndVideoId } from "../../../actions/watchHistoryActions";
import VideoCardV2 from "../../../components/cards/videoCardV2";

function MovieDetailsV2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = useSelector((state) => state);
  const { auth, selectedVideo, movies, currUserSubscription, favourites } =
    state;
  const { tokens, userInfo, profile } = auth;
  const { data } = selectedVideo;
  const { list } = movies;

  const id = location.pathname.split("/")[3];

  // useEffect(() => {
  //   dispatch(getMoviesAction());
  // }, []);

  useEffect(() => {
    if (profile) dispatch(getFavouritesByProfileIdAction(profile.id));
  }, [profile]);

  useEffect(() => {
    if (!tokens) {
      navigate(`/signin?redirect=/watch/movies/${id}`);
    } else {
      if (id) {
        if ((location.state && location.state.loadData) || !location.state) {
          dispatch(getMovieByIdAction(id));
          dispatch(
            getMovieWatchHistoryByProfileAndVideoId({
              profile: profile.id,
              video: id,
            })
          );
        } else {
          handleScroll("moreLikeThis");
        }
      } else {
        navigate("/watch/movies");
      }
    }
  }, [id]);

  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", align: false });
      }, 300);
    }
  };

  return (
    <div className="video-details-page">
      {data ? (
        <div className="mb-5">
          <HeroSlider
            data={[data]}
            addedToList={favourites.list.find((x) => x?.video?.id == id)}
            videoDetail
            videoSlide
            videoType="Movie"
            videoId={id}
          />
          {/* {list && (
            <VideoSlider
              title="More like this"
              list={list
                .filter((x) => x.id !== id && x.category == data.category)
                .slice()
                .sort(() => Math.random() - 0.5)}
              delay={5000}
              videoType="Movie"
            />
          )} */}
          <div id="moreLikeThis" className="row mb-5 mx-1 mx-md-3">
            <div className="col-lg-12">
              <div className="series-list section-ptb-50">
                <div className="section-title-4 st-border-bottom">
                  <h2>More Like This</h2>
                </div>
                <div className="row">
                  {/* {shows.list.length === 0 ? (
                    <div className="alert alert-info mt-4" role="alert">
                      {query.slice(0, 1).toUpperCase()}
                      {query.slice(1, query.length)} Movies not found !
                    </div>
                  ) : ( */}
                  <>
                    {list
                      .filter((x) => x.id !== id && x.category == data.category)
                      .slice()
                      .sort(() => Math.random() - 0.5)
                      .map((video, index) => (
                        <div
                          key={video.id + index}
                          className="col-lg-3 col-md-4 col-6 p-2"
                        >
                          <VideoCardV2
                            data={video}
                            video={video}
                            videoType="Movie"
                            cloudBaseUrl={cloudfrontUrl}
                            directPlay
                          />
                        </div>
                      ))}
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <TailSpin color="#fff" height={50} width={50} />
        </div>
      )}
    </div>
  );
}

export default MovieDetailsV2;
