// SUBSCRIPTION Contants
export const CREATE_SUBSCRIPTION_REQUEST = "CREATE_SUBSCRIPTION_REQUEST";
export const CREATE_SUBSCRIPTION_SUCCESS = "CREATE_SUBSCRIPTION_SUCCESS";
export const CREATE_SUBSCRIPTION_FAIL = "CREATE_SUBSCRIPTION_FAIL";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL";

export const GET_USER_SUBSCRIPTION_REQUEST = "GET_USER_SUBSCRIPTION_REQUEST";
export const GET_USER_SUBSCRIPTION_SUCCESS = "GET_USER_SUBSCRIPTION_SUCCESS";
export const GET_USER_SUBSCRIPTION_FAIL = "GET_USER_SUBSCRIPTION_FAIL";
