export const availableProfiles = [
  {
    name: "test",
    mainImage: "/assets/images/profiles/birds-01.png",
    textImage: "/assets/images/profiles/labrea-text.png",
    bgColor: "#F7F700",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/birds-02.png",
    textImage: "/assets/images/profiles/labrea-text.png",
    bgColor: "rgb(184 251 123)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/birds-03.png",
    textImage: "/assets/images/profiles/labrea-text.png",
    bgColor: "rgb(210 154 253)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/birds-04.png",
    textImage: "/assets/images/profiles/labrea-text.png",
    bgColor: "rgb(255 12 0)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/birds-05.png",
    textImage: "/assets/images/profiles/labrea-text.png",
    bgColor: "rgb(207 201 255)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/birds-06.png",
    textImage: "/assets/images/profiles/labrea-text.png",
    bgColor: "rgb(255 120 90)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/wonder.png",
    textImage: "/assets/images/profiles/wonder-text.png",
    bgColor: "rgb(72 229 171)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/vampire.png",
    textImage: "/assets/images/profiles/vampire-text.png",
    bgColor: "rgb(169 169 169)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/ride-along.png",
    textImage: "/assets/images/profiles/ride-along-text.png",
    bgColor: "rgb(255 255 255)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/minions.png",
    textImage: "/assets/images/profiles/minions-text.png",
    bgColor: "rgb(131 255 123)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/mickey-mouse.png",
    textImage: "/assets/images/profiles/mickey-mouse-text.png",
    bgColor: "rgb(87 247 247)",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/invisible-sue.png",
    textImage: "/assets/images/profiles/invisible-sue-text.png",
    bgColor: "#F7F700",
    id: "633340b0d8d36221340a7358",
  },
  {
    name: "test",
    mainImage: "/assets/images/profiles/bilal.png",
    textImage: "/assets/images/profiles/bilal-text.png",
    bgColor: "rgb(247 0 46)",
    id: "633340b0d8d36221340a7358",
  },
];
