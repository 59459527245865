import React from "react";
import { ThreeDots } from "react-loader-spinner";

function PrimaryButton(props) {
  return (
    <button
      {...props}
      className={`sign-in-up-btn-style admin ${props.className}`}
      type={props.button ? "button" : "submit"}
    >
      {props.loading ? (
        <ThreeDots color="#000000" height={20} width={50} />
      ) : (
        <span>{props.children}</span>
      )}
    </button>
  );
}

export default PrimaryButton;
