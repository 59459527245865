import React from "react";
import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { EDIT_PROFILE_HANDLER } from "../../../constants/profileConstants";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ProfileCard({ data, onClick, editBtn, textImg }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);

  const style = {
    normal: {
      background: data ? data.bgColor : "rgb(247, 247, 0)",
    },
    hover: {
      boxShadow: `0px 0px 400px 77px ${
        data ? data.bgColor : "rgb(247, 247, 0)"
      }`,
    },
  };

  const clickHandler = () => {
    onClick();
  };

  const editHandler = () => {
    dispatch({
      type: EDIT_PROFILE_HANDLER,
      payload: { ...data },
    });
    navigate(`/watch/profiles/choose`);
  };

  return (
    <div
      className={`profile-card ${editBtn ? "main" : "main"}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {data ? (
        <div className="img-container" onClick={clickHandler}>
          <div
            className={`profile-img`}
            style={{
              ...style.normal,
              ...(hover ? style.hover : null),
            }}
          >
            <LazyLoadImage src={data.mainImage} alt="" className="main-image" />
          </div>
          {textImg && (
            <LazyLoadImage src={data.textImage} alt="" className="text-image" />
          )}
        </div>
      ) : (
        <div className="img-container" onClick={onClick}>
          <div
            className={`profile-img`}
            style={{
              ...style.normal,
              ...(hover ? style.hover : null),
            }}
          >
            <LazyLoadImage
              src={
                "https://icons.veryicon.com/png/o/internet--web/55-common-web-icons/add-43.png"
              }
              alt=""
              className="main-image"
            />
          </div>
          {textImg && (
            <LazyLoadImage src={data.textImage} alt="" className="text-image" />
          )}
        </div>
      )}

      {data && editBtn && (
        <div className="mt-2 details">
          <h4>{data.name}</h4>
        </div>
      )}

      {data && editBtn && (
        <div className="edit-btn">
          <button onClick={editHandler}>
            <AiFillEdit />
          </button>
        </div>
      )}
    </div>
  );
}

export default ProfileCard;
