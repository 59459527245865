import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import axiosPrivate from "../../../services/api";
import { Uploader } from "../../../services/fileUploader";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import EpisodeUpload from "./episodeUpload";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";
import { deleteEpisodeByIdAction } from "../../../actions/videoActions";

const episodeObj = {
  quality: "",
  releaseDate: "",
  duration: "",
  thumbnailUrl: "1",
  videoUrl: "1",
  show: "",
};

function FormRight({ data, type }) {
  const dispatch = useDispatch();
  const params = useParams();
  const state = useSelector((state) => state);
  const { shows, currRolePermissions } = state;
  const { list } = shows;

  const [selectedShow, setSelectedShow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [episodesData, setEpisodesData] = useState(data ? data : [episodeObj]);

  const uploadImage = async (event, index, path, key) => {
    const file = event.target.files[0];
    let fileName = file.name;
    if (fileName.includes(" ")) {
      fileName = fileName.replaceAll(" ", "-");
    }
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: `${path}/${fileName}`,
      file: file,
    };
    // successHandler(`${path}/${fileName}`);
    setEpisodesData(
      episodesData.map((x, i) =>
        i == index ? { ...x, thumbnailUrl: `${path}/${fileName}` } : x
      )
    );
    // setThumbnailPath(`${path}/${file.name}`);
    const uploader = new Uploader(videoUploaderOptions);
    // setUploader(uploader);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          // setProgress(percentage);
        }
      })
      .onError((error) => {
        // setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  const changeHandler = (e, i) => {
    setEpisodesData(
      episodesData.map((x, index) =>
        i == index
          ? {
              ...x,
              [e.target.name]: e.target.value,
            }
          : x
      )
    );
  };

  const updateEpisodeHandler = async (data) => {

    try {
      setLoading(data.episodeNumber);

      const { id,description, descriptionText, language, ...rest } = data;
      await axiosPrivate.patch(`/episodes/${data.id}`, {
        ...rest,
      });
      setLoading(false);
      notifySuccess(`Episode ${data.episodeNumber} Updated Successfully`);
      // dispatch(getShowsAction());
      // navigate("/manage/shows");
    } catch (err) {
      setLoading(false);
      notifyFailure(err.response.data.message);
    }
  };

  const submitEpisodeHandler = async (data) => {

    try {
      setLoading(data.episodeNumber);

      const res = await axiosPrivate.post(`/episodes`, {
        ...data,
        addedOn: moment().format("DD-MM-YYYY"),
        show: type == "Edit" ? params.id : selectedShow,
      });

      setLoading(false);
      notifySuccess(`Episode ${data.episodeNumber} Added Successfully`);

      setEpisodesData(
        episodesData.map((x, i) =>
          i == res.data.episodeNumber - 1 ? res.data : x
        )
      );

      // dispatch(getShowsAction());
      // navigate("/manage/shows");
    } catch (err) {
      setLoading(false);
      notifyFailure(err.response.data.message);
    }
  };

  const deleteEpisode = async (id) => {
    dispatch(deleteEpisodeByIdAction(id));
  };

  useEffect(() => {
    if (selectedShow) {
      setEpisodesData(episodesData.map((x) => ({ ...x, show: selectedShow })));
    }
  }, [selectedShow]);

  useEffect(() => {
    if (data) {
      setEpisodesData(data);
    }
  }, [data]);


  return (
    <div className="card">
      <div className="card-body">
        <div className="mb-3">
          <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
            {type} Episodes
          </h5>

          <div className="mb-3">
            <label htmlFor="product-categoryq" className="form-label">
              Select Show <span className="text-danger">*</span>
            </label>
            <select
              className="form-control form-select form-select-solid"
              id="product-categoryq"
              name="show"
              onChange={(e) => setSelectedShow(e.target.value)}
              disabled={type == "Edit"}
              value={type == "Edit" ? params.id : selectedShow}
            >
              <option selected disabled="">
                Select
              </option>
              {list.map((x) => (
                <option value={x.id}>
                  {x.title} {x.seasonNumber}
                </option>
              ))}
            </select>
          </div>

          <div>
            <Accordion>
              {episodesData.map((item, index) => (
                <AccordionItem key={index} isActive={index == 0}>
                  {({ open }) => (
                    <>
                      <AccordionHeader as={"div"}>
                        <h5
                          className={`accordion-title bg-light w-full p-2 d-flex justify-content-between ${
                            open ? "accordion-active" : ""
                          }`}
                        >
                          Episode {index + 1} {item.id && "( Uploaded )"}
                          {open ? <AiOutlineCaretUp /> : <AiOutlineCaretDown />}
                        </h5>
                      </AccordionHeader>

                      <AccordionBody>
                        <div className="accordion-body">
                          <div className="mb-3">
                            <label
                              htmlFor="product-categoryq"
                              className="form-label"
                            >
                              Choose quality{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-control form-select form-select-solid"
                              id="product-categoryq"
                              name="quality"
                              onChange={(e) => changeHandler(e, index)}
                              value={episodesData[index]?.quality}
                            >
                              <option disabled="">Select</option>
                              <option value="FHD">FULL HD</option>
                              <option value="HD">HD</option>
                            </select>
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="product-name"
                              className="form-label"
                            >
                              Release Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              id="product-name"
                              className="form-control"
                              placeholder="e.g : Apple iMac"
                              name="releaseDate"
                              onChange={(e) => changeHandler(e, index)}
                              value={episodesData[index]?.releaseDate}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="product-name"
                              className="form-label"
                            >
                              Duration <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="product-name"
                              className="form-control"
                              placeholder="2h 30m"
                              name="duration"
                              onChange={(e) => changeHandler(e, index)}
                              value={episodesData[index]?.duration}
                            />
                          </div>
                          <div className="mb-3">
                            <label
                              htmlFor="product-reference"
                              className="form-label"
                            >
                              Poster Image{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              // onChange={uploadThumbnail}
                              onChange={(e) =>
                                uploadImage(e, index, "episodes/posterImages")
                              }
                            />
                          </div>
                          <EpisodeUpload
                            setEpisodesData={setEpisodesData}
                            title="Upload video file"
                            directory="shows"
                            index={index}
                          />

                          <div className="mt-2">
                            <PrimaryButton
                              type="button"
                              onClick={
                                type == "Edit" && item.id
                                  ? () =>
                                      updateEpisodeHandler({
                                        ...item,
                                        // show: selectedShow,
                                      })
                                  : () =>
                                      submitEpisodeHandler({
                                        ...item,
                                        episodeNumber: index + 1,
                                        title: `Episode  ${index + 1}`,
                                      })
                              }
                              loading={loading == index + 1}
                            >
                              <i className="fe-check-circle me-1"></i>{" "}
                              {type == "Edit" && item.id
                                ? "Update "
                                : "Submit "}
                              Episode {index + 1}
                            </PrimaryButton>{" "}
                            {type == "Edit" && item.id ? (
                              <SecondaryButton onClick={deleteEpisode}>
                                <i className="fe-x me-1"></i> Delete Episode
                              </SecondaryButton>
                            ) : (
                              <SecondaryButton
                                onClick={() =>
                                  setEpisodesData(
                                    episodesData.filter((x, i) => index != i)
                                  )
                                }
                              >
                                <i className="fe-x me-1"></i> Delete
                              </SecondaryButton>
                            )}
                          </div>
                        </div>
                      </AccordionBody>
                    </>
                  )}
                </AccordionItem>
              ))}
            </Accordion>
            <div className="mt-2">
              <PrimaryButton
                type="button"
                onClick={() =>
                  setEpisodesData([
                    ...episodesData,
                    { ...episodeObj, show: selectedShow },
                  ])
                }
              >
                <i className="fa fa-plus" aria-hidden="true"></i> Add
              </PrimaryButton>{" "}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
          <div className="col-12">
            <div className="text-center mb-3">
              <PrimaryButton>
                {loading ? (
                  <ThreeDots color="#000000" height={20} width={50} />
                ) : (
                  <>
                    <i className="fe-check-circle me-1"></i> Create
                  </>
                )}{" "}
              </PrimaryButton>
              <SecondaryButton onClick={() => navigate("/manage/shows")}>
                <i className="fe-x me-1"></i> Cancel
              </SecondaryButton>
            </div>
          </div>
        </div>{" "} */}
    </div>
  );
}

export default FormRight;
