import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getTransactionsAction } from "../../../actions/transactionActions";

function TablesSection() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransactionsAction());
  }, [dispatch]);

  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">Transaction History</h4>
            {/* {list && (
              <TransactionsTable tableColumns={tableColumns} data={list} />
            )} */}
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">Top Viewed Movies / Shows</h4>

            <div className="table-responsive">
              <table className="table table-centered table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th className="border-top-0">Name</th>
                    <th className="border-top-0">Category</th>
                    <th className="border-top-0">Year</th>
                    <th className="border-top-0">Views</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src="assets/images/movies/02.jpg"
                        alt="product-pic"
                        height="36"
                      />
                      <span className="ms-2">Champions2</span>
                    </td>
                    <td>Drama</td>
                    <td>2018</td>
                    <td>
                      <span className="badge rounded-pill bg-warning text-dark">
                        4.5k
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="assets/images/movies/08.jpg"
                        alt="product-pic"
                        height="36"
                      />
                      <span className="ms-2">Last Life</span>
                    </td>
                    <td>Action</td>
                    <td>2021</td>
                    <td>
                      <span className="badge rounded-pill bg-warning text-dark">
                        4.3k
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="assets/images/movies/06.jpg"
                        alt="product-pic"
                        height="36"
                      />
                      <span className="ms-2">Dragon5</span>
                    </td>
                    <td>Sci-Fi</td>
                    <td>2017</td>
                    <td>
                      <span className="badge rounded-pill bg-warning text-dark">
                        3.9k
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="assets/images/movies/05.jpg"
                        alt="product-pic"
                        height="36"
                      />
                      <span className="ms-2">Never Again</span>
                    </td>
                    <td>Horror</td>
                    <td>2019</td>
                    <td>
                      <span className="badge rounded-pill bg-warning text-dark">
                        3.8k
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        src="assets/images/movies/02.jpg"
                        alt="product-pic"
                        height="36"
                      />
                      <span className="ms-2">Me too</span>
                    </td>
                    <td>Drama</td>
                    <td>2021</td>
                    <td>
                      <span className="badge rounded-pill bg-warning text-dark">
                        3.7k
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TablesSection;
