import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import 'swiper/css/effect-coverflow';

import { Navigation, Mousewheel, Keyboard, EffectCoverflow } from "swiper";

import GenreCard from "../cards/genreCard";

function GenreSliderV2({ title, list, type }) {
  return (
    <div className="video-slider-section">
      <p>{title}</p>

      <Swiper
        // cssMode={true}
        slidesPerView={3}
        spaceBetween={20}
        // slidesPerGroup={1}
        // autoplay={{
        //   delay: 8000,
        //   duration: 3000,
        //   disableOnInteraction: false,
        // }}
        breakpoints={{
          320: {
            slidesPerView: 2.2,
            // slidesPerGroup: 20,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 3.5,
            // slidesPerGroup: 20,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            // slidesPerGroup: 20,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 5,
            // slidesPerGroup: 20,
            spaceBetween: 20,
          },
        }}
        //   loop={true}
        //   loopFillGroupWithBlank={false}
        navigation={true}
        // mousewheel={true}
        // keyboard={true}
        modules={[Navigation, Mousewheel, Keyboard, EffectCoverflow]}
        className="video-slider"
      >
        {list.map((x, index) => (
          // <div key={x.id + index}>
          <SwiperSlide key={index}>
            <GenreCard data={x} type={type} />
          </SwiperSlide>
          // </div>
        ))}
      </Swiper>
    </div>
  );
}

export default GenreSliderV2;
