import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import { getAllMoviesAction } from "../../../actions/videoActions";
import MoviesTable from "../../../components/dataTable/moviesTable";
import { TailSpin } from "react-loader-spinner";

const tableColumns = [
  { title: "Movie", key: "title", className: "name" },
  { title: "Quality", key: "quality" },
  { title: "Genre", key: "genre" },
  { title: "Release Year", key: "releaseDate" },
  { title: "Language", key: "language" },
  // { title: "Description", key: "description" },
  { title: "Added On", key: "addedOn" },
  { title: "Status", key: "status" },
];

function Movies() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { movies, currRolePermissions } = state;
  const { loading, list, error } = movies;
  const { permission } = currRolePermissions;

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getAllMoviesAction());
  }, [dispatch]);

  // useEffect(() => {
  //   if (list) {
  //     setFilteredData(list);
  //   }
  // }, [list]);
  useEffect(() => {
    if (list) {
      const tempData = list.filter((x) =>
        x.title.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(tempData || []);
    }
  }, [search, list]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Movies List</h4>
            </div>
          </div>
        </div>

        {list.length === 0 && loading ? (
          <div className="loader">
            <TailSpin color="#000" height={50} width={50} />
          </div>
        ) : error ? (
          <div className="loader">
            <div className="alert alert-danger mt-4" role="alert">
              {error}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-between mb-2">
                    <div className="col-auto">
                      <form className="search-bar position-relative mb-sm-0 mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="mdi mdi-magnify"></span>
                      </form>
                    </div>
                    <div className="col-md-6">
                      <div className="text-md-end">
                        <PrimaryButton onClick={() => navigate("add")}>
                          <i className="mdi mdi-plus-circle-outline me-1"></i>{" "}
                          Add Movie
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                  {permission && (
                    <MoviesTable
                      modules={
                        permission.permissions.find((x) => x.type === "Movies")
                          .modules
                      }
                      tableColumns={tableColumns}
                      data={filteredData.slice().reverse()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Movies;
