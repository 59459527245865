import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { cloudfrontUrl } from "../../constants";
import { addFavouiteAction } from "../../actions/favouriteActions";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";

function VideoCard({ data, videoType, key }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { profile } = auth;

  const [loading, setLoading] = useState(false);

  const favouriteHandler = () => {
    if (profile) {
      setLoading(true);
      dispatch(
        addFavouiteAction(
          {
            video: data.id,
            profile: profile.id,
            modelNameOfTheVideoType: videoType,
          },
          handleToast
        )
      );
    }
  };

  const handleToast = () => {
    setLoading(false);
    toast("Added to watchlist !");
  };

  return (
    <div key={key}>
      {videoType && (
        <div className="movie-wrap-plr">
          <div className="movie-wrap text-center">
            <div className="movie-img">
              <Link to={`/${videoType.toLowerCase()}s/${data.id}`}>
                <LazyLoadImage
                  src={`${cloudfrontUrl}/${data.thumbnailUrl}`}
                  alt=""
                />
              </Link>
              <button
                title="Watchlist"
                className="Watch-list-btn"
                type="button"
                onClick={favouriteHandler}
              >
                {loading ? (
                  <ThreeDots color="#fff" height={19} width={19} />
                ) : (
                  <i className="zmdi zmdi-plus"></i>
                )}
              </button>
            </div>
            <div className="movie-content">
              <h3 className="title">
                <Link to={`/${videoType.toLowerCase()}s/${data.id}`}>
                  {data.title}
                </Link>
              </h3>
              <span>Quality : {data.quality}</span>
              <div className="movie-btn">
                <Link
                  to={`/${videoType.toLowerCase()}s/${data.id}`}
                  className="btn-style-hm4-2 animated"
                >
                  Watch Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoCard;
