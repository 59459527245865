import React from "react";
import CountUp from "react-countup";

function StatsCard({ icon, title, value, onClick }) {
  return (
    <div className="col-lg-3 col-xl-3 ">
      <div className="card bg-pattern">
        <div className="card-body" onClick={onClick} role="button">
          <div className="row">
            <div className="col-6">
              <div className="avatar-md mi-bgre rounded">{icon}</div>
            </div>
            <div className="col-6">
              <div className="text-end">
                <h3 className="text-dark my-1">
                  <span data-plugin="counterup">
                    <CountUp
                      start={true ? 0 : null}
                      end={value}
                      delay={0}
                      redraw={true}
                    >
                      {({ countUpRef }) => (
                        <span ref={countUpRef} className="odometer"></span>
                      )}
                    </CountUp>
                  </span>
                </h3>
                <p className="text-muted mb-0 text-truncate">{title}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatsCard;
