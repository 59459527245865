import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  SEND_VERIFICATION_EMAIL_FAIL,
  SEND_VERIFICATION_EMAIL_REQUEST,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../constants";

import axiosPrivate from "../services/api";

//GET Users via Admin
export const getUsersAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_USERS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/users`);

    dispatch({
      type: GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_USERS_FAIL,
      payload: err.response.data.message,
    });
  }
};

//Create User via Admin
export const createUserAction =
  (userData, statusHandler) => async (dispatch) => {
    statusHandler({
      loading: true,
    });
    dispatch({
      type: CREATE_USER_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/users`, userData);
      statusHandler({
        loading: false,
        success: true,
      });
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: data,
      });
    } catch (err) {
      statusHandler({
        loading: false,
        error: err.response.data.message,
      });
      dispatch({
        type: CREATE_USER_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//Update User via Admin
export const updateUserAction =
  ({ userData, userId }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(`/users/${userId}`, userData);

      notifySuccess("User Updated Successfully !");

      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: data,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);

      dispatch({
        type: UPDATE_USER_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//Delete User via Admin
export const deleteUserAction = (userId) => async (dispatch) => {
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  try {
    await axiosPrivate.delete(`/users/${userId}`);
    notifySuccess("User Deleted Successfully !");

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: userId,
    });
  } catch (err) {
    notifyFailure(err.response.data.message);
    dispatch({
      type: DELETE_USER_FAIL,
      payload: err.response.data.message,
    });
  }
};

//Verification Email Sending
export const sendVerificationEmailAction =
  ({ email }) =>
  async (dispatch) => {
    dispatch({
      type: SEND_VERIFICATION_EMAIL_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(
        `/auth/send-verification-email`,
        { email }
      );

      dispatch({
        type: SEND_VERIFICATION_EMAIL_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: SEND_VERIFICATION_EMAIL_FAIL,
        payload: err.response.data.message,
      });
    }
  };
