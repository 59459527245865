import React from "react";

function News() {
  return (
    <div className="channel-video">
      <div className="message">
        <p className="title">News Channels Of Your Choice</p>
        <p className="text">Select any channel to start watching !</p>
      </div>
    </div>
  );
}

export default News;
