import React from "react";

function PageTitle({ title }) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <div className="page-title-right"></div>
          <h4 className="page-title">{title}</h4>
        </div>
      </div>
    </div>
  );
}

export default PageTitle;
