import React, { useCallback, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Uploader } from "../../services/fileUploader";

function VideoUpload({ setUploadDetails, directory, title, index }) {
  const [uploader, setUploader] = useState(undefined);

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  // const [videoCover, setVideoCover] = useState("");

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadVideo = async () => {
        let percentage = undefined;
        let fileName = acceptedFiles[0].name;

        if (fileName.includes(" ")) {
          fileName = fileName.replaceAll(" ", "-");
        }
        const videoUploaderOptions = {
          fileName: `${directory}/${fileName}`,
          file: acceptedFiles[0],
        };
        const uploader = new Uploader(videoUploaderOptions);
        setUploader(uploader);

        setUploadDetails({
          fileName: fileName,
          path: `${directory}/${fileName}`,
          completed: false,
        });

        uploader
          .onProgress(({ percentage: newPercentage }) => {
            // to avoid the same percentage to be logged twice
            if (newPercentage !== percentage) {
              percentage = newPercentage;
              setProgress(percentage);
            }
            setUploadDetails({
              fileName: fileName,
              path: `${directory}/${fileName}`,
              completed: true,
              index: index,
            });
          })
          .onError((error) => {
            // setFile(undefined);
            console.error(error);
          });

        uploader.start();
      };
      if (acceptedFiles[0]) {
        setFile(acceptedFiles[0]);
        uploadVideo();
      }
      // setMyFiles([...myFiles, ...acceptedFiles]);
    },
    [directory, setUploadDetails]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  // useEffect(() => {
  //   const uploadVideo = async () => {
  //     let percentage = undefined;
  //     // const fileName = videoTitle || acceptedFiles[0].name;
  //     let fileName = acceptedFiles[0].name.slice(0, 20);
  //     if (fileName.includes(" ")) {
  //       fileName = fileName.replaceAll(" ", "-");
  //     }
  //     const videoUploaderOptions = {
  //       fileName: `${directory}/${fileName}`,
  //       file: acceptedFiles[0],
  //     };
  //     const uploader = new Uploader(videoUploaderOptions);
  //     setUploader(uploader);

  //     setUploadDetails({
  //       fileName: acceptedFiles[0].name,
  //       path: `${directory}/${acceptedFiles[0].name}`,
  //       completed: false,
  //     });

  //     uploader
  //       .onProgress(({ percentage: newPercentage }) => {
  //         // to avoid the same percentage to be logged twice
  //         if (newPercentage !== percentage) {
  //           percentage = newPercentage;
  //           setProgress(percentage);
  //         }
  //         setUploadDetails({
  //           fileName: acceptedFiles[0].name,
  //           path: `${directory}/${acceptedFiles[0].name}`,
  //           completed: true,
  //         });
  //       })
  //       .onError((error) => {
  //         // setFile(undefined);
  //         console.error(error);
  //       });

  //     uploader.start();
  //   };

  //   if (acceptedFiles.length > 0) {
  //     uploadVideo();
  //   }
  // }, [acceptedFiles, directory, setUploadDetails]);

  const onCancel = () => {
    if (uploader) {
      uploader.abort();
      setFile(null);
    }
  };

  return (
    <div className="mb-3">
      {/* <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">{title}</h5> */}
      <label htmlFor="product-name" className="form-label">
        {title}
        <span className="text-danger">*</span>
      </label>
      <form
        action="/"
        method="post"
        // className="dropzone"
        // id="myAwesomeDropzone"
      >
        <div
          // className="fallback"
          {...getRootProps({ className: "dropzone" })}
        >
          <input name="file" type="file" {...getInputProps()} />
          <div className="dz-message needsclick">
            <i className="h1 text-muted dripicons-cloud-upload"></i>
            <h3>Drop files here or click to upload.</h3>
          </div>
        </div>
        {file && (
          <aside>
            <h4>Files</h4>
            {/* <ul>{files}</ul> */}
            <div>
              <div className="my-1">
                <img src={""} alt="" />
                <p>
                  {file.path} - {Math.ceil(file.size / (1024 * 1024))}mb
                </p>
              </div>
              <div>
                <ProgressBar animated={progress < 100} now={progress} />
                <p className="my-1">
                  {progress < 100 ? <>Uploading-{progress}%</> : <>Complete</>}
                </p>
                {progress < 100 && (
                  <button type="button" onClick={onCancel}>
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </aside>
        )}
      </form>

      <div className="dropzone-previews mt-3" id="file-previews"></div>
    </div>
  );
}

export default VideoUpload;
