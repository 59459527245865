import axiosPrivate, { axiosPublic } from "../services/api";

import {
  GET_USER_WALLET_FAIL,
  GET_USER_WALLET_REQUEST,
  GET_USER_WALLET_SUCCESS,
  GET_WALLETS_FAIL,
  GET_WALLETS_REQUEST,
  GET_WALLETS_SUCCESS,
} from "../constants/walletConstants";

//GET Wallets
export const getWalletsAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_WALLETS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/wallets`);

    dispatch({
      type: GET_WALLETS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_WALLETS_FAIL,
      payload: err.response.data.message,
    });
  }
};

//GET User Wallet
export const getUserWalletAction = (userId) => async (dispatch) => {
  dispatch({
    type: GET_USER_WALLET_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/wallets/user`);
    dispatch({
      type: GET_USER_WALLET_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_USER_WALLET_FAIL,
      payload: err.response.data.message,
    });
  }
};
