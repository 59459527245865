import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCategoriesAction } from "../../../actions/categoryActions";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import { UPDATE_MOVIE_SUCCESS } from "../../../constants/videoConstants";
import VideoUpload from "../../../components/videoUpload";

import axiosPrivate from "../../../services/api";
import { Uploader } from "../../../services/fileUploader";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";
import Select from "react-select";
import { getPackagesAction } from "../../../actions/packageActions";
import { getUsersAction } from "../../../actions/userActions";
import moment from "moment";

function EditSubscription() {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { users, packages, categories, subscriptions } = state;
  const [loading, setLoading] = useState(false);

  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [editFlag, setEditFlag] = useState(false);

  const changeHandler = (e) => {
    setSubscriptionDetails({
      ...subscriptionDetails,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { data } = await axiosPrivate.patch(`/subscriptions/${id}`, {
        ...subscriptionDetails,
        // thumbnailUrl: thumbnailPath,
        // bgImageUrl: bgImagePath,
        // titleImageUrl: titleImagePath,
        // videoUrl: movieUploadDetails.path,
        // bgVideoUrl: clipUploadDetails.path,
        // title: movieDetails.title,
        // category: movieDetails.category,
        // industry: movieDetails.industry,
        // quality: movieDetails.quality,
        // description: movieDetails.description,
        // descriptionText: movieDetails.descriptionText,
        // status: movieDetails.status,
        // releaseDate: movieDetails.releaseDate,
        // language: movieDetails.language,
        // duration: movieDetails.duration,
        // addedOn: movieDetails.addedOn,
      });
      notifySuccess("Subscription Updated Successfully !");

      setLoading(false);
      navigate("/manage/subscriptions");

      dispatch({
        type: UPDATE_MOVIE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);
      setLoading(false);
      // setError(err.response.data.message);
    }
  };

  useEffect(() => {
    if (subscriptions.list.length === 0) {
      navigate("/manage/subscriptions");
    } else {
      // const id = location.pathname.split("/")[4];
      if (id) {
        const selectedSubscription = subscriptions.list.find(
          (x) => x.id === id
        );
        if (selectedSubscription) {
          setSubscriptionDetails({
            user: selectedSubscription.user.id,
            package: selectedSubscription.package.id,
            expiringOn: selectedSubscription.expiringOn,
            status: selectedSubscription.status,
          });
        } else {
          navigate("/manage/subscriptions");
        }
      }
    }
  }, [navigate, location, subscriptions]);

  useEffect(() => {
    dispatch(getPackagesAction());
    dispatch(getUsersAction());
  }, [dispatch]);


  useEffect(() => {
    // if (subscriptionDetails?.keys?.length > 0 && editFlag) {
      if (subscriptionDetails.status == "Expired") {
        setSubscriptionDetails({
          ...subscriptionDetails,
          expiringOn: moment().format("YYYY-MM-DD"),
        });
      } else {
        // setSubscriptionDetails({
        //   ...subscriptionDetails,
        //   expiringOn: moment().format("YYYY-MM-DD"),
        // });
      }
    // }
  }, [subscriptionDetails?.status]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Edit Subscription</h4>
            </div>
          </div>
        </div>
        {Object.keys(subscriptionDetails).length>0 && (
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {/* <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                  General
                </h5> */}

                    <div className="mb-3">
                      <label htmlFor="product-name" className="form-label">
                        User <span className="text-danger">*</span>
                      </label>
                      <Select
                        isDisabled
                        className="form-control react-select"
                        value={users.list
                          .map((x) => ({
                            id: x.id,
                            label: `${x.firstName} ${x.lastName} ( ${x.accountNumber} )`,
                          }))
                          .find((x) => x.id == subscriptionDetails.user)}
                        onChange={(e) =>
                          setSubscriptionDetails((prev) => ({
                            ...prev,
                            user: e.id,
                          }))
                        }
                        options={users.list
                          .filter((x) => x.role == "user")
                          .map((x) => ({
                            id: x.id,
                            label: `${x.firstName} ${x.lastName} ( ${x.accountNumber} )`,
                          }))}
                      />
                      {/* <input
                    type="text"
                    className="form-control"
                    placeholder="e.g : Apple iMac"
                    name="title"
                    onChange={changeHandler}
                    required
                  /> */}
                    </div>

                    <div className="mb-3">
                      <label htmlFor="product-industry" className="form-label">
                        Package <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control form-select form-select-solid"
                        id="product-industry"
                        name="package"
                        onChange={changeHandler}
                        defaultValue={subscriptionDetails.package}
                      >
                        <option disabled="">Select</option>
                        {packages.list.map((x) => (
                          <option key={x.id} value={x.id}>
                            {x.type} ( ${x.subscriptionFee} )
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="product-industry" className="form-label">
                        Status <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control form-select form-select-solid"
                        id="product-industry"
                        name="status"
                        onChange={(e) => {
                          changeHandler(e);
                          setEditFlag(true);
                        }}
                        value={subscriptionDetails.status}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        {/* <option value="Unsubscribed">Unsubscribed</option> */}
                        <option value="Expired">Expired</option>
                      </select>
                    </div>
                    {subscriptionDetails.status !== "Expired" && (
                      <div className="mb-3">
                        <label
                          htmlFor="product-categoryq"
                          className="form-label"
                        >
                          Expiring On <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control "
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                          value={moment(subscriptionDetails.expiringOn).format(
                            "YYYY-MM-DD"
                          )}
                          name="expiringOn"
                          onChange={changeHandler}
                        />
                      </div>
                    )}

                    {/* <div className="mb-3">
                  <label htmlFor="product-category" className="form-label">
                    Status <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control form-select form-select-solid"
                    id="product-category"
                    name="status"
                    onChange={changeHandler}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Expired">Expired</option>
                    <option value="Unsubscribed">Unsubscribed</option>
                  </select>
                </div> */}
                  </div>
                  <div className="text-center mb-3">
                    <PrimaryButton>
                      {loading ? (
                        <ThreeDots color="#000000" height={20} width={50} />
                      ) : (
                        <>
                          <i className="fe-check-circle me-1"></i> Update
                        </>
                      )}{" "}
                    </PrimaryButton>
                    <SecondaryButton
                      onClick={() => navigate("/manage/subscriptions")}
                    >
                      <i className="fe-x me-1"></i> Cancel
                    </SecondaryButton>{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {/* <div className="text-center mb-3">
              <PrimaryButton>
                {loading ? (
                  <ThreeDots color="#000000" height={20} width={50} />
                ) : (
                  <>
                    <i className="fe-check-circle me-1"></i> Create
                  </>
                )}{" "}
              </PrimaryButton>
              <SecondaryButton onClick={() => navigate("/manage/movies")}>
                <i className="fe-x me-1"></i> Cancel
              </SecondaryButton>{" "}
            </div> */}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default EditSubscription;
