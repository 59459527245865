import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserWalletAction } from "../../../actions/walletActions";
import CustomStripe from "../../../components/customStripe";
import Popup from "../../../components/modal";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import axiosPrivate from "../../../services/api";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import moment from "moment";
import { getcurrUserSubscriptionAction } from "../../../actions/subscriptionActions";
import WalletComponent from "../wallet/walletComponent";

function Subscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { auth, currUserWallet, currUserSubscription } = state;

  const { userInfo } = auth;
  const { loading, data } = currUserWallet;

  const [showModal, setShowModal] = useState({ open: false });

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserWalletAction(userInfo.id));
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(getcurrUserSubscriptionAction(userInfo.id));
  }, []);

  return (
    <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4 mt-5">
      <div className="container">
        <div className="row">
          <div className="tab-pane" id="payment-info">
            {loading || currUserSubscription.loading ? (
              <div className="loading-container">
                <ThreeDots
                  color="#fff"
                  height={20}
                  width={50}
                  style={{ width: "fit-content", margin: "auto" }}
                />
              </div>
            ) : data || currUserSubscription.data ? (
              <div className="row">
                <div className="col-lg-12">
                  {/* {!false ? ( */}
                  {!currUserSubscription?.data?.package ? (
                    <div className="current-plan-wrapper white">
                      <div className="section-title-plan mb--20">
                        <h3>Current Plan</h3>
                      </div>
                      <div className="dic-pricing">
                        <div className="row">
                          <div className="col-md-8 col-xs-12">
                            <div className="dic-pricing-header left">
                              <h4 className="package">No Plan Found !</h4>
                              <p>You haven't subscribed any plan yet</p>
                            </div>
                          </div>

                          <div className="col-md-4 col-xs-12">
                            <div className="dic-pricing-btn">
                              <PrimaryButton
                                onClick={() => navigate("/my-account/pricing")}
                              >
                                Buy Plan
                              </PrimaryButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : currUserSubscription?.data?.package?.active ? (
                    <div className="current-plan-wrapper white">
                      <div className="section-title-plan mb--20 d-flex align-items-center justify-content-between">
                        <h3>
                          Current Plan ( {currUserSubscription?.data?.status} )
                        </h3>{" "}
                        <PrimaryButton
                          onClick={() => navigate("/my-account/pricing")}
                        >
                          Upgrade Plan
                        </PrimaryButton>
                      </div>
                      <div className="dic-pricing">
                        <div className="row">
                          <div className="col-md-3 col-xs-12">
                            <div className="dic-pricing-header">
                              <h4 className="package">
                                {currUserSubscription?.data?.package.type}
                              </h4>
                              <div className="price">
                                <span className="currency">$</span>
                                <span className="amount">
                                  {
                                    currUserSubscription?.data?.package
                                      ?.subscriptionFee
                                  }
                                </span>
                                <span className="period">
                                  /
                                  {currUserSubscription?.data?.package?.subscriptionType.slice(
                                    0,
                                    2
                                  )}
                                </span>
                              </div>
                              <div className="old-price">
                                <span>
                                  ${" "}
                                  {currUserSubscription?.data?.package
                                    ?.subscriptionFee + 2}
                                  /
                                  {currUserSubscription?.data?.package?.subscriptionType.slice(
                                    0,
                                    2
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-xs-12">
                            <div className="dic-pricing-items">
                              <ul>
                                {currUserSubscription?.data?.package?.description.map(
                                  (desc, index) => (
                                    <li key={desc + index}>{desc}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-4 col-xs-12 d-flex align-items-center">
                            <div className="dic-pricing-btn">
                              <span className="text-white d-table mb--10">
                                {currUserSubscription?.data?.status ===
                                "Expired"
                                  ? "Expired On"
                                  : "Expiring On"}{" "}
                                :<br />{" "}
                                {moment(
                                  currUserSubscription?.data?.expiringOn
                                ).format("DD MMMM, YYYY")}
                              </span>

                              {currUserSubscription?.data?.paymentType ===
                                "Mobile Money" && (
                                <PrimaryButton
                                  onClick={() => setShowModal({ open: true })}
                                  // onClick={() => navigate("/my-account/pricing")}
                                >
                                  Mobile Money Details
                                </PrimaryButton>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="current-plan-wrapper white">
                      <div className="section-title-plan mb--20">
                        <h3>Previous Plan</h3>
                      </div>
                      <div className="dic-pricing">
                        <div className="row">
                          <div className="col-md-3 col-xs-12">
                            <div className="dic-pricing-header">
                              <h4 className="package">Gold</h4>
                              <div className="price">
                                <span className="currency">$</span>
                                <span className="amount">
                                  {
                                    currUserSubscription?.data?.package
                                      ?.subscriptionFee
                                  }
                                </span>
                                <span className="period">
                                  /
                                  {currUserSubscription?.data?.package?.subscriptionType?.slice(
                                    0,
                                    2
                                  )}
                                </span>
                              </div>
                              <div className="old-price">
                                <span>
                                  ${" "}
                                  {currUserSubscription?.data?.package
                                    ?.subscriptionFee + 2}
                                  /
                                  {currUserSubscription?.data?.package?.subscriptionType?.slice(
                                    0,
                                    2
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-xs-12">
                            <div className="dic-pricing-items">
                              <ul>
                                {currUserSubscription?.data?.package?.description.map(
                                  (desc, index) => (
                                    <li key={desc + index}>{desc}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <div className="dic-pricing-btn">
                              <span className="text-white d-table mb--10">
                                Expired on :<br />{" "}
                                {currUserSubscription?.data?.expiringOn}
                              </span>

                              <PrimaryButton
                                onClick={() => navigate("/pricing")}
                              >
                                Buy Plan
                              </PrimaryButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="alert alert-info mt-4" role="alert">
                No Wallet found !
              </div>
            )}{" "}
            {currUserSubscription.loading ? (
              <div className="loading-container">
                {/* <ThreeDots
                  color="#fff"
                  height={20}
                  width={50}
                  style={{ width: "fit-content", margin: "auto" }}
                /> */}
              </div>
            ) : currUserSubscription.data ? (
              <div className="row">
                <div className="col-lg-12">
                  {/* {!false ? ( */}

                  <WalletComponent showWalletBtn />
                </div>
              </div>
            ) : (
              <div className="alert alert-info mt-4" role="alert">
                No Wallet found !
              </div>
            )}{" "}
            <Popup showModal={showModal.open} setShowModal={setShowModal}>
              <>
                <div className="d-flex align-items-center">
                  {/* <MdOutlineKeyboardBackspace
                  size={20}
                  onClick={() => setPaymentMethodFlag(false)}
                /> */}

                  <h3 className="mx-2">Mobile Money</h3>
                </div>
                <div>
                  {/* <p className="text-align-left"><b>Account # 7876253227389</b></p> */}
                  <p>
                    Please transfer ( if subscription is Inactive or Expired )
                    the subscription amount
                    <b>
                      {" "}
                      ${currUserSubscription?.data?.package?.subscriptionFee}
                    </b>{" "}
                    for the{" "}
                    <b>{currUserSubscription?.data?.package?.type} Package</b>{" "}
                    to this <b>Mobile Account # 7876253227389</b> and Call
                    customer support <b>252-615-5678</b> to activate the
                    subscription and enjoy watching Infisom.
                  </p>

                  <p>
                    <b>Note:</b> Don't forget to share your user account number
                    with customer support agent. Your user account number is
                    <b> {userInfo?.accountNumber}</b>
                  </p>
                </div>
              </>
            </Popup>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Subscription;
