import React from "react";
import { useNavigate } from "react-router-dom";

function HeroSection() {
  const navigate = useNavigate();
  return (
    <div className="sign-slider-section">
      <div className="container">
        <div className="row">
          {/* Header Text Start  */}
          <div className="signin-header-text ">
            <h1>infinite, Hit Movies, Live Sports & So Much More</h1>
            {/* <h3>Plans start at $4.99/month. Cancel anytime.</h3>
            <button className="btn-watch" onClick={() => navigate("/pricing")}>
              Choose Plan
            </button> */}
          </div>
          {/* Header Text End   */}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
