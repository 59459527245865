import React from "react";

function Settings() {
  const changeHandler = (e) => {};
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0"></ol>
              </div>
              <h4 className="page-title">Settings</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="form-section mb-3 font-24">Info</h5>
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      Company Name <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value=""
                      onChange={changeHandler}
                      className="form-control form-control-solid"
                      placeholder="Company Name..."
                      required
                    />
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      Street <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="street"
                      value=""
                      onChange={changeHandler}
                      className="form-control form-control-solid"
                      placeholder="Street..."
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      Apt/Suite/Other <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="suite"
                      value=""
                      onChange={changeHandler}
                      className="form-control form-control-solid"
                      placeholder="Apt/Suite/Other..."
                      required
                    />
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      Zip <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="zip"
                      value=""
                      onChange={changeHandler}
                      className="form-control form-control-solid"
                      placeholder="Zip..."
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      State <sup className="text-danger">*</sup>
                    </label>
                    <select
                      name="state_id"
                      className="form-control form-select form-select-solid"
                      required
                      value=""
                      onChange={changeHandler}
                    >
                      <option value="">Select State</option>
                      <option value="3919">Alabama</option>
                      <option value="3920">Alaska</option>
                      <option value="3921">Arizona</option>
                      <option value="3922">Arkansas</option>
                      <option value="3923">Byram</option>
                      <option value="3924">California</option>
                      <option value="3925">Cokato</option>
                      <option value="3926">Colorado</option>
                      <option value="3927">Connecticut</option>
                      <option value="3928">Delaware</option>
                      <option value="3929">Dist. of Columbia</option>
                      <option value="3930">Florida</option>
                      <option value="3931">Georgia</option>
                      <option value="3932">Hawaii</option>
                      <option value="3933">Idaho</option>
                      <option value="3934">Illinois</option>
                      <option value="3935">Indiana</option>
                      <option value="3936">Iowa</option>
                      <option value="3937">Kansas</option>
                      <option value="3938">Kentucky</option>
                      <option value="3939">Louisiana</option>
                      <option value="3940">Lowa</option>
                      <option value="3941">Maine</option>
                      <option value="3942">Maryland</option>
                      <option value="3943">Massachusetts</option>
                      <option value="3944">Medfield</option>
                      <option value="3945">Michigan</option>
                      <option value="3946">Minnesota</option>
                      <option value="3947">Mississippi</option>
                      <option value="3948">Missouri</option>
                      <option value="3949">Montana</option>
                      <option value="3950">Nebraska</option>
                      <option value="3951">Nevada</option>
                      <option value="3952">New Hampshire</option>
                      <option value="3953">New Jersey</option>
                      <option value="3955">New Mexico</option>
                      <option value="3956">New York</option>
                      <option value="3957">North Carolina</option>
                      <option value="3958">North Dakota</option>
                      <option value="3959">Ohio</option>
                      <option value="3960">Oklahoma</option>
                      <option value="3961">Ontario</option>
                      <option value="3962">Oregon</option>
                      <option value="3963">Pennsylvania</option>
                      <option value="3964">Ramey</option>
                      <option value="3965">Rhode Island</option>
                      <option value="3966">South Carolina</option>
                      <option value="3967">South Dakota</option>
                      <option value="3968">Sublimity</option>
                      <option value="3969">Tennessee</option>
                      <option value="3970">Texas</option>
                      <option value="3971">Trimble</option>
                      <option value="3972">Utah</option>
                      <option value="3973">Vermont</option>
                      <option value="3974">Virginia</option>
                      <option value="3975">Washington</option>
                      <option value="3976">West Virginia</option>
                      <option value="3977">Wisconsin</option>
                      <option value="3978">Wyoming</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      City <sup className="text-danger">*</sup>
                    </label>
                    <div id="loadingCity"></div>
                    <select
                      name="city_id"
                      className="form-control form-select form-select-solid"
                      required
                      value=""
                      onChange={changeHandler}
                    >
                      <option value="">Select City</option>
                      <option value="44063">Ahuimanu</option>
                      <option value="44064">Aiea</option>
                      <option value="44065">Aliamanu</option>
                      <option value="44066">Ewa Beach</option>
                      <option value="44067">Haiku</option>
                      <option value="44068">Halawa</option>
                      <option value="44069">Hanalei</option>
                      <option value="44070">Hilo</option>
                      <option value="44071">Holualoa</option>
                      <option value="44072">Minneapolis</option>
                      <option value="44073">Kahului</option>
                      <option value="44074">Kailua</option>
                      <option value="44075">Kalaheo</option>
                      <option value="44076">Kamuela</option>
                      <option value="44077">Kaneohe</option>
                      <option value="44078">Kaneohe Station</option>
                      <option value="44079">Kapaa</option>
                      <option value="44080">Kapolei</option>
                      <option value="44081">Kihei</option>
                      <option value="44082">Kula</option>
                      <option value="44083">Lahaina</option>
                      <option value="44084">Lanai City</option>
                      <option value="44085">Lihue</option>
                      <option value="44086">Makaha</option>
                      <option value="44087">Makakilo City</option>
                      <option value="44088">Makawao</option>
                      <option value="44089">Mi-Wuk Village</option>
                      <option value="44090">Mililani Town</option>
                      <option value="44091">Naalehu</option>
                      <option value="44092">Nanakuli</option>
                      <option value="44093">Pahoa</option>
                      <option value="44094">Pearl City</option>
                      <option value="44095">Schofield Barracks</option>
                      <option value="44096">Wahiawa</option>
                      <option value="44097">Waialua</option>
                      <option value="44098">Waianae</option>
                      <option value="44099">Wailuku</option>
                      <option value="44100">Waimalu</option>
                      <option value="44101">Waipahu</option>
                      <option value="44102">Waipio</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      Phone/Mobile <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value=""
                      onChange={changeHandler}
                      className="form-control form-control-solid"
                      placeholder="Phone/Mobile..."
                      required
                    />
                  </div>
                  <div className="form-group col-md-6 mb-3">
                    <label>
                      Email <sup className="text-danger">*</sup>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value=""
                      onChange={changeHandler}
                      className="form-control form-control-solid"
                      placeholder="Email..."
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12 mb-3">
                    <label>Description</label>
                    <textarea
                      name="note"
                      className="form-control form-control-solid"
                      placeholder="Description of your company..."
                      rows="5"
                      value=""
                      onChange={changeHandler}
                    >
                      Infisom
                    </textarea>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn text-white mi-bgre waves-effect waves-light m-1"
                    >
                      <i className="fe-check-circle me-1"></i> Update
                    </button>
                    <button
                      type="button"
                      className="btn btn-light waves-effect waves-light m-1"
                    >
                      <i className="fe-x me-1"></i> Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
