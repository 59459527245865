import React from "react";
import { GoMailRead } from "react-icons/go";

function PopupContent({ email }) {
  return (
    <>
      <GoMailRead color="#a158a1" size={45} className="mx-1" />
      <h2>Success !</h2>
      <p>
        A password reset email has been sent to <b>{email}</b>. Please check for
        an email from <b>Infisom</b> and click on the included button to reset
        your password.
      </p>
    </>
  );
}

export default PopupContent;
