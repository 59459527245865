import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";

import HeaderV2 from "../components/headerV2";
import { getProfilesAction } from "../actions/profileActions";

const navLinks = [
  { title: "Account Details", path: "/my-account/details", type: "Link" },
  { title: "Subscription", path: "/my-account/subscription" },
  { title: "Wallet", path: "/my-account/wallet" },
];

function MyAccountLayout(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;

  // const logoutHandler = () => {
  //   dispatch(
  //     userLogout({
  //       refreshToken: userInfo.tokens.refresh.token,
  //     })
  //   );
  // };

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    } else {
      dispatch(getProfilesAction());
    }
  }, [navigate, userInfo]);

  return (
    <div className="main-wrapper user-layout">
      {!location.pathname.includes("profiles") && (
        <HeaderV2 navLinks={navLinks} homeUrl="/my-account/details" />
      )}
      {/* <div className="breadcrumb-area breadcrumb-modify-padding bg-black-2 mt-5">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-style-2 center">
                  <h2>My Account</h2>
                  <ul className="breadcrumb-list-2 black">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>My Account</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4 mt-5">
        <div className="container">
          <div className="row"> */}
      {props.children}

      {/* <AccountDetails /> */}
      {/* <PaymentInfo /> */}
      {/* </div>
        </div>
      </main> */}
    </div>
  );
}

export default MyAccountLayout;
