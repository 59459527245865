import React from "react";
import Slider from "react-slick";
import GenreCard from "../cards/genreCard";

function GenreSlider({ list, type }) {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(list.length, 5),
    // slidesToScroll: 1,
    // rows: 1,
    // slidesPerRow: 1,
    // slidesToShow: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="series-list section-padding-lr section-pt-50 bg-black">
      <div className="container-fluid">
        <div className="section-title-4 st-border-bottom">
          <h2>Browse By Genre</h2>
        </div>
        <div className="movie-slider-active nav-style-2">
          <Slider {...settings}>
            {list.map((x, index) => (
              <GenreCard key={x.id + index} data={x} type={type} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default GenreSlider;
