//Movie
export const GET_MOVIES_REQUEST = "GET_MOVIES_REQUEST";
export const GET_MOVIES_SUCCESS = "GET_MOVIES_SUCCESS";
export const GET_MOVIES_FAIL = "GET_MOVIES_FAIL";

export const GET_TRENDING_MOVIES_REQUEST = "GET_TRENDING_MOVIES_REQUEST";
export const GET_TRENDING_MOVIES_SUCCESS = "GET_TRENDING_MOVIES_SUCCESS";
export const GET_TRENDING_MOVIES_FAIL = "GET_TRENDING_MOVIES_FAIL";

export const POST_MOVIE_REQUEST = "POST_MOVIE_REQUEST";
export const POST_MOVIE_SUCCESS = "POST_MOVIE_SUCCESS";
export const POST_MOVIE_FAIL = "POST_MOVIE_FAIL";

export const UPDATE_MOVIE_REQUEST = "UPDATE_MOVIE_REQUEST";
export const UPDATE_MOVIE_SUCCESS = "UPDATE_MOVIE_SUCCESS";
export const UPDATE_MOVIE_FAIL = "UPDATE_MOVIE_FAIL";

export const DELETE_MOVIE_REQUEST = "DELETE_MOVIE_REQUEST";
export const DELETE_MOVIE_SUCCESS = "DELETE_MOVIE_SUCCESS";
export const DELETE_MOVIE_FAIL = "DELETE_MOVIE_FAIL";

// SHOWS
export const GET_SHOWS_REQUEST = "GET_SHOWS_REQUEST";
export const GET_SHOWS_SUCCESS = "GET_SHOWS_SUCCESS";
export const GET_SHOWS_FAIL = "GET_SHOWS_FAIL";

export const POST_SHOW_REQUEST = "POST_SHOW_REQUEST";
export const POST_SHOW_SUCCESS = "POST_SHOW_SUCCESS";
export const POST_SHOW_FAIL = "POST_SHOW_FAIL";

export const UPDATE_SHOW_REQUEST = "UPDATE_SHOW_REQUEST";
export const UPDATE_SHOW_SUCCESS = "UPDATE_SHOW_SUCCESS";
export const UPDATE_SHOW_FAIL = "UPDATE_SHOW_FAIL";

export const DELETE_SHOW_REQUEST = "DELETE_SHOW_REQUEST";
export const DELETE_SHOW_SUCCESS = "DELETE_SHOW_SUCCESS";
export const DELETE_SHOW_FAIL = "DELETE_SHOW_FAIL";

export const VIDEO_DETAILS_REQUEST = "VIDEO_DETAILS_REQUEST";
export const VIDEO_DETAILS_SUCCESS = "VIDEO_DETAILS_SUCCESS";
export const VIDEO_DETAILS_FAIL = "VIDEO_DETAILS_FAIL";

// EPISODE CONSTANTS
export const SHOW_EPISODES_LIST_REQUEST = "SHOW_EPISODES_LIST_REQUEST";
export const SHOW_EPISODES_LIST_SUCCESS = "SHOW_EPISODES_LIST_SUCCESS";
export const SHOW_EPISODES_LIST_FAIL = "SHOW_EPISODES_LIST_FAIL";

export const EPISODE_DETAILS_REQUEST = "EPISODE_DETAILS_REQUEST";
export const EPISODE_DETAILS_SUCCESS = "EPISODE_DETAILS_SUCCESS";
export const EPISODE_DETAILS_FAIL = "EPISODE_DETAILS_FAIL";

export const DELETE_EPISODE_REQUEST = "DELETE_EPISODE_REQUEST";
export const DELETE_EPISODE_SUCCESS = "DELETE_EPISODE_SUCCESS";
export const DELETE_EPISODE_FAIL = "DELETE_EPISODE_FAIL";

// Channels
export const GET_CHANNELS_REQUEST = "GET_CHANNELS_REQUEST";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_FAIL = "GET_CHANNELS_FAIL";

export const CHANNEL_DETAILS_REQUEST = "CHANNEL_DETAILS_REQUEST";
export const CHANNEL_DETAILS_SUCCESS = "CHANNEL_DETAILS_SUCCESS";
export const CHANNEL_DETAILS_FAIL = "CHANNEL_DETAILS_FAIL";
