import React from "react";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";

function ManageCategoryModal() {
  return (
    <div
      className="modal fade"
      id="centermodal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content no-border">
          <form className="form" id="kt_modal_add_customer_form">
            <div className="modal-header">
              <h4 className="modal-title" id="myCenterModalLabel">
                Add Category
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body py-10 px-lg-17">
              <div
                className="scroll-y me-n7 pe-7"
                id="kt_modal_add_customer_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="fv-row mb-3">
                  <label className="required fs-6 fw-bold">Category Name</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="name"
                  />
                </div>

                <div className="fv-row mb-3">
                  <label htmlFor="product-description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    id="product-summary"
                    rows="3"
                    placeholder="Please enter summary"
                  ></textarea>
                </div>

                <div className="fv-row mb-3">
                  <label className="required fs-6 fw-bold">
                    Category Status
                  </label>
                  <select
                    name="client_id"
                    className="form-control form-select form-select-solid"
                    required
                  >
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="2">In-Active</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer flex-center">
              <SecondaryButton type="reset">Discard</SecondaryButton>
              <PrimaryButton>
                <span className="indicator-label">Create</span>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ManageCategoryModal;
