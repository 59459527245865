import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getUserByToken } from "../actions/authActions";
import Footer from "../components/footer";
import "react-toastify/dist/ReactToastify.css";
import { getcurrUserSubscriptionAction } from "../actions/subscriptionActions";
import HeaderV2 from "../components/headerV2";

// import openSocket from "socket.io-client";
// const socket = openSocket("http://localhost:5000");

function PublicLayout({ children }) {
  // if (!auth) {
  //   return <Navigate to="/auth" replace />;
  // }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { userInfo, profile, tokens, newRegisteration } = auth;

  const { pathname } = useLocation();

  // sendSocketIO() {
  // socket.emit("example_message", "demo");
  // }

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  useEffect(() => {
    // if (userInfo && userInfo.tokens) {
    //   dispatch(refreshToken(userInfo.tokens.refresh.token));
    // }
    if (tokens && !userInfo) {
      dispatch(getUserByToken(tokens.refresh.token));
    } else if (userInfo && userInfo.role === "user") {
      dispatch(getcurrUserSubscriptionAction(userInfo.id));
      // socket.emit("login", "userInfo.id");
    }
  }, [dispatch, tokens, userInfo]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.role === "user") {
        navigate("/watch/profiles");

        // if (profile) {
        //   navigate("/watch/all");
        // } else {
        //   navigate("/watch/profiles");
        // }
      } else navigate("/manage");
      // if (userInfo.role !== "user") navigate("/manage");
    }
  }, [userInfo, profile]);

  return (
    <div className="main-wrapper user-layout invisible-scroll">
      <HeaderV2 homeUrl="/" />
      {/* <Header /> */}
      <div className="page-content invisible-scroll">{children}</div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </div>
  );
}

export default PublicLayout;
