import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import { IoMdClose } from "react-icons/io";
import SecondaryButton from "../../../components/secondaryButton";

function ViewPermissions() {
  const navigate = useNavigate();
  const location = useLocation();

  const [permissionData, setPermissionData] = useState(null);
  const permissions = useSelector((state) => state.permissions);
  const { list, usersByRoles } = permissions;

  useEffect(() => {
    if (list.length === 0) {
      navigate("/manage/permissions");
    } else {
      const id = location.pathname.split("/")[4];
      if (id) {
        setPermissionData(list.find((x) => x.id === id));
      }
    }
  }, [location.pathname, navigate, list]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0"></ol>
              </div>
              <h4 className="page-title">Edit Permissions</h4>
            </div>
          </div>
        </div>
        {permissionData && (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="permission-box">
                    <div className="head">
                      <h3>
                        {permissionData.role.slice(0, 1).toUpperCase()}
                        {permissionData.role.slice(
                          1,
                          permissionData.role.length
                        )}
                      </h3>
                      <p>
                        Total user's with this role:{" "}
                        <span>
                          {permissionData.role === "admin"
                            ? usersByRoles.admins
                            : permissionData.role === "agent"
                            ? usersByRoles.agents
                            : permissionData.role === "accountant"
                            ? usersByRoles.accountants
                            : permissionData.role === "manager"
                            ? usersByRoles.managers
                            : permissionData.role === "editor"
                            ? usersByRoles.editors
                            : ""}
                        </span>
                      </p>
                    </div>
                    <table>
                      <tr className="table-head">
                        <td className="text-left detailed">Module</td>
                        <td className="detailed">Create</td>
                        <td className="detailed">Read</td>
                        <td className="detailed">Update</td>
                        <td className="detailed">Delete</td>
                      </tr>
                      {permissionData.permissions.map((x, index) => (
                        <tr key={x.id + index}>
                          <td className="text-left detailed">{x.type}</td>
                          <td className="detailed">
                            {x.modules.create ? (
                              <FcCheckmark />
                            ) : (
                              <IoMdClose color="red" />
                            )}
                          </td>
                          <td className="detailed">
                            {x.modules.read ? (
                              <FcCheckmark />
                            ) : (
                              <IoMdClose color="red" />
                            )}{" "}
                          </td>
                          <td className="detailed">
                            {x.modules.update ? (
                              <FcCheckmark />
                            ) : (
                              <IoMdClose color="red" />
                            )}{" "}
                          </td>
                          <td className="detailed">
                            {x.modules.delete ? (
                              <FcCheckmark />
                            ) : (
                              <IoMdClose color="red" />
                            )}
                          </td>
                        </tr>
                      ))}
                    </table>
                    <div className="my-4">
                      <SecondaryButton
                        onClick={() => navigate("/manage/permissions")}
                      >
                        Back
                      </SecondaryButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewPermissions;
