import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteShowAction } from "../../actions/videoActions";
import { cloudfrontUrl, UPDATE_USER_RESET } from "../../constants";
import TablePagination from "./tablePagination";

function ShowsTable({ tableColumns, data, modules }) {
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // const { auth } = state;
  // const { userInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  const deleteHandler = (id) => {
    dispatch(
      deleteShowAction({
        showId: id,
      })
    );
  };

  return (
    <div className="table-responsive">
      {data.length > 0 ? (
        <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
          <thead className="table-light">
            <tr>
              {/* <th style={{ width: "20px" }}>
                <div className="form-check">
                  <label className="form-check-label" for="customCheck1">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customCheck1"
                    />
                  </label>
                </div>
              </th> */}
              {tableColumns.map((column, index) => (
                <th className={column.className} key={column.key + index}>
                  {column.title}
                </th>
              ))}
              {modules.delete || modules.update ? (
                <th style={{ width: "82px" }}>Action</th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody>
            {data
              .slice(
                currentPage * entriesToShow - entriesToShow,
                currentPage * entriesToShow
              )
              .map((show, index) => (
                <tr key={show.id + index}>
                  {/* <td>
                    <div className="form-check">
                      <label className="form-check-label" for="customCheck2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheck2"
                        />
                      </label>
                    </div>
                  </td> */}
                  <td className="name">
                    <div className="media align-items-center">
                      <div className="iq-show float-start me-2">
                        <Link to="">
                          <img
                            src={`${cloudfrontUrl}/${show.thumbnailUrl}`}
                            className="img-border-radius avatar-40 img-fluid movie-thumbnail"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="media-body text-left ml-3 me-2">
                        <p className="mb-0">{show.title}</p>
                        <small>{show.duration}</small>
                      </div>
                    </div>
                  </td>
                  <td>{show[tableColumns[1].key]}</td>
                  <td>{show[tableColumns[2].key]}</td>
                  <td>{show[tableColumns[3].key]}</td>
                  <td>{show[tableColumns[4].key]}</td>
                  <td>{show.descriptionText}</td>
                  <td>{show[tableColumns[6].key]}</td>
                  <td>{show[tableColumns[7].key]}</td>

                  {/* <td>04-04-2022</td>
                <td>20-10-2021</td> */}
                  {/* <td>
                  {user.isEmailVerified ? (
                    <div className="badge badge-soft-success">Verified</div>
                  ) : (
                    <div className="badge badge-soft-danger">Not Verified</div>
                  )}
                </td> */}
                  {modules.delete || modules.update ? (
                    <td className="text-center">
                      {modules.update && (
                        <Link
                          onClick={() => dispatch({ type: UPDATE_USER_RESET })}
                          to={`/manage/shows/edit/${show.id}`}
                          className="action-icon"
                        >
                          <i className="mdi mdi-square-edit-outline"></i>
                        </Link>
                      )}
                      {modules.delete && (
                        <Link
                          onClick={() => deleteHandler(show.id)}
                          to=""
                          className="action-icon"
                        >
                          <i className="mdi mdi-delete"></i>
                        </Link>
                      )}
                    </td>
                  ) : (
                    <></>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <p>Shows not added !</p>
      )}

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default ShowsTable;
