import React from "react";

function WhyUs() {
  return (
    <div className="about-us-cont-area section-ptb bg-black">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-cont text-white-style">
              <div className="about-us-title">
                <h2>Why Choose Us</h2>
                <p>
                  Consectetur adipisicing elit, sed do eiusmod tempor incididunt
                  ut laqua. Ut enim ad minim veniam, quis{" "}
                </p>       
              </div>

              <p>
                Lorem ipsut amet, consectetur adipisicing elit, sed do irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint oecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum. Sed ut perspiciatis unde omnis{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-images s--mt--30">
              <img src="assets/images/about/01.jpg" alt="" />
            </div>
          </div>
        </div>

        <div className="row about-list-content">
          <div className="col-lg-4">
            <div className="single-about-area text-white-style">
              <h4>Our Mission</h4>

              <p>
                Lorem ipsut amet, consectetur adipisicing elit, sed do irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint oecat cupidatat non
                proident, sununt .
              </p>

              <ul className="about-feature-list">
                <li>
                  <i className="zmdi zmdi-check"></i> Mod tempor incididunt ut
                  laqua.{" "}
                </li>
                <li>
                  <i className="zmdi zmdi-check"></i> Ut enim ad minim quis
                  nostr.{" "}
                </li>
                <li>
                  <i className="zmdi zmdi-check"></i> Nostrud exercitation
                  ullamco{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="single-about-area text-white-style">
              <h4>Our Objective</h4>

              <p>
                Lorem ipsut amet, consectetur adipisicing elit, sed do irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint oecat cupidatat non
                proident, sununt .
              </p>

              <p>
                reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt{" "}
              </p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="single-about-area text-white-style">
              <h4>Our Achievement</h4>

              <p>
                Lorem ipsut amet, consectetur adipisicing elit, sed do irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu
                fugiat nulla pariatur. Excepteur sint oecat cupidatat non
                proident,{" "}
              </p>

              <p>
                sunt in culpa qui officia deserunt mollit anim id est laborum.
                Sed ut perspiciatis unde omnis Lorem ipsum dolor sit amet,
                consectetur adipisicin, sed do eiusmod tempor incididunt ut
                labore et dolor
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
