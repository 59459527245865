import React from "react";
import { Link } from "react-router-dom";

function HowItWorks() {
  return (
    <div className="video-area section-pt section-pb-b bg-black-2">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className="section-title text-center">
              <h2 className="text-white">See Videos How It Works</h2>
              <p className="text-white">
                Adminim veniam, quis nostrud exercitation ullamco laboris nisi
                ut pariatur. Excepteur t labore et dolore magnam aliquam quaerat{" "}
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="video-inner-wrap">
              <img src="assets/images/other/mokup-06.png" alt="" />
              <Link to="#Video-three" className="afterglow video-btn red">
                <i className="zmdi zmdi-play"></i>
              </Link>

              <video id="Video-three" width="960" height="540">
                <source
                  src="https://afterglowplayer.com/sandbox/v1/afterglow_local_hd.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
