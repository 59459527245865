import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import WalletsTable from "../../../components/dataTable/walletsTable";
import { getWalletsAction } from "../../../actions/walletActions";

const tableColumns = [
  "Wallet Pin",
  "Full Name",
  "Email",
  "Phone",
  "Role",
  "Amount",
];

function Wallets() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { wallets, currRolePermissions } = state;
  const { loading, list, error } = wallets;
  const { permission } = currRolePermissions;

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getWalletsAction());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      const tempData = list.filter((x) =>
        x.pin.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(tempData || []);
    }
  }, [search, list]);

  useEffect(() => {
    if (list) {
      setFilteredData(list);
    }
  }, [list]);

  return (
    <>
      {permission && (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0"></ol>
                  </div>
                  <h4 className="page-title">Wallets List</h4>
                </div>
              </div>
            </div>
            {list.length === 0 && loading ? (
              <div className="loader">
                <TailSpin color="#000" height={50} width={50} />
              </div>
            ) : error ? (
              <div className="loader">
                <div className="alert alert-danger mt-4" role="alert">
                  {error}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <form className="search-bar position-relative mb-sm-0 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="mdi mdi-magnify"></span>
                          </form>
                        </div>
                      </div>

                      <WalletsTable
                        modules={
                          permission.permissions.find(
                            (x) => x.type === "Wallets"
                          ).modules
                        }
                        tableColumns={tableColumns}
                        data={filteredData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Wallets;
