export const GET_FAVOURITE_REQUEST = "GET_FAVOURITE_REQUEST";
export const GET_FAVOURITE_SUCCESS = "GET_FAVOURITE_SUCCESS";
export const GET_FAVOURITE_FAIL = "GET_FAVOURITE_FAIL";

export const FAVOURITE_ADD_REQUEST = "FAVOURITE_ADD_REQUEST";
export const FAVOURITE_ADD_SUCCESS = "FAVOURITE_ADD_SUCCESS";
export const FAVOURITE_ADD_FAIL = "FAVOURITE_ADD_FAIL";

export const REMOVE_FAVOURITE_REQUEST = "REMOVE_FAVOURITE_REQUEST";
export const REMOVE_FAVOURITE_SUCCESS = "REMOVE_FAVOURITE_SUCCESS";
export const REMOVE_FAVOURITE_FAIL = "REMOVE_FAVOURITE_FAIL";

export const REMOVE_ALL_FAVOURITES_REQUEST = "REMOVE_ALL_FAVOURITES_REQUEST";
export const REMOVE_ALL_FAVOURITES_SUCCESS = "REMOVE_ALL_FAVOURITES_SUCCESS";
export const REMOVE_ALL_FAVOURITES_FAIL = "REMOVE_ALL_FAVOURITES_FAIL";
