import { DELETE_CHANNEL_SUCCESS } from "../constants/channelConstants";
import {
  CHANNEL_DETAILS_FAIL,
  CHANNEL_DETAILS_REQUEST,
  CHANNEL_DETAILS_SUCCESS,
  DELETE_EPISODE_FAIL,
  DELETE_EPISODE_SUCCESS,
  DELETE_MOVIE_SUCCESS,
  DELETE_SHOW_SUCCESS,
  EPISODE_DETAILS_FAIL,
  EPISODE_DETAILS_REQUEST,
  EPISODE_DETAILS_SUCCESS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_REQUEST,
  GET_CHANNELS_SUCCESS,
  GET_MOVIES_FAIL,
  GET_MOVIES_REQUEST,
  GET_MOVIES_SUCCESS,
  GET_SHOWS_FAIL,
  GET_SHOWS_REQUEST,
  GET_SHOWS_SUCCESS,
  GET_TRENDING_MOVIES_FAIL,
  GET_TRENDING_MOVIES_REQUEST,
  GET_TRENDING_MOVIES_SUCCESS,
  SHOW_EPISODES_LIST_FAIL,
  SHOW_EPISODES_LIST_REQUEST,
  SHOW_EPISODES_LIST_SUCCESS,
  UPDATE_MOVIE_SUCCESS,
  UPDATE_SHOW_SUCCESS,
  VIDEO_DETAILS_FAIL,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
} from "../constants/videoConstants";
import {
  GET_MOVIES_WATCH_HISTORY_LIST_SUCCESS,
  GET_MOVIE_WATCH_HISTORY_SUCCESS,
  GET_SHOW_WATCH_HISTORY_SUCCESS,
} from "../constants/watchHistoryConstants";

// Movies Reducer
export const moviesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_MOVIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MOVIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_MOVIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_MOVIE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    case DELETE_MOVIE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.id !== action.payload),
      };

    case GET_MOVIES_WATCH_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        watchHistory: action.payload,
      };
    default:
      return state;
  }
};

// Treanding Movies Reducer
export const trendingMoviesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_TRENDING_MOVIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRENDING_MOVIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_TRENDING_MOVIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

// Shows Reducer
export const showsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_SHOWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SHOWS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_SHOWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };
    case DELETE_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.id !== action.payload),
      };
    default:
      return state;
  }
};

// Episodes Reducer
export const episodesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case SHOW_EPISODES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SHOW_EPISODES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case SHOW_EPISODES_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_EPISODE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.id !== action.payload),
      };
    default:
      return state;
  }
};

// Current Video Reducer
export const selectedEpisodeReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case EPISODE_DETAILS_REQUEST:
      return {
        // ...state,

        loading: true,
      };
    case EPISODE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case EPISODE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_SHOW_WATCH_HISTORY_SUCCESS:
      return {
        ...state,
        watchHistory: action.payload,
      };
    default:
      return state;
  }
};

// Current Video Reducer
export const selectedVideoReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case VIDEO_DETAILS_REQUEST:
      return {
        // ...state,
        loading: true,
        data: null
      };
    case VIDEO_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case VIDEO_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_MOVIE_WATCH_HISTORY_SUCCESS:
      return {
        ...state,
        watchHistory: action.payload,
      };
    default:
      return state;
  }
};

// Channels Reducer
export const channelsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_CHANNELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CHANNELS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.reverse(),
      };
    case GET_CHANNELS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CHANNEL_SUCCESS:
      return {
        ...state,
        loading: false,
        list: state.list.filter((x) => x.id !== action.payload),
      };
    default:
      return state;
  }
};

// Current Channel Reducer
export const selectedChannelReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case CHANNEL_DETAILS_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case CHANNEL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CHANNEL_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
