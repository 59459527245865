import React from "react";

function FormSection() {
  const submitHandler = (e) => {
    e.preventDefault();
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="contact-form-wrap section-pb-90">
            <form id="contact-form" onSubmit={submitHandler}>
              <div className="contact-page-form">
                <div className="row contact-input">
                  <div className="col-lg-6 col-md-6 contact-inner black">
                    <input
                      name="name"
                      type="text"
                      placeholder="First Name"
                      id="first-name"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 contact-inner black">
                    <input
                      name="lastname"
                      type="text"
                      placeholder="Last Name"
                      id="last-name"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 contact-inner black">
                    <input
                      type="text"
                      placeholder="Enter Your E-mail"
                      id="email"
                      name="email"
                    />
                  </div>
                  <div className="col-lg-6 col-md-6 contact-inner black">
                    <input
                      name="subject"
                      type="text"
                      placeholder="Subject"
                      id="subject"
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 contact-inner black contact-message">
                    <textarea name="message" placeholder="Message"></textarea>
                  </div>
                </div>
                <div className="contact-submit-btn text-center">
                  <button className="submit-btn" type="submit">
                    Send Message
                  </button>
                  <p className="form-messege"></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSection;
