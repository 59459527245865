import React from "react";
import { Outlet } from "react-router-dom";
import AdminLayout from "../layout/adminLayout";

import Dashboard from "../screens/adminScreens/dashboard";
import ManageUsers from "../screens/adminScreens/users";
import AddUser from "../screens/adminScreens/users/addUser";
import EditUser from "../screens/adminScreens/users/editUser";
import ManageWallets from "../screens/adminScreens/wallets";
import ManageTransactions from "../screens/adminScreens/transactions";
import ManagePricing from "../screens/adminScreens/pricing";
import ManageMovies from "../screens/adminScreens/movies";
import AddMovie from "../screens/adminScreens/movies/addMovie";
import EditMovie from "../screens/adminScreens/movies/editMovie";
import ManageShows from "../screens/adminScreens/shows";
import AddShow from "../screens/adminScreens/shows/addShow";
import EditShow from "../screens/adminScreens/shows/editShow";
import ManageCategories from "../screens/adminScreens/categories";
import ManageChannels from "../screens/adminScreens/channels";
import AddChannels from "../screens/adminScreens/channels/addChannels";
import ManageSettings from "../screens/adminScreens/settings";
import ManagePermissions from "../screens/adminScreens/permissions";
import ViewPermissions from "../screens/adminScreens/permissions/viewPermissions";
import EditPermissions from "../screens/adminScreens/permissions/editPermissions";
import NotFound from "../screens/notFound";
import EditChannels from "../screens/adminScreens/channels/editChannels";
import ManageSubscriptions from "../screens/adminScreens/subscriptions";
import AddSubscription from "../screens/adminScreens/subscriptions/addSubscription";
import EditSubscription from "../screens/adminScreens/subscriptions/editSubscription";

export const adminRoutes = [
  {
    path: "manage",
    element: (
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    ),
    subRoutes: [
      { path: "", element: <Dashboard /> },
      { path: "permissions", element: <ManagePermissions /> },
      { path: "permissions/view/:id", element: <ViewPermissions /> },
      { path: "permissions/edit/:id", element: <EditPermissions /> },
      { path: "users", element: <ManageUsers /> },
      { path: "users/add", element: <AddUser /> },
      { path: "users/edit/:id", element: <EditUser /> },
      { path: "categories", element: <ManageCategories /> },
      { path: "wallets", element: <ManageWallets /> },
      { path: "transactions", element: <ManageTransactions /> },
      
      { path: "subscriptions", element: <ManageSubscriptions /> },
      { path: "subscriptions/add", element: <AddSubscription /> },
      { path: "subscriptions/edit/:id", element: <EditSubscription /> },

      { path: "channels", element: <ManageChannels /> },
      { path: "channels/add", element: <AddChannels /> },
      { path: "channels/edit/:id", element: <EditChannels /> },
      { path: "pricing", element: <ManagePricing /> },
      { path: "movies", element: <ManageMovies /> },
      { path: "movies/add", element: <AddMovie /> },
      { path: "movies/edit/:id", element: <EditMovie /> },
      { path: "shows", element: <ManageShows /> },
      { path: "shows/add", element: <AddShow /> },
      { path: "shows/edit/:id", element: <EditShow /> },
      { path: "settings", element: <ManageSettings /> },
      // { path: "*", element: <NotFound /> },
    ],
  },
];
