import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategoriesAction } from "../../../actions/categoryActions";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import VideoUpload from "../../../components/videoUpload";
import axiosPrivate from "../../../services/api";
import { Uploader } from "../../../services/fileUploader";
import moment from "moment";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";
import { getPackagesAction } from "../../../actions/packageActions";
import Select from "react-select";
import { getUsersAction } from "../../../actions/userActions";

function AddSubscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { users, packages } = state;

  const [thumbnailPath, setThumbnailPath] = useState("");
  const [bgImagePath, setBgImagePath] = useState("");
  const [titleImagePath, setTitleImagePath] = useState("");
  const [movieUploadDetails, setMovieUploadDetails] = useState({ path: "" });
  const [clipUploadDetails, setClipMovieUploadDetails] = useState({ path: "" });
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const changeHandler = (e) => {
    setSubscriptionDetails({
      ...subscriptionDetails,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axiosPrivate.post("/subscriptions/createByAdmin", {
        ...subscriptionDetails,
        paymentType: "Mobile Money",
      });
      notifySuccess("Subscription Added Successfully !");
      setLoading(false);
      navigate("/manage/subscriptions");
    } catch (err) {
      notifyFailure(err.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(getCategoriesAction());
    dispatch(getPackagesAction());
    dispatch(getUsersAction());
  }, [dispatch]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Create Subscription</h4>
            </div>
          </div>
        </div>
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                  </h5> */}

                  <div className="mb-3">
                    <label htmlFor="product-name" className="form-label">
                      User <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="form-control react-select"
                      // value={selectedOption}
                      onChange={(e) =>
                        setSubscriptionDetails((prev) => ({
                          ...prev,
                          user: e.id,
                        }))
                      }
                      options={users.list
                        .filter((x) => x.role == "user")
                        .map((x) => ({
                          id: x.id,
                          label: `${x.firstName} ${x.lastName} ( ${x.accountNumber} )`,
                        }))}
                    />
                    {/* <input
                      type="text"
                      className="form-control"
                      placeholder="e.g : Apple iMac"
                      name="title"
                      onChange={changeHandler}
                      required
                    /> */}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-industry" className="form-label">
                      Package <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="product-industry"
                      name="package"
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      {packages.list.map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.type} ( ${x.subscriptionFee} )
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-categoryq" className="form-label">
                      Expiring On <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control "
                      type="date"
                      min={
                        new Date(moment().add(30, "days"))
                          .toISOString()
                          .split("T")[0]
                      }
                      name="expiringOn"
                      onChange={changeHandler}
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="product-category" className="form-label">
                      Status <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="product-category"
                      name="status"
                      onChange={changeHandler}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Expired">Expired</option>
                      <option value="Unsubscribed">Unsubscribed</option>
                    </select>
                  </div> */}
                </div>
                <div className="text-center mb-3">
                  <PrimaryButton>
                    {loading ? (
                      <ThreeDots color="#000000" height={20} width={50} />
                    ) : (
                      <>
                        <i className="fe-check-circle me-1"></i> Create
                      </>
                    )}{" "}
                  </PrimaryButton>
                  <SecondaryButton onClick={() => navigate("/manage/movies")}>
                    <i className="fe-x me-1"></i> Cancel
                  </SecondaryButton>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              {/* <div className="text-center mb-3">
                <PrimaryButton>
                  {loading ? (
                    <ThreeDots color="#000000" height={20} width={50} />
                  ) : (
                    <>
                      <i className="fe-check-circle me-1"></i> Create
                    </>
                  )}{" "}
                </PrimaryButton>
                <SecondaryButton onClick={() => navigate("/manage/movies")}>
                  <i className="fe-x me-1"></i> Cancel
                </SecondaryButton>{" "}
              </div> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddSubscription;
