import React from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { cloudfrontUrl } from "../../constants";

function GenreCard({ data, type }) {
  const navigate = useNavigate();

  return (
      <div className="movie-wrap-plr">
        <div
          className="genre-wrap text-center"
          onClick={() =>
            navigate({
              pathname: `/watch/${type}`,
              search: createSearchParams({
                category: data.name,
              }).toString(),
            })
          }
        >
          <img
            src={`${cloudfrontUrl}/${data.bgImage}`}
            alt=""
          />
          <div className="movie-img">
            {/* <Link to={``}> */}
            <img
              // src={`${cloudfrontUrl}/thumbnails/Get-Away-If-You-Can.jpg`}
              src={`${cloudfrontUrl}/${data.fgImage}`}
              alt=""
              className="fg-img"
            />
            {/* </Link> */}
          </div>
          <h3 className="title">
            <Link to={``}>{data.name}</Link>
          </h3>
        </div>
      </div>
  );
}

export default GenreCard;
