import {
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  OLD_REGISTRAION,
  UPDATE_USER,
  UPDATE_USER_INFO,
  USER_AUTH_FAIL,
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_LOGOUT_SUCCESS,
} from "../constants";

import {
  ADD_PROFILE_HANDLER,
  CHOOSE_PROFILE_HANDLER,
  EDIT_PROFILE_HANDLER,
} from "../constants/profileConstants";

export const authReducer = (
  state = {
    auth: {
      userInfo: {},
      tokens: null,
      loading: false,
    },
  },
  action
) => {
  switch (action.type) {
    case USER_AUTH_REQUEST:
      return {
        ...state,

        loading: true,
      };
    case USER_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload.user,

        tokens: action.payload.tokens,
        // newRegisteration: action.payload.register ? true : false,
      };
    case USER_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        userInfo: null,
        tokens: null,
        profile: null,
      };

    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
      };
    case GET_USER_FAIL:
      return {
        userInfo: null,
        tokens: null,
      };
    case UPDATE_USER:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
      };
    case CHOOSE_PROFILE_HANDLER:
      return {
        ...state,
        profile: action.payload,
      };
    case ADD_PROFILE_HANDLER:
      return {
        ...state,
        profile: null,
      };
    case EDIT_PROFILE_HANDLER:
      return {
        ...state,
        profile: action.payload,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case OLD_REGISTRAION:
      return {
        ...state,
        newRegisteration: false,
      };
    default:
      return state;
  }
};
