import { TOGGLE_CHANNEL_SIDEBAR } from "../constants";

export const sidebarReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case TOGGLE_CHANNEL_SIDEBAR:
      return {
        ...state,
        channelSidebar:
          action.payload == undefined ? !state.channelSidebar : action.payload,
      };
    default:
      return state;
  }
};
