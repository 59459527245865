import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { EffectCreative, Pagination, Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import { useInView } from "react-intersection-observer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { cloudfrontUrl } from "../../constants";

function HeroImageSlider({ data, checkView, setHeroSecInView }) {
  const { inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (checkView) setHeroSecInView(inView);
  }, [inView, checkView, setHeroSecInView]);

  return (
    <>
      <Swiper
        grabCursor={data.length > 1}
        pagination={data.length > 1}
        navigation={data.length > 1}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
            duration: 2000,
            // translate: ["100%", 0, -400],
          },
          next: {
            shadow: true,
            translate: ["100%", 0, -400],
            duration: 2000,
          },
        }}
        autoplay
        // breakpoints={{
        //   320: {
        //     navigation: false,
        //   },
        //   640: {
        //     navigation: false,
        //   },
        //   768: {
        //     navigation: false,
        //   },
        //   1024: {
        //     navigation: true,
        //   },
        // }}
        modules={[EffectCreative, Navigation, Pagination]}
        className="hero-slider"
      >
        {data.map((x, index) => {
          return (
            <div key={x.id + index}>
              <SwiperSlide key={x.id + index}>
                <div className="single-hero-img-2">
                  <LazyLoadImage
                    src={`${cloudfrontUrl}/${x.sliderImageUrl}`}
                    alt=""
                    className="bg"
                  />
                </div>
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </>
  );
}

export default HeroImageSlider;
