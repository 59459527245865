import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteProfileAction,
  getProfilesAction,
} from "../../../actions/profileActions";
import ProfileCard from "./profileCard";
import { availableProfiles } from "./profilesData";
import PrimaryButton from "../../../components/primaryButton";
import {
  ADD_PROFILE_HANDLER,
  EDIT_PROFILE_HANDLER,
} from "../../../constants/profileConstants";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ChooseProfiles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth } = state;
  const { profile } = auth;

  useEffect(() => {
    dispatch(getProfilesAction());
  }, [dispatch]);

  const clickHandler = (profileData) => {
    if (profile) {
      dispatch({
        type: EDIT_PROFILE_HANDLER,
        payload: { ...profileData, name: profile.name, id: profile.id },
      });
      navigate(`/watch/profiles/edit/${profile.id}`);
    } else {
      dispatch({
        type: ADD_PROFILE_HANDLER,
        payload: profileData,
      });
      navigate(`/watch/profiles/add`);
    }
  };

  const deleteHandler = () => {
    dispatch(deleteProfileAction(profile.id, handleRoute));
  };

  const handleRoute = () => {
    navigate("/watch/profiles");
  };

  return (
    <div className="profile-page">
      <div className="profile-bg">
        <div className="title full">
          <div>
            <Link to="" onClick={() => navigate(-1)}>
              <IoIosArrowBack size={28} />
            </Link>
            <h3>Choose your avatar</h3>
          </div>
          {profile && (
            <PrimaryButton onClick={deleteHandler}>Delete</PrimaryButton>
          )}
        </div>
        <Swiper
          className="profile-slider"
          // slidesPerView={6}
          spaceBetween={20}
          centeredSlides={true}
          grabCursor={true}
          pagination={true}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            0: {
              width: 0,
              slidesPerView: 1,
            },
            // when window width is >= 320px
            320: {
              width: 320,
              slidesPerView: 1,
            },
            // when window width is >= 540px
            540: {
              width: 540,
              slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 3,
            },
          }}
        >
          {availableProfiles.map((profileData, index) => (
            // <div>
            <SwiperSlide key={profileData.id + index}>
              <ProfileCard
                data={profileData}
                onClick={() => clickHandler(profileData)}
                // edit
                textImg
              />
            </SwiperSlide>
            // </div>
          ))}
        </Swiper>
        {/* <div className="title"> */}
        {/* </div> */}
      </div>
    </div>
  );
}

export default ChooseProfiles;
