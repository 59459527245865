import { toast } from "react-toastify";
import {
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  USER_AUTH_FAIL,
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_SUCCESS,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../constants";
import { GET_USER_SUBSCRIPTION_SUCCESS } from "../constants/subscriptionConstants";

import { axiosPublic } from "../services/api";
import { getcurrUserSubscriptionAction } from "./subscriptionActions";
import { notifyFailure, notifySuccess } from "../components/toaster/toast";

//USER REGISTER
export const userRegisterAction =
  (obj, sendVerificationEmail) => async (dispatch) => {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    try {
      const { data } = await axiosPublic.post(`/auth/register`, obj);
      localStorage.setItem("tokens", JSON.stringify(data.tokens));

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: { ...data, register: true },
      });
      notifySuccess("Account registered successfully, please log in!");
      sendVerificationEmail(data);
    } catch (err) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//USER LOGIN
export const userLogin = (obj) => async (dispatch) => {
  dispatch({
    type: USER_AUTH_REQUEST,
  });
  try {
    const { data } = await axiosPublic.post(`/auth/login`, obj);
    localStorage.setItem("tokens", JSON.stringify(data.tokens));
    // localStorage.setItem("tokens", JSON.stringify(data.tokens));
    dispatch({
      type: USER_AUTH_SUCCESS,
      payload: { user: data.user, tokens: data.tokens },
    });
    dispatch({
      type: GET_USER_SUBSCRIPTION_SUCCESS,
      payload: data.subscription,
    });
  } catch (err) {
    dispatch({
      type: USER_AUTH_FAIL,
      payload: err.response.data.message,
    });
  }
};

//USER LOGOUT
export const userLogout = (obj) => async (dispatch) => {
  try {
    await axiosPublic.post(`/auth/logout`, obj);
    dispatch({
      type: USER_LOGOUT_SUCCESS,
    });
    localStorage.removeItem("tokens");
    localStorage.removeItem("selectedPackage");
    localStorage.removeItem("profile");
  } catch (err) {
    dispatch({
      type: USER_LOGOUT_FAIL,
      payload: "err.response.data.message",
    });
  }
};

//GET Users via Admin
export const getUserByToken = (token) => async (dispatch) => {
  dispatch({
    type: GET_USER_REQUEST,
  });
  try {
    const { data } = await axiosPublic.post(`/users/refresh-token`, {
      refreshToken: token,
    });

    dispatch({
      type: GET_USER_SUCCESS,
      payload: data,
    });
    if (data.role === "user") {
      dispatch(getcurrUserSubscriptionAction(data.id));
    }
  } catch (err) {
    dispatch({
      type: GET_USER_FAIL,
      payload: err.response.data.message,
    });
  }
};
