import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEpisodeByIdAction,
  updateVideoViewsAction,
} from "../../../actions/videoActions";
import { useLocation, useNavigate } from "react-router-dom";
import { cloudfrontUrl } from "../../../constants";

import { SOCKET_BASE_URL } from "../../../services/api";
import io from "socket.io-client";
import { getShowWatchHistoryByProfileAndVideoId } from "../../../actions/watchHistoryActions";
import CustomPlayer from "../../../components/customPlayer";
import { TailSpin } from "react-loader-spinner";

const socket = io(SOCKET_BASE_URL);

function PlayShow() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = useSelector((state) => state);
  const { auth, selectedEpisode, shows, episodes } = state;

  const { tokens, userInfo, profile } = auth;
  const { data, watchHistory } = selectedEpisode;

  const id = location.pathname.split("/")[4];

  const [suggestedVideo, setSuggestedVideo] = useState({ data: null });
  const [showNextVideo, setShowNextVideo] = useState(false);
  const [nextVideoFlag, setNextVideoFlag] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);

  const [showSuggestion, setShowSuggestion] = useState(false);

  const [isReady, setIsReady] = useState(false);
  const [backIsReady, setBackIsReady] = useState(false);

  const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(true);

  const onReady = useCallback(() => {
    if (!isReady) {
      const timeToStart = watchHistory
        ? watchHistory.completed
          ? 0
          : watchHistory.watchedTime
        : 0;

      playerRef.current.currentTime = timeToStart;
      playerRef.current.play();
      setIsReady(true);
    }
  }, [isReady, watchHistory]);

  useEffect(() => {
    if (isReady && userInfo && data) {
      socket.emit("start_show", {
        profileId: profile.id,
        episodeId: id,
        showId: data?.show?.id,
        time: playerRef?.current?.currentTime,
        totalDuration: playerRef?.current?.duration,
      });
    }
  }, [isReady, userInfo, data, id, profile]);

  useEffect(() => {
    if (!tokens) {
      navigate(`/signin?redirect=/watch/shows/${id}`);
    } else {
      if (id) {
        setShowNextVideo(false);
        setNextVideoFlag(false);
        setIsReady(false);
        if (!data || data?.id !== id) {
          setLoadVideo(false);
          dispatch(getEpisodeByIdAction(id));
        }
        dispatch(
          getShowWatchHistoryByProfileAndVideoId({
            profile: profile.id,
            video: id,
          })
        );

        dispatch(
          updateVideoViewsAction({
            video: id,
            modelNameOfTheVideoType: "Show",
            count: 1,
          })
        );
      } else {
        navigate("/watch/shows");
      }
    }
  }, [id, data, dispatch, navigate, profile.id, tokens]);

  useEffect(() => {
    socket.on("start_watching_show", async (data) => {
      setBackIsReady(true);
    });
  }, []);

  useEffect(() => {
    const interVal = setInterval(() => {
      if (isPlaying && backIsReady) {
        socket.emit("show_play", {
          profileId: profile?.id,

          episodeId: id,
          showId: data?.show?.id,
          time: playerRef?.current?.currentTime,
          totalDuration: playerRef?.current?.duration,
        });

        if (
          !nextVideoFlag &&
          playerRef?.current?.duration - playerRef?.current?.currentTime < 180
        ) {
          setNextVideoFlag(true);
        }
      }
    }, 5000);

    return () => {
      clearInterval(interVal);
    };
  }, [backIsReady, data, id, isPlaying, nextVideoFlag, profile]);

  useEffect(() => {
    if (nextVideoFlag) {
      setShowNextVideo(true);
    }
  }, [nextVideoFlag]);

  useEffect(() => {
    const videoEndHandler = () => {
      navigate(`/watch/shows/${id}`, {
        state: {
          loadData: false,
        },
      });
    };

    playerRef?.current?.addEventListener("ended", videoEndHandler);
  }, [playerRef]);

  const [closeTimeOut, setCloseTimeOut] = useState(true);

  const handleMouseMove = (event) => {
    if (closeTimeOut) {
      setShowSuggestion(true);
      setCloseTimeOut(false);
    }
  };

  useEffect(() => {
    if (!showSuggestion) {
      setTimeout(() => {
        setCloseTimeOut(true);
      }, 5000);
    }
  }, [showSuggestion]);

  useEffect(() => {
    if (data) {
      const episodeFound = episodes.list.find(
        (x) => x.episodeNumber == data.episodeNumber + 1
      );
      if (episodeFound) {
        setSuggestedVideo({ data: episodeFound, directPlay: true });
      } else {
        setSuggestedVideo({
          data: shows.list.filter((x) => x.id !== data?.show?.id)[0]?.id,
          directPlay: true,
        });
      }
    }
  }, [episodes.list, id, data, shows.list]);

  useEffect(() => {
    if (data) {
      setLoadVideo(true);
    }
  }, [data]);

  return (
    <div className="video-player-full" onMouseMove={handleMouseMove}>
      {loadVideo && data ? (
        <>
          <CustomPlayer
            videoRef={playerRef}
            videoType="Show"
            videoId={data.id}
            src={`${cloudfrontUrl}/${data.videoUrl}`}
            onReady={onReady}
            data={data}
            title={`${data.title} ${data.quality}`}
            titleImg={`${cloudfrontUrl}/${data?.show?.titleImageUrl}`}
            showNextVideo={showNextVideo}
            setShowNextVideo={setShowNextVideo}
            nextVideoFlag={nextVideoFlag}
            setNextVideoFlag={setNextVideoFlag}
            suggestedVideo={suggestedVideo}
            setLoadVideo={setLoadVideo}
          />
        </>
      ) : (
        <div className="loader">
          <TailSpin color="#fff" height={50} width={50} />
        </div>
      )}
    </div>
  );
}

export default PlayShow;
