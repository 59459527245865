
// //Auth Constants
// export const CHANNEL_REGISTER_REQUEST = "CHANNEL_REGISTER_REQUEST";
// export const CHANNEL_REGISTER_SUCCESS = "CHANNEL_REGISTER_SUCCESS";
// export const CHANNEL_REGISTER_FAIL = "CHANNEL_REGISTER_FAIL";

// export const CHANNEL_AUTH_REQUEST = "CHANNEL_AUTH_REQUEST";
// export const CHANNEL_AUTH_SUCCESS = "CHANNEL_AUTH_SUCCESS";
// export const CHANNEL_AUTH_FAIL = "CHANNEL_AUTH_FAIL";

// export const TOKEN_REFRESH_REQUEST = "TOKEN_REFRESH_REQUEST";
// export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
// export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";

// export const CHANNEL_LOGOUT_REQUEST = "CHANNEL_LOGOUT_REQUEST";
// export const CHANNEL_LOGOUT_SUCCESS = "CHANNEL_LOGOUT_SUCCESS";
// export const CHANNEL_LOGOUT_FAIL = "CHANNEL_LOGOUT_FAIL";

// export const GET_CHANNEL_REQUEST = "GET_CHANNEL_REQUEST";
// export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
// export const GET_CHANNEL_FAIL = "GET_CHANNEL_FAIL";

// export const UPDATE_CHANNEL_INFO = "UPDATE_CHANNEL_INFO";

// Channel Contants
export const GET_CHANNELS_REQUEST = "GET_CHANNELS_REQUEST";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_FAIL = "GET_CHANNELS_FAIL";

export const CREATE_CHANNEL_REQUEST = "CREATE_CHANNEL_REQUEST";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const CREATE_CHANNEL_FAIL = "CREATE_CHANNEL_FAIL";
export const CREATE_CHANNEL_RESET = "CREATE_CHANNEL_RESET";

export const UPDATE_CHANNEL_REQUEST = "UPDATE_CHANNEL_REQUEST";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_FAIL = "UPDATE_CHANNEL_FAIL";
export const UPDATE_CHANNEL_RESET = "UPDATE_CHANNEL_RESET";

export const DELETE_CHANNEL_REQUEST = "DELETE_CHANNEL_REQUEST";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_FAIL = "DELETE_CHANNEL_FAIL";
