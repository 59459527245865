export const GET_PERMISSION_REQUEST = "GET_PERMISSION_REQUEST";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const GET_PERMISSION_FAIL = "GET_PERMISSION_FAIL";

export const GET_ROLE_PERMISSION_REQUEST = "GET_ROLE_PERMISSION_REQUEST";
export const GET_ROLE_PERMISSION_SUCCESS = "GET_ROLE_PERMISSION_SUCCESS";
export const GET_ROLE_PERMISSION_FAIL = "GET_ROLE_PERMISSION_FAIL";

export const CREATE_PERMISSION_REQUEST = "CREATE_PERMISSION_REQUEST";
export const CREATE_PERMISSION_SUCCESS = "CREATE_PERMISSION_SUCCESS";
export const CREATE_PERMISSION_FAIL = "CREATE_PERMISSION_FAIL";

export const UPDATE_PERMISSION_REQUEST = "UPDATE_PERMISSION_REQUEST";
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";

export const DELETE_PERMISSION_REQUEST = "DELETE_PERMISSION_REQUEST";
export const DELETE_PERMISSION_SUCCESS = "DELETE_PERMISSION_SUCCESS";
export const DELETE_PERMISSION_FAIL = "DELETE_PERMISSION_FAIL";
