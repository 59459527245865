import { Uploader } from "../services/fileUploader";

export const uploadImage = async (event, path, successHandler) => {
  const file = event.target.files[0];
  let fileName = file.name;
  if (fileName.includes(" ")) {
    fileName = fileName.replaceAll(" ", "-");
  }
  let percentage = undefined;
  const fileUploaderOptions = {
    fileName: `${path}/${fileName}`,
    file: file,
  };
  successHandler(`${path}/${fileName}`);
  // setThumbnailPath(`${path}/${file.name}`);
  const uploader = new Uploader(fileUploaderOptions);
  // setUploader(uploader);

  uploader
    .onProgress(({ percentage: newPercentage }) => {
      // to avoid the same percentage to be logged twice
      if (newPercentage !== percentage) {
        percentage = newPercentage;
        // setProgress(percentage);
      }
    })
    .onError((error) => {
      // setFile(undefined);
      console.error(error);
    });

  uploader.start();
};
