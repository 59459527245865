import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  CREATE_CHANNEL_FAIL,
  CREATE_CHANNEL_REQUEST,
  CREATE_CHANNEL_SUCCESS,
  DELETE_CHANNEL_FAIL,
  DELETE_CHANNEL_REQUEST,
  DELETE_CHANNEL_SUCCESS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_REQUEST,
  GET_CHANNELS_SUCCESS,
  UPDATE_CHANNEL_FAIL,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
} from "../constants/channelConstants";

import axiosPrivate from "../services/api";

//GET Channels via Admin
export const getChannelsAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CHANNELS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/channels`);

    dispatch({
      type: GET_CHANNELS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_CHANNELS_FAIL,
      payload: err.response.data.message,
    });
  }
};

//Create Channel via Admin
export const createChannelAction =
  (channelData, statusHandler) => async (dispatch) => {
    statusHandler({
      loading: true,
    });
    dispatch({
      type: CREATE_CHANNEL_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/channels`, channelData);
      statusHandler({
        loading: false,
        success: true,
      });
      dispatch({
        type: CREATE_CHANNEL_SUCCESS,
        payload: data,
      });
    } catch (err) {
      statusHandler({
        loading: false,
        error: err.response.data.message,
      });
      dispatch({
        type: CREATE_CHANNEL_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//Update Channel via Admin
export const updateChannelAction =
  ({ channelData, channelId }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_CHANNEL_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(
        `/channels/${channelId}`,
        channelData
      );

      notifySuccess("Channel Updated Successfully !");

      dispatch({
        type: UPDATE_CHANNEL_SUCCESS,
        payload: data,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);

      dispatch({
        type: UPDATE_CHANNEL_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//Delete Channel via Admin
export const deleteChannelAction = (channelId) => async (dispatch) => {
  dispatch({
    type: DELETE_CHANNEL_REQUEST,
  });
  try {
    await axiosPrivate.delete(`/channels/${channelId}`);
    notifySuccess("Channel Deleted Successfully !");

    dispatch({
      type: DELETE_CHANNEL_SUCCESS,
      payload: channelId,
    });
  } catch (err) {
    notifyFailure(err.response.data.message);
    dispatch({
      type: DELETE_CHANNEL_FAIL,
      payload: err.response.data.message,
    });
  }
};
