import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  addFavouiteAction,
  deleteFavouriteAction,
} from "../../actions/favouriteActions";
import { cloudfrontUrl } from "../../constants";
import PrimaryButton from "../primaryButton";
import { FaPlay } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";

function VideoSlide({
  data,
  videoDetail,
  addedToList,
  videoType,
  videoId,
  showImage,
  hidePlayButton,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadingFlag, setLoadingFlag] = useState(false);

  // const { bg, textImage, bgVideo, description } = data;

  const state = useSelector((state) => state);

  const { auth, selectedVideo } = state;
  const { userInfo, profile } = auth;

  const { watchHistory } = selectedVideo;

  const addFavouriteHandler = () => {
    if (userInfo) {
      dispatch(
        addFavouiteAction(
          {
            video: data.id,
            profile: profile.id,
            modelNameOfTheVideoType: videoType,
          },
          setLoadingFlag
        )
      );
    }
  };

  const deleteFavouriteHandler = () => {
    dispatch(
      deleteFavouriteAction(
        { video: data.id, profile: profile.id },
        setLoadingFlag
      )
    );
  };

  const handleScroll = (section) => {
    const element = document.getElementById(section);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth", align: false });
    }
  };

  return (
    <div className="single-hero-img-2">
      {/* <LazyLoadImage src={bg} alt="" className="bg" /> */}
      {!showImage ? (
        <video className="bg" autoPlay muted loop>
          <source src={`${cloudfrontUrl}/${data.bgVideoUrl}`} />
        </video>
      ) : (
        <LazyLoadImage src={`${cloudfrontUrl}/${data.bgImageUrl}`} />
      )}

      <div className="content">
        <div className="row">
          <div className="col-md-6 px-md-4">
            <LazyLoadImage
              src={`${cloudfrontUrl}/${data.titleImageUrl}`}
              alt=""
              className="text-img"
            />
            <div className="video-detail-cont">
              <span>
                <LazyLoadImage
                  src="https://www.peacocktv.com/webwatch/release/prod/3.10.12/assets/audience-score.svg"
                  alt=""
                />
                <p>70%</p>
              </span>

              <span>
                <LazyLoadImage
                  src="https://www.peacocktv.com/webwatch/release/prod/3.10.12/assets/audience-score.svg"
                  alt=""
                />
                <p>89%</p>
              </span>

              <span>
                {data.addedOn} &bull; {data.category} &bull; {data.duration}
              </span>
            </div>
            {videoDetail && (
              <div className="button-div">
                {addedToList ? (
                  <PrimaryButton onClick={deleteFavouriteHandler}>
                    {loadingFlag ? (
                      <ThreeDots color="#fff" height={19} width={19} />
                    ) : (
                      <>
                        <i className="fe-check-circle me-1"></i> My List
                      </>
                    )}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton onClick={addFavouriteHandler}>
                    {loadingFlag ? (
                      <ThreeDots color="#fff" height={19} width={19} />
                    ) : (
                      <>
                        <i className="fe-plus-circle me-1"></i> My List
                      </>
                    )}
                  </PrimaryButton>
                )}
                {!hidePlayButton && (
                  <PrimaryButton
                    onClick={() =>
                      navigate(
                        `/watch/${videoType.toLowerCase()}s/play/${data.id}`
                      )
                    }
                  >
                    <FaPlay size={16} />{" "}
                    {watchHistory
                      ? watchHistory.completed
                        ? "Watch Again"
                        : watchHistory.watchedTime > 0
                        ? "Continue Watching"
                        : "Watch Now"
                      : "Watch Now"}
                  </PrimaryButton>
                )}
                <PrimaryButton onClick={() => handleScroll("moreLikeThis")}>
                  <FaInfoCircle size={16} /> More Like This
                </PrimaryButton>
                {videoType === "Show" && (
                  <PrimaryButton onClick={() => handleScroll("episodes")}>
                    Episodes
                  </PrimaryButton>
                )}
              </div>
            )}
          </div>

          <div className="col-md-6">
            <p className="desc-text">
              {data.descriptionText.slice(0, 200)}.....
            </p>
          </div>
        </div>
        {/* <div className="row sticky sticky-top">
          {videoDetail && (
            <div className="button-div">
              {addedToList ? (
                <PrimaryButton onClick={deleteFavouriteHandler}>
                  {loadingFlag ? (
                    <ThreeDots color="#fff" height={19} width={19} />
                  ) : (
                    <>
                      <i className="fe-check-circle me-1"></i> My List
                    </>
                  )}
                </PrimaryButton>
              ) : (
                <PrimaryButton onClick={addFavouriteHandler}>
                  {loadingFlag ? (
                    <ThreeDots color="#fff" height={19} width={19} />
                  ) : (
                    <>
                      <i className="fe-plus-circle me-1"></i> My List
                    </>
                  )}
                </PrimaryButton>
              )}
              {!hidePlayButton && (
                <PrimaryButton
                  onClick={() =>
                    navigate(
                      `/watch/${videoType.toLowerCase()}s/play/${data.id}`
                    )
                  }
                >
                  <FaPlay size={16} />{" "}
                  {watchHistory
                    ? watchHistory.completed
                      ? "Watch Again"
                      : watchHistory.watchedTime > 0
                      ? "Continue Watching"
                      : "Watch Now"
                    : "Watch Now"}
                </PrimaryButton>
              )}
              <PrimaryButton>
                <FaInfoCircle size={16} /> More Like This
              </PrimaryButton>
              <PrimaryButton>Extras</PrimaryButton>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default VideoSlide;
