import React from "react";
import { Modal } from "react-bootstrap";

function Popup({ showModal, setShowModal, children }) {
  return (
    <Modal
      show={showModal}
      fullscreen={false}
      onHide={() => setShowModal({ open: false, type: null })}
      centered
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Modal.Header>
        {/* <Modal.Title>
          Alert
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="modal-inner">{children}</div>
        {/* {type === "success" && (
          <SuccessModal message={message}>{children}</SuccessModal>
        )} */}
      </Modal.Body>
    </Modal>
  );
}

export default Popup;
