import React, { memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import 'swiper/css/effect-coverflow';

import { Navigation, Mousewheel, Keyboard, EffectCoverflow } from "swiper";

import VideoCardV2 from "../cards/videoCardV2";
import { cloudfrontUrl } from "../../constants";

function VideoSlider({
  title,
  list,
  videoType,
  directPlay,
  keySuffix,
  dynamicBack,
  backTo,
  showProgress,
  showMoreHandler,
}) {
  return (
    <div>
      {list.length > 0 && (
        <div className="video-slider-section">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p>{title}</p>
            {/* <p>View More</p> */}
            {list.length > 0 && showMoreHandler && (
              <button onClick={showMoreHandler}>
                <span>More</span>
                <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
              </button>
            )}
          </div>
          <Swiper
            // cssMode={true}
            slidesPerView={2}
            spaceBetween={20}
            // slidesPerGroup={1}
            // autoplay={{
            //   delay: 8000,
            //   duration: 3000,
            //   disableOnInteraction: false,
            // }}
            breakpoints={{
              320: {
                slidesPerView: 2,
                // slidesPerGroup: 20,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2.5,
                // slidesPerGroup: 20,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                // slidesPerGroup: 20,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                // slidesPerGroup: 20,
                spaceBetween: 20,
              },
            }}
            //   loop={true}
            //   loopFillGroupWithBlank={false}
            navigation={true}
            // mousewheel={true}
            // keyboard={true}
            modules={[Navigation, Mousewheel, Keyboard, EffectCoverflow]}
            className="video-slider"
          >
            {list.map((video, index) => {
              if (video)
                return (
                  // <div key={keySuffix + video.id}>
                  <SwiperSlide key={index}>
                    <VideoCardV2
                      data={video}
                      video={video}
                      videoType={videoType}
                      cloudBaseUrl={cloudfrontUrl}
                      directPlay={directPlay}
                      backTo={backTo}
                      showProgress={showProgress}
                    />
                  </SwiperSlide>
                  // </div>
                );
              else return <></>;
            })}
          </Swiper>
        </div>
      )}
    </div>
  );
}

export default memo(VideoSlider);
