import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategoriesTable from "../../../components/dataTable/categoriesTable";
import PrimaryButton from "../../../components/primaryButton";
import ManageCategoryModal from "./manageCategoryModal";
import { getCategoriesAction } from "../../../actions/categoryActions";
import { TailSpin } from "react-loader-spinner";

const tableColumns = ["Name", "Desription", "Videos", "Added On", "Status"];

function Categories() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { categories, currRolePermissions } = state;
  const { loading, list, error } = categories;
  const { permission } = currRolePermissions;

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      setFilteredData(list);
    }
  }, [list]);

  return (
    <>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <div className="page-title-right"></div>
                <h4 className="page-title">Categories</h4>
              </div>
            </div>
          </div>
          {list.length === 0 && loading ? (
            <div className="loader">
              <TailSpin color="#000" height={50} width={50} />
            </div>
          ) : error ? (
            <div className="loader">
              <div className="alert alert-danger mt-4" role="alert">
                {error}
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row justify-content-between mb-2">
                      <div className="col-auto">
                        <form className="search-bar position-relative mb-sm-0 mb-2">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                          />
                          <span className="mdi mdi-magnify"></span>
                        </form>
                      </div>
                      <div className="col-md-6">
                        <div className="text-md-end">
                          {/* <Link
                        // onClick={() => dispatch({ type: CREATE_USER_RESET })}
                        to="/manage/add-categories"
                        className="btn text-white mi-bgre waves-effect waves-light mb-2 me-2"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1"></i> Add
                        Category
                      </Link> */}
                          {/* <button
                          type="button"
                          className="btn text-white mi-bgre waves-effect waves-light mb-2 me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#centermodal"
                        >
                          Add Category
                        </button> */}
                          <PrimaryButton
                            data-bs-toggle="modal"
                            data-bs-target="#centermodal"
                          >
                            Add Category
                          </PrimaryButton>
                        </div>
                      </div>
                    </div>
                    {permission && (
                      <CategoriesTable
                        modules={
                          permission.permissions.find(
                            (x) => x.type === "Categories"
                          ).modules
                        }
                        tableColumns={tableColumns}
                        data={filteredData}
                      />
                    )}{" "}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ManageCategoryModal />
    </>
  );
}

export default Categories;
