import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import { useNavigate } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getUserWalletAction } from "../../../actions/walletActions";
import CustomStripe from "../../../components/customStripe";
import Popup from "../../../components/modal";

import axiosPrivate from "../../../services/api";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

function WalletComponent({ showWalletBtn }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth, currUserWallet, currUserSubscription } = state;

  const { userInfo } = auth;
  const { loading, data } = currUserWallet;

  const [status, setStatus] = useState("");

  const [paymentDetails, setPaymentDetails] = useState({
    amount: "",
    email: "",
  });

  const [showModal, setShowModal] = useState({ open: false });
  const [showPaymentDetails, setShowPaymentDetails] = useState({
    open: false,
  });
  const [paymentToken, setPaymentToken] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState({});

  const [paymentMethod, setPaymentMethod] = useState({
    card: false,
    agent: false,
  });

  const [paymentMethodFlag, setPaymentMethodFlag] = useState(false);

  const changeHandler = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const addAmountHandler = () => {
    setPaymentDetails({ ...paymentDetails, email: userInfo.email });
    setShowModal({ open: true, type: null });
  };
  const paymentMethodHandler = (e) => {
    e.preventDefault();
    setPaymentMethodFlag(true);
  };

  const handlePayment = async () => {
    setPaymentStatus({
      loading: true,
    });
    try {
      await axiosPrivate.post(`/transactions/pay`, {
        token: paymentToken,
        payer: auth.userInfo.id,
        receiver: auth.userInfo.id,
        type: "Wallet",
        transactionBy: "Customer",
        typeId: data.id,
        modelNameOfTypeId: "Wallet",
        amount: paymentDetails.amount,
        email: paymentDetails.email,
      });
      dispatch(getUserWalletAction(userInfo.id));

      setPaymentStatus({
        loading: false,
      });
      setShowModal({ open: false, type: null });
    } catch (err) {
      setPaymentStatus({
        loading: false,
        error: err.response.data.message,
      });
      // setStatus("error");
    }
  };

  useEffect(() => {
    if (paymentToken) {
      handlePayment();
    }
  }, [paymentToken]);

  return (
    <>{data&&<>
      <div className="current-plan-wrapper white">
        <div className="section-title-plan mb--20">
          <h3>Wallet</h3>
        </div>

        <div className="dic-pricing">
          <div className="row">
            <div className="col-md-4 col-xs-12">
              <div className="dic-pricing-header ps-4">
                <h4 className="package">Available Blanace</h4>
                <div className="price">
                  <span className="currency">$</span>
                  <span className="amount">{data.amount}</span>
                  <span className="period"></span>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-12">
              {/* <div className="dic-pricing-items miwlpr">
      <ul>
        <li>
          Payment Type: <br />
          Credit Card <br /> <span>4000 xxxx xxxx xxxx</span>
        </li>
      </ul>
    </div> */}
            </div>
            <div className="col-md-4 col-xs-12 d-flex align-items-center">
              <div className="dic-pricing-btn">
                <PrimaryButton onClick={addAmountHandler}>
                  Add amount
                </PrimaryButton>
                {showWalletBtn && (
                  <SecondaryButton
                    onClick={() => navigate("/my-account/wallet")}
                  >
                    View Wallet
                  </SecondaryButton>
                )}

                {/* <StripeComponent makePayment={makePayment} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup showModal={showModal.open} setShowModal={setShowModal}>
        {!paymentMethodFlag ? (
          <div className="text-left">
            <div>
              <h3>Select Payment Method</h3>
              <p></p>
            </div>
            <form
              onSubmit={paymentMethodHandler}
              className="payment-method-form"
            >
              <div className="my-2">
                <div className="m-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="payment-method"
                    id="agent"
                    onChange={(e) =>
                      setPaymentMethod({
                        card: false,
                        agent: e.target.checked,
                      })
                    }
                  />
                  <label className="form-check-label mx-1" htmlFor="agent">
                    Cash
                  </label>
                </div>
                <div className="m-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="payment-method"
                    id="mobileMoney"
                    onChange={(e) =>
                      setPaymentMethod({
                        agent: false,
                        mobileMoney: e.target.checked,
                      })
                    }
                  />
                  <label className="form-check-label mx-1" htmlFor="card">
                    Mobile Money
                  </label>
                </div>
                {/* <div className="m-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="payment-method"
                    id="card"
                    onChange={(e) =>
                      setPaymentMethod({
                        agent: false,
                        card: e.target.checked,
                      })
                    }
                  />
                  <label className="form-check-label mx-1" htmlFor="card">
                    Card
                  </label>
                </div> */}
              </div>
              <PrimaryButton type="submit">Next</PrimaryButton>
            </form>
          </div>
        ) : paymentMethod.card ? (
          <>
            <div className="d-flex align-items-center">
              <MdOutlineKeyboardBackspace
                size={20}
                onClick={() => setPaymentMethodFlag(false)}
              />

              <h3 className="mx-2">Pay via Card</h3>
            </div>
            <CustomStripe
              setPaymentToken={setPaymentToken}
              // amount={amount}
              changeHandler={changeHandler}
              paymentStatus={paymentStatus}
              paymentDetails={paymentDetails}
            />
          </>
        ) : paymentMethod.agent ? (
          <>
            <div className="d-flex align-items-center">
              <MdOutlineKeyboardBackspace
                size={20}
                onClick={() => setPaymentMethodFlag(false)}
              />

              <h3 className="mx-2">Pay via Cash</h3>
            </div>
            <div>
              <p>
                Your <b>Wallet</b> pin # is <b>{data.pin}</b>. Please visit your
                nearest <b>Infisom Agent</b> to add credits to your wallet.
              </p>{" "}
            </div>
          </>
        ) : (
          paymentMethod.mobileMoney && (
            <>
              {" "}
              <div className="d-flex align-items-center">
                <MdOutlineKeyboardBackspace
                  size={20}
                  onClick={() => setPaymentMethodFlag(false)}
                />

                <h3 className="mx-2">Mobile Money</h3>
              </div>
              <div>
                {/* <p className="text-align-left"><b>Account # 7876253227389</b></p> */}
                <p>
                  Please transfer the amount to this{" "}
                  <b>Mobile Account # 7876253227389</b> and Call customer
                  support <b>252-615-5678</b> to add the amount in your wallet.
                </p>

                <p>
                  <b>Note:</b> Don't forget to share your wallet pin number with
                  customer support agent. Your wallet pin number is
                  <b> {data?.pin}</b>
                </p>
              </div>
            </>
          )
        )}
      </Popup>
</>}
    </>
  );
}

export default WalletComponent;
