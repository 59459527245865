import { useCallback } from "react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace("/" + param, ""),
    location.pathname
  );
};

export const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref]);

  return isIntersecting;
};

export function useInViewport() {
  const [isInViewport, setIsInViewport] = useState(false);
  const [refElement, setRefElement] = (useState < HTMLElement) | (null > null);

  const setRef = useCallback((node) => {
    if (node !== null) {
      setRefElement(node);
    }
  }, []);

  useEffect(() => {
    if (refElement && !isInViewport) {
      const observer = new IntersectionObserver(
        ([entry]) => entry.isIntersecting && setIsInViewport(true)
      );
      observer.observe(refElement);

      return () => {
        observer.disconnect();
      };
    }
  }, [isInViewport, refElement]);

  return { isInViewport, ref: setRef };
}

export const useIntersection = (element, rootMargin) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    element.current && observer.observe(element.current);

    return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};
