import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getUserWalletAction } from "../../../actions/walletActions";
import CustomStripe from "../../../components/customStripe";
import Popup from "../../../components/modal";
import PrimaryButton from "../../../components/primaryButton";
import axiosPrivate from "../../../services/api";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import moment from "moment";
import {
  createSubscriptionAction,
  renewSubscriptionAction,
} from "../../../actions/subscriptionActions";

function Checkout() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  const { auth, selectedPackage, currUserWallet, currUserSubscription } = state;
  const { userInfo } = auth;
  const { data } = currUserWallet;

  // const [status, setStatus] = useState("");
  const status = "";

  if (!userInfo) {
    navigate("/signin");
  }

  useEffect(() => {
    if (!selectedPackage) {
      navigate("/buy-now");
    }
  }, [navigate, selectedPackage]);

  // const makePayment = async (token) => {
  //   setStatus("loading");
  //   const body = {
  //     userId: auth.userInfo.id,
  //     token,
  //     package: selectedPackage,
  //   };

  //   try {
  //     await axiosPrivate.post(`/transactions/pay`, body);
  //     dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
  //     setStatus("success");
  //   } catch (err) {
  //     setStatus("error");
  //   }
  // };

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showModal, setShowModal] = useState({
    open: false,
    type: null,
  });
  const [paymentToken, setPaymentToken] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState({});

  const [paymentMethod, setPaymentMethod] = useState({
    card: false,
    agent: false,
    mobileMoney: false,
  });

  const [paymentMethodFlag, setPaymentMethodFlag] = useState(false);

  let disableBack = false;

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserWalletAction(userInfo.id));
    }
  }, [dispatch, userInfo]);
  //   const makePayment = async (token) => {
  //     setStatus("loading");
  //     const body = {
  //     //   package: selectedPackage,
  //     token: token,
  //     payer: auth.userInfo.id,
  //     type: "Wallet",
  //     typeId: data.id,
  //     modelNameOfTypeId: "Wallet",
  //     amount: "",
  //     status: "",
  //     };

  //     try {
  //       await axiosPrivate.post(`/transactions/pay`, body);
  //     //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
  //       setStatus("success");
  //     } catch (err) {
  //       setStatus("error");
  //     }
  //   };

  useEffect(() => {
    const handlePayment = async (body) => {
      setPaymentStatus({
        loading: true,
      });
      try {
        await axiosPrivate.post(`/transactions/payUsingStripe`, body);
        dispatch(getUserWalletAction(userInfo.id));

        setPaymentStatus({
          loading: false,
        });
        setShowModal(false);

        //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
        // setStatus("success");
      } catch (err) {
        setPaymentStatus({
          loading: false,
          error: err.response.data.message,
        });
        // setStatus("error");
      }
    };
    if (paymentToken) {
      if (showModal.type === "wallet") {
        handlePayment({
          token: paymentToken,
          payer: userInfo.id,
          receiver: userInfo.id,
          transactionBy: "Customer",
          type: "Wallet",
          typeId: data.id,
          modelNameOfTypeId: "Wallet",
          amount: paymentDetails.amount,
          email: paymentDetails.email,
        });
      } else if (showModal.type === "package") {
        handlePayment({
          token: paymentToken,
          payer: userInfo.id,
          receiver: userInfo.id,
          transactionBy: "Customer",
          type: "Package",
          typeId: selectedPackage.id,
          modelNameOfTypeId: "Package",
          amount: paymentDetails.amount,
          email: paymentDetails.email,
        });
      }
    }
  }, [
    dispatch,
    paymentToken,
    userInfo,
    userInfo,
    data,
    paymentDetails,
    selectedPackage,
    showModal,
  ]);

  const changeHandler = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const paymentMethodHandler = (e) => {
    e.preventDefault();
    setPaymentMethodFlag(true);
  };

  const walletPaymentHandler = async () => {
    setPaymentStatus({
      loading: true,
    });
    try {
      await axiosPrivate.post(`/transactions/payUsingWallet`, {
        payer: userInfo.id,
        receiver: userInfo.id,
        transactionBy: "Customer",
        type: "Package",
        typeId: selectedPackage.id,
        modelNameOfTypeId: "Package",
        amount: selectedPackage.subscriptionFee,
        email: userInfo.email,
      });
      dispatch(getUserWalletAction(userInfo.id));

      setPaymentStatus({
        loading: false,
      });
      setShowModal(false);

      //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
      // setStatus("success");
    } catch (err) {
      setPaymentStatus({
        loading: false,
        error: err.response.data.message,
      });
      // setStatus("error");
    }
  };

  const cardPaymentHandler = () => {
    disableBack = true;
    setPaymentDetails({
      amount: selectedPackage.subscriptionFee,
      email: userInfo.email,
    });
    setPaymentMethodFlag(true);
    setPaymentMethod({
      card: true,
      agent: false,
    });
    setShowModal({ open: true, type: "package" });
    // setPaymentStatus({
    //   loading: true,
    // });
    // try {
    //   await axiosPrivate.post(`/transactions/pay`, {
    //     token: paymentToken,
    //     payer: auth.userInfo.id,
    //     receiver: auth.userInfo.id,
    //     type: "Wallet",
    //     typeId: data.id,
    //     modelNameOfTypeId: "Wallet",
    //     amount: paymentDetails.amount,
    //     email: paymentDetails.email,
    //   });
    //   dispatch(getUserWalletAction(userInfo.id));
    //   setPaymentStatus({
    //     loading: false,
    //   });
    //   setShowModal(false);
    //   //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
    //   // setStatus("success");
    // } catch (err) {
    //   setPaymentStatus({
    //     loading: false,
    //     error: err.response.data.message,
    //   });
    //   // setStatus("error");
    // }
  };

  const mobileMoneyHandler = () => {
    if (currUserSubscription.subscriptionChecked && currUserSubscription.data) {
      dispatch(
        renewSubscriptionAction(
          currUserSubscription.data.id,
          {
            // user: userInfo.id,
            package: selectedPackage.id,
            expiringOn: moment().add(30, "days").format("YYYY-MM-DD"),
            paymentType: "Mobile Money",
            status: "Inactive",
          },
          successHandler
        )
      );
    } else {
      dispatch(
        createSubscriptionAction(
          {
            // user: userInfo.id,
            package: selectedPackage.id,
            expiringOn: moment().add(30, "days").format("YYYY-MM-DD"),
            paymentType: "Mobile Money",
            status: "Inactive",
          },
          successHandler
        )
      );
    }
  };

  const successHandler = () => {
    setShowModal({ open: true });
    setPaymentMethodFlag(true);
    setPaymentMethod({ mobileMoney: true });
  };
  return (
    <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4 mt-5">
      <div className="container">
        <div className="row">
          <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4">
            {data && (
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="current-plan-wrapper white">
                      <div className="section-title-plan mb--20 d-flex aligns-items-center justify-content-between">
                        <h3>Selected Plan</h3>{" "}
                        <Link
                          className="btn-two-style theme-color-four py-2"
                          to="/my-account/pricing"
                        >
                          Change Plan
                        </Link>
                      </div>

                      <div className="dic-pricing">
                        <div className="row">
                          <div className="col-md-3 col-xs-12">
                            <div className="dic-pricing-header">
                              <h4 className="package">
                                {selectedPackage.type}
                              </h4>
                              <div className="price">
                                <span className="currency">$</span>
                                <span className="amount">
                                  {selectedPackage.subscriptionFee}
                                </span>
                                <span className="period">/mo</span>
                              </div>
                              <div className="old-price">
                                <span>
                                  $ {selectedPackage.subscriptionFee + 2}/mo
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 col-xs-12">
                            <div className="dic-pricing-items">
                              <ul>
                                {selectedPackage.description.map(
                                  (desc, index) => (
                                    <li key={desc + index}>{desc}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-4 col-xs-12 d-flex align-items-center">
                            <div className="col-md">
                              <h5 className="text-white">
                                Choose payment method
                              </h5>
                              <div className="payment-method-button mt--10">
                                <div className="btn--payment">
                                  {/* <Link
                          to=""
                          className="btn-two-style theme-color-four"
                          onClick={paymentHandler}
                        >
                          {" "}
                          Pay Using Stripe
                        </Link> */}
                                  {/* <StripeComponent makePayment={makePayment} /> */}
                                  <PrimaryButton
                                  // onClick={() => setShowModal(true)}
                                  // onClick={walletPaymentHandler}
                                  >
                                    Wallet
                                  </PrimaryButton>
                                  {/* <PrimaryButton
                                  // onClick={() => setShowModal(true)}
                                  onClick={cardPaymentHandler}
                                >
                                  Pay via card
                                </PrimaryButton> */}

                                  <PrimaryButton
                                    // onClick={() => setShowModal(true)}
                                    onClick={mobileMoneyHandler}
                                  >
                                    Mobile Money
                                  </PrimaryButton>
                                </div>
                              </div>
                            </div>
                            {/* <div className="dic-pricing-btn">
                    
                        {status === "" && (
                          <Link
                            className="btn-two-style theme-color-four"
                            to="/my-account/pricing"
                          >
                            Change Plan
                          </Link>
                        )}
                      </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="current-plan-wrapper white">
                      <div className="section-title-plan mb--20">
                        <h3>Wallet</h3>
                      </div>

                      <div className="dic-pricing">
                        <div className="row">
                          <div className="col-md-4 col-xs-12">
                            <div className="dic-pricing-header ps-4">
                              <h4 className="package">Available Blanace</h4>
                              <div className="price">
                                <span className="currency">$</span>
                                <span className="amount">{data.amount}</span>
                                <span className="period"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 col-xs-12">
                            <div className="dic-pricing-items miwlpr">
                              <ul>
                                <li>
                                  Payment Type: <br />
                                  Credit Card <br />{" "}
                                  <span>4000 xxxx xxxx xxxx</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-4 col-xs-12 d-flex align-items-center">
                            <div className="dic-pricing-btn">
                              <PrimaryButton onClick={() => setShowModal(true)}>
                                Add amount
                              </PrimaryButton>
                              {/* <SecondaryButton>Add Plan</SecondaryButton> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}{" "}
            <Popup showModal={showModal.open} setShowModal={setShowModal}>
              {!paymentMethodFlag ? (
                <div className="text-left">
                  <div>
                    <h3>Select Payment Method</h3>
                    <p></p>
                  </div>
                  <form
                    onSubmit={paymentMethodHandler}
                    className="payment-method-form"
                  >
                    <div className="my-2">
                      <div className="m-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment-method"
                          id="agent"
                          onChange={(e) =>
                            setPaymentMethod({
                              card: false,
                              agent: e.target.checked,
                            })
                          }
                        />
                        <label
                          className="form-check-label mx-1"
                          htmlFor="agent"
                        >
                          Pay via Cash
                        </label>
                      </div>
                      <div className="m-1">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="payment-method"
                          id="card"
                          onChange={(e) =>
                            setPaymentMethod({
                              agent: false,
                              card: e.target.checked,
                            })
                          }
                        />
                        <label className="form-check-label mx-1" htmlFor="card">
                          Pay via Card
                        </label>
                      </div>
                    </div>
                    <PrimaryButton type="submit">Next</PrimaryButton>
                  </form>
                </div>
              ) : paymentMethod.card ? (
                <>
                  <div className="d-flex align-items-center">
                    {disableBack && (
                      <MdOutlineKeyboardBackspace
                        size={20}
                        onClick={() => setPaymentMethodFlag(false)}
                      />
                    )}

                    <h3 className="mx-2">Pay via Card</h3>
                  </div>
                  <CustomStripe
                    setPaymentToken={setPaymentToken}
                    // amount={amount}
                    changeHandler={changeHandler}
                    paymentStatus={paymentStatus}
                    paymentDetails={paymentDetails}
                    disableBack
                    disableChange
                  />
                </>
              ) : paymentMethod.agent ? (
                <>
                  <div className="d-flex align-items-center">
                    <MdOutlineKeyboardBackspace
                      size={20}
                      onClick={() => setPaymentMethodFlag(false)}
                    />

                    <h3 className="mx-2">Pay via Cash</h3>
                  </div>
                  <div>
                    <p>
                      Your <b>Wallet</b> pin # is <b>{data.pin}</b>. Please
                      visit your nearest <b>Infisom Agent</b> to add credits to
                      your wallet.
                    </p>{" "}
                  </div>
                </>
              ) : (
                paymentMethod.mobileMoney && (
                  <>
                    <div className="d-flex align-items-center">
                      {/* <MdOutlineKeyboardBackspace
                  size={20}
                  onClick={() => setPaymentMethodFlag(false)}
                /> */}

                      <h3 className="mx-2">Mobile Money</h3>
                    </div>
                    <div>
                      {/* <p className="text-align-left"><b>Account # 7876253227389</b></p> */}
                      <p>
                        Please transfer the subscription amount
                        <b> ${selectedPackage.subscriptionFee}</b> for the{" "}
                        <b>{selectedPackage.type} Package</b> to this{" "}
                        <b>Mobile Account # 7876253227389</b> and Call customer
                        support <b>252-615-5678</b> to activate the subscription
                        and enjoy watching Infisom.
                      </p>

                      <p>
                        <b>Note:</b> Don't forget to share your user account
                        number with customer support agent. Your user account
                        number is
                        <b> {userInfo.accountNumber}</b>
                      </p>
                    </div>
                  </>
                )
              )}
            </Popup>
          </main>
        </div>
      </div>
    </main>
  );
}

export default Checkout;
