import React from "react";
import Slider from "react-slick";
import VideoCard from "../../components/cards/videoCard";

function ComedyShows({ settings, list }) {
  return (
    <div className="series-list section-padding-lr section-ptb-50 bg-black">
      <div className="container-fluid">
        <div className="section-title-4 st-border-bottom">
          <h2>Comedy Series</h2>
        </div>
        <div className="movie-slider-active nav-style-2">
          <Slider {...settings}>
            {list.map((show, index) => (
              <VideoCard key={show.id + index} data={show} videoType="Show" />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ComedyShows;
