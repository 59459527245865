import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserWalletAction } from "../../../actions/walletActions";
import CustomStripe from "../../../components/customStripe";
import Popup from "../../../components/modal";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import axiosPrivate from "../../../services/api";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import WalletComponent from "./walletComponent";

function Wallet() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth, currUserWallet } = state;

  const { userInfo } = auth;
  const { data } = currUserWallet;

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showModal, setShowModal] = useState({ open: false });
  const [paymentToken, setPaymentToken] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState({});

  const [paymentMethod, setPaymentMethod] = useState({
    card: false,
    agent: false,
  });
  const [paymentMethodFlag, setPaymentMethodFlag] = useState(false);

  useEffect(() => {
    dispatch(getUserWalletAction(userInfo.id));
  }, [dispatch, userInfo]);

  useEffect(() => {
    const handlePayment = async () => {
      setPaymentStatus({
        loading: true,
      });
      try {
        await axiosPrivate.post(`/transactions/pay`, {
          token: paymentToken,
          payer: auth.userInfo.id,
          receiver: auth.userInfo.id,
          transactionBy: "Customer",
          type: "Wallet",
          typeId: data.id,
          modelNameOfTypeId: "Wallet",
          amount: paymentDetails.amount,
          email: paymentDetails.email,
        });
        dispatch(getUserWalletAction(userInfo.id));

        setPaymentStatus({
          loading: false,
        });
        setShowModal({ open: false });
      } catch (err) {
        setPaymentStatus({
          loading: false,
          error: err.response.data.message,
        });
      }
    };
    if (paymentToken) {
      handlePayment();
    }
  }, [paymentToken, auth, data, dispatch, paymentDetails]);

  const changeHandler = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const paymentMethodHandler = (e) => {
    e.preventDefault();
    setPaymentMethodFlag(true);
  };

  return (
    <>
      {/* <div className="breadcrumb-area breadcrumb-modify-padding bg-black-2">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-style-2 center">
                  <h2>My Wallet</h2>
                  <ul className="breadcrumb-list-2 black">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Wallet</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4 mt-5">
        <div className="container">
          <div className="row">
            {" "}
            {data && (
              <div className="mt-5">
                <div className="row">
                  <div className="col-lg-12 me-auto ms-auto">
                    <WalletComponent />

                    <div className="movie-list-top-bar">
                      <div className="movie-list-title">
                        <h2 className="title">Recent Transaction History</h2>
                      </div>
                      {/* <div className="movie-list-clear">
                      <PrimaryButton>Clear history</PrimaryButton>
                    </div> */}
                    </div>
                    <div className="movielist-wrap">
                      <div className="single-movielist">
                        <div className="movielist-img-content p-3">
                          <div className="movielist-content">
                            {/* <h3 className="title"></h3> */}
                            <p>
                              <span className="text-success">+ 35$</span> Added
                              to wallet{" "}
                              <small className="text-white">
                                @ 16:20:01, 04/04/2022
                              </small>
                            </p>
                          </div>
                        </div>
                        {/* <div className="movielist-close">
                        <Link className="watchlist-close-btn" to="#">
                          <i className="zmdi zmdi-close"></i>
                        </Link>
                      </div> */}
                      </div>
                      <div className="single-movielist">
                        <div className="movielist-img-content p-3">
                          <div className="movielist-content">
                            {/* <h3 className="title"></h3> */}
                            <p>
                              <span className="text-danger">- 24$</span>{" "}
                              Deducted for{" "}
                              <strong className="text-white">
                                3 month standard plan
                              </strong>{" "}
                              <small className="text-white">
                                @ 16:20:01, 25/03/2022
                              </small>
                            </p>
                          </div>
                        </div>
                        {/* <div className="movielist-close">
                        <Link className="movielist-close-btn" to="">
                          <i className="zmdi zmdi-close"></i>
                        </Link>
                      </div> */}
                      </div>
                      <div className="single-movielist">
                        <div className="movielist-img-content p-3">
                          <div className="movielist-content">
                            {/* <h3 className="title"></h3> */}
                            <p>
                              <span className="text-success">+ 105$</span> Added
                              to wallet{" "}
                              <small className="text-white">
                                @ 16:20:01, 04/03/2022
                              </small>
                            </p>
                          </div>
                        </div>

                        {/* <div className="movielist-close">
                        <Link className="watchlist-close-btn" to="">
                          <i className="zmdi zmdi-close"></i>
                        </Link>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Popup showModal={showModal.open} setShowModal={setShowModal}>
        {!paymentMethodFlag ? (
          <div className="text-left">
            <div>
              <h3>Select Payment Method</h3>
              <p></p>
            </div>
            <form
              onSubmit={paymentMethodHandler}
              className="payment-method-form"
            >
              <div className="my-2">
                <div className="m-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="payment-method"
                    id="agent"
                    onChange={(e) =>
                      setPaymentMethod({
                        card: false,
                        agent: e.target.checked,
                      })
                    }
                  />
                  <label className="form-check-label mx-1" htmlFor="agent">
                    Pay via Cash
                  </label>
                </div>
                <div className="m-1">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="payment-method"
                    id="card"
                    onChange={(e) =>
                      setPaymentMethod({
                        agent: false,
                        card: e.target.checked,
                      })
                    }
                  />
                  <label className="form-check-label mx-1" htmlFor="card">
                    Pay via Card
                  </label>
                </div>
              </div>
              <PrimaryButton type="submit">Next</PrimaryButton>
            </form>
          </div>
        ) : paymentMethod.card ? (
          <>
            <div className="d-flex align-items-center">
              <MdOutlineKeyboardBackspace
                size={20}
                onClick={() => setPaymentMethodFlag(false)}
              />

              <h3 className="mx-2">Pay via Card</h3>
            </div>
            <CustomStripe
              setPaymentToken={setPaymentToken}
              changeHandler={changeHandler}
              paymentStatus={paymentStatus}
            />
          </>
        ) : (
          paymentMethod.agent && (
            <>
              <div className="d-flex align-items-center">
                <MdOutlineKeyboardBackspace
                  size={20}
                  onClick={() => setPaymentMethodFlag(false)}
                />

                <h3 className="mx-2">Pay via Cash</h3>
              </div>
              <div>
                <p>
                  Your <b>Wallet</b> pin # is <b>{data.pin}</b>. Please visit
                  your nearest <b>Infisom Agent</b> to add credits to your
                  wallet.
                </p>
              </div>
            </>
          )
        )}
      </Popup>
    </>
  );
}

export default Wallet;
