import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Footer() {
  var settings = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <footer className="border-bottom">
        <div className="container pt-5 ">
          <div className="row">
            <div className="col-md-3  mb-3">
              <div className="footer-logo mb-3">
                <Link to="/">
                  <LazyLoadImage
                    src="/assets/new-images/infisom-logo.png"
                    alt=""
                    height={100}
                  />
                </Link>
              </div>
              {/* <div className="footer-text">
                <p>
                  Eiusmod tempor incididunt ut la abore et minim ven exerc
                  itation ulla mco lboris naliquip comm.
                </p>
              </div> */}
            </div>
            <div className="col-md-3  mb-3">
              <div className="footer-text">
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/watch/movies">Movies</Link>
                  </li>
                  {/* <li>
                    <Link to="/watch/shows">Shows</Link>
                  </li>
                  <li>
                    <Link to="/watch/tv">TV Shows</Link>
                  </li> */}
                  <li>
                    <Link to="/watch/sports">Sports</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3  mb-3">
              <div className="footer-text">
                <h3>Privacy & Terms</h3>
                <ul>
                  <li>
                    <Link to="">Report a Bug</Link>
                  </li>
                  <li>
                    <Link to="">Request a Feature</Link>
                  </li>

                  <li>
                    <Link to="">Support</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3  mb-3 text-right">
              <div className="footer-text">
                <h3>Follow Us</h3>
              </div>
              <div className="footer-social">
                <ul>
                  <li>
                    <Link to="">
                      {/* <i className="fa fa-facebook"></i> */}
                      <FaFacebookF />
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      {/* <i className="fa fa-twitter"></i> */}
                      <BsTwitter />
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      {/* <i className="fa fa-linkedin"></i> */}
                      <FaLinkedinIn />
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      {/* <i className="fa fa-instagram"></i> */}
                      <BsInstagram />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-text">
                <ul className="d-flex">
                  <li>
                    <Link to="">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="">Terms of Services</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <footer className="p-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-text-bottom">
                <p>
                  Copyright ©2022 All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
