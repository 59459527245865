import React, { useState } from "react";
import Topbar from "../../../components/topbar";
import LeftSidebar from "../../../components/leftSidebar";
import ChannelSidebar from "./channelSidebar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getChannelsAction } from "../../../actions/videoActions";
import { useMediaQuery } from "react-responsive";

function IPTV({ children }) {
  // const [showSidebar, setShowSidebar] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  // const query = location.search ? location.search.split("=")[1] : null;

  // const state = useSelector((state) => state);
  // const { channels, trendingMovies, categories, auth } = state;
  // const { userInfo } = auth;
  // const { list } = channels;

  useEffect(() => {
    dispatch(getChannelsAction(location.pathname.split("/")[3]));
  }, []);

  return (
    <div className="wrapper">
      {/* <Topbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} /> */}
      {isTabletOrMobile ? (
        <>
        {/* <ChannelPlayer /> */}
          <div className="content-page">{children}</div>
        </>
      ) : (
        <>
          <ChannelSidebar
          // showSidebar={showSidebar}
          // setShowSidebar={setShowSidebar}
          />
          <div className="content-page">{children}</div>
        </>
      )}
    </div>
  );
}

export default IPTV;
