import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Modal from "../../../components/modal";
import { OLD_REGISTRAION } from "../../../constants";
import {
  getPackagesAction,
  packageDetailsAction,
  selectPackageAction,
} from "../../../actions/packageActions";
import axiosPrivate, { axiosNoAuth, axiosPublic } from "../../../services/api";
import { userRegisterAction } from "../../../actions/authActions";
import ModalContent from "./modalContent";
import axios from "axios";

function BuyPackage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { auth, userRegister, packages } = state;
  const { loading, userInfo, error } = userRegister;

  const { list } = packages;

  const [showModal, setShowModal] = useState(false);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    package: "",
  });

  const changeHandler = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (userInfo) {
      setUserData({
        ...userInfo,
        // package: data.find((x) => x.type === "Basic")
      });
    } else {
      // navigate("/auth");
      setUserData({
        ...auth.userInfo,
        // package: data.find((x) => x.type === "Basic")
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (!list) {
      dispatch(getPackagesAction());
    }
  }, [dispatch, list]);

  const submitHandler = (e) => {
    e.preventDefault();

    //   if (userInfo.isEmailVerified) {
    //     navigate()

    //   } else {
    //     sendVerificationEmail();
    //   }
    dispatch(
      selectPackageAction({
        // ...userData,
        package: list.find((x) => x.id === userData.package),
      })
    );
    navigate("/my-account/checkout");
  };


  return (
    <>
      {/* <div className="breadcrumb-area breadcrumb-modify-padding bg-black-3">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="container">
                  <div className="breadcrumb-style-2 center">
                    <h2>Buy Now</h2>
                    <ul className="breadcrumb-list-2 black">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>{userInfo ? "Buy Now" : "Sign up"}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <main className="my-account-wrapper pt-4 section-pb-90 bg-black-4 mt-5">
        <div className="container">
          <div className="row"> 
      <div className="register-page bg-black section-pt-90 section-pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 m-auto">
              <div className="login-register-wrapper">
                  <h2>Confirm Details</h2>
                <div className="login-form-container border-black">
                  <div className="login-register-form black-style">

                    <form onSubmit={submitHandler} autoComplete="off">
                      <div className="login-input-box">
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="">
                              First Name <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              value={userData.firstName}
                              onChange={changeHandler}
                              className="form-control form-control-solid"
                              placeholder="First Name"
                              required
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="">
                              Last Name <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              value={userData.lastName}
                              onChange={changeHandler}
                              className="form-control form-control-solid"
                              placeholder="Last Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="">
                              Email <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={userData.email}
                              onChange={changeHandler}
                              className="form-control form-control-solid mb-1"
                              placeholder="Email"
                              required
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="">
                              Phone/Mobile <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="text"
                              name="phone"
                              value={userData.phone}
                              onChange={changeHandler}
                              className="form-control form-control-solid mb-1"
                              placeholder="Phone"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label htmlFor="">
                              Package <sup className="text-danger">*</sup>
                            </label>
                            <select
                              name="package"
                              className="form-control form-select form-select-solid"
                              value={userData.package}
                              onChange={changeHandler}
                              required
                            >
                              <option value="" hidden>
                                Select Package...
                              </option>
                              {list &&
                                list.map((pkg, index) => (
                                  <option key={pkg.id + index} value={pkg.id}>
                                    {pkg.type}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {error && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {error}
                          </div>
                        )}
                        <div className="button-box">
                          <button className="register-btn btn" type="submit">
                            {loading ? (
                              <ThreeDots
                                color="#000000"
                                height={20}
                                width={50}
                              />
                            ) : (
                              <span>Next</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div></div></main>
      {/* <StripeComponent /> */}
      {/* {userInfo && ( */}
      <Modal showModal={showModal} setShowModal={setShowModal} type="success">
        <ModalContent email={userInfo ? userInfo.email : ""} />
      </Modal>
      {/* )} */}
      {/* {step === 1 ? (
          <> */}
      {/* <p className="">
          <GoVerified color="green" size={22} className="mx-1" />
          You have successfully registered and verification email has been sent !
        </p>

        <button className="modal-btn btn" onClick={closeHandler}>
          <span>Close</span>
        </button> */}

      {/* </>
        ) : (
          <>
            <p> */}
      {/* <span className="">
            <FeatherIcon icon="alert-triangle" />
          </span> */}
      {/* It seems that your email is not Verified, please verify your email
              to continue !
            </p>
            <div>
              <div className="button-box">
                <button
                  className="modal-btn btn"
                  onClick={sendVerificationEmail}
                >
                  {showLoading ? (
                    <ThreeDots color="#000000" height={20} width={50} />
                  ) : (
                    <span>Verify Now</span>
                  )}
                </button>
                <button className="modal-btn btn secondary mx-2">
                  <span>Close</span>
                </button>
              </div>
            </div>
          </> */}
      {/* )} */}
      {/* </Modal> */}
    </>
  );
}

export default BuyPackage;
