import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteUserAction } from "../../../actions/userActions";
import { UPDATE_USER_RESET } from "../../../constants";
import TablePagination from "../../../components/dataTable/tablePagination";

function UsersTable({ tableColumns, data, modules }) {
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // const { auth } = state;
  // const { userInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  const deleteHandler = (id) => {
    dispatch(deleteUserAction(id));
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
        <thead className="table-light">
          <tr>
            {/* <th style={{ width: "20px" }}>
              <div className="form-check">
                <label className="form-check-label" htmlFor="customCheck1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                  />
                </label>
              </div>
            </th> */}
            {tableColumns.map((column, index) => (
              <th key={column + index}>{column}</th>
            ))}
            {modules.delete || modules.update ? (
              <th style={{ width: "82px" }}>Action</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              currentPage * entriesToShow - entriesToShow,
              currentPage * entriesToShow
            )
            .map((user, index) => (
              <tr key={user.id + index}>
                {/* <td>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="customCheck2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck2"
                      />
                    </label>
                  </div>
                </td> */}
                <td>{user.displayName}</td>
                <td>{user.accountNumber ? user.accountNumber : "-"}</td>

                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>{user.role}</td>
                {/* <td>
                  <div className="badge bg-success">
                    {user.role === "user" ? "Basic" : ""}
                  </div>
                </td> */}
                {/* <td>04-04-2022</td>
                <td>20-10-2021</td> */}
                <td>
                  {user.isEmailVerified ? (
                    <div className="badge badge-soft-success">Verified</div>
                  ) : (
                    <div className="badge badge-soft-danger">Not Verified</div>
                  )}
                </td>
                {modules.delete || modules.update ? (
                  <td className="text-center">
                    {modules.update && (
                      <Link
                        onClick={() => dispatch({ type: UPDATE_USER_RESET })}
                        to={`/manage/users/edit/${user.id}`}
                        className="action-icon"
                      >
                        <i className="mdi mdi-square-edit-outline"></i>
                      </Link>
                    )}
                    {modules.delete && (
                      <Link
                        onClick={() => deleteHandler(user.id)}
                        to=""
                        className="action-icon"
                      >
                        <i className="mdi mdi-delete"></i>
                      </Link>
                    )}
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default UsersTable;
