import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUsersAction } from "../../../actions/userActions";
import UsersTable from "./usersTable";
import { CREATE_USER_RESET } from "../../../constants";
import PrimaryButton from "../../../components/primaryButton";

const tableColumns = [
  "Full Name",
  "Account #",
  "Email",
  "Phone",
  "Role",
  // "User Plan",
  "Verification",
];

function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { users, currRolePermissions } = state;
  const { loading, list, error } = users;
  const { permission } = currRolePermissions;

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getUsersAction());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      const tempData = list.filter(
        (x) =>
          x?.displayName?.toLowerCase()?.includes(search?.toLowerCase()) ||
          x?.accountNumber?.toLowerCase()?.includes(search?.toLowerCase()) ||
          x?.email?.toLowerCase()?.includes(search?.toLowerCase())
      );
      setFilteredData(tempData || []);
    }
  }, [search, list]);

  useEffect(() => {
    if (list) {
      setFilteredData(list);
    }
  }, [list]);

  return (
    <>
      {permission && (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0"></ol>
                  </div>
                  <h4 className="page-title">Users List</h4>
                </div>
              </div>
            </div>
            {list.length === 0 && loading ? (
              <div className="loader">
                <TailSpin color="#000" height={50} width={50} />
              </div>
            ) : error ? (
              <div className="loader">
                <div className="alert alert-danger mt-4" role="alert">
                  {error}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <form className="search-bar position-relative mb-sm-0 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="mdi mdi-magnify"></span>
                          </form>
                        </div>
                        {permission.permissions.find((x) => x.type == "Users")
                          ?.modules.create && (
                          <div className="col-md-6">
                            <div className="text-md-end">
                              {/* <Link
                          onClick={() => dispatch({ type: CREATE_USER_RESET })}
                          to="/manage/users/add"
                          className="btn text-white mi-bgre waves-effect waves-light mb-2 me-2"
                        >
                          <i className="mdi mdi-plus-circle-outline me-1"></i>{" "}
                          Add User
                        </Link> */}
                              <PrimaryButton
                                onClick={() => {
                                  dispatch({ type: CREATE_USER_RESET });
                                  navigate("/manage/users/add");
                                }}
                              >
                                <i className="mdi mdi-plus-circle-outline me-1"></i>{" "}
                                Add User
                              </PrimaryButton>
                            </div>
                          </div>
                        )}
                      </div>

                      <UsersTable
                        modules={
                          permission.permissions.find((x) => x.type === "Users")
                            .modules
                        }
                        tableColumns={tableColumns}
                        data={filteredData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Users;
