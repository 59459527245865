import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChannelByIdAction,
  // getMovieByIdAction,
  // getMoviesAction,
  // updateVideoViewsAction,
} from "../../../actions/videoActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// import { cloudfrontUrl } from "../../constants";

// import { IoMdClose } from "react-icons/io";
// import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";
import ChannelsListSection from "./mobileComponents/channelsListSection";

function PlayChannel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();
  const { id } = useParams();

  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });

  const state = useSelector((state) => state);
  const { auth, selectedChannel, movies, currUserSubscription, favourites } =
    state;
  const { tokens, userInfo } = auth;
  const { data } = selectedChannel;

  const iframeRef = useRef();
  // const id = location.pathname.split("/")[4];

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  useEffect(() => {
    dispatch(getChannelByIdAction(id));
  }, [id]);

  // useEffect(() => {
  //   if (userInfo) dispatch(getFavouritesByProfileIdAction(userInfo.id));
  // }, [userInfo]);

  useEffect(() => {
    if (!tokens) {
      navigate(`/signin?redirect=/watch/live/${id}`);
    } else {
      if (id) {
        dispatch(getChannelByIdAction(id));
      } else {
        navigate("/watch/live");
      }
    }
  }, [id]);

  // useEffect(() => {
  //   if (data && iframeRef.current) {
  //     // if (document.getElementsByClassName("share_plugin")[0]) {
  //     // setTimeout(() => {
  //     // const iframe = document.getElementById("channel-iframe");

  //     const shareCont = iframeRef.current.contentWindow;

  //     // .style.display = "none");
  //     // }, 1000);
  //     // }
  //   }
  // }, [data, iframeRef]);

  return (
    <>
      {isTabletOrMobile ? (
        <div className="channel-video">
          {data && (
            <>
              <iframe
                ref={iframeRef}
                id="channel-iframe"
                width="100%"
                height="300px"
                src={`${data.iframeUrl}?autoplay=1&loop=1&autopause=0`}
                // frameBorder="0"
                allow="autoplay"
                allowFullScreen
                // plugins={false}
              ></iframe>
              <ChannelsListSection />
            </>
          )}
        </div>
      ) : (
        <div className="channel-video py-3">
          {/* <div className="close-div" onClick={() => navigate(-1)}>
        <IoMdClose color="white" size={24} />
      </div> */}
          {data && (
            <>
              {/* <CustomReactPlayer /> */}
              {/* <ReactPlayer
            playsinline={true}
            url={[
              // `https://190765.gvideo.io/cmaf/190765_735003/master.m3u8`,
              // `https://190765.gvideo.io/cmaf/190765_735003/media_1.m3u8`,
              `https://190765.gvideo.io/cmaf/190765_735003/media_2.m3u8`,
              // `https://190765.gvideo.io/cmaf/190765_735003/media_3.m3u8`,
              // `https://190765.gvideo.io/cmaf/190765_735003/media_4.m3u8`,
            ]}
            controls // gives the front end video controls
            width="100%"
            className="channel-react-player"
            height="100%"
            allow="false"
            allowFullScreen
            // muted={true}
            playing={true}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
            onContextMenu={(e) => e.preventDefault()}
            onEnded={(e) => console.log(e)}
            onError={(e) => console.log(e)}
          /> */}
              <iframe
                ref={iframeRef}
                id="channel-iframe"
                width="100%"
                height="100%"
                src={`${data.iframeUrl}?autoplay=1&loop=1&autopause=0`}
                // frameborder="0"
                allow="autoplay"
                allowFullScreen
                // plugins={false}
              ></iframe>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default PlayChannel;
