import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useBasePath } from "../../../../services/hooks";
import { TOGGLE_CHANNEL_SIDEBAR, cloudfrontUrl } from "../../../../constants";

function ChannelsListSection() {
  const location = useLocation();
  const navigate = useNavigate();
  const basePath = useBasePath();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth, currRolePermissions, channels, sidebars } = state;
  const { userInfo } = auth;
  const { list } = channels;
  const { channelSidebar } = sidebars;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [filteredChannels, setFilteredChannels] = useState([]);

  // const logoutHandler = () => {
  //   dispatch(
  //     userLogout({
  //       refreshToken: tokens.refresh.token,
  //     })
  //   );
  // };

  useEffect(() => {
    if (list.length > 0) {
      if (location.pathname.split("/")[3] == "live") {
        setFilteredChannels(list.filter((x) => x.category == "other"));
        // navigate(
        //   `/watch/tv/${location.pathname.split("/")[3]}/${
        //     list.filter((x) => x.category == "Other")[0].id
        //   }`
        // );
      }

      if (location.pathname.split("/")[3] == "sports") {
        setFilteredChannels(list.filter((x) => x.category == "sports"));
      }

      if (location.pathname.split("/")[3] == "news") {
        setFilteredChannels(list.filter((x) => x.category == "news"));
        // navigate(
        //   `/watch/tv/${location.pathname.split("/")[3]}/${
        //     list.filter((x) => x.category == "News")[0].id
        //   }`
        // );
      }
    }
  }, [list, basePath]);

  useEffect(() => {
    if (filteredChannels.length > 0)
      navigate(
        `/watch/tv/${location.pathname.split("/")[3]}/${filteredChannels[0].id}`
      );
    dispatch({ type: TOGGLE_CHANNEL_SIDEBAR, payload: false });
  }, [filteredChannels]);

  return (
    <div className="channel-list-section">
      <h4 className="uppercase"> {location?.pathname.split("/")[3]} Channels</h4>
      <div className="d-flex align-items-center justify-content-between flex-wrap ">
        {filteredChannels &&
          filteredChannels.slice().map((x, i) => (
            <NavLink
              to={`/watch/tv/${location.pathname.split("/")[3]}/${x.id}`}
              // onClick={() => dispatch({ type: TOGGLE_CHANNEL_SIDEBAR })}
              className={`channel-nav ${
                location.pathname.includes(x.id) ? "active" : ""
              }`}
            >
              <div className="channel-item" key={i}>
                {/* <FeatherIcon icon="airplay" /> */}
                {/* <span className="badge rounded-pill float-end">4</span> */}
                <img
                  src={`${cloudfrontUrl}/${x.logo}`}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                />
                {/* <p> {x.name} </p> */}
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );
}

export default ChannelsListSection;
