import { GET_USERS_ROLES_SUCCESS } from "../constants";

export const usersRolesReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_USERS_ROLES_SUCCESS:
      return action.payload;


    default:
      return state;
  }
};
