import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteChannelAction } from "../../actions/channelActions";
import { cloudfrontUrl, UPDATE_USER_RESET } from "../../constants";
import TablePagination from "./tablePagination";
import { LazyLoadImage } from "react-lazy-load-image-component";

function ChannelsTable({ tableColumns, data, modules }) {
  const dispatch = useDispatch();
  // const state = useSelector((state) => state);
  // const { auth } = state;
  // const { channelInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  const deleteHandler = (id) => {
    dispatch(deleteChannelAction(id));
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
        <thead className="table-light">
          <tr>
            {/* <th style={{ width: "20px" }}>
              <div className="form-check">
                <label className="form-check-label" htmlFor="customCheck1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                  />
                </label>
              </div>
            </th> */}
            {tableColumns.map((column, index) => (
              <th key={column + index}>{column}</th>
            ))}
            {modules.delete || modules.update ? (
              <th style={{ width: "82px" }}>Action</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              currentPage * entriesToShow - entriesToShow,
              currentPage * entriesToShow
            )
            .map((channel, index) => (
              <tr key={channel.id + index}>
                {/* <td>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="customCheck2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck2"
                      />
                    </label>
                  </div>
                </td> */}
                <td className="name">
                  <div className="media align-items-center">
                    <div className="iq-movie float-start me-2">
                      <Link to="">
                        <LazyLoadImage
                          src={`${cloudfrontUrl}/${channel.logo}`}
                          className="img-border-radius avatar-40 img-fluid movie-thumbnail"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="media-body text-left ml-3 me-2">
                      <p className="mb-0">{channel.name}</p>
                      <small>{channel.language}</small>
                    </div>
                  </div>
                </td>
                <td>{channel.quality}</td>
                <td>{channel.category}</td>
                <td>{channel.description}</td>
                <td>{channel.status}</td>

                {/* <td>04-04-2022</td>
                <td>20-10-2021</td> */}

                {modules.delete || modules.update ? (
                  <td className="text-center">
                    {modules.update && (
                      <Link
                        onClick={() => dispatch({ type: UPDATE_USER_RESET })}
                        to={`/manage/channels/edit/${channel.id}`}
                        className="action-icon"
                      >
                        <i className="mdi mdi-square-edit-outline"></i>
                      </Link>
                    )}
                    {modules.delete && (
                      <Link
                        onClick={() => deleteHandler(channel.id)}
                        to=""
                        className="action-icon"
                      >
                        <i className="mdi mdi-delete"></i>
                      </Link>
                    )}
                  </td>
                ) : (
                  <></>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default ChannelsTable;
