import React from "react";
import { Link } from "react-router-dom";

function TablePagination({
  entriesToShow,
  totalPages,
  currentPage,
  setCurrentPage,
}) {
  const pages = new Array(totalPages).fill(0);
  return (
    <ul className="pagination pagination-rounded justify-content-end my-2">
      <li className="page-item">
        <Link
          className="page-link"
          to=""
          aria-label="Previous"
          onClick={() => currentPage > 1 && setCurrentPage((prev) => prev - 1)}
        >
          <span aria-hidden="true">«</span>
          <span className="visually-hidden">Previous</span>
        </Link>
      </li>
      {pages.map((page, index) => (
        <li
          key={page + index}
          className={`page-item ${currentPage === index + 1 ? "active" : ""}`}
        >
          <Link
            className="page-link"
            to=""
            onClick={() => setCurrentPage(index + 1)}
          >
            {index + 1}
          </Link>
        </li>
      ))}

      <li className="page-item">
        <Link
          className="page-link"
          to=""
          aria-label="Next"
          onClick={() =>
            currentPage < totalPages && setCurrentPage((prev) => prev + 1)
          }
        >
          <span aria-hidden="true">»</span>
          <span className="visually-hidden">Next</span>
        </Link>
      </li>
    </ul>
  );
}

export default TablePagination;
