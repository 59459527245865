import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  CREATE_SUBSCRIPTION_FAIL,
  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTION_FAIL,
  GET_USER_SUBSCRIPTION_REQUEST,
  GET_USER_SUBSCRIPTION_SUCCESS,
} from "../constants/subscriptionConstants";
import axiosPrivate from "../services/api";

// Create Subscription
export const createSubscriptionAction =
  (obj, successHandler) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/subscriptions`, obj);
      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
      notifySuccess("Subscription Created Successfully !");
      successHandler();
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: CREATE_SUBSCRIPTION_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// Renew Subscription
export const renewSubscriptionAction =
  (subscriptionId, obj, successHandler) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(`/subscriptions/renew`, obj);
      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
      notifySuccess("Subscription Updated Successfully !");
      successHandler();
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: CREATE_SUBSCRIPTION_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//GET user subscription
export const getcurrUserSubscriptionAction =
  (id) => async (dispatch, getState) => {
    dispatch({
      type: GET_USER_SUBSCRIPTION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.get(`/subscriptions/user`);
      dispatch({
        type: GET_USER_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_SUBSCRIPTION_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// GET all subscriptions
export const getAllSubscriptionsAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_SUBSCRIPTIONS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/subscriptions`);
    dispatch({
      type: GET_SUBSCRIPTIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SUBSCRIPTIONS_FAIL,
      payload: err.response.data.message,
    });
  }
};

// Create Subscription
export const createSubscriptionByAdminAction =
  (obj) => async (dispatch, getState) => {
    dispatch({
      type: CREATE_SUBSCRIPTION_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(
        `/subscriptions/createByAdmin`,
        obj
      );
      dispatch({
        type: CREATE_SUBSCRIPTION_SUCCESS,
        payload: data,
      });
      notifySuccess("Subscription Created Successfully !");
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: CREATE_SUBSCRIPTION_FAIL,
        payload: err.response.data.message,
      });
    }
  };
