import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

import {
  getAgentTransactionsAction,
  getTransactionsAction,
} from "../../../actions/transactionActions";
import TransactionsTable from "../../../components/dataTable/transactionsTable";

const tableColumns = [
  "Transaction ID",
  "Wallet Pin",
  "Full Name",
  "Email",
  "Phone",
  "Amount Added",
  "Status",
];

function Transactions() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { agentTransactions, currRolePermissions, auth } = state;
  const { loading, list, error } = agentTransactions;
  const { permission } = currRolePermissions;

  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (auth.userInfo) {
      if (auth.userInfo.role === "admin") {
        dispatch(getTransactionsAction());
      } else {
        dispatch(getAgentTransactionsAction(auth.userInfo.id));
      }
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (list) {
      const tempData = list.filter(
        (x) =>
          x.typeId?.pin &&
          x.typeId.pin.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(tempData || []);
    }
  }, [search, list]);

  useEffect(() => {
    if (list) {
      setFilteredData(list);
    }
  }, [list]);

  return (
    <>
      {permission && (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0"></ol>
                  </div>
                  <h4 className="page-title">Transactions List</h4>
                </div>
              </div>
            </div>
            {list && list.length === 0 && loading ? (
              <div className="loader">
                <TailSpin color="#000" height={50} width={50} />
              </div>
            ) : error ? (
              <div className="loader">
                <div className="alert alert-danger mt-4" role="alert">
                  {error}
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <form className="search-bar position-relative mb-sm-0 mb-2">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <span className="mdi mdi-magnify"></span>
                          </form>
                        </div>
                      </div>

                      <TransactionsTable
                        modules={
                          permission.permissions.find(
                            (x) => x.type === "Transactions"
                          ).modules
                        }
                        tableColumns={tableColumns}
                        data={filteredData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Transactions;
