import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  FAVOURITE_ADD_FAIL,
  FAVOURITE_ADD_REQUEST,
  FAVOURITE_ADD_SUCCESS,
  GET_FAVOURITE_FAIL,
  GET_FAVOURITE_REQUEST,
  GET_FAVOURITE_SUCCESS,
  REMOVE_ALL_FAVOURITES_FAIL,
  REMOVE_ALL_FAVOURITES_REQUEST,
  REMOVE_ALL_FAVOURITES_SUCCESS,
  REMOVE_FAVOURITE_FAIL,
  REMOVE_FAVOURITE_REQUEST,
  REMOVE_FAVOURITE_SUCCESS,
} from "../constants/favouriteConstants";

import axiosPrivate from "../services/api";

export const getFavouritesByProfileIdAction =
  (profileId) => async (dispatch, getState) => {
    dispatch({
      type: GET_FAVOURITE_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.get(
        `/favourites/profile/${profileId}`
      );

      dispatch({
        type: GET_FAVOURITE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_FAVOURITE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const addFavouiteAction =
  (favouriteData, setLoadingFlag) => async (dispatch) => {
    dispatch({
      type: FAVOURITE_ADD_REQUEST,
    });
    setLoadingFlag(true);
    try {
      const { data } = await axiosPrivate.post(`/favourites`, favouriteData);
      setLoadingFlag(false);

      dispatch({
        type: FAVOURITE_ADD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      setLoadingFlag(false);

      dispatch({
        type: FAVOURITE_ADD_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteFavouriteAction =
  (obj, setLoadingFlag) => async (dispatch) => {
    dispatch({
      type: REMOVE_FAVOURITE_REQUEST,
    });
    setLoadingFlag(true);
    try {
      await axiosPrivate.post(`/favourites/deleteByProfileAndVideo`, obj);
      notifySuccess("Removed From Favourites Successfully !");

      setLoadingFlag(false);
      dispatch({
        type: REMOVE_FAVOURITE_SUCCESS,
        payload: obj.video,
      });
    } catch (err) {
      setLoadingFlag(false);
      notifyFailure(err.response.data.message);
      dispatch({
        type: REMOVE_FAVOURITE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteAllFavouritesAction =
  (userId, setLoadingFlag) => async (dispatch) => {
    dispatch({
      type: REMOVE_ALL_FAVOURITES_REQUEST,
    });
    setLoadingFlag(true);
    try {
      await axiosPrivate.delete(`/favourites/user/${userId}`);
      setLoadingFlag(false);
      notifySuccess("All Favourites Removed Successfully !");

      dispatch({
        type: REMOVE_ALL_FAVOURITES_SUCCESS,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);

      dispatch({
        type: REMOVE_ALL_FAVOURITES_FAIL,
        payload: err.response.data.message,
      });
    }
  };
