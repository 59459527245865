import React, { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getCategoriesAction } from "../../actions/categoryActions";
import { getShowsAction } from "../../actions/videoActions";
import VideoCard from "../../components/cards/videoCard";
import GenreSlider from "../../components/sliders/genreSlider";
import ComedyShows from "./comedyShows";
import HorrorShows from "./horrorShows";
import PopularShows from "./popularShows";
import TrendingShows from "./trendingShows";
import { LazyLoadImage } from "react-lazy-load-image-component";

var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function Shows() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = location.search ? location.search.split("=")[1] : null;

  const state = useSelector((state) => state);
  const { categories, shows } = state;
  const { loading, list } = shows;

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getShowsAction(query));
  }, [dispatch, query]);

  return (
    <>
      <div className="slider-area bg-black">
        <div className="container-fluid p-0">
          <div className="series-slider-active nav-style-1">
            <div className="single-hero-img-2">
              <Link to="">
                <LazyLoadImage src="assets/images/slider/series-2.png" alt="" />
              </Link>
            </div>
            {/* <div className="single-hero-img-2">
              <Link to="">
                <img src="assets/images/slider/series-1.png" alt="" />
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <GenreSlider list={categories.list} type="shows" />

      {query ? (
        <>
          <div className="row mb-5">
            <div className="col-lg-12">
              <div className="series-list section-ptb-50">
                <div className="section-title-4 st-border-bottom">
                  <h2>
                    {query.slice(0, 1).toUpperCase()}
                    {query.slice(1, query.length)} Shows
                  </h2>
                </div>
                <div className="row">
                  {list &&
                    list.map((movie, index) => (
                      <div
                        key={movie.id + index}
                        className="col-lg-2 col-md-3 col-sm-6 col-12 p-2"
                      >
                        <VideoCard
                          key={movie.id}
                          data={movie}
                          videoType="Show"
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading && (
            <div className="loader">
              <ThreeDots color="#fff" height={50} width={50} />
            </div>
          )}
          {list && (
            <>
              <HorrorShows
                settings={settings}
                list={list.filter((x) => x.category === "Horror")}
              />
              <PopularShows settings={settings} list={list} />
              <div className="banner-area bg-black">
                <div className="series-banner-img">
                  <Link to="">
                    <img src="assets/images/product/series-banner.jpg" alt="" />
                  </Link>
                </div>
              </div>
              <TrendingShows settings={settings} list={list} />
              <div className="banner-area bg-black">
                <div className="series-banner-img">
                  <Link to="">
                    <img
                      src="assets/images/product/series-banner-2.jpg"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <ComedyShows
                settings={settings}
                list={list.filter((x) => x.category === "Comedy")}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Shows;
