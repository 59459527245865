import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { EffectCreative, Pagination, Navigation } from "swiper";
import ImageSlide from "./imageSlide";
import VideoSlide from "./videoSlide";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import { useInView } from "react-intersection-observer";

function HeroSlider({
  data,
  videoDetail,
  addedToList,
  videoType,
  videoId,
  videoSlide,
  noDesc,
  showImage,
  hidePlayButton,
  checkView,
  setHeroSecInView,
}) {
  const { inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (checkView) setHeroSecInView(inView);
  }, [inView, checkView, setHeroSecInView]);

  return (
    <>
      <Swiper
        grabCursor={data.length > 1}
        pagination={data.length > 1}
        navigation={data.length > 1}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -400],
            duration: 2000,
            // translate: ["100%", 0, -400],
          },
          next: {
            shadow: true,
            translate: ["100%", 0, -400],
            duration: 2000,
          },
        }}
        // breakpoints={{
        //   320: {
        //     navigation: false,
        //   },
        //   640: {
        //     navigation: false,
        //   },
        //   768: {
        //     navigation: false,
        //   },
        //   1024: {
        //     navigation: true,
        //   },
        // }}
        modules={[EffectCreative, Navigation, Pagination]}
        className="hero-slider"
      >
        {data.map((x, index) => {
          return (
            <div key={x.id + index}>
              <SwiperSlide key={x.id + index}>
                {/* <div className="single-hero-img-2">
            <Link to="">
              <img src={x} alt="" />
            </Link>
          </div> */}
                {videoSlide ? (
                  <VideoSlide
                    data={x}
                    videoDetail={videoDetail}
                    videoSlide={videoSlide}
                    addedToList={addedToList}
                    videoType={videoType}
                    videoId={data.id}
                    noDesc={noDesc}
                    showImage={showImage}
                    hidePlayButton={hidePlayButton}
                  />
                ) : (
                  <ImageSlide
                    data={x}
                    videoDetail={videoDetail}
                    videoSlide={videoSlide}
                    addedToList={addedToList}
                    videoType={videoType}
                    videoId={videoId}
                    noDesc={noDesc}
                    hidePlayButton={hidePlayButton}
                  />
                )}
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </>
  );
}

export default HeroSlider;
