import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getShowByIdAction,
  getEpisodesByShowIdAction,
} from "../../../actions/videoActions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeroSlider from "../../../components/sliders/heroSlider";
import { getFavouritesByProfileIdAction } from "../../../actions/favouriteActions";
import { cloudfrontUrl } from "../../../constants";
import { TailSpin } from "react-loader-spinner";
import { getShowWatchHistoryByProfileAndVideoId } from "../../../actions/watchHistoryActions";
import EpisodesSection from "./episodesSection";
import { useState } from "react";
import VideoCardV2 from "../../../components/cards/videoCardV2";

function ShowDetailsV2() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const state = useSelector((state) => state);
  const {
    auth,
    selectedVideo,
    shows,
    episodes,
    // currUserSubscription,
    favourites,
  } = state;

  const { tokens, userInfo, profile } = auth;
  const { data } = selectedVideo;
  // const { list } = shows;

  const [episodesInView, setEpisodesInView] = useState(false);

  // const id = location.pathname.split("/")[3];

  // useEffect(() => {
  //   dispatch(getShowsAction());
  // }, []);

  useEffect(() => {
    if (profile) dispatch(getFavouritesByProfileIdAction(profile.id));
  }, [userInfo]);

  useEffect(() => {
    if (!tokens) {
      navigate(`/signin?redirect=/watch/shows/${id}`);
    } else {
      if (id) {
        if (!location.state || location?.state?.loadData) {
          dispatch(getShowByIdAction(id));
          dispatch(getEpisodesByShowIdAction(id));
          dispatch(
            getShowWatchHistoryByProfileAndVideoId({
              profile: profile.id,
              video: id,
            })
          );
        } else {
          handleScroll("episodes");
        }
      } else {
        navigate("/watch/shows");
      }
    }
  }, [id, location.state]);

  const handleScroll = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth", align: false });
      }, 300);
    }
  };

  const listenScrollEvent = () => {
    let header = document.getElementById("header");
    if (document.body.scrollTop < window.innerHeight) {
      // header.classList.add("transparent");
      // return setHeader("header");
    } else if (document.body.scrollTop > window.innerHeight) {
      // header.classList.remove("transparent");

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;
      // if any scroll is attempted,
      // set this to the previous value
      // (window.onscroll = function () {
      window.body.scrollTo(scrollLeft, scrollTop);
      // });
    }
  };

  useEffect(() => {
    document.body.addEventListener("scroll", listenScrollEvent);

    return () => document.body.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <div className="video-details-page">
      {data ? (
        <div className="mb-5">
          <HeroSlider
            // setHeroSecInView={setHeroSecInView}
            data={[data]}
            addedToList={favourites.list.find((x) => x?.video?.id == id)}
            videoDetail
            videoSlide
            videoType="Show"
            videoId={id}
            showImage
            hidePlayButton
            // checkView
          />
          {episodes.list && (
            <EpisodesSection
              // ref={ref}
              setEpisodesInView={setEpisodesInView}
              list={episodes.list
                .slice()
                .sort((a, b) => (a.episodeNumber < b.episodeNumber ? -1 : 1))}
            />
          )}
          {/* {shows.list && (
            <VideoSlider
              title="More like this"
              list={
                shows.list.slice()
                // .sort((a, b) => (a.episodeNumber < b.episodeNumber ? -1 : 1))
              }
              delay={5000}
              videoType="Show"
              directPlay
            />
          )} */}

          {/* {shows.list.map((video, index) => {
            if (video)
              return (
                <div key={index}>
                  <SwiperSlide>
                    <VideoCardV2
                      data={video}
                      video={video}
                      videoType={"Show"}
                      cloudBaseUrl={cloudfrontUrl}
                      directPlay
                    />
                  </SwiperSlide>
                </div>
              );
          })} */}

          <div id="moreLikeThis" className="row mb-5 mx-1 mx-md-3">
            <div className="col-lg-12">
              <div className="series-list section-ptb-50">
                <div className="section-title-4 st-border-bottom">
                  <h2>More Like This</h2>
                </div>
                <div className="row">
                  {/* {shows.list.length === 0 ? (
                    <div className="alert alert-info mt-4" role="alert">
                      {query.slice(0, 1).toUpperCase()}
                      {query.slice(1, query.length)} Movies not found !
                    </div>
                  ) : ( */}
                  <>
                    {shows.list.map((video, index) => (
                      <div
                        key={video.id + index}
                        className="col-lg-3 col-md-4 col-6 p-2"
                      >
                        <VideoCardV2
                          data={video}
                          video={video}
                          videoType={"Show"}
                          cloudBaseUrl={cloudfrontUrl}
                          // directPlay
                        />
                      </div>
                    ))}
                  </>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loader">
          <TailSpin color="#fff" height={50} width={50} />
        </div>
      )}
    </div>
  );
}

export default ShowDetailsV2;
