import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { cloudfrontUrl } from "../../constants";
import VideoCardV2 from "../../components/cards/videoCardV2";
import { useLocation } from "react-router-dom";
import axiosPrivate from "../../services/api";
import { useEffect } from "react";

function Search() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const query = location.search;

  useEffect(() => {
    const searchData = async () => {
      setLoading(true);
      try {
        const { data } = await axiosPrivate.get(`/search${query}`);
        setLoading(false);

        setSearchData(data);
      } catch (err) {
        setLoading(false);
        setError(true);
        console.log("err==>", err);
      }
    };
    const delayDebounceFn = setTimeout(() => {
      searchData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  return (
    <>
      {loading ? (
        <div className="loader">
          <TailSpin color="#fff" height={50} width={50} />
        </div>
      ) : (
        <div className="row my-lg-5 pt-lg-5  mx-1 mx-md-3">
          <div className="col-lg-12">
            <div className="series-list section-ptb-50">
              <div className="section-title-4 st-border-bottom d-flex align-items-center justify-content-between">
                <h2>Showing results for: {query.split("=")[1]}</h2> <h5 className="text-white">Total Results: {searchData.length}</h5>
              </div>
              <div className="row">
                {error ? (
                  <div className="alert alert-danger mt-4" role="alert">
                    Results not found !
                  </div>
                ) : (
                  <>
                    {searchData.map((video, index) => (
                      <div
                        key={video.id + index}
                        className="col-lg-3 col-md-3 col-sm-4 col-6 p-2"
                      >
                        <VideoCardV2
                          key={video.id}
                          data={video}
                          video={video}
                          videoType={video.seasonNumber ? "Show" : "Movie"}
                          cloudBaseUrl={cloudfrontUrl}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Search;
