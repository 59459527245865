import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getEpisodesByShowIdAction } from "../../../actions/videoActions";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import { UPDATE_MOVIE_SUCCESS } from "../../../constants/videoConstants";
import axiosPrivate from "../../../services/api";
import FormLeft from "./FormLeft";
import FormRight from "./FormRight";

function EditShow() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { shows, episodes } = state;
  const [loading, setLoading] = useState(false);

  const [showDetails, setShowDetails] = useState(null);
  // const [episodesData, setEpisodesData] = useState(null);

  // const changeHandler = (e) => {
  //   setShowDetails({
  //     ...showDetails,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const getEpisodes = async (e) => {
  //   try {
  //     setLoading(true);
  //     const {data}=await axiosPrivate.get(`/episodes/show/${params.id}`);
  //     setEpisodesData(data)
  //   } catch (err) {}
  // };

  useEffect(() => {
    if (shows.list.length === 0) {
      navigate("/manage/shows");
    } else {
      if (params.id) {
        // getEpisodes()
        dispatch(getEpisodesByShowIdAction(params.id));
        const selectedShow = shows.list.find((x) => x.id === params.id);
        if (selectedShow) {
          setShowDetails(selectedShow);
        } else {
          navigate("/manage/shows");
        }
      }
    }
  }, [navigate, location, shows.list]);

  const quillChange = (content, delta, source, editor) => {
    const text = editor.getText(content);
    setShowDetails({
      ...showDetails,
      description: content,
      descriptionText: text,
    });
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Edit Show</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FormLeft data={showDetails} type="Edit" />
          </div>
          <div className="col-lg-6">
            <FormRight
              data={episodes.list.length > 0 ? episodes.list : null}
              type="Edit"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditShow;
