import { applyMiddleware, createStore, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "./reducers/authReducers";
import { favouritesReducer } from "./reducers/favouriteReducers";
import { historiesReducer } from "./reducers/historyReducers";
import {
  packagesReducer,
  selectedPackageReducer,
} from "./reducers/packageReducers";
import {
  currRolePermissionsReducer,
  permissionsReducer,
} from "./reducers/permissionReducers";

import { usersRolesReducer } from "./reducers/rolesReducers";
import {
  currUserSubscriptionReducer,
  subscriptionsReducer,
} from "./reducers/subscriptionReducers";
import {
  agentTransactionsReducer,
  transactionsReducer,
} from "./reducers/transactionReducers";
import {
  newUserReducer,
  updateUserReducer,
  userRegisterReducer,
  usersReducer,
} from "./reducers/userReducers";
import {
  channelsReducer,
  episodesReducer,
  moviesReducer,
  selectedChannelReducer,
  selectedEpisodeReducer,
  selectedVideoReducer,
  showsReducer,
  trendingMoviesReducer,
} from "./reducers/videoReducers";
import { userWalletReducer, walletsReducer } from "./reducers/walletsReducers";
import { categoriesReducer } from "./reducers/categoryReducers";
import { statsReducer } from "./reducers/statsReducers";
import {
  profilesReducer,
  selectProfileReducer,
} from "./reducers/profileReducers";
import { sidebarReducer } from "./reducers/sidebarReducers";
import {
  homePageDataReducer,
  moviesPageDataReducer,
  showsPageDataReducer,
} from "./reducers/pageReducers";

const initialState = {
  sidebars: {
    channelSidebar: true,
  },
  auth: {
    tokens: localStorage.getItem("tokens")
      ? JSON.parse(localStorage.getItem("tokens"))
      : null,
    userInfo: null,
    profile: localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : null,
    loading: false,
    // newRegisteration: true,
  },
  selectedPackage: localStorage.getItem("selectedPackage")
    ? JSON.parse(localStorage.getItem("selectedPackage"))
    : null,
};

const reducer = combineReducers({
  sidebars: sidebarReducer,
  auth: authReducer,
  users: usersReducer,
  transactions: transactionsReducer,
  agentTransactions: agentTransactionsReducer,
  newUser: newUserReducer,
  userRegister: userRegisterReducer,
  updateUser: updateUserReducer,
  packages: packagesReducer,
  selectedPackage: selectedPackageReducer,
  permissions: permissionsReducer,
  usersWithRoles: usersRolesReducer,
  currRolePermissions: currRolePermissionsReducer,
  currUserSubscription: currUserSubscriptionReducer,
  movies: moviesReducer,
  channels: channelsReducer,
  selectedChannel: selectedChannelReducer,
  trendingMovies: trendingMoviesReducer,
  shows: showsReducer,
  episodes: episodesReducer,
  selectedEpisode: selectedEpisodeReducer,
  selectedVideo: selectedVideoReducer,
  favourites: favouritesReducer,
  history: historiesReducer,
  currUserWallet: userWalletReducer,
  wallets: walletsReducer,
  categories: categoriesReducer,
  stats: statsReducer,
  profiles: profilesReducer,
  selectedProfile: selectProfileReducer,
  subscriptions: subscriptionsReducer,
  homePage: homePageDataReducer,
  moviesPage: moviesPageDataReducer,
  showsPage: showsPageDataReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
