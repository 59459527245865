export const GET_HOME_PAGE_REQUEST = "GET_HOME_PAGE_REQUEST";
export const GET_HOME_PAGE_SUCCESS = "GET_HOME_PAGE_SUCCESS";
export const GET_HOME_PAGE_FAIL = "GET_HOME_PAGE_FAIL";

export const GET_MOVIES_PAGE_REQUEST = "GET_MOVIES_PAGE_REQUEST";
export const GET_MOVIES_PAGE_SUCCESS = "GET_MOVIES_PAGE_SUCCESS";
export const GET_MOVIES_PAGE_FAIL = "GET_MOVIES_PAGE_FAIL";

export const GET_SHOWS_PAGE_REQUEST = "GET_SHOWS_PAGE_REQUEST";
export const GET_SHOWS_PAGE_SUCCESS = "GET_SHOWS_PAGE_SUCCESS";
export const GET_SHOWS_PAGE_FAIL = "GET_SHOWS_PAGE_FAIL";
