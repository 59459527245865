import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TablePagination from "./tablePagination";
import { FcMoneyTransfer } from "react-icons/fc";
import axiosPrivate from "../../services/api";
import Popup from "../../components/modal";
import PrimaryButton from "../primaryButton";
import { ThreeDots } from "react-loader-spinner";
import { getWalletsAction } from "../../actions/walletActions";
import { notifySuccess } from "../toaster/toast";

function WalletsTable({ tableColumns, data, modules }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { auth } = state;
  const { userInfo } = auth;

  const entriesToShow = 8;
  const totalPages = Math.ceil(data.length / entriesToShow);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedWallet, setSelectedWallet] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState({
    amount: "",
    email: "",
  });
  const [showModal, setShowModal] = useState({ open: false, type: null });
  // const [paymentToken, setPaymentToken] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState({});

  // const handlePayment = async () => {
  //   setPaymentStatus({
  //     loading: true,
  //   });
  //   try {
  //     await axiosPrivate.post(`/transactions/pay`, {
  //       token: paymentToken,
  //       payer: auth.userInfo.id,
  //       type: "Wallet",
  //       typeId: selectedWallet.id,
  //       receiver: selectedWallet.user.id,
  //       modelNameOfTypeId: "Wallet",
  //       amount: paymentDetails.amount,
  //       email: paymentDetails.email,
  //     });
  //     setPaymentStatus({
  //       loading: false,
  //     });
  //     setShowModal({ open: false, type: null });
  //     //   dispatch({ type: UPDATE_USER, payload: { subscriptionValid: true } });
  //     // setStatus("success");
  //   } catch (err) {
  //     setPaymentStatus({
  //       loading: false,
  //       error: err.response.data.message,
  //     });
  //     // setStatus("error");
  //   }
  // };

  // useEffect(() => {
  //   if (paymentToken) {
  //     handlePayment();
  //   }
  // }, [paymentToken]);

  const changeHandler = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  const clickHandler = (wallet) => {
    setSelectedWallet(wallet);
    setPaymentDetails({
      ...paymentDetails,
      email: wallet.user.email,
    });
    setShowModal({ open: true, type: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPaymentStatus({
      loading: true,
    });
    try {
      await axiosPrivate.post("/transactions", {
        payer: userInfo.id,
        receiver: selectedWallet.user.id,
        type: "Wallet",
        typeId: selectedWallet.id,
        modelNameOfTypeId: "Wallet",
        amount: paymentDetails.amount,
        email: userInfo.email,
        transactionBy: "Agent",
        status: "Completed",
      });
      notifySuccess("Amount added Successfully !");
      dispatch(getWalletsAction());

      setPaymentStatus({
        loading: false,
        success: true,
      });
      setShowModal({ open: false });
    } catch (err) {
      setPaymentStatus({
        loading: false,
        error: err.response.data.message,
      });
    }
  };

  return (
    <div className="table-responsive">
      <table className="table table-centered table-nowrap table-borderless table-hover mb-0">
        <thead className="table-light">
          <tr>
            {/* <th style={{ width: "20px" }}>
              <div className="form-check">
                <label className="form-check-label" htmlFor="customCheck1">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="customCheck1"
                  />
                </label>
              </div>
            </th> */}
            {tableColumns.map((column, index) => (
              <th key={column + index}>{column}</th>
            ))}
            {modules.delete || modules.update ? (
              <th style={{ width: "82px" }}>Action</th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {data
            .slice(
              currentPage * entriesToShow - entriesToShow,
              currentPage * entriesToShow
            )
            .map((wallet, index) => (
              <tr key={wallet.id + index}>
                {/* <td>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="customCheck2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck2"
                      />
                    </label>
                  </div>
                </td> */}
                <td>{wallet.pin}</td>
                <td>{wallet.user?.displayName}</td>

                <td>{wallet.user?.email}</td>
                <td>{wallet.user?.phone}</td>
                <td>{wallet.user?.role}</td>
                {/* <td>
                  <div className="badge bg-success">
                    {wallet.user.role === "user" ? "Basic" : ""}
                  </div>
                </td> */}
                {/* <td>04-04-2022</td>
                <td>20-10-2021</td> */}
                <td>${wallet.amount}</td>
                <td className="text-center">
                  <Link
                    onClick={() => clickHandler(wallet)}
                    to=""
                    className="action-icon"
                  >
                    {/* <i className="mdi mdi-square-edit-outline"></i> */}
                    <FcMoneyTransfer />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <TablePagination
        entriesToShow={entriesToShow}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      {selectedWallet && (
        <Popup showModal={showModal.open} setShowModal={setShowModal}>
          <div>
            <h3>Deposit to {selectedWallet.user.firstName}'s Wallet</h3>
            <p></p>
          </div>
          <form onSubmit={handleSubmit} className="stripe-checkout-form">
            <label>
              Email
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  min={1}
                  name="email"
                  onChange={changeHandler}
                  disabled={true}
                  value={paymentDetails.email}
                />
              </div>
            </label>
            <label>
              Wallet Pin
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  min={1}
                  name="email"
                  onChange={changeHandler}
                  disabled={true}
                  value={selectedWallet.pin}
                />
              </div>
            </label>
            <label>
              Amount
              <div>
                <input
                  type="number"
                  placeholder="Amount"
                  min={1}
                  name="amount"
                  onChange={changeHandler}
                  // disabled={disableChange}
                  value={paymentDetails.amount}
                />
              </div>
            </label>
            <PrimaryButton
              type="submit"
              className="single"
              disabled={paymentStatus.loading}
            >
              {paymentStatus.loading ? (
                <span>
                  <ThreeDots
                    color="#000000"
                    height={20}
                    width={50}
                    style={{ width: "fit-content", margin: "auto" }}
                  />
                </span>
              ) : (
                <span>Pay</span>
              )}
            </PrimaryButton>
          </form>
        </Popup>
      )}
    </div>
  );
}

export default WalletsTable;
