import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosPrivate from "../../../services/api";
import { uploadImage } from "../../../utils/s3Uploader";
import {
  notifySuccess,
  notifyFailure,
} from "../../../components/toaster/toast";

function EditChannels() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { channels, categories } = state;

  const [logoPath, setLogoPath] = useState("");
  const [channelDetails, setChannelDetails] = useState({});
  const [selectedChannel, setSelectedChannel] = useState({});
  const [loading, setLoading] = useState(false);

  const id = location.pathname.split("/")[4];

  const changeHandler = (e) => {
    setChannelDetails((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axiosPrivate.patch(`/channels/${id}`, {
        ...channelDetails,
        logo: logoPath,
        addedOn: moment().format("DD-MM-YYYY"),
      });
      notifySuccess("Channel Updated Successfully !");
      setLoading(false);
      navigate("/manage/channels");
    } catch (err) {
      setLoading(false);
      notifyFailure(err.response.data.message);
    }
  };

  useEffect(() => {
    if (channels.list.length === 0) {
      navigate("/manage/channels");
    } else {
      if (id) {
        const selectedChannel = channels.list.find((x) => x.id === id);
        if (selectedChannel) {
          setChannelDetails(() => {
            const { id, ...rest } = selectedChannel;

            return rest;
          });

          setLogoPath(selectedChannel.logo);
        } else {
          navigate("/manage/channels");
        }
      }
    }
  }, [navigate, location, channels]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Add / Edit Channels</h4>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <form onSubmit={submitHandler}>
              <div className="row">
                <div className="col-lg-6">
                  {/* <div className="card"> */}
                  {/* <div className="card-body"> */}
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                  </h5>

                  <div className="mb-3">
                    <label htmlFor="channel-name" className="form-label">
                      Channel Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="channel-name"
                      className="form-control"
                      placeholder="CNN"
                      name="name"
                      value={channelDetails?.name}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-reference" className="form-label">
                      Channel Logo <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) =>
                        uploadImage(e, "channels/logos", setLogoPath)
                      }
                    />{" "}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="channel-category" className="form-label">
                      Category <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="channel-category"
                      name="category"
                      value={channelDetails?.category}
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      <option value="news">News</option>
                      <option value="sports">Sports</option>
                      <option value="other">Other</option>
                    </select>
                  </div>

                  {/* <div className="mb-3">
                  <label htmlFor="product-description" className="form-label">
                    Channel Description <span className="text-danger">*</span>
                  </label>
                  <div id="snow-editor" style={{ height: "150px" }}></div>
                </div> */}
                  <div className="mb-3">
                    <label className="mb-2">
                      Status <span className="text-danger">*</span>
                    </label>
                    <br />
                    <div className="radio form-check-inline">
                      <input
                        type="radio"
                        value="Active"
                        name="status"
                        id="status-1"
                        checked={channelDetails.status === "Active"}
                        onChange={(e) =>
                          setChannelDetails({
                            ...channelDetails,
                            status: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="status-1"> Active </label>
                    </div>
                    <div className="radio form-check-inline">
                      <input
                        type="radio"
                        value="Inactive"
                        name="status"
                        id="status-2"
                        checked={channelDetails.status === "Inactive"}
                        onChange={(e) =>
                          setChannelDetails({
                            ...channelDetails,
                            status: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="status-2"> In-active </label>
                    </div>
                    <div className="radio form-check-inline">
                      <input
                        type="radio"
                        value="Draft"
                        name="status"
                        id="status-3"
                        checked={channelDetails.status === "Draft"}
                        onChange={(e) =>
                          setChannelDetails({
                            ...channelDetails,
                            status: e.target.value,
                          })
                        }
                      />
                      <label htmlFor="status-3"> Draft </label>
                    </div>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                </div>

                <div className="col-lg-6">
                  {/* <div className="card"> */}
                  {/* <div className="card-body"> */}
                  <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                    Streaming Url
                  </h5>
                  <div className="mb-3">
                    <label htmlFor="product-source" className="form-label">
                      Url <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="product-source"
                      className="form-control"
                      placeholder="https://www.youtube.com/"
                      name="source"
                      value={channelDetails?.source}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="product-source" className="form-label">
                      IFrame Url <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      id="product-source"
                      className="form-control"
                      placeholder="https://www.youtube.com/"
                      name="iframeUrl"
                      value={channelDetails?.iframeUrl}
                      onChange={changeHandler}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="channel-language" className="form-label">
                      Choose Language <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="channel-language"
                      name="language"
                      value={channelDetails?.language}
                      onChange={changeHandler}
                    >
                      <option selected disabled="">
                        Select
                      </option>
                      <option value="English">English</option>
                      <option value="Somali">Somali</option>{" "}
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="channel-quality" className="form-label">
                      Choose quality <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="channel-quality"
                      name="quality"
                      value={channelDetails?.quality}
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      <option value="Full HD">Full HD</option>
                      <option value="HD">HD</option>
                    </select>
                  </div>{" "}
                </div>
              </div>
              {/* <h4 className="text-uppercase text-center mt-0 mb-3 p-2">Or</h4> */}
              {/* </div> */}
              {/* </div> */}

              <div className="row mt-3">
                <div className="col-12 text-center">
                  <PrimaryButton type="submit">
                    {loading ? (
                      <ThreeDots color="#000000" height={20} width={50} />
                    ) : (
                      <>
                        <i className="fe-check-circle me-1"></i> Update
                      </>
                    )}{" "}
                  </PrimaryButton>
                  <SecondaryButton onClick={() => navigate("/manage/channels")}>
                    <i className="fe-x me-1"></i> Cancel
                  </SecondaryButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditChannels;
