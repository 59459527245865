import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { axiosPublic } from "../../services/api";

function AreaChart() {
  const [type, setType] = useState("month");
  const [chartData, setChartData] = useState([]);
  const [currSales, setCurrSales] = useState(null);
  const [prevSales, setPrevSales] = useState(null);

  const options = {
    chart: {
      id: "basic-area",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData.map((x) => x.date),
    },
  };

  const series = [
    {
      data: chartData.map((x) => x.totalAmount),
    },
    // {
    //   data: [25, 30, 15, 22, 28, 40],
    // },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axiosPublic.get(`stats/sales?type=${type}`);
        setChartData(data.chartData);
        setPrevSales(data.prevSales);
        setCurrSales(data.currSales);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, [type]);

  return (
    <div className="card">
      <div className="card-body pb-2">
        <div className="float-end d-none d-md-inline-block">
          <div className="btn-group mb-2">
            <button
              type="button"
              className={`btn btn-xs ${type === "day" ? "btn-secondary" : ""}`}
              onClick={() => setType("day")}
            >
              Daily
            </button>
            <button
              type="button"
              className={`btn btn-xs ${type === "week" ? "btn-secondary" : ""}`}
              onClick={() => setType("week")}
            >
              Weekly
            </button>
            <button
              type="button"
              className={`btn btn-xs ${
                type === "month" ? "btn-secondary" : ""
              }`}
              onClick={() => setType("month")}
            >
              Monthly
            </button>
          </div>
        </div>

        <h4 className="header-title mb-3">Sales Analytics</h4>
        {chartData.length === 0 ? (
          <div
            style={{ width: "100%", height: "100%" }}
            className="d-flex align-items-center justify-content-center"
          >
            <p>No data found</p>
          </div>
        ) : (
          <>
            <div className="row text-center">
              <div className="col-md-6">
                <p className="text-muted mb-0 mt-3">
                  {type === "day" ? "Today" : <>Current {type}</>}
                </p>
                <h2 className="fw-normal mb-3">
                  <small className="mdi mdi-checkbox-blank-circle text-primary align-middle me-1"></small>
                  <span>${currSales?.totalAmount}</span>
                </h2>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0 mt-3">
                  {type === "day" ? "Yesterday" : <>Previous {type}</>}
                </p>
                <h2 className="fw-normal mb-3">
                  <small className="mdi mdi-checkbox-blank-circle text-success align-middle me-1"></small>
                  <span>${prevSales?.totalAmount}</span>
                </h2>
              </div>
              {/* <div className="col-md-4">
            <p className="text-muted mb-0 mt-3">Targets</p>
            <h2 className="fw-normal mb-3">
              <small className="mdi mdi-checkbox-blank-circle text-success align-middle me-1"></small>
              <span>-</span>
            </h2>
          </div> */}
            </div>
            {/* <div
          id="revenue-chart"
          className="apex-charts mt-3"
          data-colors="#6658dd,#1abc9c"
        ></div> */}

            <Chart options={options} series={series} type="bar" width="100%" />
          </>
        )}
      </div>
    </div>
  );
}

export default AreaChart;
