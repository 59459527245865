import React, { useState, useEffect, useRef, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
// import "./player.css";
import "./player.css";
import { IoMdClose } from "react-icons/io";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Puff } from "react-loader-spinner";
import NextVideoPopup from "./NextVideoPopup";
import { axiosPublic } from "../../services/api";
import axios from "axios";

function CustomPlayer({
  videoRef,
  src,
  onReady,
  title,
  titleImg,
  videoType,
  videoId,

  showNextVideo,
  setShowNextVideo,
  nextVideoFlag,
  setNextVideoFlag,
  suggestedVideo,
  data,
  setLoadVideo,
}) {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  //   const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const videoDetailsRef = useRef(null);
  const videoControlsRef = useRef(null);
  const timeElapsedRef = useRef(null);
  const totalDurationRef = useRef(null);
  const playButtonRef = useRef(null);
  const playBackButtonRef = useRef(null);
  const muteButtonRef = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(0.65);

  const [videoReady, setVideoReady] = useState(false);
  const [videoValid, setVideoValid] = useState(true);
  const [videoBlob, setVideoBlob] = useState("");

  useEffect(() => {
    // Select elements here
    // const video = document.getElementById("video");
    // const videoControls = document.getElementById("video-controls");
    // const playButton = document.getElementById("play");
    const playbackIcons = document.querySelectorAll(".playback-icons use");
    // const timeElapsed = document.getElementById("time-elapsed");
    // const duration = document.getElementById("duration");
    // const progressBar = document.getElementById("progress-bar");
    const seek = document.getElementById("seek");
    const seekTooltip = document.getElementById("seek-tooltip");
    const volumeButton = document.getElementById("volume-button");
    const volumeIcons = document.querySelectorAll(".volume-button use");
    const volumeMute = document.querySelector('use[href="#volume-mute"]');
    const volumeLow = document.querySelector('use[href="#volume-low"]');
    const volumeHigh = document.querySelector('use[href="#volume-high"]');
    const volume = document.getElementById("volume");
    const playbackAnimation = document.getElementById("playback-animation");
    const fullscreenButton = document.getElementById("fullscreen-button");
    const videoContainer = document.getElementById("video-container");
    const fullscreenIcons = fullscreenButton.querySelectorAll("use");
    // const pipButton = document.getElementById("pip-button");

    const videoWorks = !!document.createElement("video").canPlayType;

    if (videoWorks) {
      videoRef.current.controls = false;
      videoDetailsRef.current.classList.remove("hidden");
      videoControlsRef.current.classList.remove("hidden");
    }

    // Add functions here

    // togglePlay toggles the playback state of the video.
    // If the video playback is paused or ended, the video is played
    // otherwise, the video is paused
    function togglePlay() {
      if (videoRef.current.paused || videoRef.current.ended) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }

    // updatePlayButton updates the playback icon and tooltip
    // depending on the playback state
    function updatePlayButton() {
      playbackIcons.forEach((icon) => icon.classList.toggle("hidden"));

      if (videoRef.current.paused) {
        playButtonRef.current.setAttribute("data-title", "Play (k)");
      } else {
        playButtonRef.current.setAttribute("data-title", "Pause (k)");
      }
    }

    // formatTime takes a time length in seconds and returns the time in
    // minutes and seconds
    function formatTime(timeInSeconds) {
      const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);

      return {
        hours: result.substr(0, 2),

        minutes: result.substr(3, 2),
        seconds: result.substr(6, 2),
      };
    }

    // initializeVideo sets the video duration, and maximum value of the
    // progressBar
    function initializeVideo() {
      const videoDuration = Math.round(videoRef.current.duration);
      seek.setAttribute("max", videoDuration);
      progressBarRef.current.setAttribute("max", videoDuration);
      // progressBarRef.current.style.width = `${videoDuration}%`;
      const time = formatTime(videoDuration);
      totalDurationRef.current.innerText = `${time.hours}:${time.minutes}:${time.seconds}`;
      totalDurationRef.current.setAttribute(
        "datetime",
        `${time.hours}h:${time.minutes}m ${time.seconds}s`
      );

      setVideoReady(true);
      onReady();
    }

    // updateTimeElapsed indicates how far through the video
    // the current playback is by updating the timeElapsed element
    function updateTimeElapsed() {
      const time = formatTime(Math.round(videoRef.current.currentTime));
      timeElapsedRef.current.innerText = `${time.hours}:${time.minutes}:${time.seconds}`;
      timeElapsedRef.current.setAttribute(
        "datetime",
        `${time.hours}:${time.minutes}m ${time.seconds}s`
      );
    }

    // updateProgress indicates how far through the video
    // the current playback is by updating the progress bar
    function updateProgress() {
      seek.value = Math.floor(videoRef.current.currentTime);
      progressBarRef.current.value = Math.floor(videoRef.current.currentTime);
      progressBarRef.current.style.width = `${
        (videoRef.current.currentTime / videoRef.current.duration) * 100
      }%`;
    }

    // updateSeekTooltip uses the position of the mouse on the progress bar to
    // roughly work out what point in the video the user will skip to if
    // the progress bar is clicked at that point
    function updateSeekTooltip(event) {
      const skipTo = Math.round(
        (event.offsetX / event.target.clientWidth) *
          parseInt(event.target.getAttribute("max"), 10)
      );
      seek.setAttribute("data-seek", skipTo);
      const t = formatTime(skipTo);
      seekTooltip.textContent = `${t.hours}:${t.minutes}:${t.seconds}`;
      const rect = videoRef.current.getBoundingClientRect();
      seekTooltip.style.left = `${event.pageX + 20 - rect.left}px`;
    }

    // skipAhead jumps to a different point in the video when the progress bar
    // is clicked

    function skipAhead(event) {
      const skipTo = event.target.dataset.seek
        ? event.target.dataset.seek
        : event.target.value;
      videoRef.current.currentTime = skipTo;
      progressBarRef.current.value = skipTo;

      const progressCalc = (skipTo / videoRef.current.duration) * 100;
      progressBarRef.current.style.width = `${progressCalc}%`;
      seek.value = skipTo;
      updateTimeElapsed();
    }

    // updateVolume updates the video's volume
    // and disables the muted state if active
    function updateVolume() {
      if (videoRef.current.muted) {
        videoRef.current.muted = false;
      }

      videoRef.current.volume = volume.value;
    }

    // updateVolumeIcon updates the volume icon so that it correctly reflects
    // the volume of the video
    function updateVolumeIcon() {
      volumeIcons.forEach((icon) => {
        icon.classList.add("hidden");
      });

      volumeButton.setAttribute("data-title", "Mute (m)");

      if (videoRef.current.muted || videoRef.current.volume === 0) {
        volumeMute.classList.remove("hidden");
        volumeButton.setAttribute("data-title", "Unmute (m)");
      } else if (
        videoRef.current.volume > 0 &&
        videoRef.current.volume <= 0.5
      ) {
        volumeLow.classList.remove("hidden");
      } else {
        volumeHigh.classList.remove("hidden");
      }
    }

    // toggleMute mutes or unmutes the video when executed
    // When the video is unmuted, the volume is returned to the value
    // it was set to before the video was muted
    function toggleMute() {
      videoRef.current.muted = !videoRef.current.muted;

      if (videoRef.current.muted) {
        volume.setAttribute("data-volume", volume.value);
        volume.value = 0;
      } else {
        volume.value = volume.dataset.volume;
      }
    }

    // animatePlayback displays an animation when
    // the video is played or paused
    function animatePlayback() {
      playbackAnimation.animate(
        [
          {
            opacity: 1,
            transform: "scale(1)",
          },
          {
            opacity: 0,
            transform: "scale(1.3)",
          },
        ],
        {
          duration: 500,
        }
      );
    }

    // toggleFullScreen toggles the full screen state of the video
    // If the browser is currently in fullscreen mode,
    // then it should exit and vice versa.
    function toggleFullScreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.webkitFullscreenElement) {
        // Need this to support Safari
        document.webkitExitFullscreen();
      } else if (videoContainer.webkitRequestFullscreen) {
        // Need this to support Safari
        videoContainer.webkitRequestFullscreen();
      } else {
        videoContainer.requestFullscreen();
      }
    }

    // updateFullscreenButton changes the icon of the full screen button
    // and tooltip to reflect the current full screen state of the video
    function updateFullscreenButton() {
      fullscreenIcons.forEach((icon) => icon.classList.toggle("hidden"));

      if (document.fullscreenElement) {
        fullscreenButton.setAttribute("data-title", "Exit full screen (f)");
      } else {
        fullscreenButton.setAttribute("data-title", "Full screen (f)");
      }
    }

    // // togglePip toggles Picture-in-Picture mode on the video
    // async function togglePip() {
    //   try {
    //     if (videoRef.current !== document.pictureInPictureElement) {
    //       pipButton.disabled = true;
    //       await videoRef.current.requestPictureInPicture();
    //     } else {
    //       await document.exitPictureInPicture();
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   } finally {
    //     pipButton.disabled = false;
    //   }
    // }

    // hideControls hides the video controls when not in use
    // if the video is paused, the controls must remain visible
    function hideControls() {
      if (videoRef.current.paused) {
        return;
      }
      videoDetailsRef.current.classList.add("hide");
      videoControlsRef.current.classList.add("hide");
    }

    // showControls displays the video controls
    function showControls() {
      videoDetailsRef.current.classList.remove("hide");
      videoControlsRef.current.classList.remove("hide");
    }

    // keyboardShortcuts executes the relevant functions for
    // each supported shortcut key
    function keyboardShortcuts(event) {
      const { key } = event;
      switch (key) {
        case "k":
          togglePlay();
          animatePlayback();
          if (videoRef.current.paused) {
            showControls();
          } else {
            setTimeout(() => {
              hideControls();
            }, 2000);
          }
          break;
        case "m":
          toggleMute();
          break;
        case "f":
          toggleFullScreen();
          break;
        case "p":
          // togglePip();
          break;
      }
    }
    // function videoEndHandler() {
    // }
    // Add eventlisteners here
    playButtonRef.current.addEventListener("click", togglePlay);
    playBackButtonRef.current.addEventListener("click", togglePlay);

    // videoDetailsRef.current.addEventListener("click", togglePlay);
    videoRef.current.addEventListener("play", updatePlayButton);
    videoRef.current.addEventListener("pause", updatePlayButton);
    videoRef.current.addEventListener("loadedmetadata", initializeVideo);
    videoRef.current.addEventListener("timeupdate", updateTimeElapsed);
    videoRef.current.addEventListener("timeupdate", updateProgress);
    videoRef.current.addEventListener("volumechange", updateVolumeIcon);
    videoRef.current.addEventListener("click", togglePlay);
    videoRef.current.addEventListener("click", animatePlayback);
    // videoRef.current.addEventListener('ended', videoEndHandler)

    // containerRef.current.addEventListener("mousemove", () => {
    //   showControls();
    //   let timeout;

    //   // clearTimeout(timer);
    //   // timer = setTimeout(() => hideControls(), 5000);

    //   if (timeout !== undefined) {
    //     window.clearTimeout(timeout);
    //   }
    //   timeout = window.setTimeout(function () {
    //     // trigger the new event on event.target, so that it can bubble appropriately
    //     hideControls();
    //   }, 8000);
    // });

    var timer;

    containerRef.current.addEventListener("mousemove", function () {
      // stoppedElement.innerHTML="Mouse moving";
      showControls();
      clearTimeout(timer);
      timer = setTimeout(hideControls, 3000);
    });

    // videoRef.current.addEventListener("mouseenter", showControls);
    // videoRef.current.addEventListener("mouseleave", hideControls);
    // videoControlsRef.current.addEventListener("mouseenter", showControls);
    // videoControlsRef.current.addEventListener("mouseleave", hideControls);
    seek.addEventListener("mousemove", updateSeekTooltip);
    seek.addEventListener("input", skipAhead);
    // volume.addEventListener("input", updateVolume);
    volumeButton.addEventListener("click", toggleMute);
    fullscreenButton.addEventListener("click", toggleFullScreen);
    videoContainer.addEventListener("fullscreenchange", updateFullscreenButton);
    // pipButton.addEventListener("click", togglePip);

    // document.addEventListener("DOMContentLoaded", () => {
    //   if (!("pictureInPictureEnabled" in document)) {
    //     pipButton.classList.add("hidden");
    //   }
    // });
    document.addEventListener("keyup", keyboardShortcuts);

    // return () => {
    //   playButtonRef.current.removeEventListener(togglePlay);
    //   // videoDetailsRef.current.removeEventListener(togglePlay);

    //   videoRef.current.removeEventListener(updatePlayButton);
    //   videoRef.current.removeEventListener(updatePlayButton);
    //   videoRef.current.removeEventListener(initializeVideo);
    //   videoRef.current.removeEventListener(updateTimeElapsed);
    //   videoRef.current.removeEventListener(updateProgress);
    //   videoRef.current.removeEventListener(updateVolumeIcon);
    //   videoRef.current.removeEventListener(togglePlay);
    //   videoRef.current.removeEventListener(animatePlayback);
    //   videoRef.current.removeEventListener(showControls);
    //   videoRef.current.removeEventListener(hideControls);
    //   videoControlsRef.current.removeEventListener(showControls);
    //   videoControlsRef.current.removeEventListener(hideControls);
    //   videoDetailsRef.current.removeEventListener(showControls);
    //   videoDetailsRef.current.removeEventListener(hideControls);
    //   seek.removeEventListener(updateSeekTooltip);
    //   seek.removeEventListener(skipAhead);
    //   volume.removeEventListener(updateVolume);
    //   volumeButton.removeEventListener(toggleMute);
    //   fullscreenButton.removeEventListener(toggleFullScreen);
    //   videoContainer.removeEventListener(updateFullscreenButton);
    //   // pipButton.removeEventListener(togglePip);

    //   // document.addEventListener("DOMContentLoaded", () => {
    //   //   if (!("pictureInPictureEnabled" in document)) {
    //   //     pipButton.classList.add("hidden");
    //   //   }
    //   // });
    //   document.removeEventListener(keyboardShortcuts);
    // };

    // checkUrl(src);
  }, []);

  useEffect(() => {
    // setVideoValid(false);
    checkUrl(src);
  }, [src]);

  const updateVolumeHandler = (e) => {
    setVolume(e.target.value);
    videoRef.current.volume = e.target.value;
  };

  const checkUrl = async (url) => {
    const result = await fetch(url, { method: "HEAD" });
    setVideoValid(result.ok);
    
    // axios({
    //   method: "GET",
    //   url,
    //   responseType: "blob",
    //   withCredentials: true,
    // }).then(
    //   axios.spread((...responses) => {
    //     responses.map((res) => console.log(URL.createObjectURL(res.data)));
    //   })
    // );

    // console.log("blob 2=>", result);
    // return result;
  };

  return (
    <div ref={containerRef} className="player-container">
      {videoValid ? (
        <>
          {" "}
          {!videoReady && (
            <div className="video-loader">
              <Puff
                height="120"
                width="120"
                radius={1}
                color="#4fa94d"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <div className="video-loader">
            <div className="bg-black error-container">
              <p className="not-allowed-message">
                {" "}
                <span color="text-danger">
                  <i class="fa fa-ban text-danger" aria-hidden="true"></i>{" "}
                </span>
                An error encountered while playing the video. <br />
              </p>
              <p className="not-allowed-message text-center">
                <Link to="" onClick={() => navigate(-1)}>
                  Go Back
                </Link>
              </p>
            </div>
          </div>
        </>
      )}
      <div className="video-container" id="video-container">
        <video
          controls
          className="video"
          id="video"
          preload="metadata"
          poster="poster.jpg"
          ref={videoRef}
          // onError={(err) => console.log("video err==>", err)}
        >
          <source src={src} type="video/mp4"></source>
        </video>

        <div ref={videoDetailsRef} className="video-details">
          <div
            className="playback-animation"
            id="playback-animation"
            ref={playBackButtonRef}
          >
            <svg className="playback-icons">
              <use href="#play-icon"></use>
              <use className="hidden" href="#pause"></use>
            </svg>
          </div>
          <div className="video-details-head">
            <div
              className="close-div"
              onClick={() =>
                navigate(
                  `/watch/${videoType.toLowerCase()}s/${
                    videoType == "Show" ? data.show.id : data.id
                  }`,
                  {
                    state: { loadData: false },
                  }
                )
              }
            >
              <IoMdClose color="white" size={24} />
            </div>
            <h4>{title}</h4>
          </div>
          <div className="video-details-body">
            <LazyLoadImage src={titleImg} alt="" className="text-img" />
          </div>
          <div
            ref={videoControlsRef}
            className="video-controls hidden"
            id="video-controls"
          >
            <div className="video-progress">
              <div
                ref={progressBarRef}
                className="progress-bar bg-danger"
                role="progressbar"
                // style={{ width: "85%" }}
                aria-valuenow="15"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
              {/* <div
                  className="progress-bar bg-success"
                  role="progressbar"
                  style="width: 30%"
                  aria-valuenow="30"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style="width: 20%"
                  aria-valuenow="20"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div> */}
              {/* <progress
                ref={progressBarRef}
                id="progress-bar"
                value="0"
                min="0"
              ></progress>*/}
              <input
                className="seek"
                id="seek"
                defaultValue="50"
                min="0"
                type="range"
                step="1"
              />
              <div className="seek-tooltip" id="seek-tooltip">
                00:00
              </div>
            </div>

            <div className="bottom-controls">
              <div className="left-controls">
                <button ref={playButtonRef} data-title="Play" id="play">
                  <svg className="playback-icons">
                    <use href="#play-icon"></use>
                    <use className="hidden" href="#pause"></use>
                  </svg>
                </button>

                <div className="volume-controls">
                  <button
                    data-title="Mute (m)"
                    className="volume-button"
                    ref={muteButtonRef}
                    id="volume-button"
                  >
                    <svg>
                      <use className="hidden" href="#volume-mute"></use>
                      <use className="hidden" href="#volume-low"></use>
                      <use href="#volume-high"></use>
                    </svg>
                  </button>

                  <input
                    className="volume"
                    type="range"
                    id="vol"
                    name="vol"
                    min="0"
                    max="1"
                    step="0.01"
                    onChange={updateVolumeHandler}
                    value={volume}
                  />
                  {/* <input
                    // className="volume"
                    // ref={volumeControlRef}
                    onChange={updateVolumeHandler}
                    id="volume"
                    value={volume}
                    data-mute="0.5"
                    type="range"
                    max="1"
                    min="0"
                    step="0.01"
                  /> */}
                </div>

                <div className="time">
                  <time ref={timeElapsedRef} id="time-elapsed">
                    00:00
                  </time>
                  <span> / </span>
                  <time ref={totalDurationRef} id="duration">
                    00:00
                  </time>
                </div>
              </div>

              <div className="right-controls">
                {/* {videoType == "Show" ||
                  (true && ( */}
                <button
                  data-title="More like this"
                  className="pip-button"
                  onClick={() =>
                    navigate(`/watch/${videoType.toLowerCase()}s/${videoId}`, {
                      state: {
                        scrollTo:
                          videoType == "Show" ? "episodes" : "moreLikeThis",
                        loadData: false,
                      },
                    })
                  }
                  // id="pip-button"
                >
                  {/* <svg>
                    <use href="#pip"></use>
                  </svg> */}
                  <svg
                    width="800px"
                    height="800px"
                    viewBox="0 0 21 21"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#fff"
                      // stroke-linecap="round"
                      // stroke-linejoin="round"
                      transform="translate(1 4)"
                    >
                      <path d="m13.5 12.5v-6c0-1.1045695-.8954305-2-2-2h-9c-1.1045695 0-2 .8954305-2 2v6c0 1.1045695.8954305 2 2 2h9c1.1045695 0 2-.8954305 2-2z" />

                      <path d="m15.5 12.5v-6.99481259c0-1.65685425-1.3431458-3-3-3-.0017276 0-.0034553 0-.0051829 0l-8.9948171.01554432" />

                      <path d="m17.5 10.5v-5.99308345c0-2.209139-1.790861-4-4-4-.0023035 0-.004607 0-.0069106 0l-7.9930894.01381519" />
                    </g>
                  </svg>
                </button>
                {/* ))} */}
                <button
                  data-title="Full screen (f)"
                  className="fullscreen-button"
                  id="fullscreen-button"
                >
                  <svg>
                    <use href="#fullscreen"></use>
                    <use href="#fullscreen-exit" className="hidden"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        {showNextVideo && (
          <NextVideoPopup
            showNextVideo={showNextVideo}
            setShowNextVideo={setShowNextVideo}
            videoRef={videoRef}
            suggestedVideo={suggestedVideo}
            nextVideoFlag={nextVideoFlag}
            setNextVideoFlag={setNextVideoFlag}
            videoType={videoType}
            setLoadVideo={setLoadVideo}
            // showCard={showCard}
            // setShowCard={setShowCard}
          />
        )}
      </div>

      <svg style={{ display: "none" }}>
        <defs>
          <symbol id="pause" viewBox="0 0 24 24">
            <path d="M14.016 5.016h3.984v13.969h-3.984v-13.969zM6 18.984v-13.969h3.984v13.969h-3.984z"></path>
          </symbol>

          <symbol id="play-icon" viewBox="0 0 24 24">
            <path d="M8.016 5.016l10.969 6.984-10.969 6.984v-13.969z"></path>
          </symbol>

          <symbol id="volume-high" viewBox="0 0 24 24">
            <path d="M14.016 3.234q3.047 0.656 5.016 3.117t1.969 5.648-1.969 5.648-5.016 3.117v-2.063q2.203-0.656 3.586-2.484t1.383-4.219-1.383-4.219-3.586-2.484v-2.063zM16.5 12q0 2.813-2.484 4.031v-8.063q1.031 0.516 1.758 1.688t0.727 2.344zM3 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6z"></path>
          </symbol>

          <symbol id="volume-low" viewBox="0 0 24 24">
            <path d="M5.016 9h3.984l5.016-5.016v16.031l-5.016-5.016h-3.984v-6zM18.516 12q0 2.766-2.531 4.031v-8.063q1.031 0.516 1.781 1.711t0.75 2.32z"></path>
          </symbol>

          <symbol id="volume-mute" viewBox="0 0 24 24">
            <path d="M12 3.984v4.219l-2.109-2.109zM4.266 3l16.734 16.734-1.266 1.266-2.063-2.063q-1.547 1.313-3.656 1.828v-2.063q1.172-0.328 2.25-1.172l-4.266-4.266v6.75l-5.016-5.016h-3.984v-6h4.734l-4.734-4.734zM18.984 12q0-2.391-1.383-4.219t-3.586-2.484v-2.063q3.047 0.656 5.016 3.117t1.969 5.648q0 2.203-1.031 4.172l-1.5-1.547q0.516-1.266 0.516-2.625zM16.5 12q0 0.422-0.047 0.609l-2.438-2.438v-2.203q1.031 0.516 1.758 1.688t0.727 2.344z"></path>
          </symbol>

          <symbol id="fullscreen" viewBox="0 0 24 24">
            <path d="M14.016 5.016h4.969v4.969h-1.969v-3h-3v-1.969zM17.016 17.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 9.984v-4.969h4.969v1.969h-3v3h-1.969zM6.984 14.016v3h3v1.969h-4.969v-4.969h1.969z"></path>
          </symbol>

          <symbol id="fullscreen-exit" viewBox="0 0 24 24">
            <path d="M15.984 8.016h3v1.969h-4.969v-4.969h1.969v3zM14.016 18.984v-4.969h4.969v1.969h-3v3h-1.969zM8.016 8.016v-3h1.969v4.969h-4.969v-1.969h3zM5.016 15.984v-1.969h4.969v4.969h-1.969v-3h-3z"></path>
          </symbol>

          <symbol id="pip" viewBox="0 0 24 24">
            <path d="M21 19.031v-14.063h-18v14.063h18zM23.016 18.984q0 0.797-0.609 1.406t-1.406 0.609h-18q-0.797 0-1.406-0.609t-0.609-1.406v-14.016q0-0.797 0.609-1.383t1.406-0.586h18q0.797 0 1.406 0.586t0.609 1.383v14.016zM18.984 11.016v6h-7.969v-6h7.969z"></path>
          </symbol>
        </defs>
      </svg>

      {/* <script src="index.js"></script> */}
    </div>
  );
}

export default memo(CustomPlayer);
