import React from "react";
import { useNavigate } from "react-router-dom";

function SportsSection() {
  const navigate = useNavigate();
  return (
    <div className="thi-section mt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <div className="thi-text">
              <h2>INFINITE SPORTS</h2>
              <p>
                Stream live action from the LaLiga, Premier League, Serie A, and
                more, along with highlights, replays and expert analysis from
                Iftiin Sports.
              </p>
              <button className="btn-watch" onClick={() => navigate("/pricing")}>
                Get Started
              </button>
            </div>
          </div>
          <div className="col-md-3">
            <div className="thi-img">
              <img src="images/thi1.png" alt="" id="thi-img" />
              <img src="images/thi2.png" alt="" id="thi-img" />
              <img src="images/thi3.png" alt="" id="thi-img" />
              <img src="images/thi4.png" alt="" id="thi-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SportsSection;
