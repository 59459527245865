export const GET_WALLETS_REQUEST = "GET_WALLETS_REQUEST";
export const GET_WALLETS_SUCCESS = "GET_WALLETS_SUCCESS";
export const GET_WALLETS_FAIL = "GET_WALLETS_FAIL";

export const GET_USER_WALLET_REQUEST = "GET_USER_WALLET_REQUEST";
export const GET_USER_WALLET_SUCCESS = "GET_USER_WALLET_SUCCESS";
export const GET_USER_WALLET_FAIL = "GET_USER_WALLET_FAIL";

export const UPDATE_WALLET_REQUEST = "UPDATE_WALLET_REQUEST";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL";

export const DELETE_WALLET_REQUEST = "DELETE_WALLET_REQUEST";
export const DELETE_WALLET_SUCCESS = "DELETE_WALLET_SUCCESS";
export const DELETE_WALLET_FAIL = "DELETE_WALLET_FAIL";
