import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { userLogout } from "../../actions/authActions";

function Topbar({ showSidebar, setShowSidebar }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { userInfo, tokens } = auth;

  const logoutHandler = () => {
    dispatch(
      userLogout({
        refreshToken: tokens.refresh.token,
      })
    );
  };

  return (
    <div className="navbar-custom">
      <div className="container-fluid">
        <ul className="list-unstyled topnav-menu float-end mb-0">
          <li className="d-none d-lg-block">
            <form className="app-search">
              <div className="app-search-box dropdown">
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                    id="top-search"
                  />
                  <button className="btn input-group-text" type="submit">
                    <i className="fe-search"></i>
                  </button>
                </div>
                <div className="dropdown-menu dropdown-lg" id="search-dropdown">
                  <div className="dropdown-header noti-title">
                    <h5 className="text-overflow mb-2">Found 22 results</h5>
                  </div>

                  <Link to="" className="dropdown-item notify-item">
                    <i className="fe-home me-1"></i>
                    <span>Analytics Report</span>
                  </Link>

                  <Link to="" className="dropdown-item notify-item">
                    <i className="fe-aperture me-1"></i>
                    <span>How can I help you?</span>
                  </Link>

                  <Link to="" className="dropdown-item notify-item">
                    <i className="fe-settings me-1"></i>
                    <span>User profile settings</span>
                  </Link>

                  <div className="dropdown-header noti-title">
                    <h6 className="text-overflow mb-2 text-uppercase">Users</h6>
                  </div>

                  <div className="notification-list">
                    <Link to="" className="dropdown-item notify-item">
                      <div className="d-flex align-items-start">
                        <img
                          className="d-flex me-2 rounded-circle"
                          src="assets/images/users/user-2.jpg"
                          alt=""
                          height="32"
                        />
                        <div className="w-100">
                          <h5 className="m-0 font-14">Erwin E. Brown</h5>
                          <span className="font-12 mb-0">UI Designer</span>
                        </div>
                      </div>
                    </Link>

                    <Link to="" className="dropdown-item notify-item">
                      <div className="d-flex align-items-start">
                        <img
                          className="d-flex me-2 rounded-circle"
                          src="assets/images/users/user-5.jpg"
                          alt=""
                          height="32"
                        />
                        <div className="w-100">
                          <h5 className="m-0 font-14">Jacob Deo</h5>
                          <span className="font-12 mb-0">Developer</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </li>

          <li className="dropdown d-inline-block d-lg-none">
            <Link
              className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
              data-bs-toggle="dropdown"
              to=""
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="fe-search noti-icon"></i>
            </Link>
            <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
              <form className="p-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
              </form>
            </div>
          </li>

          <li className="dropdown notification-list topbar-dropdown">
            <Link
              className="nav-link dropdown-toggle waves-effect waves-light"
              data-bs-toggle="dropdown"
              to=""
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <i className="fe-bell noti-icon"></i>
              <span className="badge bg-danger rounded-circle noti-icon-badge">
                9
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end dropdown-lg">
              <div className="dropdown-item noti-title">
                <h5 className="m-0">
                  <span className="float-end">
                    <Link to="" className="text-dark">
                      <small>Clear All</small>
                    </Link>
                  </span>
                  Notification
                </h5>
              </div>

              <div className="noti-scroll" data-simplebar>
                <Link to="" className="dropdown-item notify-item active">
                  <div className="notify-icon">
                    <img
                      src="assets/images/users/user-1.jpg"
                      className="img-fluid rounded-circle"
                      alt=""
                    />
                  </div>
                  <p className="notify-details">Cristina Pride</p>
                  <p className="text-muted mb-0 user-msg">
                    <small>Hi, How are you? What about our next meeting</small>
                  </p>
                </Link>
                <Link to="" className="dropdown-item notify-item">
                  <div className="notify-icon bg-primary">
                    <i className="mdi mdi-comment-account-outline"></i>
                  </div>
                  <p className="notify-details">
                    Caleb Flakelar commented on Admin
                    <small className="text-muted">1 min ago</small>
                  </p>
                </Link>
                <Link to="" className="dropdown-item notify-item">
                  <div className="notify-icon">
                    <img
                      src="assets/images/users/user-4.jpg"
                      className="img-fluid rounded-circle"
                      alt=""
                    />
                  </div>
                  <p className="notify-details">Karen Robinson</p>
                  <p className="text-muted mb-0 user-msg">
                    <small>
                      Wow ! this admin looks good and awesome design
                    </small>
                  </p>
                </Link>
                <Link to="" className="dropdown-item notify-item">
                  <div className="notify-icon bg-warning">
                    <i className="mdi mdi-account-plus"></i>
                  </div>
                  <p className="notify-details">
                    New user registered.
                    <small className="text-muted">5 hours ago</small>
                  </p>
                </Link>
                <Link to="" className="dropdown-item notify-item">
                  <div className="notify-icon bg-info">
                    <i className="mdi mdi-comment-account-outline"></i>
                  </div>
                  <p className="notify-details">
                    Caleb Flakelar commented on Admin
                    <small className="text-muted">4 days ago</small>
                  </p>
                </Link>
                <Link to="" className="dropdown-item notify-item">
                  <div className="notify-icon bg-secondary">
                    <i className="mdi mdi-heart"></i>
                  </div>
                  <p className="notify-details">
                    Carlos Crouch liked
                    <b>Admin</b>
                    <small className="text-muted">13 days ago</small>
                  </p>
                </Link>
              </div>

              <Link
                to=""
                className="dropdown-item text-center text-primary notify-item notify-all"
              >
                View all
                <i className="fe-arrow-right"></i>
              </Link>
            </div>
          </li>
          {userInfo && (
            <li className="dropdown notification-list topbar-dropdown">
              <Link
                className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                data-bs-toggle="dropdown"
                to=""
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  src="assets/images/users/user-1.jpg"
                  alt="user"
                  className="rounded-circle"
                />
                <span className="pro-user-name ms-1">
                  {userInfo.displayName}
                  <i className="mdi mdi-chevron-down"></i>
                </span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                <div className="dropdown-header noti-title">
                  <h6 className="text-overflow m-0">
                    Welcome {userInfo.role.slice(0, 1).toUpperCase()}
                    {userInfo.role.slice(1, userInfo.role.length)}!
                  </h6>
                </div>
                <Link to="" className="dropdown-item notify-item">
                  <i className="fe-user"></i>
                  <span>My Account</span>
                </Link>
                {/* <Link to="" className="dropdown-item notify-item">
                  <i className="fe-settings"></i>
                  <span>Settings</span>
                </Link> */}
                {/* <Link to="" className="dropdown-item notify-item">
                <i className="fe-lock"></i>
                <span>Lock Screen</span>
              </Link> */}
                <div className="dropdown-divider"></div>
                <Link
                  onClick={logoutHandler}
                  to=""
                  className="dropdown-item notify-item"
                >
                  <i className="fe-log-out"></i>
                  <span>Logout</span>
                </Link>
              </div>
            </li>
          )}
        </ul>

        <div className="logo-box">
          <Link to="/manage" className="logo logo-dark text-center">
            <span className="logo-sm">
              <img src="/assets/new-images/infisom-logo.png" alt="" height="80" />
              {/* <span className="logo-lg-text-light">UBold</span> */}
            </span>
            <span className="logo-lg">
              <img src="/assets/new-images/infisom-logo.png" alt="" width="80" />
              {/* <span className="logo-lg-text-light">U</span> */}
            </span>
          </Link>

          <Link to="" className="logo logo-light text-center">
            <span className="logo-sm">
              <img src="/assets/new-images/infisom-logo.png" alt="" width="55" />
            </span>
            <span className="logo-lg">
              <img src="/assets/new-images/infisom-logo.png" alt="" width="55" />
            </span>
          </Link>
        </div>

        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
          <li>
            <button
              onClick={() => setShowSidebar(!showSidebar)}
              className="button-menu-mobile waves-effect waves-light"
            >
              <i className="fe-menu"></i>
            </button>
          </li>

          <li>
            <Link
              to=""
              className="navbar-toggle nav-link"
              data-bs-toggle="collapse"
              data-bs-target="#topnav-menu-content"
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </li>
        </ul>
        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default Topbar;
