// export const serverAddress = "http://localhost:5000/v1";
// export const serverAddress = "https://infisom-bckend.herokuapp.com/v1";

// Cloud Front Url
export const cloudfrontUrl = "https://d1zfjb9fqprm4h.cloudfront.net";

//Auth Constants
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_AUTH_REQUEST = "USER_AUTH_REQUEST";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAIL = "USER_AUTH_FAIL";

export const TOKEN_REFRESH_REQUEST = "TOKEN_REFRESH_REQUEST";
export const TOKEN_REFRESH_SUCCESS = "TOKEN_REFRESH_SUCCESS";
export const TOKEN_REFRESH_FAIL = "TOKEN_REFRESH_FAIL";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

// Users Contants
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_RESET = "CREATE_USER_RESET";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

// Extras
export const SEND_VERIFICATION_EMAIL_REQUEST =
  "SEND_VERIFICATION_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_FAIL = "SEND_VERIFICATION_EMAIL_FAIL";

export const GET_PACKAGES_REQUEST = "GET_PACKAGES_REQUEST";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAIL = "GET_PACKAGES_FAIL";

export const SAVE_PACKAGE_DETAILS = "SAVE_PACKAGE_DETAILS";
export const OLD_REGISTRAION = "OLD_REGISTRAION";

export const UPDATE_USER = "UPDATE_USER";

export const GET_USERS_ROLES_SUCCESS = "GET_USERS_ROLES_SUCCESS";

export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";

// STATS CONSTANTS
export const GET_STATS_REQUEST = "GET_STATS_REQUEST";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAIL = "GET_STATS_FAIL";

export const TOGGLE_CHANNEL_SIDEBAR = "TOGGLE_CHANNEL_SIDEBAR";
