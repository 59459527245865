import {
  GET_HOME_PAGE_FAIL,
  GET_HOME_PAGE_REQUEST,
  GET_HOME_PAGE_SUCCESS,
  GET_MOVIES_PAGE_FAIL,
  GET_MOVIES_PAGE_REQUEST,
  GET_MOVIES_PAGE_SUCCESS,
  GET_SHOWS_PAGE_FAIL,
  GET_SHOWS_PAGE_REQUEST,
  GET_SHOWS_PAGE_SUCCESS,
} from "../constants/pageConstants";

export const homePageDataReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case GET_HOME_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOME_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_HOME_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const moviesPageDataReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case GET_MOVIES_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MOVIES_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_MOVIES_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const showsPageDataReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case GET_SHOWS_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SHOWS_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SHOWS_PAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
