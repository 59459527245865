import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissionByRoleAction } from "../../../actions/permissionActions";
import { UPDATE_PERMISSION_SUCCESS } from "../../../constants/permissionConstants";
import axiosPrivate from "../../../services/api";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";

function EditPermissions() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { userInfo } = auth;

  const [status, setStatus] = useState("");
  const [permissionData, setPermissionData] = useState(null);
  const permissions = useSelector((state) => state.permissions);

  useEffect(() => {
    if (permissions.list.length === 0) {
      navigate("/manage/permissions");
    } else {
      const id = location.pathname.split("/")[4];

      if (id) {
        setPermissionData(permissions.list.find((x) => x.id === id));
      }
    }
  }, [location.pathname, navigate, permissions.list]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const permissionId = location.pathname.split("/")[4];
    let data = permissionData;

    delete data.id;

    setStatus("loading");
    try {
      const { data } = await axiosPrivate.patch(
        `/permissions/${permissionId}`,
        permissionData
      );

      dispatch(getPermissionByRoleAction(userInfo.role));
      dispatch({
        type: UPDATE_PERMISSION_SUCCESS,
        payload: data,
      });
      setStatus("success");

      navigate("/manage/permissions");
    } catch (err) {
      setStatus("error");
      // setError(err.response.data.message);
    }
  };

  const moduleHandler = (e, index) => {
    if (e.target.checked) {
      setPermissionData({
        ...permissionData,
        permissions: permissionData.permissions.map((x, i) =>
          i === index
            ? {
                ...x,
                enabled: e.target.checked,
              }
            : x
        ),
      });
    } else {
      setPermissionData({
        ...permissionData,
        permissions: permissionData.permissions.map((x, i) =>
          i === index
            ? {
                ...x,
                enabled: e.target.checked,
                modules: {
                  create: false,
                  read: false,
                  update: false,
                  delete: false,
                },
              }
            : x
        ),
      });
    }
  };

  const crudHandler = (e, index, type) => {
    setPermissionData({
      ...permissionData,
      permissions: permissionData.permissions.map((x, i) =>
        i === index
          ? {
              ...x,
              modules: {
                ...x.modules,
                [type]: e.target.checked,
              },
            }
          : x
      ),
    });
  };

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0"></ol>
              </div>
              <h4 className="page-title">Edit Permissions</h4>
            </div>
          </div>
        </div>
        {permissionData && (
          <div className="row">
            <div className="col-12">
              <form onSubmit={submitHandler}>
                <div className="card">
                  <div className="card-body">
                    <div className="permission-box">
                      <div className="head">
                        <h3>
                          {permissionData.role.slice(0, 1).toUpperCase()}
                          {permissionData.role.slice(
                            1,
                            permissionData.role.length
                          )}
                        </h3>
                      </div>
                      <table>
                        <tr className="table-head">
                          <td className="text-left detailed">Module</td>
                          <td className="detailed">Create</td>
                          <td className="detailed">Read</td>
                          <td className="detailed">Update</td>
                          <td className="detailed">Delete</td>
                        </tr>
                        {permissionData.permissions.map((x, index) => (
                          <tr key={x.id + index}>
                            <td className="form-switch text-left detailed">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={x.enabled}
                                onChange={(e) => moduleHandler(e, index)}
                              />
                              <label className="mx-2" htmlFor="">
                                {x.type}
                              </label>
                            </td>
                            <td className="form-switch detailed">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={x.modules.create}
                                onChange={(e) =>
                                  crudHandler(e, index, "create")
                                }
                              />
                            </td>
                            <td className="form-switch detailed">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={x.modules.read}
                                onChange={(e) => crudHandler(e, index, "read")}
                              />
                            </td>
                            <td className="form-switch detailed">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={x.modules.update}
                                onChange={(e) =>
                                  crudHandler(e, index, "update")
                                }
                              />
                            </td>
                            <td className="form-switch detailed">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={x.modules.delete}
                                onChange={(e) =>
                                  crudHandler(e, index, "delete")
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </table>
                      <div className="my-4">
                        {/* <button>Update</button> */}
                        <div className="sign-in-up-btn-wrap">
                          {/* <button> */}
                          <SecondaryButton
                            onClick={() => navigate("/manage/permissions")}
                          >
                            Cancel
                          </SecondaryButton>
                          <PrimaryButton>
                            {status === "loading" ? (
                              <ThreeDots color="#fff" height={20} width={50} />
                            ) : (
                              <>Update</>
                            )}{" "}
                          </PrimaryButton>

                          {/* </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditPermissions;
