//Watch History
export const GET_WATCH_HISTORY_LIST_REQUEST = "GET_WATCH_HISTORY_LIST_REQUEST";
export const GET_WATCH_HISTORY_LIST_SUCCESS = "GET_WATCH_HISTORY_LIST_SUCCESS";
export const GET_WATCH_HISTORY_LIST_FAIL = "GET_WATCH_HISTORY_LIST_FAIL";

//Watch History
export const GET_MOVIES_WATCH_HISTORY_LIST_REQUEST =
  "GET_MOVIES_WATCH_HISTORY_LIST_REQUEST";
export const GET_MOVIES_WATCH_HISTORY_LIST_SUCCESS =
  "GET_MOVIES_WATCH_HISTORY_LIST_SUCCESS";
export const GET_MOVIES_WATCH_HISTORY_LIST_FAIL =
  "GET_MOVIES_WATCH_HISTORY_LIST_FAIL";

export const POST_MOVIE_WATCH_HISTORY_REQUEST =
  "POST_MOVIE_WATCH_HISTORY_REQUEST";
export const POST_MOVIE_WATCH_HISTORY_SUCCESS =
  "POST_MOVIE_WATCH_HISTORY_SUCCESS";
export const POST_MOVIE_WATCH_HISTORY_FAIL = "POST_MOVIE_WATCH_HISTORY_FAIL";

export const GET_MOVIE_WATCH_HISTORY_REQUEST =
  "GET_MOVIE_WATCH_HISTORY_REQUEST";
export const GET_MOVIE_WATCH_HISTORY_SUCCESS =
  "GET_MOVIE_WATCH_HISTORY_SUCCESS";
export const GET_MOVIE_WATCH_HISTORY_FAIL = "GET_MOVIE_WATCH_HISTORY_FAIL";

//Show Watch History
export const GET_SHOWS_WATCH_HISTORY_LIST_REQUEST =
  "GET_SHOWS_WATCH_HISTORY_LIST_REQUEST";
export const GET_SHOWS_WATCH_HISTORY_LIST_SUCCESS =
  "GET_SHOWS_WATCH_HISTORY_LIST_SUCCESS";
export const GET_SHOWS_WATCH_HISTORY_LIST_FAIL =
  "GET_SHOWS_WATCH_HISTORY_LIST_FAIL";

export const POST_SHOW_WATCH_HISTORY_REQUEST =
  "POST_SHOW_WATCH_HISTORY_REQUEST";
export const POST_SHOW_WATCH_HISTORY_SUCCESS =
  "POST_SHOW_WATCH_HISTORY_SUCCESS";
export const POST_SHOW_WATCH_HISTORY_FAIL = "POST_SHOW_WATCH_HISTORY_FAIL";

export const GET_SHOW_WATCH_HISTORY_REQUEST = "GET_SHOW_WATCH_HISTORY_REQUEST";
export const GET_SHOW_WATCH_HISTORY_SUCCESS = "GET_SHOW_WATCH_HISTORY_SUCCESS";
export const GET_SHOW_WATCH_HISTORY_FAIL = "GET_SHOW_WATCH_HISTORY_FAIL";
