import axiosPrivate, { axiosPublic } from "../services/api";

import {
  CHANNEL_DETAILS_FAIL,
  CHANNEL_DETAILS_REQUEST,
  CHANNEL_DETAILS_SUCCESS,
  DELETE_EPISODE_FAIL,
  DELETE_EPISODE_REQUEST,
  DELETE_EPISODE_SUCCESS,
  DELETE_MOVIE_FAIL,
  DELETE_MOVIE_REQUEST,
  DELETE_MOVIE_SUCCESS,
  DELETE_SHOW_FAIL,
  DELETE_SHOW_REQUEST,
  DELETE_SHOW_SUCCESS,
  EPISODE_DETAILS_FAIL,
  EPISODE_DETAILS_REQUEST,
  EPISODE_DETAILS_SUCCESS,
  GET_CHANNELS_FAIL,
  GET_CHANNELS_REQUEST,
  GET_CHANNELS_SUCCESS,
  GET_MOVIES_FAIL,
  GET_MOVIES_REQUEST,
  GET_MOVIES_SUCCESS,
  GET_SHOWS_FAIL,
  GET_SHOWS_REQUEST,
  GET_SHOWS_SUCCESS,
  GET_TRENDING_MOVIES_FAIL,
  GET_TRENDING_MOVIES_REQUEST,
  GET_TRENDING_MOVIES_SUCCESS,
  SHOW_EPISODES_LIST_FAIL,
  SHOW_EPISODES_LIST_REQUEST,
  SHOW_EPISODES_LIST_SUCCESS,
  UPDATE_MOVIE_FAIL,
  UPDATE_MOVIE_REQUEST,
  UPDATE_MOVIE_SUCCESS,
  UPDATE_SHOW_FAIL,
  UPDATE_SHOW_REQUEST,
  UPDATE_SHOW_SUCCESS,
  VIDEO_DETAILS_FAIL,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
} from "../constants/videoConstants";
import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  GET_MOVIES_WATCH_HISTORY_LIST_FAIL,
  GET_MOVIES_WATCH_HISTORY_LIST_REQUEST,
  GET_MOVIE_WATCH_HISTORY_FAIL,
  GET_MOVIE_WATCH_HISTORY_REQUEST,
  GET_MOVIE_WATCH_HISTORY_SUCCESS,
  GET_SHOW_WATCH_HISTORY_FAIL,
  GET_SHOW_WATCH_HISTORY_REQUEST,
  GET_SHOW_WATCH_HISTORY_SUCCESS,
  GET_WATCH_HISTORY_LIST_FAIL,
  GET_WATCH_HISTORY_LIST_REQUEST,
  GET_WATCH_HISTORY_LIST_SUCCESS,
} from "../constants/watchHistoryConstants";
import { GET_MOVIES_WATCH_HISTORY_LIST_SUCCESS } from "../constants/watchHistoryConstants";

//GET Watch History List
export const getWatchHistoryListAction = () => async (dispatch) => {
  dispatch({
    type: GET_WATCH_HISTORY_LIST_REQUEST,
  });
  try {
    const { data } = await axiosPublic.get(`/watchHistory`);
    dispatch({
      type: GET_WATCH_HISTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_WATCH_HISTORY_LIST_FAIL,
      payload: err.response.data.message,
    });
  }
};

//GET Watch History List
export const getMoviesWatchHistoryListAction = (id) => async (dispatch) => {
  dispatch({
    type: GET_MOVIES_WATCH_HISTORY_LIST_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/watchHistories/movies/${id}`);
    dispatch({
      type: GET_MOVIES_WATCH_HISTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_MOVIES_WATCH_HISTORY_LIST_FAIL,
      payload: err.response.data.message,
    });
  }
};

//GET Movie Watch History
export const getMovieWatchHistoryByProfileAndVideoId =
  (obj) => async (dispatch, getState) => {
    dispatch({
      type: GET_MOVIE_WATCH_HISTORY_REQUEST,
    });
    const watchHistoryList = getState().movies.watchHistory;
    const watchHistoryFound = watchHistoryList?.find(
      (x) => x.video.id == obj.video
    );

    if (watchHistoryFound) {
      dispatch({
        type: GET_MOVIE_WATCH_HISTORY_SUCCESS,
        payload: watchHistoryFound,
      });
    } else {
      try {
        const { data } = await axiosPrivate.post(
          `/watchHistories/getByProfileAndVideoId`,
          obj
        );
        dispatch({
          type: GET_MOVIE_WATCH_HISTORY_SUCCESS,
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: GET_MOVIE_WATCH_HISTORY_FAIL,
          payload: err.response.data.message,
        });
      }
    }
  };

//GET Show Watch History
export const getShowWatchHistoryByProfileAndVideoId =
  (obj) => async (dispatch) => {
    dispatch({
      type: GET_SHOW_WATCH_HISTORY_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(
        `/watchHistories/getByProfileAndVideoId`,
        obj
      );
      dispatch({
        type: GET_SHOW_WATCH_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_SHOW_WATCH_HISTORY_FAIL,
        payload: err.response.data.message,
      });
    }
  };

//GET Trending Movies
export const getTrendingMoviesAction = (query) => async (dispatch) => {
  dispatch({
    type: GET_TRENDING_MOVIES_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/movies/trending`);
    dispatch({
      type: GET_TRENDING_MOVIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_TRENDING_MOVIES_FAIL,
      payload: err.response.data.message,
    });
  }
};

//Update Movie
export const updateMovieAction =
  ({ movieData, movieId }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_MOVIE_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(
        `/movies/${movieId}`,
        movieData
      );

      dispatch({
        type: UPDATE_MOVIE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_MOVIE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// Delete Movie
export const deleteMovieAction =
  ({ movieId }) =>
  async (dispatch) => {
    dispatch({
      type: DELETE_MOVIE_REQUEST,
    });
    try {
      await axiosPrivate.delete(`/movies/${movieId}`);
      notifySuccess("Movie Deleted Successfully !");

      dispatch({
        type: DELETE_MOVIE_SUCCESS,
        payload: movieId,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: DELETE_MOVIE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// Movie By Id
export const getMovieByIdAction = (movieId) => async (dispatch) => {
  dispatch({
    type: VIDEO_DETAILS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/movies/${movieId}`);
    dispatch({
      type: VIDEO_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VIDEO_DETAILS_FAIL,
      payload: err.response.data.message,
    });
  }
};

// Update Views
export const updateVideoViewsAction = (obj) => async (dispatch) => {
  // dispatch({
  //   type: VIDEO_DETAILS_REQUEST,
  // });
  try {
    const { data } = await axiosPrivate.patch(`/views/video/${obj.video}`, obj);
    // dispatch({
    //   type: VIDEO_DETAILS_SUCCESS,
    //   payload: data,
    // });
  } catch (err) {
    // dispatch({
    //   type: VIDEO_DETAILS_FAIL,
    //   payload: err.response.data.message,
    // });
  }
};

//GET Shows
export const getShowsAction = (query) => async (dispatch) => {
  dispatch({
    type: GET_SHOWS_REQUEST,
  });
  try {
    const { data } = await axiosPublic.get(
      `/shows${query ? `?type=${query}` : ""}`
    );
    dispatch({
      type: GET_SHOWS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SHOWS_FAIL,
      payload: err.response.data.message,
    });
  }
};

//Update Show
export const updateShowAction =
  ({ showData, showId }) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_SHOW_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.patch(`/shows/${showId}`, showData);

      dispatch({
        type: UPDATE_SHOW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_SHOW_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// Delete Show
export const deleteShowAction =
  ({ showId }) =>
  async (dispatch) => {
    dispatch({
      type: DELETE_SHOW_REQUEST,
    });
    try {
      await axiosPrivate.delete(`/shows/${showId}`);
      notifySuccess("Show Deleted Successfully !");

      dispatch({
        type: DELETE_SHOW_SUCCESS,
        payload: showId,
      });
      // notifySuccess
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: DELETE_SHOW_FAIL,
        payload: err.response.data.message,
      });
      notifyFailure(err.response.data.message);
    }
  };

// Show By Id
export const getShowByIdAction = (showId) => async (dispatch) => {
  dispatch({
    type: VIDEO_DETAILS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/shows/${showId}`);
    dispatch({
      type: VIDEO_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VIDEO_DETAILS_FAIL,
      payload: err.response.data.message,
    });
  }
};

// Episodes By Show Id
export const getEpisodesByShowIdAction = (showId) => async (dispatch) => {
  dispatch({
    type: SHOW_EPISODES_LIST_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/episodes/show/${showId}`);
    dispatch({
      type: SHOW_EPISODES_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SHOW_EPISODES_LIST_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const deleteEpisodeByIdAction =
  ({ episodeId }) =>
  async (dispatch) => {
    dispatch({
      type: DELETE_EPISODE_REQUEST,
    });
    try {
      await axiosPrivate.delete(`/episodes/${episodeId}`);
      notifySuccess("Episode Deleted Successfully !");

      dispatch({
        type: DELETE_EPISODE_SUCCESS,
        payload: episodeId,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: DELETE_EPISODE_FAIL,
        payload: err.response.data.message,
      });
    }
  };

// Episode By Id
export const getEpisodeByIdAction = (episodeId) => async (dispatch) => {
  dispatch({
    type: EPISODE_DETAILS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/episodes/${episodeId}`);
    dispatch({
      type: EPISODE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: EPISODE_DETAILS_FAIL,
      payload: err.response.data.message,
    });
  }
};

//GET TV Channels
export const getChannelsAction = (query) => async (dispatch) => {
  dispatch({
    type: GET_CHANNELS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/channels`);
    dispatch({
      type: GET_CHANNELS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_CHANNELS_FAIL,
      payload: err.response.data.message,
    });
  }
};

// Channel By Id
export const getChannelByIdAction = (channelId) => async (dispatch) => {
  dispatch({
    type: CHANNEL_DETAILS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/channels/${channelId}`);
    dispatch({
      type: CHANNEL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CHANNEL_DETAILS_FAIL,
      payload: err.response.data.message,
    });
  }
};
