import {
  GET_HOME_PAGE_FAIL,
  GET_HOME_PAGE_REQUEST,
  GET_HOME_PAGE_SUCCESS,
  GET_MOVIES_PAGE_FAIL,
  GET_MOVIES_PAGE_REQUEST,
  GET_MOVIES_PAGE_SUCCESS,
  GET_SHOWS_PAGE_FAIL,
  GET_SHOWS_PAGE_REQUEST,
  GET_SHOWS_PAGE_SUCCESS,
} from "../constants/pageConstants";

import axiosPrivate from "../services/api";

export const getHomePageDataAction = () => async (dispatch, getState) => {
  if (!getState().homePage.data) {
    dispatch({
      type: GET_HOME_PAGE_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.get(
        `/pages/home/v2/${getState().auth.profile.id}`
      );

      dispatch({
        type: GET_HOME_PAGE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_HOME_PAGE_FAIL,
        payload: err.response.data.message,
      });
    }
  }
};

export const getMoviesPageDataAction = () => async (dispatch, getState) => {
  if (!getState().moviesPage.data) {
    dispatch({
      type: GET_MOVIES_PAGE_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.get(
        `/pages/movies/${getState().auth.profile.id}`
      );

      dispatch({
        type: GET_MOVIES_PAGE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      console.log("err==>", err);
      dispatch({
        type: GET_MOVIES_PAGE_FAIL,
        payload: err.response.data.message,
      });
    }
  }
};

export const getShowsPageDataAction = () => async (dispatch, getState) => {
  if (!getState().showsPage.data) {
    dispatch({
      type: GET_SHOWS_PAGE_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.get(
        `/pages/shows/${getState().auth.profile.id}`
      );

      dispatch({
        type: GET_SHOWS_PAGE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_SHOWS_PAGE_FAIL,
        payload: err.response.data.message,
      });
    }
  }
};
