export const GET_HISTORY_REQUEST = "GET_HISTORY_REQUEST";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_FAIL = "GET_HISTORY_FAIL";

export const HISTORY_ADD_REQUEST = "HISTORY_ADD_REQUEST";
export const HISTORY_ADD_SUCCESS = "HISTORY_ADD_SUCCESS";
export const HISTORY_ADD_FAIL = "HISTORY_ADD_FAIL";

export const REMOVE_HISTORY_REQUEST = "REMOVE_HISTORY_REQUEST";
export const REMOVE_HISTORY_SUCCESS = "REMOVE_HISTORY_SUCCESS";
export const REMOVE_HISTORY_FAIL = "REMOVE_HISTORY_FAIL";

export const REMOVE_ALL_HISTORIES_REQUEST = "REMOVE_ALL_HISTORIES_REQUEST";
export const REMOVE_ALL_HISTORIES_SUCCESS = "REMOVE_ALL_HISTORIES_SUCCESS";
export const REMOVE_ALL_HISTORIES_FAIL = "REMOVE_ALL_HISTORIES_FAIL";
