import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCategoriesAction } from "../../../actions/categoryActions";
import {
  // getMoviesAction,
  getShowsAction,
  getTrendingMoviesAction,
} from "../../../actions/videoActions";
import HeroSlider from "../../../components/sliders/heroSlider";
import VideoCardV2 from "../../../components/cards/videoCardV2";
import VideoSlider from "../../../components/sliders/videoSlider";

// import GenreSliderV2 from "../../../components/sliders/genreSliderV2";
import { getFavouritesByProfileIdAction } from "../../../actions/favouriteActions";
import { cloudfrontUrl } from "../../../constants";


function ShowsV2() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = location.search ? location.search.split("=")[1] : null;

  const state = useSelector((state) => state);
  const { shows,
    //  trendingShows, categories,
     auth } = state;
  const { 
    // userInfo,
     profile } = auth;
  const { list } = shows;

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getShowsAction(query));
    dispatch(getTrendingMoviesAction());
  }, [dispatch,query]);

  useEffect(() => {
    if (profile) dispatch(getFavouritesByProfileIdAction(profile.id));
  }, [dispatch,profile]);

  return (
    <div>
      {!query ? (
        <>
          <HeroSlider
            // data={list.slice(list.length - 7, list.length - 2).reverse()}
            data={list.slice(0, 3).reverse()}
            noDesc
            videoDetail
            videoType="Show"
            hidePlayButton
          />
          <VideoSlider
            title="Just Added"
            list={list.slice().reverse()}
            delay={4000}
            videoType="Show"
          />

          {/* {categories.list.map((category, index) => (
            <VideoSlider
              title={category.name + " Movies"}
              list={list.filter((x) => x.category === category.name)}
              delay={6000}
              key={category.id + index}
              videoType="Show"
            />
          ))} */}
          <VideoSlider
            title="Only On Infisom"
            list={list}
            delay={3000}
            videoType="Show"
          />
        </>
      ) : (
        <>
          <div className="row my-5 pt-5 mt-5 mx-1 mx-md-3">
            <div className="col-lg-12">
              <div className="series-list section-ptb-50">
                <div className="section-title-4 st-border-bottom">
                  <h2>
                    {query.slice(0, 1).toUpperCase()}
                    {query.slice(1, query.length)} Shows
                  </h2>
                </div>
                <div className="row">
                  {list.length === 0 ? (
                    <div className="alert alert-info mt-4" role="alert">
                      {query.slice(0, 1).toUpperCase()}
                      {query.slice(1, query.length)} Shows not found !
                    </div>
                  ) : (
                    <>
                      {list.map((show, index) => (
                        <div
                          key={show.id + index}
                          className="col-lg-3 col-md-4 col-sm-6 col-12 p-2"
                        >
                          <VideoCardV2
                            key={show.id}
                            data={show}
                            video={show}
                            videoType="Movie"
                            cloudBaseUrl={cloudfrontUrl}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ShowsV2;
