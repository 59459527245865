import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AreaChart from "../../../components/areaChart";
import { axiosPublic } from "../../../services/api";

function ChartSection() {
  const [viewsData, setViewsData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axiosPublic.get(`stats/views`);
        setViewsData(data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-xl-8">
        <AreaChart />
      </div>

      <div className="col-xl-4">
        <div className="card">
          <div className="card-body">
            {/* <div className="dropdown float-end">
              <Link
                to=""
                className="dropdown-toggle arrow-none card-drop"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi mdi-dots-vertical"></i>
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="" className="dropdown-item">
                  Sales Report
                </Link>

                <Link to="" className="dropdown-item">
                  Export Report
                </Link>

                <Link to="" className="dropdown-item">
                  Profit
                </Link>

                <Link to="" className="dropdown-item">
                  Action
                </Link>
              </div>
            </div> */}

            <h4 className="header-title mb-0">Total Views</h4>

            <div className="widget-chart text-center" dir="ltr">
              {/* <div
                id="world-map-markers"
                style={{ height: "280px" }}
                className="mt-4"
              ></div> */}

              <h1 className="mt-5">{viewsData?.visitorsToday}</h1>
              <h5 className="text-muted mt-4">Viewers today</h5>

              <p className="text-muted w-75 mx-auto sp-line-2 mt-5">
                Total viewer from
                <br /> around the world
              </p>

              <div className="row mt-4">
                <div className="col-6">
                  <p className="text-muted font-15 mb-1 text-truncate">
                    This week
                  </p>
                  <h4>
                    {viewsData && (
                      <i
                        className={`${
                          viewsData.thisWeek > viewsData.lastWeek
                            ? "fe-arrow-up text-success"
                            : "fe-arrow-down text-danger"
                        }  me-1`}
                      ></i>
                    )}
                    {viewsData?.thisWeek}
                  </h4>
                </div>
                <div className="col-6">
                  <p className="text-muted font-15 mb-1 text-truncate">
                    Last week
                  </p>
                  <h4>
                    {viewsData && (
                      <i
                        className={`${
                          viewsData.thisWeek < viewsData.lastWeek
                            ? "fe-arrow-up text-success"
                            : "fe-arrow-down text-danger"
                        } me-1`}
                      ></i>
                    )}
                    {viewsData?.lastWeek}
                  </h4>
                </div>
                <div className="col-6 mt-3">
                  <p className="text-muted font-15 mb-1 text-truncate">
                    This Month
                  </p>
                  <h4>
                    {viewsData && (
                      <i
                        className={`${
                          viewsData.thisMonth > viewsData.lastMonth
                            ? "fe-arrow-up text-success"
                            : "fe-arrow-down text-danger"
                        } me-1`}
                      ></i>
                    )}{" "}
                    {viewsData?.thisMonth}
                  </h4>
                </div>
                <div className="col-6 mt-3">
                  <p className="text-muted font-15 mb-1 text-truncate">
                    Last Month
                  </p>
                  <h4>
                    {viewsData && (
                      <i
                        className={`${
                          viewsData.thisMonth < viewsData.lastMonth
                            ? "fe-arrow-up text-success"
                            : "fe-arrow-down text-danger"
                        } me-1`}
                      ></i>
                    )}{" "}
                    {viewsData?.lastMonth}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartSection;
