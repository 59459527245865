import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesAction } from "../../../actions/packageActions";
import PrimaryButton from "../../../components/primaryButton";
import EditPricingModal from "./editPricingModal";

function PackagePricing() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { packages, currRolePermissions } = state;
  const { list, loading, error } = packages;
  const { permission } = currRolePermissions;

  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    dispatch(getPackagesAction());
  }, [dispatch]);

  const editHandler = (id) => {
    setSelectedPackage(list.find((x) => x.id === id));
  };

  return (
    <>
      {permission && (
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item active">Pricing</li>
                    </ol>
                  </div>
                  <h4 className="page-title">Pricing</h4>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="text-center">
                  <h3 className="mb-2">
                    Our <b>Plans</b>
                  </h3>
                </div>
                {!list && loading ? (
                  <div className="loader">
                    <TailSpin color="#000" height={50} width={50} />
                  </div>
                ) : error ? (
                  <div className="loader">
                    <div className="alert alert-danger mt-4" role="alert">
                      {error}
                    </div>
                  </div>
                ) : (
                  <div className="row my-3">
                    {list &&
                      list.map((x, index) => (
                        <div key={x.id + index} className="col-md-4">
                          <div className="card card-pricing">
                            <div className="card-body pricing-card text-center">
                              <p className="card-pricing-plan-name fw-bold text-uppercase">
                                {x.type}
                              </p>
                              <span className="card-pricing-icon text-dark">
                                <i className="fe-users"></i>
                              </span>
                              <h2 className="card-pricing-price">
                                ${x.subscriptionFee}{" "}
                                <span>
                                  / {x.subscriptionType} <br />
                                  for {x.numberOfScreens} users
                                </span>
                              </h2>
                              <ul className="card-pricing-features">
                                {x.description.map((y, index) => (
                                  <li key={y + index}>{y} </li>
                                ))}
                                <li>
                                  <strong>{x.slogan}</strong>
                                </li>
                              </ul>
                              {permission.permissions.find(
                                (x) => x.type == "Pricing"
                              )?.modules.update && (
                                <PrimaryButton
                                  data-bs-toggle="modal"
                                  data-bs-target="#centermodal"
                                  onClick={() => editHandler(x.id)}
                                >
                                  Edit
                                </PrimaryButton>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}

                    <div className="col-md-4">
                      <div className="card card-pricing">
                        <div className="card-body pricing-card text-center">
                          <p className="card-pricing-plan-name fw-bold text-uppercase">
                            Premium
                          </p>
                          <span className="card-pricing-icon text-dark">
                            <i className="fe-aperture"></i>
                          </span>
                          <h2 className="card-pricing-price">
                            <span>
                              For Larger Businesses
                              <br />
                              &nbsp;
                            </span>
                          </h2>
                          <ul className="card-pricing-features">
                            <li>&nbsp;</li>

                            <li>Call us</li>
                            <li>&nbsp;</li>
                            <li>&nbsp;</li>

                            <li>
                              <strong>
                                Custom pricing For premium clients
                              </strong>
                            </li>
                          </ul>
                          {permission.permissions.find(
                            (x) => x.type == "Pricing"
                          )?.modules.update && (
                            <PrimaryButton
                              data-bs-toggle="modal"
                              data-bs-target="#centermodal"
                              disabled={true}
                            >
                              Edit
                            </PrimaryButton>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* {selectedPackage && */}
      <EditPricingModal
        selectedPackage={selectedPackage}
        setSelectedPackage={setSelectedPackage}
      />
      {/* } */}
    </>
  );
}

export default PackagePricing;
