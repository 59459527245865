import React from "react";
import { Link } from "react-router-dom";
import FormSection from "./formSection";
import MapSection from "./mapSection";

function Contact() {
  return (
    <>
      <div className="breadcrumb-area breadcrumb-modify-padding bg-black-3">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-style-2 center">
                  <h2>Contact Us</h2>
                  <ul className="breadcrumb-list-2 black">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-area bg-black section-pt-90">
        <FormSection />
        <MapSection />
      </div>
    </>
  );
}

export default Contact;
