import React, { useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { getPermissionsAction } from "../../../actions/permissionActions";

import PermissionCard from "./permissionCard";

function Permissions() {
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.permissions);
  const { list, usersByRoles, loading, error } = permissions;

  useEffect(() => {
    dispatch(getPermissionsAction());
  }, [dispatch]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <ol className="breadcrumb m-0"></ol>
              </div>
              <h4 className="page-title">Permissions</h4>
            </div>
          </div>
        </div>
        {/* {list && <> */}
        {!list && loading ? (
          <div className="loader">
            <TailSpin color="#000" height={50} width={50} />
          </div>
        ) : error ? (
          <div className="loader">
            <div className="alert alert-danger mt-4" role="alert">
              {error}
            </div>
          </div>
        ) : (
          <div className="row">
            {list &&
              list.map((x, index) => (
                <div key={x.id + index} className="col-12 col-md-6 my-2">
                  <PermissionCard data={x} usersWithRoles={usersByRoles} />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Permissions;
