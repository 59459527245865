import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { axiosPublic } from "../../services/api";
import Popup from "../../components/modal";
import PopupContent from "./popupContent";

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("Your verification link is expired");

  useEffect(() => {
    if (location.search === "") {
      navigate("/404");
    }
  }, [location.search, navigate]);

  const changeHandler = (e) => {
    setNewPassword(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setStatus("loading");
    try {
      await axiosPublic.post(`/auth/reset-password${location.search}`, {
        password: newPassword,
      });
      setStatus("success");
      setShowModal(true);

    } catch (err) {
      setStatus("error");
      setMessage(err.response.data.message);
    }
  };

  return (
    <>
      <div className="breadcrumb-area breadcrumb-modify-padding bg-black-3">
        <div className="container">
          <div className="in-breadcrumb">
            <div className="row">
              <div className="col">
                <div className="breadcrumb-style-2 center">
                  <h2>Reset Password</h2>
                  <ul className="breadcrumb-list-2 black">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Reset Password</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="register-page bg-black section-pt-90 section-pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-9 m-auto">
              <div className="login-register-wrapper">
                <div className="login-form-container border-black">
                  <div className="login-register-form black-style">
                    <form onSubmit={submitHandler}>
                      <div className="login-input-box">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label htmlFor="">
                              New Password <sup className="text-danger">*</sup>
                            </label>
                            <input
                              type="password"
                              name="password"
                              value={newPassword}
                              onChange={changeHandler}
                              className="form-control form-control-solid"
                              placeholder="Enter new password"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {status === "error" && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {message}
                          </div>
                        )}
                        <div className="button-box">
                          <button className="register-btn btn" type="submit">
                            {status === "loading" ? (
                              <ThreeDots
                                color="#000000"
                                height={20}
                                width={50}
                              />
                            ) : (
                              <span>Update</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup showModal={showModal} setShowModal={setShowModal}>
        <PopupContent />
      </Popup>
    </>
  );
}

export default ResetPassword;
