import React from "react";
import { useNavigate } from "react-router-dom";

function ActionSection() {
  const navigate = useNavigate();

  return (
    <div className="ifi-section mt-5">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="ifi-img">
              <img src="images/if1.png" alt="" id="if-img" />
              <img src="images/if2.png" alt="" id="if-img" />
              <img src="images/if3.png" alt="" id="if-img" />
              <img src="images/if4.png" alt="" id="if-img" />
            </div>
          </div>
          <div className="col-md-9 ">
            <div className="ifi-text">
              <h2>INFINITE MOVIES</h2>
              <p>
                From shows and movies you can only catch here, to the moments
                the world can't stop watching, stream it all on Infisom
              </p>
              <button className="btn-watch" onClick={() => navigate("/pricing")}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionSection;
