import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMovieByIdAction,
  getMoviesAction,
  updateVideoViewsAction,
} from "../../../actions/videoActions";
import { useLocation, useNavigate } from "react-router-dom";
import { cloudfrontUrl } from "../../../constants";

import { IoMdClose } from "react-icons/io";
import { SOCKET_BASE_URL } from "../../../services/api";
import io from "socket.io-client";
import { getMovieWatchHistoryByProfileAndVideoId } from "../../../actions/watchHistoryActions";
import VideoSlider from "../../../components/sliders/videoSlider";
import CustomPlayer from "../../../components/customPlayer";
import { TailSpin } from "react-loader-spinner";

const socket = io(SOCKET_BASE_URL);
function PlayMovie() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = useSelector((state) => state);
  const { auth, selectedVideo, movies } = state;

  const { tokens, userInfo, profile } = auth;
  const { data, watchHistory } = selectedVideo;
  const { list } = movies;

  const id = location.pathname.split("/")[4];

  const [suggestedVideo, setSuggestedVideo] = useState({ data: null });
  const [showNextVideo, setShowNextVideo] = useState(false);
  const [loadVideo, setLoadVideo] = useState(false);
  const [nextVideoFlag, setNextVideoFlag] = useState(false);

  const [showSuggestion, setShowSuggestion] = useState(false);
  const [blobUrl, setBlobUrl] = useState("");

  const [isReady, setIsReady] = useState(false);
  const [backIsReady, setBackIsReady] = useState(false);

  const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(true);

  const onReady = useCallback(() => {
    if (!isReady) {
      const timeToStart = watchHistory
        ? watchHistory.completed
          ? 0
          : watchHistory.watchedTime
        : 0;

      playerRef.current.currentTime = timeToStart;
      playerRef.current.play();
      setIsReady(true);
      // playerRef.current.play();
      // setIsPlaying(true);
    }
  }, [isReady, watchHistory]);

  useEffect(() => {
    // const socketInterval = setInterval(() => {
    //   if (isReady) {
    //     socket.emit("movie_play", {
    //       userId: userInfo.id,
    //       movieId: id,
    //       time: playerRef.current.getCurrentTime(),
    //       totalDuration: playerRef.current.getDuration(),
    //     });
    //   }
    // }, 5000);

    if (isReady && userInfo) {
      socket.emit("start_movie", {
        profileId: profile.id,
        movieId: id,
        time: playerRef.current.currentTime,
        totalDuration: playerRef.current.duration,
      });

      //     const timeInterval = setInterval(() => {

      //     }, 1000);
    }
    // return () => {
    //   clearInterval(socketInterval);
    //   socket.disconnect();
    //   // socket.off("movie_play");
    //   // socket.off("start_movie");
    // };
  }, [isReady, userInfo, socket]);

  useEffect(() => {
    if (!tokens) {
      navigate(`/signin?redirect=/watch/movies/${id}`);
    } else {
      if (id) {
        setShowNextVideo(false);
        setNextVideoFlag(false);
        setIsReady(false);
        // dispatch(getMoviesAction())
        if (!data || data?.id !== id) {
          setLoadVideo(false);
          dispatch(getMovieByIdAction(id));
        }
        dispatch(
          getMovieWatchHistoryByProfileAndVideoId({
            profile: profile.id,
            video: id,
          })
        );

        dispatch(
          updateVideoViewsAction({
            video: id,
            modelNameOfTheVideoType: "Movie",
            count: 1,
          })
        );

        // if (list.length == 0) dispatch(getMoviesAction());
      } else {
        navigate("/watch/movies");
      }
    }
  }, [id]);

  useEffect(() => {
    socket.on("start_watching_movie", async (data) => {
      setBackIsReady(true);
    });
  }, [socket]);

  useEffect(() => {
    const interVal = setInterval(() => {
      if (isPlaying && backIsReady) {
        socket.emit("movie_play", {
          profileId: profile?.id,
          movieId: id,
          time: playerRef?.current?.currentTime,
          totalDuration: playerRef?.current?.duration,
        });

        if (
          !nextVideoFlag &&
          playerRef?.current?.duration - playerRef?.current?.currentTime < 180
        ) {
          setNextVideoFlag(true);
        }
      }
    }, 5000);

    return () => {
      clearInterval(interVal);
    };
  }, [backIsReady]);

  useEffect(() => {
    if (nextVideoFlag) {
      setShowNextVideo(true);
    }
  }, [nextVideoFlag]);

  useEffect(() => {
    const videoEndHandler = () => {
      navigate(`/watch/movies/${id}`, {
        state: {
          loadData: false,
        },
      });
    };

    playerRef?.current?.addEventListener("ended", videoEndHandler);
    // return{

    // }
  }, [playerRef]);

  // useEffect(() => {
  //   if (userInfo) {
  //     dispatch(getMoviesAction());

  //     dispatch(getFavouritesByProfileIdAction(userInfo.id));
  //     // socket.emit("login", { userId: userInfo.id, videoId: id, type: "Movie" });
  //     // socket.emit("login", { userId: "userInfo.id, videoId: id, type: " });
  //   }
  //   // return () => {
  //   //   socket.disconnect();
  //   //   socket.off("login");
  //   // };
  // }, [userInfo]);

  // useEffect(() => {
  //   const interVal = setInterval(() => {
  //     // if (userInfo && isReady) {
  //     socket.emit("movie_play", {
  //       userId: userInfo?.id,
  //       movieId: id,
  //       time: playerRef?.current?.getCurrentTime(),
  //       totalDuration: playerRef?.current?.getDuration(),
  //     });
  //     // }
  //   }, 10000);

  //   //   // if (userInfo)
  //   //   //   socket.emit("start_movie", {
  //   //   //     userId: userInfo.id,
  //   //   //     movieId: "64346989217a5d4dd9ef921a",
  //   //   //     time: playerRef.current.getCurrentTime(),
  //   //   //     totalDuration: playerRef.current.getDuration(),
  //   //   //   });

  //   return () => {
  //     socket.disconnect();
  //     socket.off("login");
  //     clearInterval(interVal);
  //   };
  // }, [userInfo]);

  const [closeTimeOut, setCloseTimeOut] = useState(true);

  const handleMouseMove = (event) => {
    if (closeTimeOut) {
      // setMousePos({ x: event.clientX, y: event.clientY });
      setShowSuggestion(true);
      setCloseTimeOut(false);
    }
  };

  useEffect(() => {
    if (!showSuggestion) {
      setTimeout(() => {
        setCloseTimeOut(true);
      }, 5000);
    }
  }, [showSuggestion]);

  useEffect(() => {
    if (data) {
      setSuggestedVideo({
        data: list
          .filter((x) => x.id !== id && x.category == data.category)
          .slice()
          .sort(() => Math.random() - 0.5)[0],
        directPlay: true,
      });
    }
  }, [list, id, data]);

  useEffect(() => {
    if (data) {
      fetchVideo();
      setLoadVideo(true);
    }
  }, [data]);

  const fetchVideo = async () => {
    console.log("main chala");
    // let blob = await fetch(`${cloudfrontUrl}/${data.videoUrl}`).then(async(r) => {
    //   console.log("res=>", r);
    //   console.log("blob 1=>", await r.blob());
    // });

    // const myRequest = new Request(`${cloudfrontUrl}/${data.videoUrl}`);

    // fetch(myRequest)
    //   .then((response) => response.blob())
    //   .then((myBlob) => {
    //     console.log("main chahaha");

    //     const objectURL = URL.createObjectURL(myBlob);
    //     setBlobUrl(objectURL);
    //     console.log("object url=>", objectURL);
    //     // myImage.src = objectURL;
    //   })
    //   .catch((err) => {
    //     console.log("err=>", err);
    //   });

    // var videoUrl = window.URL.createObjectURL(blob);
    // console.log("blob=>", blob, videoUrl);
  };

  console.log("blob url =>", blobUrl);
  return (
    <div className="video-player-full" onMouseMove={handleMouseMove}>
      {/* <div className="close-div" onClick={() => navigate(-1)}>
          <IoMdClose color="white" size={24} />
        </div> */}
      {loadVideo && data ? (
        <>
          {/* <ReactPlayer
              ref={playerRef}
              onReady={onReady}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              playing={isPlaying}
              playsinline={true}
              url={`${cloudfrontUrl}/${data.videoUrl}`}
              controls // gives the front end video controls
              width="100%"
              className="react-player"
              height="100%"
              allow="false"
              allowFullScreen
              // muted={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
              onContextMenu={(e) => e.preventDefault()}
              onEnded={(e) => console.log(e)}
              onError={(e) => console.log(e)}
            /> */}

          <CustomPlayer
            videoRef={playerRef}
            videoType="Movie"
            videoId={data.id}
            src={`${cloudfrontUrl}/${data.videoUrl}`}
            onReady={onReady}
            data={data}
            title={`${data.title} ${data.quality}`}
            titleImg={`${cloudfrontUrl}/${data.titleImageUrl}`}
            showNextVideo={showNextVideo}
            setShowNextVideo={setShowNextVideo}
            nextVideoFlag={nextVideoFlag}
            setNextVideoFlag={setNextVideoFlag}
            suggestedVideo={suggestedVideo}
            setLoadVideo={setLoadVideo}
          />
        </>
      ) : (
        <div className="loader">
          <TailSpin color="#fff" height={50} width={50} />
        </div>
      )}
      {/* {data && <TitleArea {...{ showSuggestion, setShowSuggestion }} />}
        {data && <SuggestionsArea {...{ showSuggestion, setShowSuggestion }} />} */}
    </div>
  );
}

export default PlayMovie;
