import { axiosPublic } from "../services/api";
import {
  GET_PACKAGES_FAIL,
  GET_PACKAGES_REQUEST,
  GET_PACKAGES_SUCCESS,
  SAVE_PACKAGE_DETAILS,
} from "../constants";

//GET Packages
export const getPackagesAction = () => async (dispatch) => {
  dispatch({
    type: GET_PACKAGES_REQUEST,
  });
  try {
    const { data } = await axiosPublic.get(`/packages`);
    dispatch({
      type: GET_PACKAGES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_PACKAGES_FAIL,
      payload: err.response.data.message,
    });
  }
};

//Save Package Details
export const selectPackageAction = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_PACKAGE_DETAILS,
    payload: data,
  });
  localStorage.setItem("selectedPackage", JSON.stringify(data))
};
