import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import { ThreeDots } from "react-loader-spinner";
import axiosPrivate, { axiosPublic } from "../../../services/api";
import { USER_AUTH_SUCCESS, USER_REGISTER_SUCCESS } from "../../../constants";

function VerifyEmail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const state = useSelector((state) => state);
  const { auth, selectedPackage } = state;
  const { userInfo } = auth;

  const [showLoading, setShowLoading] = useState(false);

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("Your verification link is expired");

  const sendVerificationEmail = async () => {
    setShowLoading(true);
    try {
      await axiosPrivate.post(`/auth/send-verification-email`, {
        email: userInfo.email,
      });

      setShowLoading(false);
      setMessage("Verification Email has been Sent !");
    } catch (err) {
      setShowLoading(false);
    }
  };

  useEffect(() => {
    const verifyEmailHandler = async () => {
      setStatus("loading");
      try {
        const { data } = await axiosPublic.get(
          `/auth/verify-email${location.search}`
        );
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: { ...data, register: true },
        });
        localStorage.setItem("tokens", JSON.stringify(data.tokens));
        setStatus("success");
      } catch (err) {
        setStatus("error");
        setMessage("Your verification link is expired");
      }
    };

    verifyEmailHandler();
  }, [location.search, navigate]);

  return (
    <div className="w-fit mx-auto text-center">
      {status === "success" ? (
        <div>
          <p>
            <GoVerified color="green" size={28} className="mx-1" />
            Your Email has been verified !
          </p>
          <h3>
            <Link to={"/buy-now"}>Next</Link>
          </h3>
        </div>
      ) : status === "loading" ? (
        <div className="d-flex ">
          <ThreeDots color="#fff" height={20} width={50} />
          <p className="mx-2">Verifying Email</p>
        </div>
      ) : (
        status === "error" && (
          <div>
            <h4>{message}</h4>
            <div className="button-box">
              <button className="modal-btn btn" onClick={sendVerificationEmail}>
                {showLoading ? (
                  <ThreeDots color="#000000" height={20} width={50} />
                ) : (
                  <span>Get verification link</span>
                )}
              </button>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default VerifyEmail;
