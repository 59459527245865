import React from "react";
import PricingSection from "../../../components/pricingSection";
import ActionSection from "./actionSection";
import ComicSection from "./comicSection";
import FaqSection from "./faqSection";
import HeroSection from "./heroSection";
import PlanSection from "./planSection";
// import SportsSection from "./sportsSection";
import SportsSection from "./sportsSection";

function HomeV2() {
  return (
    <div>
      <HeroSection />
      <ActionSection />

      <SportsSection />

      <ComicSection />
      {/* <PricingSection /> */}
      <FaqSection />
    </div>
  );
}

export default HomeV2;
