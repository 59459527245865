import {
  GET_AGENT_TRANSACTIONS_FAIL,
  GET_AGENT_TRANSACTIONS_REQUEST,
  GET_AGENT_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_SUCCESS,
} from "../constants/transactionConstants";

import axiosPrivate from "../services/api";

//GET Users via Admin
export const getTransactionsAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_TRANSACTIONS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/transactions`);
    dispatch({
      type: GET_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_TRANSACTIONS_FAIL,
      payload: err.response.data.message,
    });
  }
};

//GET Users via Admin
export const getAgentTransactionsAction =
  (id) => async (dispatch, getState) => {
    dispatch({
      type: GET_TRANSACTIONS_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.get(`/transactions/payer/${id}`);
      dispatch({
        type: GET_TRANSACTIONS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: GET_TRANSACTIONS_FAIL,
        payload: err.response.data.message,
      });
    }
  };
