import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProfilesAction } from "../../../actions/profileActions";
import ProfileCard from "./profileCard";
import { userLogout } from "../../../actions/authActions";

import {
  ADD_PROFILE_HANDLER,
  CHOOSE_PROFILE_HANDLER,
} from "../../../constants/profileConstants";
import PrimaryButton from "../../../components/primaryButton";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Profiles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);
  const { auth, profiles } = state;
  const { userInfo, tokens } = auth;
  const { list } = profiles;

  useEffect(() => {
    if (userInfo) {
      dispatch(getProfilesAction(userInfo.id));
    }
  }, [dispatch, userInfo]);

  const logoutHandler = () => {
    dispatch(
      userLogout({
        refreshToken: tokens.refresh.token,
      })
    );
  };

  const myAccountHandler = () => {
    navigate("/my-account/details");
  };

  return (
    <div className="profile-page">
      <div className="profile-bg">
        <div className="title full">
          <h3 className="">Who is watching ?</h3>
          <div className="ml-auto">
            <PrimaryButton onClick={myAccountHandler}>
              Account Details
            </PrimaryButton>
            <PrimaryButton onClick={logoutHandler}>Logout</PrimaryButton>
          </div>
        </div>
        <Swiper
          className="profile-slider"
          // slidesPerView={6}
          spaceBetween={20}
          centeredSlides={true}
          grabCursor={true}
          pagination={true}
          navigation={true}
          modules={[Navigation]}
          breakpoints={{
            0: {
              width: 0,
              slidesPerView: 1,
              spaceBetween: 5,
            },
            // when window width is >= 320px
            320: {
              width: 320,
              slidesPerView: 1.5,
              spaceBetween: 5,
            },
            // when window width is >= 540px
            540: {
              width: 540,
              slidesPerView: 2.5,
              spaceBetween: 5,
            },
            // when window width is >= 640px
            640: {
              width: 640,
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 3,
            },
          }}
        >
          {list.map((profile, index) => (
            // <div >
            <SwiperSlide key={profile.id + index}>
              <ProfileCard
                data={profile}
                editBtn
                onClick={() => {
                  localStorage.setItem("profile", JSON.stringify(profile));
                  dispatch({
                    type: CHOOSE_PROFILE_HANDLER,
                    payload: { ...profile },
                  });
                  navigate("/watch/all");
                }}
              />
            </SwiperSlide>
            // </div>
          ))}
          <SwiperSlide>
            <ProfileCard
              data={null}
              onClick={() => {
                dispatch({
                  type: ADD_PROFILE_HANDLER,
                  payload: {
                    name: "",
                    mainImage: "",
                    textImage: "",
                    bgColor: "",
                  },
                });
                navigate("/watch/profiles/choose");
              }}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Profiles;
