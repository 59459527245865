import axios from "axios";
import tokenService from "./tokenService";
// export const BASE_URL = "";
// export const BASE_URL = "http://localhost:5000";

export const SOCKET_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://infisom-backend-20ce9f8e50d3.herokuapp.com";
// : "https://api.infisom.com";

// export const SOCKET_BASE_URL = "https://infisom.com/api";

export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api"
    : "https://infisom-backend-20ce9f8e50d3.herokuapp.com/api";
// : "https://api.infisom.com/api";

// export const BASE_URL = "https://infisom-bckend.herokuapp.com";
// export const BASE_URL = "https://infisom.com/api";

// Development
export const axiosPublic = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",
  },
});

const axiosPrivate = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",

    Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
  },
});

export const axiosNoAuth = axios.create({
  baseURL: `${BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    accepts: "application/json",

    Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
  },
});

//Production
// export const axiosPublic = axios.create({
//   baseURL: `/api`,
// });

// const axiosPrivate = axios.create({
//   baseURL: `/api`,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
//   },
// });

// export const axiosNoAuth = axios.create({
//   baseURL: `${BASE_URL}/api`,
//   headers: {
//     "Content-Type": "application/json",
//     accepts: "application/json",

//     Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
//   },
// });

// Request interceptor for API calls

axiosPrivate.interceptors.request.use((req) => {
  const bearerInReq = req.headers.Authorization;

  if (bearerInReq.includes("undefined")) {
    req.headers["Authorization"] =
      "Bearer " + tokenService.getLocalAccessToken();
  }

  // req.meta = req.meta || {};
  // req.meta.requestStartedAt = new Date().getTime();
  return req;
});

// Response interceptor for API calls
axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axiosPrivate.post(`/auth/refresh-tokens`, {
          refreshToken: tokenService.getLocalRefreshToken(),
        });

        // dispatch(refreshToken(accessToken));

        tokenService.updateLocalAccessToken(data);
        originalRequest.headers["Authorization"] = "Bearer " + data.token;

        return axiosPrivate(originalRequest);
        // return axiosApiInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

axiosNoAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const { data } = await axiosPrivate.post(`/auth/refresh-tokens`, {
          refreshToken: tokenService.getLocalRefreshToken(),
        });

        // dispatch(refreshToken(accessToken));

        originalRequest.headers["Authorization"] = "Bearer " + data.token;
        // tokenService.updateLocalAccessToken(data);
        localStorage.removeItem("tokens");

        return axiosPrivate(originalRequest);
        // return axiosApiInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosPrivate;
