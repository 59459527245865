import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getPackagesAction,
  selectPackageAction,
} from "../actions/packageActions";
import { OLD_REGISTRAION } from "../constants";

function PricingSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { auth, packages, selectedPackage } = state;
  const { userInfo } = auth;
  const { list } = packages;

  const selectPackageHandler = (packageItem) => {
    dispatch(selectPackageAction(packageItem));
    // navigate("/my-account/checkout");
    // if (auth.newRegisteration) {
    //   dispatch({ type: OLD_REGISTRAION });
    // }
  };

  useEffect(() => {
    dispatch(getPackagesAction());
  }, [dispatch]);

  return (
    <div className="movie-site-info-wrap bg-black section-pt-90 section-pb-60">
      <div className="container">
        <div className="section-title-5 text-center">
          <div className="pack-text text-center">
            <h1>Pick a Plan. Cancel Anytime.</h1>
          </div>
        </div>
        {list && list.length > 0 && (
          <div className="row row-cols-md-3 row-cols-1">
            {list.map((packageItem, index) => (
              <div key={packageItem.id + index} className="col mb-30">
                <div
                  className={`pricing-panel modify-pricing-panel text-center ${
                    packageItem.type === "Standard" ? "active" : ""
                  }`}
                >
                  <div className="pricing-heading">
                    <h3>{packageItem.type}</h3>
                    <h2>
                      ${packageItem.subscriptionFee} <span>/ Month</span>
                    </h2>
                    <h2 className="mt-n1">
                      <span>for {packageItem.numberOfScreens} users</span>
                    </h2>
                  </div>
                  <div className="pricing-body">
                    <ul>
                      {packageItem.description.map((details, i) => (
                        <li key={details.id + i}>{details}</li>
                      ))}
                      <li>
                        <strong className="text-white">
                          {packageItem.slogan}
                        </strong>
                      </li>
                    </ul>
                  </div>
                  <div className="pricing-footer">
                    {userInfo ? (
                      <Link
                        to={
                          "/my-account/checkout"
                          // selectedPackage && userInfo.subscriptionValid
                          //   ? "/my-account/checkout"
                          //   : "/my-account/buy-now"
                        }
                        onClick={() => selectPackageHandler(packageItem)}
                        className="plan-btn"
                      >
                        Buy Now
                        {/* Sign Up Now */}
                      </Link>
                    ) : (
                      <Link to="/register" className="plan-btn">
                        Sign Up Now
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
            <div className="col mb-30">
              <div className="pricing-panel modify-pricing-panel text-center">
                <div className="pricing-heading">
                  <h3>Premium</h3>
                  <h2>
                    <span>For Larger Businesses</span>
                  </h2>
                  <h2 className="mt-n1">
                    <span>&nbsp;</span>
                  </h2>
                </div>
                <div className="pricing-body">
                  <ul>
                    <li>&nbsp;</li>
                    <li>Call us</li>
                    <li>&nbsp;</li>
                    <li>(612) 4567891</li>
                    <li>&nbsp;</li>
                    <li>
                      <strong className="text-white">
                        Custom pricing For premium clients
                      </strong>
                    </li>
                  </ul>
                </div>
                <div className="pricing-footer">
                  <Link to="" className="plan-btn">
                    Call Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PricingSection;
