import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/primaryButton";
import { getAllMoviesAction } from "../../../actions/videoActions";
import MoviesTable from "../../../components/dataTable/moviesTable";
import { getAllSubscriptionsAction } from "../../../actions/subscriptionActions";
import SubscriptionsTable from "./subscriptionsTable";
import { TailSpin } from "react-loader-spinner";

const tableColumns = [
  // { title: "Movie", key: "title", className: "name" },
  { title: "User Name", key: "userName" },
  { title: "Account #", key: "account" },
  { title: "Package", key: "packages" },
  { title: "Amount", key: "amount" },
  { title: "Subscribed On", key: "subscriptionDate" },
  { title: "Expiry Date", key: "expiringOn" },
  { title: "Status", key: "status" },
];

function Subscriptions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const state = useSelector((state) => state);
  const { subscriptions, currRolePermissions } = state;
  const { loading, list, error } = subscriptions;
  const { permission } = currRolePermissions;

  const [search, setSearch] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    dispatch(getAllSubscriptionsAction());
  }, [dispatch]);

  useEffect(() => {
    if (list) {
      const tempData = list.filter(
        (x) =>
          x?.user?.accountNumber.toLowerCase().includes(search.toLowerCase()) ||
          x?.user?.firstName.toLowerCase().includes(search.toLowerCase()) ||
          x?.user?.lastName.toLowerCase().includes(search.toLowerCase()) ||
          x?.package?.type.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(tempData || []);
    }
  }, [search, list]);

  useEffect(() => {
    if (list) {
      setFilteredData(list);
    }
  }, [list]);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Subscriptions List</h4>
            </div>
          </div>
        </div>

        {list.length === 0 && loading ? (
          <div className="loader">
            <TailSpin color="#000" height={50} width={50} />
          </div>
        ) : error ? (
          <div className="loader">
            <div className="alert alert-danger mt-4" role="alert">
              {error}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-between mb-2">
                    <div className="col-auto">
                      <form className="search-bar position-relative mb-sm-0 mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <span className="mdi mdi-magnify"></span>
                      </form>
                    </div>
                    <div className="col-md-6">
                      <div className="text-md-end">
                        <PrimaryButton onClick={() => navigate("add")}>
                          <i className="mdi mdi-plus-circle-outline me-1"></i>{" "}
                          Add Subscription
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                  {permission && (
                    <SubscriptionsTable
                      modules={
                        permission.permissions.find(
                          (x) => x.type === "Subscriptions"
                        ).modules
                      }
                      tableColumns={tableColumns}
                      data={filteredData.slice().reverse()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Subscriptions;
