import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCategoriesAction } from "../../../actions/categoryActions";
import PrimaryButton from "../../../components/primaryButton";
import SecondaryButton from "../../../components/secondaryButton";
import VideoUpload from "../../../components/videoUpload";
import axiosPrivate from "../../../services/api";
import { Uploader } from "../../../services/fileUploader";
import moment from "moment";
import {
  notifyFailure,
  notifySuccess,
} from "../../../components/toaster/toast";
import ReactSelect from "react-select";

const options = [
  {
    label: "Action",
    value: "Action",
  },
  {
    label: "Horror",
    value: "Horror",
  },
  {
    label: "Comedy",
    value: "Comedy",
  },
  {
    label: "Drama",
    value: "Drama",
  },
  {
    label: "Documentry",
    value: "Documentry",
  },
  {
    label: "Kids",
    value: "Kids",
  },
  {
    label: "Fiction",
    value: "Fiction",
  },
  {
    label: "Family",
    value: "Family",
  },
];
function AddMovie() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categories = useSelector((state) => state.categories);

  const [thumbnailPath, setThumbnailPath] = useState("");
  const [bgImagePath, setBgImagePath] = useState("");
  const [titleImagePath, setTitleImagePath] = useState("");
  const [sliderImagePath, setSliderImagePath] = useState("");
  const [movieUploadDetails, setMovieUploadDetails] = useState({ path: "" });
  const [clipUploadDetails, setClipMovieUploadDetails] = useState({ path: "" });
  const [movieDetails, setMovieDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const changeHandler = (e) => {
    setMovieDetails({
      ...movieDetails,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axiosPrivate.post("/movies", {
        ...movieDetails,
        thumbnailUrl: thumbnailPath,
        bgImageUrl: bgImagePath,
        titleImageUrl: titleImagePath,
        sliderImageUrl: sliderImagePath,
        videoUrl: movieUploadDetails.path,
        bgVideoUrl: clipUploadDetails.path,
        addedOn: moment().format("DD-MM-YYYY"),
        genre: movieDetails.genre.map((x) => x.value),
      });
      notifySuccess("Movie Added Successfully !");
      setLoading(false);
      navigate("/manage/movies");
    } catch (err) {
      notifyFailure(err.response.data.message);
      setLoading(false);
    }
  };

  const uploadImage = async (event, path, successHandler) => {
    const file = event.target.files[0];
    let fileName = file.name;
    if (fileName.includes(" ")) {
      fileName = fileName.replaceAll(" ", "-");
    }
    let percentage = undefined;
    const videoUploaderOptions = {
      fileName: `${path}/${fileName}`,
      file: file,
    };
    successHandler(`${path}/${fileName}`);
    // setThumbnailPath(`${path}/${file.name}`);
    const uploader = new Uploader(videoUploaderOptions);
    // setUploader(uploader);

    uploader
      .onProgress(({ percentage: newPercentage }) => {
        // to avoid the same percentage to be logged twice
        if (newPercentage !== percentage) {
          percentage = newPercentage;
          // setProgress(percentage);
        }
      })
      .onError((error) => {
        // setFile(undefined);
        console.error(error);
      });

    uploader.start();
  };

  const quillChange = (content, delta, source, editor) => {
    const text = editor.getText(content);

    let tempValue = content.replaceAll("<", "&lt;");
    tempValue = tempValue.replaceAll(">", "&rt;");
    setMovieDetails({
      ...movieDetails,
      description: tempValue,
      descriptionText: text,
    });
  };

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  console.log("data=>", movieDetails);

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right"></div>
              <h4 className="page-title">Add / Edit Movies</h4>
            </div>
          </div>
        </div>
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="text-uppercase bg-light p-2 mt-0 mb-3">
                    General
                  </h5>

                  <div className="mb-3">
                    <label htmlFor="product-name" className="form-label">
                      Movie Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="e.g : Apple iMac"
                      name="title"
                      onChange={changeHandler}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-reference" className="form-label">
                      Poster Image <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) =>
                        uploadImage(e, "movies/thumbnails", setThumbnailPath)
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-reference" className="form-label">
                      Background Image <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) =>
                        uploadImage(
                          e,
                          "movies/backgroundImages",
                          setBgImagePath
                        )
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-reference" className="form-label">
                      Title Image <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) =>
                        uploadImage(e, "movies/titleImages", setTitleImagePath)
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-reference" className="form-label">
                      Slider Image <span className="text-danger">*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept="image/*"
                      onChange={(e) =>
                        uploadImage(
                          e,
                          "movies/sliderImages",
                          setSliderImagePath
                        )
                      }
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-industry" className="form-label">
                      Industry <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="product-industry"
                      name="industry"
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      <option value="Hollywood">Hollywood</option>
                      <option value="Nollywood">Nollywood</option>
                      <option value="Bollywood">Bollywood</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="product-category" className="form-label">
                      Category <span className="text-danger">*</span>
                    </label>

                    <select
                      className="form-control form-select form-select-solid"
                      id="product-category"
                      name="category"
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      {categories.list.length > 0 &&
                        categories.list.map((category, index) => (
                          <option
                            key={category.id + index}
                            value={category.name}
                          >
                            {category.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="product-category" className="form-label">
                      Genre <span className="text-danger">*</span>
                    </label>
                    <ReactSelect
                      options={options}
                      isMulti
                      onChange={(e) =>
                        // console.log(e)
                        setMovieDetails({
                          ...movieDetails,
                          genre: e,
                        })
                      }
                    />

                    {/* <select
                      className="form-control form-select form-select-solid"
                      id="product-category"
                      name="category"
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      {categories.list.length > 0 &&
                        categories.list.map((category, index) => (
                          <option
                            key={category.id + index}
                            value={category.name}
                          >
                            {category.name}
                          </option>
                        ))}
                    </select> */}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-categoryq" className="form-label">
                      Choose quality <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control form-select form-select-solid"
                      id="product-categoryq"
                      name="quality"
                      onChange={changeHandler}
                    >
                      <option disabled="">Select</option>
                      <option value="FHD">FULL HD</option>
                      <option value="HD">HD</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="product-description" className="form-label">
                      Movie Description <span className="text-danger">*</span>
                    </label>
                    <ReactQuill theme="snow" onChange={quillChange} />
                  </div>

                  <div className="mb-3">
                    <label className="mb-2">
                      Status <span className="text-danger">*</span>
                    </label>
                    <br />
                    <div className="radio form-check-inline">
                      <input
                        type="radio"
                        value="Active"
                        name="status"
                        id="status-1"
                      />
                      <label htmlFor="status-1"> Active </label>
                    </div>
                    <div className="radio form-check-inline">
                      <input
                        type="radio"
                        value="Inactive"
                        name="status"
                        id="status-2"
                      />
                      <label htmlFor="status-2"> In-active </label>
                    </div>
                    <div className="radio form-check-inline">
                      <input
                        type="radio"
                        value="Draft"
                        name="status"
                        id="status-3"
                      />
                      <label htmlFor="status-3"> Draft </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <div className="mb-3">
                    <h5 className="text-uppercase mt-0 mb-3 bg-light p-2">
                      Duration / Year
                    </h5>

                    <div className="mb-3">
                      <label htmlFor="product-name" className="form-label">
                        Release Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        id="product-name"
                        className="form-control"
                        placeholder="e.g : Apple iMac"
                        name="releaseDate"
                        onChange={changeHandler}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="product-categoryq" className="form-label">
                        Choose Language <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control form-select form-select-solid"
                        id="product-categoryq"
                        name="language"
                        onChange={changeHandler}
                      >
                        <option selected disabled="">
                          Select
                        </option>
                        <option value="English">English</option>
                        <option value="Somali">Somali</option>
                        {/* <option value="">Spanish</option>
                        <option value="">French</option> */}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="product-name" className="form-label">
                        Duration <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="product-name"
                        className="form-control"
                        placeholder="2h 30m"
                        name="duration"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  <VideoUpload
                    setUploadDetails={setClipMovieUploadDetails}
                    directory="movies/shortClips"
                    title="Upload short video clip"
                  />
                  <VideoUpload
                    setUploadDetails={setMovieUploadDetails}
                    title="Upload Movie file"
                    directory="movies/videoFiles"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="text-center mb-3">
                <PrimaryButton>
                  {loading ? (
                    <ThreeDots color="#000000" height={20} width={50} />
                  ) : (
                    <>
                      <i className="fe-check-circle me-1"></i> Create
                    </>
                  )}{" "}
                </PrimaryButton>
                <SecondaryButton onClick={() => navigate("/manage/movies")}>
                  <i className="fe-x me-1"></i> Cancel
                </SecondaryButton>{" "}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddMovie;
