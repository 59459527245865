import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import CheckoutForm from "./checkoutForm";

function CustomStripe({
  setPaymentToken,
  changeHandler,
  paymentStatus,
  disableBack,
  disableChange,
  paymentDetails,
}) {
  const stripePromise = loadStripe(
    "pk_test_6Uso6hRlumZHpyzsgtODUsLk00mpHIFKqu"
  );

  return (
    <>
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            setPaymentToken={setPaymentToken}
            changeHandler={changeHandler}
            paymentStatus={paymentStatus}
            disableBack={disableBack}
            disableChange={disableChange}
            paymentDetails={paymentDetails}
          />
        </Elements>
      ) : (
        <></>
      )}
    </>
  );
}

export default CustomStripe;
