import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { BiCaretRight } from "react-icons/bi";

function LeftSidebar({ showSidebar, setShowSidebar }) {
  const state = useSelector((state) => state);
  const { auth, currRolePermissions } = state;
  const { userInfo } = auth;
  const { permission } = currRolePermissions;

  const [openDropdown, setOpenDropdown] = useState(false);

  // const logoutHandler = () => {
  //   dispatch(
  //     userLogout({
  //       refreshToken: tokens.refresh.token,
  //     })
  //   );
  // };

  return (
    <div
      className={`left-side-menu invisible-scroll ${showSidebar ? "show" : ""}`}
    >
      <div className="h-100 admin" data-simplebar>
        <div id="sidebar-menu" className="invisible-scroll">
          {permission && (
            <ul className="invisible-scroll">
              <li className="menu-title">Navigation</li>

              <li className="menuitem">
                <NavLink
                  to="/manage"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <FeatherIcon icon="airplay" />
                  {/* <span className="badge rounded-pill float-end">4</span> */}
                  <span> Dashboard </span>
                </NavLink>
              </li>

              <li className="menu-title mt-2">Manage</li>
              {/* <li className="menuitem">
                <NavLink
                  to="/manage/wallets"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <FeatherIcon icon="dollar-sign" />

                  <span> Wallets </span>
                </NavLink>
              </li>
              <li className="menuitem">
                <NavLink
                  to="/manage/transactions"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <FeatherIcon icon="dollar-sign" />

                  <span> Transactions </span>
                </NavLink>
              </li> */}
              {permission.permissions
                .filter((x) => x.enabled)
                .map((x, index) => (
                  <li key={index}>
                    <NavLink
                      to={`/manage/${x.type.toLowerCase()}`}
                      className={({ isActive }) =>
                        isActive ? "active" : undefined
                      }
                    >
                      <FeatherIcon icon={x.icon} />
                      <span> {x.type} </span>
                    </NavLink>
                  </li>
                ))}

              <li onClick={() => setOpenDropdown(!openDropdown)}>
                <NavLink
                  to="/manage/settings"
                  className={({ isActive }) =>
                    isActive ? "active" : undefined
                  }
                >
                  <FeatherIcon icon="settings" />
                  <span> Settings </span>
                  {userInfo.role === "admin" && (
                    <span
                      className={`drop-icon ${openDropdown ? "rotate-90" : ""}`}
                    >
                      <BiCaretRight />
                    </span>
                  )}
                </NavLink>
              </li>
              {userInfo.role === "admin" && (
                <div
                  className={`sidebar-menu dropdown-container p-2 ${
                    openDropdown ? "show" : ""
                  }`}
                >
                  <ul >
                    <li>
                      <NavLink
                        to="permissions"
                        className={({ isActive }) =>
                          isActive ? "active" : undefined
                        }
                      >
                        {/* <FeatherIcon icon="slash" /> */}
                        <span> Permissions </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}

              {/* {userInfo.role === "admin" && (
                <li>
                  <NavLink to="permissions">
                    <FeatherIcon icon="slash" />
                    <span> Permissions </span>
                  </NavLink>
                </li>
              )} */}

              {/* <li onClick={logoutHandler}>
                <NavLink to="">
                  <FeatherIcon icon="power" />
                  <span> Logout </span>
                </NavLink>
              </li> */}
            </ul>
          )}
        </div>

        <div className="clearfix"></div>
      </div>
    </div>
  );
}

export default LeftSidebar;
