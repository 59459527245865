import { notifyFailure, notifySuccess } from "../components/toaster/toast";
import {
  CATEGORY_ADD_FAIL,
  CATEGORY_ADD_REQUEST,
  CATEGORY_ADD_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  REMOVE_ALL_CATEGORIES_FAIL,
  REMOVE_ALL_CATEGORIES_REQUEST,
  REMOVE_ALL_CATEGORIES_SUCCESS,
  REMOVE_CATEGORY_FAIL,
  REMOVE_CATEGORY_REQUEST,
  REMOVE_CATEGORY_SUCCESS,
} from "../constants/categoryConstants";

import axiosPrivate from "../services/api";

export const getCategoriesAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORIES_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/categories`);

    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_CATEGORIES_FAIL,
      payload: err.response.data.message,
    });
  }
};

export const addCategoryAction =
  (categoryData, handleToast) => async (dispatch) => {
    dispatch({
      type: CATEGORY_ADD_REQUEST,
    });
    try {
      const { data } = await axiosPrivate.post(`/categories`, categoryData);
      handleToast();
      dispatch({
        type: CATEGORY_ADD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: CATEGORY_ADD_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteCategoryAction =
  (categoryId, setLoadingFlag) => async (dispatch) => {
    dispatch({
      type: REMOVE_CATEGORY_REQUEST,
    });
    setLoadingFlag(true);
    try {
      await axiosPrivate.delete(`/categories/${categoryId}`);
      setLoadingFlag(false);
      notifySuccess("Category Deleted Successfully !");
      dispatch({
        type: REMOVE_CATEGORY_SUCCESS,
        payload: categoryId,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: REMOVE_CATEGORY_FAIL,
        payload: err.response.data.message,
      });
    }
  };

export const deleteAllCategoriesAction =
  (userId, setLoadingFlag) => async (dispatch) => {
    dispatch({
      type: REMOVE_ALL_CATEGORIES_REQUEST,
    });
    setLoadingFlag(true);
    try {
      await axiosPrivate.delete(`/categories/user/${userId}`);
      setLoadingFlag(false);
      notifySuccess("All Categories Deleted Successfully !");

      dispatch({
        type: REMOVE_ALL_CATEGORIES_SUCCESS,
      });
    } catch (err) {
      notifyFailure(err.response.data.message);
      dispatch({
        type: REMOVE_ALL_CATEGORIES_FAIL,
        payload: err.response.data.message,
      });
    }
  };
