import React from "react";
import { GoMailRead } from "react-icons/go";
import { useSelector } from "react-redux";

function ModalContent({ email }) {
  // const auth = useSelector((state) => state.auth);
  // const { userInfo } = auth;

  return (
    <>
      <GoMailRead color="#a158a1" size={45} className="mx-1" />
      <h2>Success !</h2>
      <p>
        An email has been sent to <b>{email}</b>. Please check for an email from{" "}
        <b>Infisom</b> and click on the included button to confirm your
        account.
      </p>
    </>
  );
}

export default ModalContent;
