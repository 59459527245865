import {
  CREATE_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_USER_SUBSCRIPTION_FAIL,
  GET_USER_SUBSCRIPTION_REQUEST,
  GET_USER_SUBSCRIPTION_SUCCESS,
} from "../constants/subscriptionConstants";

export const subscriptionsReducer = (state = { list: [] }, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const currUserSubscriptionReducer = (
  state = { subscriptionChecked: false, data: null },
  action
) => {
  switch (action.type) {
    case GET_USER_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
        subscriptionChecked: false,
      };
    case GET_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionChecked: true,
        data: action.payload,
      };
    case GET_USER_SUBSCRIPTION_FAIL:
      return {
        ...state,
        loading: false,
        subscriptionChecked: true,
        error: action.payload,
      };

    // case CREATE_SUBSCRIPTION_SUCCESS:
    //   return {
    //     ...state,
    //     data: action.payload,
    //   };

    default:
      return state;
  }
};
