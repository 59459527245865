import {
  GET_STATS_FAIL,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
} from "../constants";

import axiosPrivate from "../services/api";

//GET Users via Admin
export const getStatsAction = () => async (dispatch, getState) => {
  dispatch({
    type: GET_STATS_REQUEST,
  });
  try {
    const { data } = await axiosPrivate.get(`/stats`);

    dispatch({
      type: GET_STATS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_STATS_FAIL,
      payload: err.response.data.message,
    });
  }
};
